import { NoopScrollStrategy } from '@angular/cdk/overlay';
import { Injectable } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { Language } from 'library-explorer';
import { LanguageSelectDialogComponent } from '@app/shared/components/language-select-dialog/language-select-dialog.component';
import { take } from 'rxjs/operators';
import { UserService } from './api/user.service';
import { BehaviorSubject, Observable } from 'rxjs';
import { ProfileService } from 'library-explorer';
import { LanguageService } from './language.service';

@Injectable({
  providedIn: 'root'
})
export class LanguageSelectDialogService {

  public languageSelectDialog: MatDialogRef<LanguageSelectDialogComponent>;
  public contentLanguage$: BehaviorSubject<string> = new BehaviorSubject(undefined);

  constructor(
    private readonly dialog: MatDialog,
    private readonly userService: UserService,
    private readonly profileService: ProfileService,
    private readonly languageService: LanguageService
  ) { }

  public open(element: HTMLElement, platformLanguages: Language[], contentLanguages: Language[]): void {
    if (this.languageSelectDialog) {
      this.close();
      return;
    }

    if (!this.contentLanguage$.value) {
      this.userService.getUserById(this.profileService.getCurrentProfileValue()?.id).subscribe(data => {
        this.contentLanguage$.next(data.contentLanguage || 'en');
      })
    }

    const isDesktop = window.innerWidth > 991;
    const isRtl = this.languageService.isRtl();
    const rect = element?.getBoundingClientRect();

    const top = `${rect?.bottom + 5}px`;
    const right = isRtl ? null : `${window.innerWidth - rect?.right}px`
    const left = isRtl ? `${rect?.left}px` : null;

    this.languageSelectDialog = this.dialog.open(LanguageSelectDialogComponent, {
      data: {platform: platformLanguages, content: contentLanguages},
      closeOnNavigation: true,
      hasBackdrop: !isDesktop,
      panelClass: ['language-select-panel', 'dialog-light-box-shadow'],
      position: isDesktop && {
        top,
        right,
        left
      },
      scrollStrategy: isDesktop ? new NoopScrollStrategy() : null,
    });

    this.languageSelectDialog.afterClosed().pipe(
      take(1)
    ).subscribe(() => {
      this.languageSelectDialog = null;
    });
  }

  public getContentLanguage(): Observable<string> {
    return this.contentLanguage$.asObservable();
  }

  public setContentLanguage(value: string): void{
    this.contentLanguage$.next(value);
  }

  public close(): void {
    if (!this.languageSelectDialog) {
      return;
    }

    this.languageSelectDialog.close();
    this.languageSelectDialog = null;
  }
}
