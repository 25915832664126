<div class="library-filters" [formGroup]="form">
  <ng-select 
    [ngClass]="cssClass"
    placeholder="{{'LIBRARY_PAGE.type' | translate }}"
    [searchable]="false" 
    [items]="typesOptions"
    bindValue="type"
    appendTo="lib-library-filters"
    formControlName="extensionType"
    data-cy="library-type-filter"
    (open)="handleOpen()">

    <ng-template ng-label-tmp let-item="item">
      <span>{{ item.label | translate }}</span>
    </ng-template>

    <ng-template ng-option-tmp let-item="item">
      <div class="library-type-select-option">
        <div class="option-icon">
          <lib-library-item-icon [size]="24" [extensionType]="item.type"></lib-library-item-icon>
        </div>
        <span>{{ item.label | translate }}</span>
      </div>
    </ng-template>


  </ng-select>

  <ng-select 
    [ngClass]="cssClass"
    placeholder="{{'LIBRARY_PAGE.modified' | translate | titlecase }}"
    [searchable]="false" 
    appendTo="lib-library-filters"
    formControlName="modified"
    data-cy="library-modified-filter"
    (open)="handleOpen()">
    <ng-option *ngFor="let item of modifiedOptions" [value]="item.value">
      {{ item.label | translate : item.params }}
    </ng-option>
  </ng-select>

  <lib-advanced-select
    *ngIf="tags?.length"
    formControlName="tags" 
    [cssClass]="cssClass"
    [disableAddNew]="true"
    [items]="tags"
    bindLabel="title"
    bindValue="id"
    [multiple]="true"
    appendTo="lib-library-filters"
    placeholder="{{ 'LIBRARY_PAGE.tags' | translate }}"
    data-cy="library-tags-filter">
  </lib-advanced-select>

  <mat-slide-toggle
    *ngIf="!hidePinned"
    class="pinned-toggle"
    formControlName="isPinned"
    color="primary"
    data-cy="library-pinned-filter">
    {{ 'LIBRARY.pinned' | translate }}
  </mat-slide-toggle>
</div>
