import { Component, Input, OnInit } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Component({
  selector: 'app-feature-highlight',
  templateUrl: './feature-highlight.component.html',
  styleUrls: ['./feature-highlight.component.scss']
})
export class FeatureHighlightComponent implements OnInit {
  @Input() block: any;

  public isFirstSlide: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(true);
  public isLastSlide: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  public showCarouselBtns = false;

  public carouselConfig = {
    lazyLoad: 'ondemand',
    infinite: false,
    slidesToShow: 4,
    slidesToScroll: 1,
    arrows: false,
    adaptiveHeight: true,
    touchMove: true,
    swipeToSlide: true,
    autoplay: false,
    centerMode: false,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
        }
      },
      {
        breakpoint: 640,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1
        }
      }
    ]
  };

  constructor() { }

  ngOnInit() {
  }

  public breakpoint(event) {
    this.showCarouselBtns = event.slick.slickGetOption('slidesToShow') < event.slick.slideCount;
  }

  public afterChange(event): void {
    setTimeout(() => {
      const slidesToShow = event.slick.slickGetOption('slidesToShow');
      const slideCount = event.slick.slideCount;
      const currentSlide = event.slick.currentSlide;

      this.showCarouselBtns = slidesToShow < slideCount;
      this.isFirstSlide.next(currentSlide === 0);
      this.isLastSlide.next(currentSlide + slidesToShow >= slideCount);
    });
  }

  public slickInit(event): void {
    this.afterChange(event);
  }

}
