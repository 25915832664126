<div class="wrapper" #targetTmp>
  <ng-container *ngTemplateOutlet="template"></ng-container>
</div>

<button mat-fab color="primary" class="primary-background fullscreen-button" (click)="fullScreenModelToggle()" *ngIf="showFullScreenBtn">
  <mat-icon svgIcon="lms-fullscreen"></mat-icon>
</button>

<button mat-fab color="primary" class="primary-background download-button" (click)="download()" *ngIf="showDownloadBtn" [class.no-transform]="!showFullScreenBtn">
  <mat-icon svgIcon="lms-download" *ngIf="!isDownloadLoading; else loadingSpinnerTmp"></mat-icon>

  <ng-template #loadingSpinnerTmp>
    <mat-spinner mode="indeterminate" class="secondary-progress-spinner-color" diameter="24"></mat-spinner> 
  </ng-template>
</button>

<ng-template #fullscreenTmp>
  <div id="iframe-fullscreen">
    <button mat-fab color="primary" class="primary-background fullscreen-button" (click)="exitFromFullScreenMode()">
      <mat-icon>fullscreen_exit</mat-icon>
    </button>

    <div class="fullscreen-mode">
      <div class="rotate-device">
        <mat-icon>screen_rotation</mat-icon>
      </div>
    </div>
  </div>
</ng-template>