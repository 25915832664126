import { Pipe, PipeTransform } from '@angular/core';
import * as moment from 'moment-timezone';

@Pipe({
  name: 'timezoneAbbreviation'
})
export class TimezoneAbbreviationPipe implements PipeTransform {

  transform(value: string): string {
    if (!value) {
      return;
    }

    return moment().tz(value).format('z') || value;
  }
}
