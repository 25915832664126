import { switchMap } from 'rxjs/operators';
import { Injectable } from "@angular/core";
import { StorageMap } from "@ngx-pwa/local-storage";
import { NetworkService } from "./network.service";
import { EncryptedOfflineModel } from '../models';
import { CryptoService } from "./crypto.service";
import { Observable, from, of } from "rxjs";

@Injectable({
  providedIn: 'root'
})
export class OfflineModeService {

  constructor(private storage: StorageMap, private networkService: NetworkService, private cryptoService: CryptoService) { }

  clearDb(): void {
    this.storage.clear().subscribe();
  }

  storeRequest<T>(path: string, item: T) {
    if (!path || !this.networkService.isOnlineValue()) return;
    return this.storage.set(path, item).subscribe();
  }

  getRequest(path: string) {
    if (!path) return;
    return this.storage.get(path);
  }

  storeEncryptedRequest<T>(path: string, item: T, secret: string) {
    if (!path || !this.networkService.isOnlineValue()) return;

    let key: CryptoKey;
    let encryptionIV: Uint8Array;

    return from(this.cryptoService.generateKeyFromString(secret)).pipe(
      switchMap(generatedKey => (key = generatedKey, from(this.cryptoService.generateRandomBytes(12)))),
      switchMap(generatedIV => (encryptionIV = generatedIV, from(this.cryptoService.encrypt(item, key, encryptionIV)))),
      switchMap(encryptedData => this.storage.set(path, { data: encryptedData, ivv: encryptionIV }))
    ).subscribe();
  }

  getEncryptedRequest(path: string, secret: string): Observable<any> {
    if (!path) return;
    return from(this.cryptoService.generateKeyFromString(secret)).pipe(
      switchMap(key => this.storage.get(path).pipe(
        switchMap((encryptedDataWithIv: EncryptedOfflineModel) =>
          from(this.cryptoService.decrypt(encryptedDataWithIv.data, key, encryptedDataWithIv.ivv))
        )
      ))
    );
  }

  removeRequest(path: string) {
    if (!path) return;
    return this.storage.delete(path).subscribe();
  }

  storeOrUpdateArr(key: string, item: any, filteringKeys?: string[]) {
    this.storage.get(key).subscribe((res: any[] | undefined) => {
      if (res) {
        if (this.doesRequestExist(res, item, filteringKeys || undefined)) { return }

        res.push(item);
        this.storage.set(key, res).subscribe();
      } else {
        this.storage.set(key, [item]).subscribe();
      }
    });
  }

  storeOrUpdateEncryptedArr(path: string, item: any, secret: string, filteringKeys?: string[]) {
    let key: CryptoKey;
    let encryptionIV: Uint8Array;

    from(this.cryptoService.generateKeyFromString(secret)).pipe(
      switchMap(generatedKey => {
        key = generatedKey;
        return this.storage.get(path).pipe(
          switchMap((encryptedDataWithIv: EncryptedOfflineModel) =>
            encryptedDataWithIv
              ? from(this.cryptoService.decrypt(encryptedDataWithIv.data, key, encryptedDataWithIv.ivv))
              : of(undefined)
          ),
          switchMap((decryptedData: any) => {
            if (decryptedData && !this.doesRequestExist(decryptedData, item, filteringKeys)) {
              decryptedData.push(item);
            } else {
              decryptedData = [item];
            }

            return from(this.cryptoService.generateRandomBytes(12)).pipe(
              switchMap(generatedIV => {
                encryptionIV = generatedIV;
                return from(this.cryptoService.encrypt(decryptedData, key, encryptionIV));
              }),
              switchMap(encryptedData => this.storage.set(path, { data: encryptedData, ivv: encryptionIV }))
            );
          })
        );
      })
    ).subscribe();
  }

  removeAndUpdateArr(path: string, item: any, filteringKey?: string) {
    this.storage.get(path).subscribe((res: any[] | undefined) => {
      if (res && this.doesRequestExist(res, item)) {
        res = (typeof item === 'object' && filteringKey)
          ? res.filter(existingItem => existingItem[filteringKey] !== item[filteringKey])
          : res.filter(existingItem => existingItem !== item);

        res.length === 0 ? this.storage.delete(path).subscribe() : this.storage.set(path, res).subscribe();
      }
    });
  }

  removeAndUpdateEncryptedArr(path: string, item: any, secret: string, filteringKey?: string) {
    let key: CryptoKey;
    let encryptionIV: Uint8Array;

    from(this.cryptoService.generateKeyFromString(secret)).pipe(
      switchMap(generatedKey => {
        key = generatedKey;
        return this.storage.get(path).pipe(
          switchMap((encryptedDataWithIv: EncryptedOfflineModel) =>
            encryptedDataWithIv
              ? from(this.cryptoService.decrypt(encryptedDataWithIv.data, key, encryptedDataWithIv.ivv))
              : of(undefined)
          ),
          switchMap((decryptedData: any) => {
            if (decryptedData && this.doesRequestExist(decryptedData, item)) {
              decryptedData = (typeof item === 'object' && filteringKey)
                ? decryptedData.filter(existingItem => existingItem[filteringKey] !== item[filteringKey])
                : decryptedData.filter(existingItem => existingItem !== item);

              if (decryptedData.length === 0) {
                return this.storage.delete(path);
              } else {
                return from(this.cryptoService.generateRandomBytes(12)).pipe(
                  switchMap(generatedIV => {
                    encryptionIV = generatedIV;
                    return from(this.cryptoService.encrypt(decryptedData, key, encryptionIV));
                  }),
                  switchMap(encryptedData => this.storage.set(path, { data: encryptedData, ivv: encryptionIV }))
                );
              }
            } else {
              return of(undefined);
            }
          })
        );
      })
    ).subscribe();
  }

  doesRequestExist(myArray: any[], myItemToCheck: any, keys: string[] = ['path', 'type']): boolean {
    return myArray.some(item => {
      if (typeof myItemToCheck === 'object') {
        return keys.every(key => item[key] === myItemToCheck[key]);
      } else if (typeof myItemToCheck === 'string') {
        return item === myItemToCheck;
      }
      return false;
    });
  }

  storeOrUpdatePendingRequestArr(key: string, item: any) {
    this.storage.get(key).subscribe((res: any[] | undefined) => {
      if (res) {
        if (this.doesPendingRequestExist(res, item)) { 
          return 
        }

        res.push(item);
        this.storage.set(key, res).subscribe();
      } else {
        this.storage.set(key, [item]).subscribe();
      }
    });
  }

  doesPendingRequestExist(myArray: any[], myItemToCheck: any): boolean {
    const isEqual = (obj1: any, obj2: any): boolean => {
      return JSON.stringify(obj1) === JSON.stringify(obj2);
    };

    return myArray.some(item => {
      return item.url === myItemToCheck.url && isEqual(this.sortPendingRequestObjectKeys(item.body), this.sortPendingRequestObjectKeys(myItemToCheck.body));
    });
  }

  sortPendingRequestObjectKeys(obj: { [key: string]: any }): { [key: string]: any } {
    return Object.entries(obj)
      .sort(([key1], [key2]) => key1.localeCompare(key2))
      .reduce((sortedObject, [key, value]) => {
        sortedObject[key] = value;
        return sortedObject;
      }, {});
  }

  removeAndUpdatePendingArr(key: string, item: any) {
    this.storage.get(key).subscribe((res: any[] | undefined) => {
      if (res && this.doesPendingRequestExist(res, item)) {
        res = res.filter(existingItem => existingItem?.tId !== item?.tId);

        res.length === 0 ? this.storage.delete(key).subscribe() : this.storage.set(key, res).subscribe();
      }
    });
  }
}
