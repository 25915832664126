export enum SlideType {
  SlideText = 'slide_text',
  SlideImage = 'slide_image',
  SlideTextImage = 'slide_text_image',
  SlideTextVideo = 'slide_text_video',
  SlideTextAudio = 'slide_text_audio',
  Hotspots = 'hotspots',
  SlideTextFlipcards = 'slide_text_flipcards',
  SlideAccordion = 'slide_accordion',
  SlideBuckets = 'slide_buckets',
  SlideScormPackage = 'slide_scorm_package',
  SlideEmbeddedDocument = 'slide_embedded_document',
  SlideWebinar = 'slide_webinar',
  SlideBuilder = 'slide_builder',
  SlideDocuments = 'slide_documents',
  SlideSpacer = 'slide_spacer',
  SlideInteractiveVideo = 'slide_interactive_video'
}
