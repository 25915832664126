<div class="leaderboard-points-popup">
  <button mat-mini-fab class="leaderboard-points-popup__close" mat-dialog-close>
    <mat-icon>close</mat-icon>
  </button>
  <div class="leaderboard-points-popup__content">
    <h4 class="leaderboard-points-popup__label">{{'LEADERBOARD.congratulations' | translate }}</h4>
    <div class="leaderboard-points-popup__image">
      <img class="leaderboard-points-popup__image--bg" src="/assets/images/leaderboard-icon-bg.svg" alt="">
      <img class="leaderboard-points-popup__image--up" src="/assets/images/leaderboard-up.svg" alt="">
      <img class="leaderboard-points-popup__image--user" src="/assets/images/user-icon.svg" alt="">
    </div>
    <h2 class="leaderboard-points-popup__title" *ngIf="notification.earnedPoints">
      {{'LEADERBOARD.you_just_earned' | translate }}
      <span class="leaderboard-points-popup__highlight">{{'LEADERBOARD.points_count' | translate : {count: notification.earnedPoints || 0 } }}</span> 
      <br> {{'LEADERBOARD.on_the_leaderboard' | translate}}!
    </h2>
    <span class="leaderboard-points-popup__position" *ngIf="notification.lastPosition">
      <ng-container *ngIf="notification.currentPosition < notification.lastPosition; else positionMsgTmp">
        {{'LEADERBOARD.leveled_up_from_to' | translate : { from: notification.lastPosition, to: notification.currentPosition } }}
      </ng-container>

      <ng-template #positionMsgTmp>
        {{ 'LEADERBOARD.you_are_now_on_position' | translate : { position: notification.currentPosition } }}
      </ng-template>

      <br>
      {{'LEADERBOARD.keep_on_going' | translate }}
    </span>
  </div>
  <div class="leaderboard-points-popup__footer">
    <button mat-flat-button [disableRipple]="true" class="leaderboard-points-popup__btn button" routerLink="/leaderboard" mat-dialog-close>
      {{'LEADERBOARD.see_leaderboard' | translate }}
      <mat-icon class="leaderboard-points-popup__btn--icon" svgIcon="lms-arrow-right"></mat-icon>
    </button>
    <button mat-flat-button [disableRipple]="true" mat-dialog-close class="leaderboard-points-popup__btn button button--primary" cdkFocusInitial>
      {{'COMMON.close' | translate | titlecase }}
    </button>
  </div>
</div>
