<div *ngIf="languages?.length > 1" class="language-switcher">
  <button
    mat-button
    class="button button--icon button--transparent primary-menu-button"
    #languageSelectButton
    (click)="openLanguageSwitcher(languageSelectButton)">

    <lib-icon icon="globe"></lib-icon>
    <ng-container *ngIf="currentLanguage && showSelected">
      <div class="selected-language">{{languagesMap[currentLanguage]?.name}}</div>
      <lib-icon class="select-language-icon" icon="caret-down" [size]="16" [bold]="true"></lib-icon>
    </ng-container>
  </button>
  <div class="language-menu-trigger" #languageMenuTrigger="matMenuTrigger" [matMenuTriggerFor]="languageMenu"></div>
</div>
<mat-menu #languageMenu="matMenu" class="language-menu primary-menu">
  <button 
    mat-menu-item 
    *ngFor="let language of languages"
    class="primary-menu-button primary-button-dark-hover mat-menu-item"
    [ngClass]="{'primary-button-dark': language.code === currentLanguage}"
    (click)="changeCurrentLanguage(language.code)">
    <span class="align-middle">{{language.name}}</span>
  </button>
</mat-menu>
