import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { CalendarEvent } from '@app/model/calendar-event';
import { ExportCalendarDialogComponent } from '@app/shared/components/export-calendar-dialog/export-calendar-dialog.component';

@Injectable({
  providedIn: 'root'
})
export class CalendarExportService {
  constructor(private readonly dialog: MatDialog) { }

  public openCalendarExport(event: CalendarEvent): void {
    if (!event) {
      return;
    }
  
    this.dialog.open(ExportCalendarDialogComponent, {
      panelClass: ['mobile-full-width-dialog'],
      maxWidth: '90vw',
      data: event
    });
  }

}
