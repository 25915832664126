import { Component, OnInit } from '@angular/core';
import { LanguageService } from 'src/app/services/language.service';
import { sessionKeys } from 'src/app/shared/constants/translation-helper.const';

@Component({
  selector: 'app-translation-key-toggle',
  templateUrl: './translation-key-toggle.component.html',
  styleUrls: ['./translation-key-toggle.component.scss']
})
export class TranslationKeyToggleComponent implements OnInit {

  public status = false;

  constructor(
    private languageService: LanguageService
  ) { }

  ngOnInit() {
    this.status = sessionStorage.getItem(sessionKeys.active) === 'true'
  }

  public toggelShowTranslationKeys(): void {
    this.languageService.toggelShowTranslationKeys();
  }

}
