import { DragDropModule } from '@angular/cdk/drag-drop';
import { CloseScrollStrategy, Overlay, OverlayModule, RepositionScrollStrategy } from '@angular/cdk/overlay';
import { CommonModule, DatePipe } from '@angular/common';
import { CUSTOM_ELEMENTS_SCHEMA, LOCALE_ID, NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { RouterModule } from '@angular/router';
import { YouTubePlayerModule } from '@angular/youtube-player';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { CodeInputModule } from 'angular-code-input';
import { AngularSvgIconModule } from 'angular-svg-icon';
import { ClickOutsideModule } from 'ng-click-outside';
import { DisqusModule } from 'ngx-disqus';
import { EllipsisModule } from 'ngx-ellipsis';
import { NgScrollbarModule, NG_SCROLLBAR_OPTIONS } from 'ngx-scrollbar';
import { SlickCarouselModule } from 'ngx-slick-carousel';

import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatDialogModule } from '@angular/material/dialog';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatListModule } from '@angular/material/list';
import { MatMenuModule } from '@angular/material/menu';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatRadioModule } from '@angular/material/radio';
import { MatSelectModule, MAT_SELECT_SCROLL_STRATEGY } from '@angular/material/select';
import { MatSliderModule } from '@angular/material/slider';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatTabsModule } from '@angular/material/tabs';
import { PopoverModule } from 'ngx-smart-popover';
import { BaseWarningModalComponent } from '../components/base-warning-modal/base-warning-modal.component';
import { CardLabelComponent } from '../components/commons/card-label/card-label.component';
import { NewitemComponent } from '../components/commons/newitem/newitem.component';
import { ProgressComponent } from '../components/commons/progress/progress.component';
import { SearchDialogComponent } from '../components/commons/search-dialog/search-dialog.component';
import { DiscussChatDialogComponent } from '../components/discuss-chat-dialog/discuss-chat-dialog.component';
import { DisableCopyPasteDirective } from '../directives/disable-copy-paste.directive';
import { ExtendedRouterLinkDirective } from '../directives/extended-router-link.directive';
import { HighlightDirective } from '../directives/hightlight.directive';
import { TripleClickDirective } from '../directives/triple-click.directive';
import { DisableControlDirective } from '../model/directives/disable-control.directive';
import { InputDebounceDirective } from '../model/directives/input-debounce.directive';
import { FilterArrayPipe } from '../model/pipes/filter-array.pipe';
import { AchievementModalComponent } from './components/achievement-modal/achievement-modal.component';
import { AudioPlayerComponent } from './components/audio-player/audio-player.component';
import { BranchSelectDialogComponent } from './components/branch-select-dialog/branch-select-dialog.component';
import { ContentGroupComponent } from './components/content-group/content-group.component';
import { DeleteProfileModalComponent } from './components/delete-profile-modal/delete-profile-modal.component';
import { DownloadingOfflineModalComponent } from './components/downloading-offline-modal/downloading-offline-modal.component';
import { EntityCardComponent } from './components/entity-card/entity-card.component';
import { FullscreenComponent } from './components/fullscreen/fullscreen.component';
import { GoogleModelViewerComponent } from './components/google-model-viewer/google-model-viewer.component';
import { LanguageSwitcherComponent } from './components/language-switcher/language-switcher.component';
import { LeaderboardPointsPopupComponent } from './components/leaderboard-points-popup/leaderboard-points-popup.component';
import { LessonFeedbackModalComponent } from './components/lesson-feedback-modal/lesson-feedback-modal.component';
import { LoadMoreBtnComponent } from './components/load-more-btn/load-more-btn.component';
import { LoadingButtonComponent } from './components/loading-button/loading-button.component';
import { NotificationListDialogComponent } from './components/notification-list-dialog/notification-list-dialog.component';
import { NotificationListComponent } from './components/notification-list/notification-list.component';
import { NotificationPopupComponent } from './components/notification-popup/notification-popup.component';
import { PasswordInputComponent } from './components/password-input/password-input.component';
import { ZoomMeetingDialogComponent } from './components/zoom-meeting-dialog/zoom-meeting-dialog.component';
import { DateDiffPipe } from './pipes/date-diff.pipe';
import { GlossaryTooltipPipe } from './pipes/glossary-tooltip.pipe';
import { LangFlagPipe } from './pipes/lang-flag.pipe';
import { MinutesToTimePipe } from './pipes/minutes-to-time.pipe';
import { NumberToLetterPipe } from './pipes/number-to-letter.pipe';
import { SecondsToMinutesPipe } from './pipes/seconds-to-minutes.pipe';
import { TimezoneAbbreviationPipe } from './pipes/timezone-abbreviation.pipe';
import { TrustUrlPipe } from './pipes/trust-url.pipe';
import { UserNamePipe } from './pipes/user-name.pipe';

import { DateAdapter, MAT_DATE_LOCALE } from '@angular/material/core';
import { DisableOnOfflineDirective } from '@app/directives/disable-on-offline.directive';
import { ExtendedRouterLinkActiveDirective } from '@app/directives/extended-router-link-active.directive';
import { ModalImageDirective } from '@app/directives/modal-image.directive';
import { NgIfOfflineDirective } from '@app/directives/ng-if-offline.directive';
import { PanzoomDirective } from '@app/directives/panzoom.directive';
import { ProportionalScaleDirective } from '@app/directives/proportional-scale.directive';
import { SlickCarouselResizeDirective } from '@app/directives/slick-carousel-resize.directive';
import { WatermarkDirective } from '@app/directives/watermark.directive';
import { NgSelectModule } from '@ng-select/ng-select';
import { CustomDatePipe, regexList, REGEX_LIST, SharedLibraryModule, AudioPlayerV2Module } from 'library-explorer';
import { NgxPaginationModule } from 'ngx-pagination';
import { EntityPlaceholderDirective } from '../directives/entity-placeholder.directive';
import { NgIfMediaQuery } from '../directives/ng-if-media-query.directive';
import { DownloadProgressButtonComponent } from './components/download-progress-button/download-progress-button.component';
import { EntityCardFeatureIconsComponent } from './components/entity-card-feature-icons/entity-card-feature-icons.component';
import { EntityCardRowChildComponent } from './components/entity-card-row-child/entity-card-row-child.component';
import { EntityCardStatusIconsComponent } from './components/entity-card-status-icons/entity-card-status-icons.component';
import { EntityProgressSpinnerComponent } from './components/entity-progress-spinner/entity-progress-spinner.component';
import { FullscreenProtectionOverlayComponent } from './components/fullscreen-protection-overlay/fullscreen-protection-overlay.component';
import { IconButtonComponent } from './components/icon-button/icon-button.component';
import { LanguageSelectDialogComponent } from './components/language-select-dialog/language-select-dialog.component';
import { ListLayoutSwitcherComponent } from './components/list-layout-switcher/list-layout-switcher.component';
import { ModalImageDialogComponent } from './components/modal-image-dialog/modal-image-dialog.component';
import { OptionToggleSwitchComponent } from './components/option-toggle-switch/option-toggle-switch.component';
import { SidebarMenuComponent } from './components/sidebar-menu/sidebar-menu.component';
import { SvgIconComponent } from './components/svg-icon/svg-icon.component';
import { WarningModalComponent } from './components/warning-modal/warning-modal.component';
import { EventCardComponent } from './components/event-card/event-card.component';
import { DateWithoutTimezonePipe } from './pipes/date-without-timezone.pipe';
import { DurationPipe } from './pipes/duration.pipe';
import { OfflineActivityImagePipe } from './pipes/offline-activity-image.pipe';
import { SettingsMediaPresignedUrl } from './pipes/settings-media-presigned-url.pipe';
import { ListEmptyStateComponent } from './components/list-empty-state/list-empty-state.component';
import { ExportCalendarDialogComponent } from './components/export-calendar-dialog/export-calendar-dialog.component';
import { ExportCalendarButtonDirective } from '@app/directives/export-calendar-button.directive';
import { AdaptiveFontSizePipe } from './pipes/adaptive-font-size.pipe';

import { CardsListPlaceholderComponent } from './components/cards-list-placeholder/cards-list-placeholder.component';
import { CustomDateAdapter } from '@app/services/custom-date-adapter.service';
import { LiveUpdateModalComponent } from '@app/components/live-update-modal/live-update-modal.component';
import { UserAvatarComponent } from './components/user-avatar/user-avatar.component';
import { DateLabelChipComponent } from './components/date-label-chip/date-label-chip.component';
import { DateToTimezonePipe } from './pipes/date-timezone.pipe';
import { CourseDetailsComponent } from './components/course-details/course-details.component';
import { CourseDetailsDialogComponent } from './components/course-details-dialog/course-details-dialog.component';
import { ContentCardComponent } from './components/content-card/content-card.component';
import { ModuleContentExpansionPanelComponent } from './components/module-content-expansion-panel/module-content-expansion-panel.component';
import { EntityBundleIconPipe } from './pipes/entity-bundle-icon.pipe';
import { StepsProgressComponent } from './components/steps-progress/steps-progress.component';
import { NativeShowBiometricLoginDirective } from '@app/directives/native-biometric-login.directive';
import { BiometricAuthModalComponent } from '@app/components/biometric-auth-modal/biometric-auth-modal.component';
import { BiometricIconPipe } from './pipes/biometric-icon.pipe';
import { QuickSearchInputComponent } from './components/quick-search-input/quick-search-input.component';

const COMPONENTS = [
  LoadMoreBtnComponent,
  NewitemComponent,
  ProgressComponent,
  CardLabelComponent,
  DiscussChatDialogComponent,
  BaseWarningModalComponent,
  LiveUpdateModalComponent,
  BiometricAuthModalComponent,
  AchievementModalComponent,
  SearchDialogComponent,
  LoadMoreBtnComponent,
  LanguageSwitcherComponent,
  FullscreenComponent,
  LoadMoreBtnComponent,
  LanguageSwitcherComponent,
  LoadingButtonComponent,
  EntityCardComponent,
  DeleteProfileModalComponent,
  AudioPlayerComponent,
  NotificationListDialogComponent,
  NotificationListComponent,
  NotificationPopupComponent,
  PasswordInputComponent,
  BranchSelectDialogComponent,
  PasswordInputComponent,
  LeaderboardPointsPopupComponent,
  DownloadingOfflineModalComponent,
  ZoomMeetingDialogComponent,
  ContentGroupComponent,
  EntityCardFeatureIconsComponent,
  EntityCardStatusIconsComponent,
  EntityCardRowChildComponent,
  GoogleModelViewerComponent,
  WarningModalComponent,
  LessonFeedbackModalComponent,
  ListLayoutSwitcherComponent,
  FullscreenProtectionOverlayComponent,
  EventCardComponent,
  ModalImageDialogComponent,
  LanguageSelectDialogComponent,
  OptionToggleSwitchComponent,
  SidebarMenuComponent,
  IconButtonComponent,
  SvgIconComponent,
  EntityProgressSpinnerComponent,
  DownloadProgressButtonComponent,
  SvgIconComponent,
  ListEmptyStateComponent,
  ExportCalendarDialogComponent,
  CardsListPlaceholderComponent,
  UserAvatarComponent,
  DateLabelChipComponent,
  CourseDetailsComponent,
  CourseDetailsDialogComponent,
  ContentCardComponent,
  ModuleContentExpansionPanelComponent,
  StepsProgressComponent,
  QuickSearchInputComponent
];

const DIRECTIVES = [
  HighlightDirective,
  InputDebounceDirective,
  DisableControlDirective,
  ExtendedRouterLinkDirective,
  DisableCopyPasteDirective,
  TripleClickDirective,
  NgIfMediaQuery,
  PanzoomDirective,
  ModalImageDirective,
  OptionToggleSwitchComponent,
  WatermarkDirective,
  SlickCarouselResizeDirective,
  ExtendedRouterLinkActiveDirective,
  ProportionalScaleDirective,
  NgIfOfflineDirective,
  DisableOnOfflineDirective,
  NativeShowBiometricLoginDirective,
  ExtendedRouterLinkActiveDirective
];

const PIPES = [
  FilterArrayPipe,
  SecondsToMinutesPipe,
  TrustUrlPipe,
  LangFlagPipe,
  UserNamePipe,
  NumberToLetterPipe,
  DateDiffPipe,
  MinutesToTimePipe,
  DateWithoutTimezonePipe,
  GlossaryTooltipPipe,
  TimezoneAbbreviationPipe,
  SettingsMediaPresignedUrl,
  OfflineActivityImagePipe,
  BiometricIconPipe,
  DurationPipe,
  SettingsMediaPresignedUrl,
  EntityPlaceholderDirective,
  ExportCalendarButtonDirective,
  AdaptiveFontSizePipe,
  DateToTimezonePipe,
  EntityBundleIconPipe
];

const ANGULAR_MODULES = [
  CommonModule,
  FormsModule,
  ReactiveFormsModule,
  RouterModule
];

const OTHER_MODULES = [
  MatProgressSpinnerModule,
  MatProgressBarModule,
  MatIconModule,
  MatCardModule,
  MatButtonModule,
  MatInputModule,
  MatFormFieldModule,
  MatCheckboxModule,
  MatToolbarModule,
  MatSelectModule,
  MatMenuModule,
  MatListModule,
  MatDialogModule,
  MatExpansionModule,
  MatTooltipModule,
  MatRadioModule,
  MatSliderModule,
  DragDropModule,
  NgScrollbarModule,
  SlickCarouselModule,
  AngularSvgIconModule,
  DisqusModule,
  ClickOutsideModule,
  EllipsisModule,
  YouTubePlayerModule,
  PopoverModule,
  OverlayModule,
  NgxPaginationModule,
  CodeInputModule,
  NgSelectModule,
  SharedLibraryModule,
  TranslateModule,
  AudioPlayerV2Module,
  MatTabsModule
];

export function scrollFactory(overlay: Overlay): () => CloseScrollStrategy | RepositionScrollStrategy {
  return () => {
    const isPhone = window.matchMedia('(max-width: 768px)').matches;

    return isPhone ? overlay.scrollStrategies.close() : overlay.scrollStrategies.reposition();
  };
}

const PROVIDERS = [
  {
    provide: NG_SCROLLBAR_OPTIONS,
    useValue: {
      scrollAuditTime: 20
    }
  },
  DatePipe,
  CustomDatePipe,
  { provide: REGEX_LIST, useValue: regexList },
  { provide: MAT_SELECT_SCROLL_STRATEGY, useFactory: scrollFactory, deps: [Overlay] },
  {
    provide: LOCALE_ID,
    deps: [TranslateService],
    useFactory: (translateService: TranslateService) => translateService.currentLang || translateService.defaultLang
  },
  {
    provide: MAT_DATE_LOCALE,
    deps: [TranslateService],
    useFactory: (translateService: TranslateService) => translateService.currentLang || translateService.defaultLang
  },
  { 
    provide: DateAdapter,
    useClass: CustomDateAdapter
  }
];

@NgModule({
  declarations: [
    ...COMPONENTS,
    ...DIRECTIVES,
    ...PIPES
  ],
  imports: [
    ...ANGULAR_MODULES,
    ...OTHER_MODULES
  ],
  exports: [
    ...ANGULAR_MODULES,
    ...OTHER_MODULES,
    ...COMPONENTS,
    ...DIRECTIVES,
    ...PIPES
  ],
  providers: [
    ...PROVIDERS
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA]
})
export class SharedModule {
}
