import { Injectable } from '@angular/core';
import { HttpService } from 'library-explorer';
import { LearningPathModel } from '@app/model/learning-path.model';
import { Observable } from 'rxjs';
import { HttpParams } from '@angular/common/http';
import { map } from 'rxjs/operators';
import { DataResponse } from '@app/model/data-response.model';
import { LessonDTO } from '@app/model/lessonsDTO.model';

@Injectable({
  providedIn: 'root'
})
export class LearningPathService {

  constructor(
    private httpService: HttpService,
  ) { }

  public getLearningPathsForUser(id: string): Observable<DataResponse<LearningPathModel>> {
    return this.httpService.get<DataResponse<LearningPathModel>>(`learning-paths/user/${id}`);
  }

  public getLearningPath(id: string): Observable<LearningPathModel> {
    const params = new HttpParams().set('id', id);
    return this.httpService.get<LearningPathModel>('node', params).pipe(
      map(this.processLearningPath.bind(this))
    );
  }

  public getNextLesson(learningPathId: string): Observable<Partial<LessonDTO>> {
    return this.httpService.get<Partial<LessonDTO>>(`learning-path/${learningPathId}/next-in-progress`);
  }

  public processLearningPath(data: LearningPathModel): LearningPathModel {
    if (data.duration && !data.deadline && data.startAt) {
      const durationMs = data.duration * 1000 * 60 * 60 * 24;
      const deadlineMs = new Date(data.startAt).getTime() + durationMs;
      data.deadline = new Date(deadlineMs).toJSON();
    }

    if (data.deadline) {
      const deadlineMs = new Date(data.deadline).getTime();
      const dateInMs = (data.dateOfCompletion ? new Date(data.dateOfCompletion) : new Date()).getTime();
      const remainingMs = deadlineMs - new Date().getTime();
      data.overdue = deadlineMs < dateInMs;
      data.remainingMinutes = remainingMs > 0 ? Math.round(remainingMs / 1000 / 60) : 0; 
    }

    return data;
  }
}
