<mat-expansion-panel class="content-group" (opened)="groupOpened()" (closed)="groupClosed()">
  <mat-expansion-panel-header class="primary-background-expansion-panel content-group__header" [collapsedHeight]="'90px'" [expandedHeight]="'90px'">
    <div class="content-group__header-content">
      <div class="d-flex align-items-center justify-content-center">
        <h3 class="content-group__title">{{ group?.name }}</h3>
      </div>
      <div class="content-group__info">
        <div class="content-group__completed" *ngIf="group.isCompleted && (group.lessonsCount || group.quizzesCount)">
          <mat-icon>done</mat-icon>
        </div>

        <div class="content-group__count">
          <span class="title">{{'COMMON.lessons' | translate }}</span>
          <span class="value"> {{ group.lessonsCount + group.quizzesCount }} </span>
        </div>
      </div>
    </div>

  </mat-expansion-panel-header>

  <ng-container *ngIf="initialized">
    <div class="content-group__items-wrapper row mb-5">
      <ng-container *ngIf="items.length; else emptyTmp">
        <div 
          class="col-12 py-2 align-self-stretch content-group__item" 
          [ngClass]="{ 'col-sm-6 col-lg-4': layout === layouts.GRID, 'layout-row': layout === layouts.ROW }"
          *ngFor="let item of items">
            <ng-container *ngTemplateOutlet="template; context: { item: item }"></ng-container>
          </div>
      </ng-container>
    </div>
  </ng-container>
  <div *ngIf="isLoading" class="d-flex align-items-center justify-content-center m-4">
    <mat-spinner color="primary" [diameter]="40"></mat-spinner>
  </div>
</mat-expansion-panel>

<ng-template #emptyTmp>
  <div class="col-12">
    <h3 class="content-group__empty">{{'COMMON.ther_are_no' | translate}} {{label}} {{'COMMON.yet' | translate}}.</h3>
  </div>
</ng-template>
