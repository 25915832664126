import { Component, Input } from '@angular/core';
import * as moment from 'moment-timezone';

@Component({
  selector: 'app-date-label-chip',
  templateUrl: './date-label-chip.component.html',
  styleUrls: ['./date-label-chip.component.scss']
})
export class DateLabelChipComponent {
  @Input() public set date(value: Date) {
    if (!value) {
      return;
    }

    this._date = value;
    this.setLabel();
  }

  public get date() {
    return this._date;
  }

  @Input() public set timezone(value: string) {
    if (!value) {
      return;
    }

    this._timezone = value;
    this.setLabel();
  }

  public get timezone() {
    return this._timezone;
  }

  public dateLabel: string;

  private _date: Date;
  private _timezone: string;

  private setLabel(): void {
    if (!this._date) {
      return;
    }
  
    const now = new Date().setHours(0, 0, 0, 0);
    const date = this.getDateBasedOnTimezone().setHours(0, 0, 0, 0);

    const diffTime = date - now;
    const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));

    switch (diffDays) {
      case 0:
        this.dateLabel = 'COMMON.today';
        break;
      case 1: {
        this.dateLabel = 'COMMON.tomorrow';
      }
    }
  }

  private getDateBasedOnTimezone(): Date {
    if (this._timezone) {
      return moment(this._date).utc(false).tz(this._timezone, true).toDate();
    }

    return new Date(this._date);
  }
}
