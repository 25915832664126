import { storageKeys } from 'library-explorer';
import { Injectable } from '@angular/core';
import { Device } from '@capacitor/device';
import { KeychainAccess, SecureStorage } from '@aparajita/capacitor-secure-storage'
import { Capacitor } from '@capacitor/core';

@Injectable({
  providedIn: 'root'
})
export class SecureStorageService {

  constructor() { }

  /**
   * Stores a value in secure storage.
   * @param key - The key under which the value should be stored.
   * @param item - The value to store.
   * @returns A promise that resolves to true if the value was successfully stored, false otherwise.
   */
  async storeValue<T>(key: string, item: T): Promise<boolean> {
    if (!this.isNativePlatformAndPluginAvailable()) {
      return false;
    }

    try {
      await SecureStorage.set(key, JSON.stringify(item), undefined, true, KeychainAccess.whenUnlockedThisDeviceOnly);
      return true;
    } catch (error) { return false; }
  }

  /**
   * Retrieves a value from secure storage.
   * @param key - The key of the value to retrieve.
   * @returns A promise that resolves to the value if found, or null if not found or an error occurs.
   */
  async getValue<T>(key: string): Promise<T | null> {
    if (!this.isNativePlatformAndPluginAvailable()) {
      return null;
    }

    try {
      const storedValue = await SecureStorage.get(key, undefined, true) as string;
      return storedValue ? JSON.parse(storedValue) as T : null;
    } catch (error) { return null; }
  }

  /**
 * Checks if a value exists in secure storage.
 * @param key - The key of the value to check.
 * @returns A promise that resolves to true if the value exists, otherwise false.
 */
  async hasValue(key: string): Promise<boolean> {
    if (!this.isNativePlatformAndPluginAvailable()) {
      return false;
    }

    try {
      const storedValue = await SecureStorage.get(key, undefined, true);
      return !!storedValue; // Returns true if the value exists, otherwise false.
    } catch (error) {
      return false;
    }
  }

  /**
   * Removes a value from SecureStorage and returns a boolean
   * @param {string} key - The key of the value to be removed from the secure storage.
   * @returns The `removeValue` function returns a Promise that resolves to a boolean value. If the
   * function is unable to remove the value for any reason, it will return `false`.
   */
  async removeValue(key: string): Promise<boolean> {
    if (!this.isNativePlatformAndPluginAvailable()) {
      return false;
    }

    try {
      return await SecureStorage.remove(key, true);
    } catch (error) { return false; }
  }

  /**
   * Gets the encryption secret key.
   * If not available, it generates a new one.
   * @returns {Promise<DataType>} The encryption secret.
   */
  async getEncryptionSecret(): Promise<string> {
    if (!this.isNativePlatformAndPluginAvailable()) {
      return;
    }

    let encryptionSecret = await SecureStorage.get(storageKeys.ENCRYPTION_SECRET) as string;
    if (!encryptionSecret) {
      encryptionSecret = await this.generateEncryptionSecret();
    }
    return encryptionSecret;
  }

  /**
   * Generates a new encryption secret and stores it.
   * @returns {Promise<string>} The newly generated encryption secret.
   */
  private async generateEncryptionSecret(): Promise<string> {
    const deviceId = (await Device.getId()).identifier;
    const timestamp = new Date().toISOString().replace(/\.|:|-/g, '');
    const encHash = `${deviceId}-${timestamp}`;
    await SecureStorage.set(storageKeys.ENCRYPTION_SECRET, encHash);
    return encHash;
  }

  private isNativePlatformAndPluginAvailable(): boolean {
    return Capacitor.isNativePlatform() && Capacitor.isPluginAvailable('SecureStorage');
  }
}
