import { DOCUMENT } from '@angular/common';
import { Inject, Injectable, Renderer2, RendererFactory2 } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class YoutubeService {

  private renderer: Renderer2;
  private addedScript = false;
  private youtubeApiReady: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

  constructor(
    @Inject(DOCUMENT) private document: Document,
    private rendererFactory: RendererFactory2
  ) {
    this.renderer = this.rendererFactory.createRenderer(null, null);
  }

  public isYoutubeApiReady(): Observable<boolean> {
    if (!this.addedScript && !this.youtubeApiReady.value) {
      this.addYoutubeApiScript();
    }

    return this.youtubeApiReady.asObservable();
  }

  private addYoutubeApiScript() {
    (window as any).onYouTubePlayerAPIReady = () => {
      this.youtubeApiReady.next(true);
    };

    const firstScriptTag = this.document.getElementsByTagName('script')[0];
    const tag = this.renderer.createElement('script');
    tag.id = 'youtube-embed-script';
    tag.src = 'https://www.youtube.com/iframe_api';
    this.renderer.insertBefore(firstScriptTag.parentNode, tag, firstScriptTag);
    this.addedScript = true;
  }
}
