<div class="lesson-feedback-modal">
  <button mat-mini-fab class="lesson-feedback-modal__close" mat-dialog-close>
    <mat-icon>close</mat-icon>
  </button>
  <div class="lesson-feedback-modal__content">
    <div *ngIf="feedbackSettings.text; else textFallbackTmp" class="lesson-feedback-modal__title" [innerHtml]="feedbackSettings.text | safeHtml"></div>

    <img *ngIf="feedbackSettings.banner; else imageFallbackTmp" class="lesson-feedback-modal__image" [cropImage]="feedbackSettings.banner | preSignedUrl | async">

    <div class="lesson-feedback-modal__rating">
      <ng-container *ngIf="feedbackSettings.ratingEnabled">
        <label class="lesson-feedback-modal__rating--label">
          {{'LESSON_FEEDBACK.give_us_your_feedback' | translate }}
        </label>
  
        <div class="lesson-feedback-modal__rating--control">
          <div 
            class="lesson-feedback-modal__rating--star" 
            *ngFor="let item of ratings" (click)="setRating(item)" 
            [class.active]="form.get('rate').value >= item">
            <img src="/assets/images/star.svg" alt="">
          </div>
        </div>
      </ng-container>

      <ng-container *ngIf="feedbackSettings.fields?.length">
        <button *ngIf="!formVisible" mat-flat-button class="primary-button mt-5" (click)="showForm()">{{feedbackSettings.showFormButtonLabel}}</button>
  
        <div *ngIf="formVisible" class="lesson-feedback-modal__feedback" [formGroup]="form">
          <div *ngFor="let field of feedbackSettings.fields" class="lesson-feedback-modal__feedback--field-wrapper">
            <div class="lesson-feedback-modal__feedback--label">{{field.displayName}}</div>
            <ng-container [ngSwitch]="field.type">
              <mat-form-field *ngSwitchCase="fieldTypes.FORMATTED_TEXT" class="lesson-feedback-modal__feedback--field form-field-textarea" appearance="outline">
                <textarea 
                  class="lesson-feedback-modal__feedback--textarea"
                  matInput
                  cdkTextareaAutosize
                  cdkAutosizeMinRows="1"
                  cdkAutosizeMaxRows="1"
                  [formControlName]="field.id"
                  [placeholder]="'COMMON.type_here' | translate ">
                </textarea>
              </mat-form-field>
              <mat-form-field *ngSwitchDefault class="lesson-feedback-modal__feedback--field" appearance="outline">
                <input matInput [formControlName]="field.id">
              </mat-form-field>
            </ng-container>
          </div>
        </div>
      </ng-container>
    </div>
  </div>
  <div class="lesson-feedback-modal__footer">
    <button mat-flat-button [disableRipple]="true" class="lesson-feedback-modal__btn button" mat-dialog-close>
      {{ feedbackSettings.cancelButtonLabel || ('COMMON.cancel' | translate) }}
    </button>
    <button mat-flat-button [disableRipple]="true" class="lesson-feedback-modal__btn primary-button" cdkFocusInitial (click)="submit()">
      {{ feedbackSettings.submitButtonLabel || ('COMMON.submit' | translate) }}
    </button>
  </div>
</div>

<ng-template #textFallbackTmp>
  <h4 class="lesson-feedback-modal__title-fallback">
    {{'LESSON_FEEDBACK.well_done' | translate}}
    <br>
    {{'LESSON_FEEDBACK.you_finished_this_lesson' | translate}}
  </h4>
</ng-template>

<ng-template #imageFallbackTmp>
  <div class="lesson-feedback-modal__image">
    <img class="lesson-feedback-modal__image--bg" src="/assets/images/lesson-feedback-bg.svg" alt="">
    <img class="lesson-feedback-modal__image--laptop" src="/assets/images/laptop.svg" alt="">
  </div>
</ng-template>
