import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { debounceTime, shareReplay, switchMap, tap } from 'rxjs/operators';
import { PaymentModeEnum, OfflineModeService } from 'library-explorer';
import { PaymentConfigModel } from '../model/payment-config.model';
import { PaymentDiscount } from '../model/payment-discount';
import { HttpService } from 'library-explorer';
import { IngenicoService } from './api/ingenico.service';
import { StripeService } from './api/stripe.service';
import { ProfileService } from 'library-explorer';

@Injectable({
  providedIn: 'root'
})
export class PaymentService {

  private paymentMode: PaymentModeEnum;
  private paymentConfig$: Observable<any>;

  constructor(
    private stripeService: StripeService,
    private ingenicoService: IngenicoService,
    private httpService: HttpService,
    private profileService: ProfileService,
    private offlineModeService: OfflineModeService
  ) {
    this.getPaymentMode();
  }

  public pay(entityId: string): Observable<any> {
    switch (this.paymentMode) {
      case PaymentModeEnum.STRIPE:
        return this.payStripe(entityId);
      case PaymentModeEnum.INGENICO:
        return this.payIngenico(entityId);
    }
  }

  public getPaymentConfig(force: boolean = false): Observable<PaymentConfigModel> {
    if (!this.paymentConfig$ || force) {
      this.paymentConfig$ = this.fetchPaymentConfig();
    }

    return this.paymentConfig$;
  }

  public checkPaymentStatus(entityId: string): Observable<{ paid: boolean }> {
    return this.httpService.post('check-payment', { entityId })
  }

  public getEntityDiscount(entityId: string): Observable<PaymentDiscount> {
    return this.httpService.get<PaymentDiscount>(`payment/get-discount/${entityId}`);    
  }

  private fetchPaymentConfig(): Observable<PaymentConfigModel> {
    const paymentConfigUrl = 'payment/public-config';

    return this.profileService.getCurrentProfile()
      .pipe(
        debounceTime(200),
        switchMap(res => {
          if (!res) {
            return of({ enabled: 0, payment_provider: null })
          }

          return this.httpService.get<PaymentConfigModel>(paymentConfigUrl)
            .pipe(
              tap(config => this.offlineModeService.storeRequest(paymentConfigUrl, config))
            )
        }),
        shareReplay({ bufferSize: 1, refCount: true }),
      );
  }

  private getPaymentMode(): void {
    this.getPaymentConfig().subscribe(res => {
      this.paymentMode = (res.enabled && res.payment_provider) || PaymentModeEnum.DISABLED;
    });
  }

  private payStripe(entityId: string): Observable<any> {
    return this.stripeService.sendPayment({ courseId: entityId });
  }

  private payIngenico(entityId: string): Observable<any> {
    return this.ingenicoService.sendPayment({ contentId: entityId });
  }
}
