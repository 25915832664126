import {
  HttpEvent, HttpHandler, HttpInterceptor, HttpRequest
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { ProfileService } from 'library-explorer';
import { mergeMap, take } from 'rxjs/operators';


@Injectable()
export class ClientIdentificationInterceptor implements HttpInterceptor {

  constructor(private readonly profileService: ProfileService) { }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return this.profileService.getCurrentProfile()
      .pipe(
        take(1),
        mergeMap(user => {
          request = request.clone({
            setHeaders: {
              'X-App-User': user ? user.id : '0'
            }
          });

          return next.handle(request);
        })
      );
  }
}
