import { Directive, ElementRef, Input, OnInit } from '@angular/core';
import { NetworkService } from 'library-explorer';

@Directive({
  selector: '[disableOnOffline]'
})
export class DisableOnOfflineDirective implements OnInit {
  @Input() public set disableOnOffline(value: boolean) {
    this._disabledOnOffline = value;
    this.disableElement();
  }

  public get disableOnOffline() {
    return this._disabledOnOffline;
  }

  private readonly disableCssClass = 'offline-mode-disabled';

  private isOnline = true;
  private _disabledOnOffline = false;

  constructor(
    private readonly networkService: NetworkService,
    private readonly element: ElementRef) { }

  ngOnInit(): void {
    this.networkService.isOnline()
      .subscribe(isOnline => {
        this.isOnline = isOnline;

        this.disableElement()
      });
  }

  private disableElement(): void {
    const shouldBeDisabled = !this.isOnline && this._disabledOnOffline;
  
    if (shouldBeDisabled) {
      this.element.nativeElement.classList?.add(this.disableCssClass);
      return;
    }
  
    this.element.nativeElement.classList?.remove(this.disableCssClass);
  }
}
