import { ChangeDetectorRef, Directive, ElementRef, Input, Renderer2 } from '@angular/core';
import { Router, RouterLinkActive } from '@angular/router';
import { ExtendedRouterLinkDirective } from './extended-router-link.directive';

@Directive({
  selector: '[extendedRouterLinkActive]'
})
export class ExtendedRouterLinkActiveDirective extends RouterLinkActive {
  @Input() public set extendedRouterLinkActive(value: string) {
    this.routerLinkActive = [value];
  }

  constructor(
    router: Router,
    element: ElementRef,
    renderer: Renderer2,
    cdr: ChangeDetectorRef, 
    link?: ExtendedRouterLinkDirective,
  ) {
    super(router, element, renderer, cdr, link);
  }
}

