<div class="notification-list" [style.--color]="color" *ngIf="notifications && notifications.length; else emptyTmp">
  <div class="notification-item" *ngFor="let notification of notifications" [ngClass]="{active: !notification.seen}" (click)="openNotificationPopup(notification)">
    <div class="notification-item__info">
      <small class="notification-item__date">
        {{notification.sentAt | date:'dd/MM/yyyy'}}
      </small>
      <div class="notification-item__status ml-2"></div>
    </div>
    <img *ngIf="notification.message.imageUrl" class="notification-item__image" [src]="notification.message.imageUrl" alt=""/>
    <div class="notification-item__content">
      <h6 class="notification-item__title" [class.primary-text]="!notification.seen">
        {{notification.message.title}}
      </h6>
      <p class="notification-item__short mt-3" ellipsis [innerHtml]="notification.message.text"></p>
    </div>
  </div>
</div>

<ng-container *ngIf="!isDialog">
  <div class="d-flex align-items-center justify-content-center mt-5">
    <app-load-more-btn [loading]="isLoading" (click)="loadMoreNotifications()" *ngIf="totalNotifications > notifications.length"></app-load-more-btn>
  </div>
</ng-container>


<ng-template #emptyTmp>
  <ng-container *ngIf="!isLoading">
    <div class="notification-list__empty">
      <span class="notification-list__empty-title">You don't have any notifications yet!</span>
      <img class="notification-list__empty-image" src="/assets/images/no-chats.svg" alt="">
    </div>
  </ng-container>
</ng-template>