import { Directive, ElementRef, Input } from '@angular/core';


@Directive({
  // tslint:disable-next-line: directive-selector
  selector: '[sectionMinHeight]'
})
export class SectionMinHeightDirective {
  @Input() public set sectionMinHeight(value: string) {
    this._sectionMinHeight = value;
    this.setSectionMinHeight();
  }

  public get sectionMinHeight() {
    return this._sectionMinHeight;
  }

  @Input() public set sectionContainerHeight(value: number) {
    this._containerHeight = value;
    this.setSectionMinHeight();
  }

  public get sectionContainerHeight() {
    return this._containerHeight;
  }


  private _sectionMinHeight!: string;

  private _containerHeight: number;

  constructor(private readonly element: ElementRef) {}


  private setSectionMinHeight(): void {
    if (!this._sectionMinHeight) {
      return;
    }

    const heightPx = this.convertHeightToPx();
    this.element.nativeElement.style.minHeight = `${heightPx}px`;
  }

  private convertHeightToPx(): number {
    if (!this._sectionMinHeight) {
      return 0;
    }

    const regex = /^(\d+)\s*(px|%)$/;
    const match = this._sectionMinHeight.match(regex);
    
    if (match) {
      const value = parseInt(match[1], 10);
      const unit = match[2];

      if (unit === 'px') {
        return value;
      } 

      if (unit === '%') {
        return (value / 100) * this._containerHeight;
      } 
    }

    return 0;
  }

}
