import { Inject, Injectable } from '@angular/core';
import { LessonService } from './api/lesson.service';
import { KeepAwake } from '@capacitor-community/keep-awake';
import { switchMap, map, take, tap, finalize, catchError, takeUntil } from 'rxjs/operators';
import { SlidesApiService } from './api/slides-api.service';
import { forkJoin, from, Observable, of, Subject, throwError } from 'rxjs';
import { ModuleService } from './api/module.service';
import { ClassService } from './api/class.service';
import { CourseService } from './api/course.service';
import { CardItemDTO } from '../model/cardItemDTO.model';
import { BundleType, SlideType, FieldName, OFFLINE_MEDIA_PREFIX, ProviderType, OfflineModeService, CryptoService, interceptorsToken, StorageKeys, STORAGE_KEYS, EntityTypeId } from 'library-explorer';
import { ActiveToast, Toast, ToastrService } from 'ngx-toastr';
import { LibraryService } from './api/library.service';
import { environment } from 'src/environments/environment';
import { GamificationService } from './api/gamification.service';
import { CourseDTO } from '@app/model/courseDTO.model';
import { ModuleDTO } from '@app/model/moduleDTO.model';
import { LessonDTO } from '@app/model/lessonsDTO.model';
import { ContentGroupService } from './api/content-group.service';
import { DownloadFileResult } from '@capacitor/filesystem';
import { Capacitor } from '@capacitor/core';
import { ClassDTO } from '@app/model/classDTO.model';
import { DownloadService } from './api/download.service';

import * as JSZip from 'jszip';
import { StorageMap } from '@ngx-pwa/local-storage';
import { FileSystemService } from './file-system.service';

interface ProgressInfo {
  totalSteps: number;
  currentStep: number;
  courseCard: any,
  downloadInfoToast: ActiveToast<Toast>,
  stepDescriptions: string[];
}
@Injectable({
  providedIn: 'root'
})
export class IdbService {
  public isDownloading = false;
  private offlineDownloadPath: string = 'saved-data/course';
  private activeDownloads: Map<string, { subject: Subject<void>, errorSubject: Subject<any>, courseData: any }> = new Map();

  private mediaPropertyMap = {
    audio: 'audios',
    backAudio: 'audios',
    frontAudio: 'audios',
    mediaVideo: 'videos',
    packagePath: 'documents',
    mediaImage: 'images',
    image: 'images',
    mediaBackground: 'images',
    backMediaBackground: 'images',
    frontMediaBackground: 'images',
  };

  private readonly toastrOptions = {
    disableTimeOut: true,
    tapToDismiss: false,
  };

  /* This property is an object that contains various paths for making API requests. Some of the
  paths also include query parameters for enabling maintenance mode or skipping maintenance checking. */
  private readonly paths = {
    //Simple requests path
    glossaries: `v2/taxonomies?vocabulary=glossary`,
    badgeCategories: `v2/taxonomies?vocabulary=badge_category`,

    //Download action paths
    classes: `classes?active=true&${interceptorsToken.HANDLE_PERMISSION_DENIED_PARAM}=true`,
    sitemap: `classes?active=true&display=site-map`
  };

  /* Contains objects with two properties: `observable` and `path`. Each object represents a request to be made to different
  services or APIs. The `observable` property represents the request itself, while the `path` property represents the path or endpoint of the request. */
  private readonly simpleRequests = [
    { observable: this.libraryService.fetchGlosaries(), path: this.paths.glossaries },
    { observable: this.gamificationService.getAchievementGroups(), path: this.paths.badgeCategories },
  ];

  constructor(
    @Inject(STORAGE_KEYS) private readonly storageKeys: StorageKeys,
    private offlineModeService: OfflineModeService,
    private storage: StorageMap,
    private classService: ClassService,
    private courseService: CourseService,
    private downloadService: DownloadService,
    private moduleService: ModuleService,
    private lessonService: LessonService,
    private libraryService: LibraryService,
    private slideService: SlidesApiService,
    private gamificationService: GamificationService,
    private contentGroupService: ContentGroupService,
    private toastr: ToastrService,
    private fileSystemService: FileSystemService,
  ) { }

  public clearDb(): void {
    this.offlineModeService.clearDb();
  }

  processObjectMediaOffline(obj: any, mediaKeysToFind: Record<string, string>, nestedKeys: string[] = []): any {
    if (obj && typeof obj === 'object') {
      // Check if the current object has any keys of interest
      const keysInObject = Object.keys(obj);
      const matchingKeys = keysInObject.filter(key => Object.keys(mediaKeysToFind).includes(key));

      // If there are matching keys, perform actions or modifications
      if (matchingKeys.length > 0) {
        matchingKeys.forEach(async key => {
          const mediaType = mediaKeysToFind[key];
          await this.fetchMedia(null, null, mediaType, obj[key], EntityTypeId.SETTINGS, BundleType.SETTINGS);
        });
      }

      // Iterate through nested keys and perform recursive search only if needed
      for (const nestedKey of nestedKeys) {
        if (obj.hasOwnProperty(nestedKey) && typeof obj[nestedKey] === 'object') {
          obj[nestedKey] = this.processObjectMediaOffline(obj[nestedKey], mediaKeysToFind);
        }
      }

      // Continue recursively checking other properties
      for (const prop in obj) {
        if (obj.hasOwnProperty(prop) && typeof obj[prop] === 'object' && !nestedKeys.includes(prop)) {
          obj[prop] = this.processObjectMediaOffline(obj[prop], mediaKeysToFind);
        }
      }
    }

    return obj;
  }

  //Remove all course data/files
  async removeCourseFiles(courseSavedData: { path: string, type: string }): Promise<void> {
    if (courseSavedData.type == 'response') {
      this.offlineModeService.removeRequest(courseSavedData?.path);
    } else if (courseSavedData.type == 'scorm') {
      await this.fileSystemService.deleteDirectory(courseSavedData?.path);
    } else {
      await this.fileSystemService.deleteFilePath(courseSavedData?.path);
    }
  }

  // Call this method to cancel a specific download
  stopDownload(courseId: string): void {
    const cancelSubject = this.activeDownloads.get(courseId);
    if (cancelSubject) {
      cancelSubject.subject.next(); //REMOVING
      cancelSubject.errorSubject.next();
      cancelSubject.subject.isStopped = true;
    }
  }

  // Call this method to cancel all active downloads
  cancelAllActiveSaveForOffline(): void {
    this.activeDownloads.forEach((cancelSubject) => {
      //cancelSubject.subject.next();
      cancelSubject.errorSubject.next();
      cancelSubject.courseData.availableOffline = false;

      const courseSavedDataKey = `${this.offlineDownloadPath}/${cancelSubject.courseData?.id}`;

      //Remove any saved data from IndexedDB
      this.offlineModeService.getRequest(courseSavedDataKey).subscribe((res: { path: string, type: string }[] | undefined) => {
        if (res) {
          res.forEach(async (courseSavedData) => {
            await this.removeCourseFiles(courseSavedData);
          });
        }
      })

      cancelSubject.courseData.uploadProgress = 0; //Reset course download progress
      this.removeItemsFromCacheList(cancelSubject.courseData);
      this.offlineModeService.removeRequest(courseSavedDataKey); //Delete the saved data when done
    });
  }

  public cancelSaveForOffline(courseCard: any): Observable<any> {
    let cancelInfoToast = this.toastr.info(null, `Canceling "${courseCard.title}" for offline use`, this.toastrOptions);

    try {
      const courseSavedDataKey = `${this.offlineDownloadPath}/${courseCard.id}`;

      //Stop the download if its still active
      this.stopDownload(courseCard?.id);

      //Remove any saved data from IndexedDB
      this.offlineModeService.getRequest(courseSavedDataKey).pipe(
        finalize(() => cancelInfoToast.toastRef.close())
      ).subscribe((res: { path: string, type: string }[] | undefined) => {
        if (res) {
          res.forEach(async (courseSavedData) => {
            await this.removeCourseFiles(courseSavedData);
          });
        }
      })

      courseCard.availableOffline = false;
      courseCard.uploadProgress = 0; //Reset course download progress

      this.removeItemsFromCacheList(courseCard);
      this.offlineModeService.removeRequest(courseSavedDataKey);
      return of(true);
    } catch (error) {
      console.error('Error updating download tracker:', error);
    }
  }

  public saveCourseForOffline(courseCard: any): Observable<any> {
    const numberOfActiveDownloads: number = this.activeDownloads.size;

    if (numberOfActiveDownloads > 3) {
      courseCard.availableOffline = false;
      courseCard.uploadProgress = 0;
      return of(null);
    }

    this.keepAwake(); // Keep the app awake to avoid sleep

    this.isDownloading = true;
    const downloadInfoToast: ActiveToast<Toast> = this.toastr.info(null, `Saving "${courseCard.title}" for offline use`, this.toastrOptions);
    const cancelDownloadSubject = new Subject<void>();
    const errorSubject = new Subject<any>();
    const courseObject = { subject: cancelDownloadSubject, errorSubject: errorSubject, courseData: courseCard };

    this.activeDownloads.set(courseCard?.id, courseObject);

    const ids: any = { class: null, course: null, moduleList: null };
    const responses: { path: string, data: any[] }[] = [];

    this.handleSimpleRequests(this.simpleRequests);

    return this.downloadCourseData(courseObject, ids, responses, downloadInfoToast).pipe(
      takeUntil(errorSubject),
      tap({
        next: () => this.downloadCompletion(courseCard, ids, responses),
        complete: () => this.downloadFinalization(courseCard.id, downloadInfoToast),
        error: (err) => {
          // Rollback and cancel downloads if an error occurs in the process
          this.cancelSaveForOffline(courseCard);
          this.toastr.error('An error has occurred during download, please try again later');
          errorSubject.next(err);
        }
      }),
      catchError(() => of(null)) // You may return a default value or rethrow the error as needed
    );
  }

  private downloadCourseData(courseObject: { subject: Subject<void>, courseData: CardItemDTO }, ids: any, responses: any[], downloadInfoToast: ActiveToast<Toast>): Observable<any> {
    const { subject, courseData } = courseObject;
    const classObservables = [this.classService.getAllClasses(), this.classService.getSitemap()];
    const downloadProgress: ProgressInfo = { totalSteps: 0, currentStep: 0, courseCard: courseData, downloadInfoToast, stepDescriptions: [] };

    return forkJoin(classObservables).pipe(
      takeUntil(subject),
      tap(() => this.setToastrDownloadMessage(downloadInfoToast, 'Downloading classes...')),
      switchMap(([classes, sitemap]) => this.processClassList(classes, sitemap, courseData, responses)),
      tap(() => this.setToastrDownloadMessage(downloadInfoToast, 'Downloading courses...')),
      switchMap(([course, courseParentClass]) => this.processCourseList(course, courseParentClass, ids, responses)),
      tap(() => this.setToastrDownloadMessage(downloadInfoToast, 'Downloading modules...')),
      switchMap(moduleList => this.processModuleList(moduleList, ids, responses)),
      tap(() => this.setToastrDownloadMessage(downloadInfoToast, 'Downloading lessons...')),
      switchMap(lessonList => this.processLessonList(lessonList, ids, responses)),
      tap(() => this.setToastrDownloadMessage(downloadInfoToast, 'Downloading slides...')),
      switchMap(slideList => this.processSlideList(slideList, ids, responses)),
      tap(() => this.setToastrDownloadMessage(downloadInfoToast, 'Sorting media...')),
      switchMap(() => this.sortMedia(courseData, downloadProgress, responses)),
      tap(() => this.setToastrDownloadMessage(downloadInfoToast, 'Downloading media...')),
      switchMap(() => this.processMedia(ids, responses, downloadProgress, courseObject)),
      tap(() => this.updateProgress(downloadProgress, 'Finishing downloads...')),
      take(1)
    );
  }

  private async handleSimpleRequests(requests: any[]) {
    requests.forEach((req) => req.observable.pipe(
      tap((res) => this.offlineModeService.storeRequest(req.path, res))
    ).subscribe());
  }

  private downloadCompletion(courseCard, ids, responses): void {
    this.setOfflineProperties(responses, ids);
    this.addItemToCacheList(this.storageKeys.OFFLINE_CLASSES, courseCard?.parent?.uuid);
    this.addItemToCacheList(this.storageKeys.OFFLINE_COURSES, { course: courseCard?.uuid, parentClass: courseCard?.parent?.uuid }, ['course', 'parentClass']);
    courseCard.availableOffline = true;
    this.toastr.success(`Course ${courseCard.title} is available offline`);
  }

  private downloadFinalization(courseId: string, downloadToast: ActiveToast<Toast>): void {
    this.activeDownloads.delete(courseId);
    this.isDownloading = false;
    this.allowSleep(); //Allow app to sleep if there are no active downloads
    downloadToast.toastRef.close();
  }

  /* This method manages the action for processing classlist for offline */
  private processClassList(classes, sitemap, course, responses: any[]): Observable<any> {
    responses.push({ path: this.paths.classes, data: classes });
    responses.push({ path: this.paths.sitemap, data: sitemap });

    return forkJoin([
      this.courseService.getCourseByID(course.id),
      this.classService.getSitemapByEntityId(course.id)
    ])
  }

  /* This method manages the action for processing course list for offline usage */
  private processCourseList(course: CourseDTO, courseParentClass: ClassDTO, ids: any, responses: any[]): Observable<ModuleDTO[]> {
    const parentClass = course.parent.uuid;

    // Set ids and add items to the cache list
    this.setIdsAndCacheItems(ids, parentClass, course.id);
    const coursePath = {
      course: `node?active=true&_format=json&id=${course.id}&${interceptorsToken.HANDLE_PERMISSION_DENIED_PARAM}=true`,
      courseParentClass: `content-info/${course.id}?active=true&display=site-map`
    };

    // Add the course data to responses
    responses.push({ path: coursePath.course, data: course });
    responses.push({ path: coursePath.courseParentClass, data: courseParentClass });

    //update downloadTracker
    this.updateDownloadTracker(course?.id, [coursePath.course, coursePath.courseParentClass]);

    const courseListObservable = this.getCourseListObservable(parentClass, ids, responses);
    return courseListObservable;
  }

  private setIdsAndCacheItems(ids: any, parentClass: string, courseId: string): void {
    ids.course = courseId;
    ids.class = parentClass;
  }

  private getCourseListObservable(parentClass: string, ids: any, responses: any[]): Observable<ModuleDTO[]> {
    const courseObservables = [
      this.classService.getClassByID(parentClass),
      this.courseService.getCoursesOfClass(parentClass),
      this.courseService.getStartedCourses(parentClass, true),
      this.courseService.getStartedCourses(parentClass, false),
    ];

    return forkJoin(courseObservables).pipe(
      switchMap(([parentClassRes, courses, startedCourses, notStartedCourses]) => {
        const courseListPath = {
          parentClass: `node?active=true&_format=json&id=${parentClass}&${interceptorsToken.HANDLE_PERMISSION_DENIED_PARAM}=true`,
          courses: `courses?parentClass=${ids.class}&active=true&${interceptorsToken.HANDLE_PERMISSION_DENIED_PARAM}=true`,
          startedCourses: `courses?active=true&_format=json&inProgress=1&parentClass=${ids.class}`,
          notStartedCourses: `courses?active=true&_format=json&inProgress=0&parentClass=${ids.class}`
        };

        // Add other paths to responses
        responses.push({ path: courseListPath.parentClass, data: parentClassRes });
        responses.push({ path: courseListPath.courses, data: courses });
        responses.push({ path: courseListPath.startedCourses, data: startedCourses, skipMediaFetch: true });
        responses.push({ path: courseListPath.notStartedCourses, data: notStartedCourses, skipMediaFetch: true });

        return this.moduleService.getModulesByCourseID(ids.course);
      })
    );
  }

  /* This method manages the action for processing course list for offline usage */
  private processModuleList(moduleList: ModuleDTO[], ids: any, responses: any[]): Observable<any> {
    const moduleItemList = this.createModuleItemList(moduleList, ids, responses);

    return forkJoin(moduleItemList).pipe(
      switchMap(moduleItem => this.processModuleItems(moduleItem, ids, responses))
    );
  }

  private setModuleListPath(moduleList: ModuleDTO[], courseId: string, responses: any[]) {
    const path = {
      moduleList: `modules?parentCourse=${courseId}&active=true&${interceptorsToken.HANDLE_PERMISSION_DENIED_PARAM}=true`
    };

    // Add the module list and path to responses
    responses.push({
      path: path.moduleList,
      data: {
        items: moduleList.map(item => ({ ...item, availableOffline: true }))
      }
    });

    //update downloadTracker
    this.updateDownloadTracker(courseId, [path.moduleList]);
  }

  private createModuleItemList(moduleList: ModuleDTO[], ids: any, responses: any[]): Observable<ModuleDTO>[] {
    const moduleItemList: Observable<ModuleDTO>[] = [];

    //verbose beccause moduleList objects doesn't contain the contentGroups field
    moduleList.forEach(item => {
      moduleItemList.push(this.moduleService.getModuleByID(item.id));
      this.storeSitemapByEntityId(item.id, ids, responses);
      item.availableOffline = true;
    });

    return moduleItemList;
  }

  private processModuleItems(moduleItems: ModuleDTO[], ids, responses): Observable<any> {
    this.setModuleListPath(moduleItems, ids.course, responses);

    const lessonObservables = moduleItems.map(moduleItem => {
      // Add each module path to responses
      const modulePath = `node?active=true&_format=json&id=${moduleItem.id}&${interceptorsToken.HANDLE_PERMISSION_DENIED_PARAM}=true`;
      ids.moduleList = ids.moduleList?.length ? [...ids.moduleList, moduleItem.id] : [moduleItem.id];

      responses.push({ path: modulePath, data: moduleItem });

      //update downloadTracker
      this.updateDownloadTracker(ids.course, [modulePath]);

      const hasGroups = moduleItem.contentGroups && moduleItem.contentGroups.length !== 0;
      return this.lessonService.getLessonByParentModule(moduleItem.id, hasGroups);
    });

    return forkJoin(lessonObservables).pipe(
      map(arrays => [].concat(...arrays))
    );
  }

  /* This method manages the action for processing lessons for offline usage */
  private processLessonList(lessonList: LessonDTO[], ids: any, responses: any[]): Observable<any> {
    const slideObservables: Observable<any>[] = [];
    const lessonObservables: Observable<LessonDTO>[] = [];

    // Separate lessons and slides processing
    lessonList.forEach(lesson => {
      if (lesson.bundle === BundleType.LESSON) {
        // If it's a lesson bundle
        lesson.availableOffline = true;
        lessonObservables.push(this.lessonService.getLessonByID(lesson.id));

        // Add slide observable if lesson is available
        slideObservables.push(this.slideService.getSlidesByLesson(lesson.id).pipe(
          map((slides: any) => ({ items: slides, parentLesson: lesson.id }))
        ));
      } else {
        // If it's not a lesson bundle, add a null observable
        slideObservables.push(of(null));
      }
    });

    // Process lessons and slides
    const observables = ids.moduleList.map(moduleId => {
      return this.processModuleLessonsAndGroups(moduleId, lessonList);
    });

    // Combine and subscribe to all observables
    forkJoin(observables).subscribe(reqs => {
      reqs.forEach((req: { lesson: any, group: any }) => {
        responses.push(req.lesson);
        req.group ? responses.push(req.group) : null;

        //update downloadTracker
        this.updateDownloadTracker(ids.course, [req.lesson?.path, req.group?.path]);
      });
    });

    return forkJoin(lessonObservables).pipe(
      switchMap(res => {
        res.forEach((lessonItem: LessonDTO) => {
          this.storeSitemapByEntityId(lessonItem.id, ids, responses);
          const lessonPath = `node?active=true&_format=json&id=${lessonItem.id}&${interceptorsToken.HANDLE_PERMISSION_DENIED_PARAM}=true`;
          responses.push({ path: lessonPath, data: lessonItem });

          //update downloadTracker
          this.updateDownloadTracker(ids.course, [lessonPath]);
        });

        return forkJoin(slideObservables);
      })
    );
  }

  private processModuleLessonsAndGroups(moduleId: string, lessonList: LessonDTO[]): Observable<any> {
    return this.moduleService.getModuleByID(moduleId).pipe(
      switchMap(module => {
        const hasGroups = module.contentGroups && module.contentGroups.length !== 0;
        const path = `lessons?parentModule=${moduleId}&active=true&withoutContentGroup=${hasGroups ? '1' : '0'}&${interceptorsToken.HANDLE_PERMISSION_DENIED_PARAM}=true`;

        const lessonsForModule = lessonList.filter(item => item.parentModuleId === moduleId);

        const contentGroups$ = hasGroups ? this.contentGroupService.getContentGroupsByParentId(module.id) : of(null);

        return forkJoin({
          lesson: of({ path, data: { items: lessonsForModule } }),
          group: contentGroups$.pipe(map(groups => groups ? ({ path: `content-groups?parentId=${module.id}`, data: groups }) : null))
        });
      })
    );
  }

  /* This method manages the action for processing slides for offline usage */
  private processSlideList(slideList: { parentLesson: any, items: any }[], ids: any, responses: any[]): Observable<any> {
    const slideItemObservables = [];
    slideList
      .filter(slides => !!slides)
      .forEach((slides: { parentLesson: any, items: any }) => {
        const path = `slides?parentLesson=${slides.parentLesson}&_format=json&active=true`;
        responses.push({ path, data: slides.items });

        //update downloadTracker
        this.updateDownloadTracker(ids.course, [path]);

        slides.items.forEach(item => {
          slideItemObservables.push(this.slideService.getSlideById(item.id));
        });
      });

    return forkJoin(slideItemObservables).pipe(
      tap(res => {
        res.forEach(itemArray => {
          const item = itemArray[0];
          if (!item) { return; }

          item.availableOffline = true;
          const slidePath = `slides?id=${item.id}`;
          responses.push({ path: slidePath, data: [item] });

          //update downloadTracker
          this.updateDownloadTracker(ids.course, [slidePath]);
        });
      })
    );
  }

  private async storeSitemapByEntityId(itemId: string, ids: any, responses: any[]) {
    await this.classService.getSitemapByEntityId(itemId).subscribe((classData: ClassDTO) => {
      const path = `content-info/${itemId}?active=true&display=site-map`;
      responses.push({ path, data: classData });

      //update downloadTracker
      this.updateDownloadTracker(ids.course, [path]);
    });
  }

  private sortMedia(courseCard: any, downloadProgress: ProgressInfo, responses: any[]): Observable<any> {
    let mediaFiles = { images: [], audios: [], videos: [], documents: [] };

    for (const response of responses) {
      if (
        response.data.bundle !== 'lesson' &&
        (!Array.isArray(response.data?.items || response.data) || response.skipMediaFetch)
      ) {
        continue;
      }

      const dataList = response.data.items || (Array.isArray(response.data) ? response.data : [response.data]);
      dataList.forEach(item => this.sortMediaProperties(mediaFiles, item));
    }

    courseCard.mediaFiles = mediaFiles;
    const totalFilesLength = Object.values(mediaFiles).reduce((total, mediaArray) => total + mediaArray.length, 0);
    downloadProgress.totalSteps = totalFilesLength;

    return of(mediaFiles);
  }

  private sortMediaProperties(mediaFiles: any, item: any): void {
    const stack = [item];

    while (stack.length > 0) {
      const currentItem = stack.pop();

      for (const key of Object.keys(currentItem)) {
        const mediaType = this.mediaPropertyMap[key];
        if (mediaType && currentItem[key]) {
          mediaFiles[mediaType].push(currentItem[key]);
        }

        if (['items', 'leftBackground', 'rightBackground', 'left_columns_items', 'right_columns_items', 'flipcards', 'hotspots', 'background', 'accordion'].includes(key) &&
          (Array.isArray(currentItem[key]) || typeof currentItem[key] === 'object')) {
          stack.push(...(Array.isArray(currentItem[key]) ? currentItem[key] : [currentItem[key]]));
        }
      }
    }
  }

  private async processMedia(ids: any, responses: any[], downloadProgress: ProgressInfo, courseObject: any): Promise<any> {
    for (const response of responses) {
      if (
        response.data.bundle !== 'lesson' &&
        (!Array.isArray(response.data?.items || response.data) || response.skipMediaFetch)
      ) {
        continue;
      }

      const dataList = response.data.items || (Array.isArray(response.data) ? response.data : [response.data]);
      for (const item of dataList) {
        if (courseObject.subject.isStopped) {
          downloadProgress.totalSteps = 0;
          downloadProgress.currentStep = 0;
          this.updateProgress(downloadProgress, null, true)
          courseObject.errorObject.next('Media processing cancelled');
          return;
        }

        await this.processMediaProperties(ids, downloadProgress, item);
      }
    }
  }

  private async processMediaProperties(ids: any, downloadProgress: ProgressInfo, item: any): Promise<void> {
    const stack = [item];

    while (stack.length > 0) {
      const currentItem = stack.pop();

      for (const key of Object.keys(currentItem)) {
        const mediaType = this.mediaPropertyMap[key];
        if (mediaType && currentItem[key]) {
          await this.fetchMedia(ids, downloadProgress, mediaType, currentItem[key], currentItem?.entityTypeId, currentItem?.bundle);
        }

        if (['items', 'leftBackground', 'rightBackground', 'left_columns_items', 'right_columns_items', 'flipcards', 'hotspots', 'background', 'accordion'].includes(key) &&
          (Array.isArray(currentItem[key]) || typeof currentItem[key] === 'object')) {
          stack.push(...(Array.isArray(currentItem[key]) ? currentItem[key] : [currentItem[key]]));
        }
      }
    }
  }

  private async fetchMedia(ids: any, downloadProgress: ProgressInfo, mediaType: string, mediaItem: string, entityTypeId: EntityTypeId, bundle: BundleType): Promise<void> {
    switch (mediaType) {
      case 'images':
        await this.fetchCdnImage(ids, downloadProgress, mediaItem, entityTypeId, bundle);
        break;
      case 'audios':
        await this.fetchAudioItem(ids, downloadProgress, mediaItem);
        break;
      case 'videos':
        await this.fetchVideoItem(ids, downloadProgress, mediaItem);
        break;
      case 'documents':
        await this.fetchDocumentItem(ids, downloadProgress, mediaItem, entityTypeId, bundle);
        break;
    }
  }

  //Fetching and storing CDN Images for offline Use
  private async fetchCdnImage(ids: any, downloadProgess: ProgressInfo, image: any, entityTypeId: EntityTypeId, bundle: BundleType): Promise<void> {
    if (!image) return of(null).toPromise();

    await this.processAwsImage(image, entityTypeId, bundle);
    const imageOptions = this.getImageOptions(bundle, image.crop);
    const options = Object.keys(imageOptions)
      .filter(key => imageOptions[key] !== undefined)
      .map(key => `${key}=${imageOptions[key]}`);

    const imageUrlHasQueryParams = (image.uri || image.url).indexOf('?') !== -1;
    const joinSeparator = imageUrlHasQueryParams ? '&' : '?';
    let url = environment.cloudImgUrl + (image.uri || image.url) + joinSeparator + options.join('&');
    await this.downloadAndStoreItem(ids?.course, downloadProgess, url, `images/${image?.filename}`, image);

    if (image.url) {
      image.url = image.uri;
    }
  }

  private async processAwsImage(image: any, entityTypeId: EntityTypeId, bundle: BundleType): Promise<void> {
    if (image.provider === ProviderType.AWS && image.key) {
      const presignedUrl = await this.fileSystemService.generatePreSignedUrl(
        image,
        entityTypeId,
        bundle,
        FieldName.MEDIA_IMAGE,
      ).toPromise();
      image.uri = presignedUrl;
    }
  }

  private getImageOptions(bundle: BundleType, crop: string): any {
    const isSlide = Object.values(BundleType).indexOf(bundle) === -1;
    const imageOptions: any = {
      force_format: 'webp,png',
      width: isSlide ? undefined : 400,
      q: 90,
      org_if_sml: 1,
      optipress: 3
    };

    if (crop) {
      const [x1, y1, x2, y2] = crop.split(',');
      imageOptions.tl_px = `${x1},${y1}`;
      imageOptions.br_px = `${x2},${y2}`;
    }

    return imageOptions;
  }

  //Fetching and storing Audios for offline Use
  private async fetchAudioItem(ids: any, downloadProgess: ProgressInfo, item: any): Promise<void> {
    if (!item) return;

    const itemUrlHasQueryParams = (item?.uri || item?.url).indexOf('?') !== -1;
    const joinSeparator = itemUrlHasQueryParams ? '&' : '?';
    const url = `${(item?.uri || item?.url)}${joinSeparator}t=${new Date().getTime()}`;

    await this.downlaodAndStoreBlobItem(ids?.course, downloadProgess, url, `audios/${item?.filename}`, item, 'audios');
  }

  //Fetching and storing Videos for offline Use
  private async fetchVideoItem(ids: any, downloadProgess: ProgressInfo, item: any): Promise<void> {
    if (!item) return;
    item.stream ? item.stream = null : null;

    await this.downlaodAndStoreBlobItem(ids.course, downloadProgess, (item?.uri || item?.url), `videos/${item?.filename}`, item, 'videos');
  }

  //Fetching and storing Documents for offline Use
  private async fetchDocumentItem(ids: any, downloadProgess: ProgressInfo, item: any, entityTypeId: EntityTypeId, bundle: BundleType | SlideType): Promise<void> {
    if (!item) return;

    const presignedUrl = await this.fileSystemService.generatePreSignedUrl(
      item,
      entityTypeId,
      bundle as BundleType,
      FieldName.AWS_FILE,
    ).toPromise();

    const itemUrlHasQueryParams = presignedUrl.indexOf('?') !== -1;
    const joinSeparator = itemUrlHasQueryParams ? '&' : '?';
    const url = `${presignedUrl}${joinSeparator}`;

    if (bundle === SlideType.SlideScormPackage) {
      await this.downloadAndStoreScormItem(ids.course, downloadProgess, url, `docs/${this.replaceSpecialCharsWithUnderscore(item?.filename)}/`, item, 'docs');
    } else {
      await this.downlaodAndStoreBlobItem(ids.course, downloadProgess, url, `docs/${item?.filename}`, item, 'docs');
    }
    return;
  }

  private async downloadAndStoreScormItem(courseId: string, downloadProgess: ProgressInfo, url: string, path: string, item: any, type: string = 'scorms') {
    try {
      let scormBlob: Blob;
      if (item.provider === ProviderType.AWS) {
        var zip = new JSZip();

        const data = await this.fileSystemService.downloadBlob(url, (progress: number) => {
          this.setToastrDownloadMessage(downloadProgess.downloadInfoToast, `${item.filename}: ( ${progress.toFixed(2)}% )`);
        });

        const file: DownloadFileResult = {
          blob: new Blob([data]),
          path: await this.fileSystemService.writeBlobFile(path + item?.filename, new Blob([data])),
        };

        if (file?.path) {
          //Read the file locally
          const result = await this.fileSystemService.readFilePath(file?.path);
          if (typeof result.data === 'string') {
            scormBlob = this.fileSystemService.stringToBlob(result.data);
          } else scormBlob = result.data;

          //Unzip the zip file
          let zipContent = await zip.loadAsync(scormBlob);
          zipContent.forEach(async (relativePath: string, zipFile: JSZip.JSZipObject) => {
            if (zipFile.dir === true) {
              await this.fileSystemService.createDirectory(path + relativePath);
            } else {
              await this.fileSystemService.writeBlobFile(path + relativePath, await zipFile.async('blob'));
            }
          });

          //store the path url
          const cleanPath = file.path.substring(0, file.path.lastIndexOf('/'));
          item.isOffline = true;
          item.filePath = `${cleanPath}/${this.getFileNameWithQueryFromUrl(item.uri)}`;
          item.uri = this.fileSystemService.filePathToWebUrl(item.filePath);

          //Update download progress
          this.updateProgress(downloadProgess);

          //update downloadTracker
          this.updateDownloadTracker(courseId, [path], 'scorm');

          //Delete zip file after storing contents
          await this.fileSystemService.deleteFilePath(file?.path);
        }
      }

    } catch (error) {
      // Handle download errors
      downloadProgess.totalSteps--;
      console.error('Error downloading and storing blob item:', error);
    }
  }

  //This will download and store the file immediately: best for small size files like images
  private async downloadAndStoreItem(courseId: string, downloadProgess: ProgressInfo, url: string, path: string, item: any, type: string = 'images'): Promise<void> {
    try {
      this.fileSystemService.checkPermission();

      let file: DownloadFileResult, data;
      if (Capacitor.isNativePlatform()) {
        data = await this.fileSystemService.downloadBlob(url, (progress: number) => {
          downloadProgess && this.setToastrDownloadMessage(downloadProgess?.downloadInfoToast, `${item.filename}: ( ${progress.toFixed(2)}% )`);
        });
        file = {
          blob: new Blob([data]),
          path: await this.fileSystemService.writeBlobFile(path, new Blob([data])),
        };
      } else {
        file = await this.fileSystemService.downloadAndStoreFile({ url }, path, (progress: number) => {
          downloadProgess && this.setToastrDownloadMessage(downloadProgess?.downloadInfoToast, `${item.filename}: ( ${progress.toFixed(2)}% )`);
        });
      }

      if (file?.path) {
        item.isOffline = true;
        item.filePath = file.path;
        item.uri = await this.getMediaWebUrl(file, type);

        //Update download progress
        downloadProgess && this.updateProgress(downloadProgess);

        //update downloadTracker
        courseId && this.updateDownloadTracker(courseId, [item.filePath], type);
      }
    } catch (error) {
      // Handle download errors
      downloadProgess && downloadProgess.totalSteps--;
      console.error('Error downloading and storing item:', error);
    }
  }

  //This will first download the item as blob and use the blob writer to store it: best for bigger files
  private async downlaodAndStoreBlobItem(courseId: string, downloadProgess: ProgressInfo, url: string, path: string, item: any, type: string = 'docs'): Promise<void> {
    try {
      let data: any;
      this.fileSystemService.checkPermission();

      if (item.provider === ProviderType.AWS) {

        if (type === 'docs') {
          const decodedUrl = decodeURIComponent(url);
          data = await this.downloadService.getFileAsArrayBuffer(decodedUrl).toPromise();
        } else {
          data = await this.fileSystemService.downloadBlob(url, (progress: number) => {
            this.setToastrDownloadMessage(downloadProgess.downloadInfoToast, `${item.filename}: ( ${progress.toFixed(2)}% )`);
          });
        }

        if (data) {
          const file: DownloadFileResult = {
            blob: new Blob([data]),
            path: await this.fileSystemService.writeBlobFile(path, new Blob([data])),
          };

          item.isOffline = true;
          item.filePath = file.path;
          item.uri = await this.getMediaWebUrl(file, type);

          //Update download progress
          this.updateProgress(downloadProgess);

          //update downloadTracker
          this.updateDownloadTracker(courseId, [item.filePath], type);
        }
      }
    } catch (error) {
      // Handle download errors
      downloadProgess && downloadProgess.totalSteps--;
      console.error('Error downloading and storing blob item:', error);
    }
  }


  private addItemToCacheList(cacheKey: string, item: any, filteringKey?: string[]): void {
    this.offlineModeService.storeOrUpdateArr(cacheKey, item, filteringKey);
  }

  private removeItemsFromCacheList(courseCard) {
    const courseData = { course: courseCard.uuid, parentClass: courseCard.parent.uuid };

    this.storage.get(this.storageKeys.OFFLINE_COURSES).subscribe((res: any[] | undefined) => {
      if (res && this.offlineModeService.doesRequestExist(res, courseData)) {
        res = res.filter(existingItem => existingItem.course !== courseData.course)

        this.storage.set(this.storageKeys.OFFLINE_COURSES, res).subscribe();

        const offlineClassCourses = res?.filter(obj => obj.parentClass === courseData.parentClass) || [];
        if (offlineClassCourses.length === 0) {
          this.offlineModeService.removeAndUpdateArr(this.storageKeys.OFFLINE_CLASSES, courseData.parentClass);
        }
      }
    });
  }

  private setOfflineProperties(responses: any[], ids) {
    const sitemap = responses.find(item => item.path === 'classes?_format=json&active=true&display=site-map');
    if (sitemap) {
      sitemap.data = sitemap.data.map(c => {
        if (c.id === ids.class) {
          c.availableOffline = true;
          if (c.courses) {
            c.courses = c.courses.map(course => {
              if (course.id === ids.course) {
                course.availableOffline = true;
                if (course.modules) {
                  course.modules = course.modules.map(module => {
                    if (module.id === ids.module) {
                      module.availableOffline = true;
                    }
                    return module;
                  });
                }
              }
              return course;
            });
          }
        }
        return c;
      });
    }

    let idsCombined = [];

    Object.keys(ids).forEach(key => {
      idsCombined = Array.isArray(ids[key]) ? [...idsCombined, ...ids[key]] : [...idsCombined, ids[key]];
    });

    responses = responses.map(item => {
      if (Array.isArray(item.data)) {
        item.data = this.setOfflinePropertyFor(idsCombined, item.data);
      }

      return item;
    });

    this.saveData(responses);
  }

  private setOfflinePropertyFor(idList: string[], list: any[]) {
    return list.map(item => {
      const isOffline = idList.indexOf(item.id) !== -1;

      if (isOffline) {
        item.availableOffline = true;
      }

      return item;
    });
  }

  private setToastrDownloadMessage(downloadToaster: ActiveToast<Toast>, message: string): void {
    downloadToaster.toastRef.componentInstance.message = message;
  }

  private saveData(responses) {
    responses.forEach(item => {
      if (!item.path) { return; }
      this.offlineModeService.storeEncryptedRequest(item.path, item.data, environment.encrytionSecret);
    });
  }

  /* Below are helper functions used in this service */
  private async getMediaWebUrl(file: DownloadFileResult, type: string): Promise<string> {
    const prefix = (type === 'images') ? OFFLINE_MEDIA_PREFIX : '';

    if (Capacitor.isNativePlatform()) {
      return prefix + this.fileSystemService.filePathToWebUrl(file.path);
    } else {
      const result = await this.fileSystemService.readFilePath(file.path);
      return prefix + URL.createObjectURL(result.data as any);
    }
  }

  private getFileNameWithQueryFromUrl(url: string): string | null {
    const urlObj = new URL(url);
    const pathNameParts = urlObj.pathname.split('/');
    const fileNameWithQuery = pathNameParts[pathNameParts.length - 1];

    return fileNameWithQuery || null;
  }

  private replaceSpecialCharsWithUnderscore(str: string): string {
    return str.replace(/[^a-zA-Z0-9_]/g, '_');
  }

  /**
   * The function `updateDownloadTracker` updates the download tracker for a specific course by storing
   * or updating the saved data keys and their corresponding data types.
   * @param {string} courseId - The courseId parameter is a string that represents the unique identifier
   * of a course.
   * @param {string[]} savedDataKeys - The `savedDataKeys` parameter is an array of strings that
   * represents the keys or paths of the saved data. These keys or paths are used to identify and
   * retrieve the saved data from the offline storage.
   * @param {string} [dataType=response] - The dataType parameter is a string that specifies the type of
   * data being stored or updated in the download tracker. It has a default value of 'response', but can
   * be overridden with a different value if needed.
   */
  private updateDownloadTracker(courseId: string, savedDataKeys: string[], dataType: string = 'response') {
    const courseSavedDataKey = `${this.offlineDownloadPath}/${courseId}`;

    this.offlineModeService.getRequest(courseSavedDataKey).subscribe((res: { path: string, type: string }[] | undefined) => {
      if (res) {
        savedDataKeys.forEach((savedDataKey) => {
          if (!savedDataKey) return;
          const item = { path: savedDataKey, type: dataType };

          this.offlineModeService.storeOrUpdateArr(courseSavedDataKey, item);
        })
      } else {
        let data: { path: string, type: string }[] = [];
        savedDataKeys.forEach((savedDataKey) => {
          if (!savedDataKey) return;
          data.push({ path: savedDataKey, type: dataType });
        })

        this.offlineModeService.storeRequest(courseSavedDataKey, data);
      }
    });
  }

  /**
   * The function updates the progress information and sets the upload progress for a course card, and
   * optionally sets a toast message for a download step.
   * @param {ProgressInfo} progress - The `progress` parameter is an object that contains information
   * about the progress of a task. It has the following properties:
   * @param {string} [stepDescription] - The `stepDescription` parameter is an optional string that
   * represents the description of the current step in the progress. It is used to update the
   * `downloadInfoToast` property of the `progress` object.
   */
  private updateProgress(progress: ProgressInfo, stepDescription?: string, stopProgress: boolean = false): void {
    // Increment the current step and add the step decription
    if (stopProgress === false) progress.currentStep++;

    const progressPercentage = (progress.currentStep / progress.totalSteps) * 100;
    progress.courseCard.uploadProgress = progressPercentage;

    if (stepDescription) this.setToastrDownloadMessage(progress.downloadInfoToast, stepDescription);
  }

  /* This code is implementing a "Keep Awake" functionality. */
  async keepAwake() {
    if (await this.isKeptAwake()) return;
    await KeepAwake.keepAwake();
  }

  async allowSleep() {
    if (this.activeDownloads.size === 0) await KeepAwake.allowSleep();
  }

  async isKeptAwake() {
    const result = await KeepAwake.isKeptAwake();
    return result.isKeptAwake;
  };
}


//LEGACY CODES REMOVING SOON

//   public saveCourseForOfflineX(courseCard: any): Observable < any > {
//   const numberOfActiveDownloads: number = this.activeDownloads.size;

//   if(numberOfActiveDownloads > 3) {
//   courseCard.availableOffline = false;
//   courseCard.uploadProgress = 0;
//   return of(null);
// };

// this.keepAwake(); //Keep the app awake. to avoid sleep

// this.isDownloading = true;
// const downloadInfoToast: ActiveToast<Toast> = this.toastr.info(null, `Saving "${courseCard.title}" for offline use`, this.toastrOptions);
// const cancelDownloadSubject = new Subject<void>();

// // this.activeDownloads.set(courseCard?.id, { subject: cancelDownloadSubject, courseData: courseCard });

// const ids: any = { class: null, course: null, moduleList: null };
// const responses: { path: string, data: any[] }[] = [];

// this.handleSimpleRequests(this.simpleRequests);

// return this.downloadCourseData(courseCard, ids, responses, downloadInfoToast).pipe(
//   takeUntil(cancelDownloadSubject),
//   tap(() => this.downloadCompletion(courseCard, ids, responses)),
//   finalize(() => this.downloadFinalization(courseCard.id, downloadInfoToast)),
//   catchError((err) => {
//     //Rollback and cancel downloads if an error occurs in the process
//     this.cancelSaveForOffline(courseCard);

//     this.toastr.error('An error has occured during download, please try again later');
//     return err;
//   })
// );
// }

//   private downloadCourseDataX(courseCard: CardItemDTO, ids: any, responses: any[], downloadInfoToast: ActiveToast<Toast>): Observable < any > {
//   const classObservables = [this.classService.getAllClasses(), this.classService.getSitemap()]; //This is the first step
//   const downloadProgress: ProgressInfo = { totalSteps: 0, currentStep: 0, courseCard, downloadInfoToast, stepDescriptions: [] };

//   return forkJoin(classObservables).pipe(
//     tap(() => this.setToastrDownloadMessage(downloadInfoToast, 'Downloading classes...')),
//     switchMap(([classes, sitemap]) => this.processClassList(classes, sitemap, courseCard, responses)),
//     tap(() => this.setToastrDownloadMessage(downloadInfoToast, 'Downloading courses...')),
//     switchMap(([course, courseParentClass]) => this.processCourseList(course, courseParentClass, ids, responses)),
//     tap(() => this.setToastrDownloadMessage(downloadInfoToast, 'Downloading modules...')),
//     switchMap(moduleList => this.processModuleList(moduleList, ids, responses)),
//     tap(() => this.setToastrDownloadMessage(downloadInfoToast, 'Downloading lessons...')),
//     switchMap(lessonList => this.processLessonList(lessonList, ids, responses)),
//     tap(() => this.setToastrDownloadMessage(downloadInfoToast, 'Downloading slides...')),
//     switchMap(slideList => this.processSlideList(slideList, ids, responses)),
//     tap(() => this.setToastrDownloadMessage(downloadInfoToast, 'Sorting media...')),
//     switchMap(() => this.sortMedia(courseCard, downloadProgress, responses)),
//     tap(() => this.setToastrDownloadMessage(downloadInfoToast, 'Downloading media...')),
//     // switchMap(() => this.processMedia(ids, responses, downloadProgress)),
//     // tap(() => this.updateProgress(downloadProgress, 'Finishing downloads...')),
//     take(1)
//   );
// }

//   private async processMediaX(ids: any, responses: any[], downloadProgress: ProgressInfo): Promise < any > {
//   for(const response of responses) {
//     if (
//       response.data.bundle !== 'lesson' &&
//       (!Array.isArray(response.data?.items || response.data) || response.skipMediaFetch)
//     ) {
//       continue;
//     }

//     const dataList = response.data.items || (Array.isArray(response.data) ? response.data : [response.data]);
//     for (const item of dataList) {
//       await this.processMediaProperties(ids, downloadProgress, item);
//     }
//   }
// }
