<div
  class="flip-cards-wrapper"
  cdkDropListGroup
  [cdkDropListGroupDisabled]="!canEdit">
  <div
    *ngFor="let item of slideData?.flipcards; let i = index; trackBy: trackFlipcardBy"
    class="flip-card-item"
    cdkDropList
    [cdkDropListData]="{ order: i }"
    cdkDropListSortingDisabled
    (cdkDropListDropped)="drop($event)"
  >
    <lib-flipcard
      class="d-block animate__animated"
      cdkDrag
      [cdkDragData]="item"
      [class.landscape]="item.orientation === orientations.LANDSCAPE"
      [ngClass]="animationEnabled && slideData.animation"
      [style.animationDelay.ms]="slideData.animationDelay + i * 200"
      [item]="item"
      [isTranslation]="isTranslation"
      [isRtl]="isRtl"
      [flipcardFormGroup]="formArray?.controls[i]"
      [errors]="formArray?.controls[i]?.errors"
      [backgroundPresigned]="backgroundPresigned"
      [slide]="slideData"
      [canEdit]="canEdit" 
      (cardFlipped)="cardFlipped(i)"
      (edit)="edit.emit(i)"
      (remove)="remove.emit(i)"
      (changeOrientation)="changeOrientation.emit(i)">
      <div *ngIf="canEdit" class="icon-button" cdkDragHandle drag-handle>
        <div class="icon-button__icon">
          <mat-icon class="handle-icon" svgIcon="lms-handle"></mat-icon>
        </div>
      </div>
      <ng-template cdkDragPlaceholder>
        <div class="item-drag-placeholder">
          <div></div>
        </div>
      </ng-template>
    </lib-flipcard>
  </div>
</div>
