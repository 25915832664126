import { HttpClient, HttpHeaders, HttpParams, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { forkJoin, Observable, of } from 'rxjs';
import { catchError, map, mergeMap, tap } from 'rxjs/operators';
import { HydraResponseDto } from 'src/app/model/hydra-response-dto';
import { NotificationDelivery } from '@app/model/notification-delivery';
import { environment } from 'src/environments/environment';
import { FileUploadApiService, SessionStorageService } from 'library-explorer';



@Injectable({
  providedIn: 'root'
})
export class NotificationApiService {
  private readonly apiUrl = environment.coreLearningLabApi;

  constructor(
    private readonly fileUploadApiService: FileUploadApiService,
    private readonly sessionStorageService: SessionStorageService,
    private readonly httpClient: HttpClient) { }

  public authorize(): Observable<HttpResponse<any>> {
    return this.httpClient.get(`${this.apiUrl}/notifications/authorize`, {
      observe: 'response',
      headers: this.buildHeaders(),
      withCredentials: true
    });
  }

  public markNotificationAsSeen(id: string): Observable<void> {
    return this.httpClient.put<void>(`${this.apiUrl}/notifications/${id}/see`, null, { headers: this.buildHeaders() });
  }

  public getNotifications(page = 1, preloadImages = true): Observable<HydraResponseDto<NotificationDelivery>> {
    const params = new HttpParams().set('page', page.toString());
    return this.httpClient.get<HydraResponseDto<NotificationDelivery>>(`${this.apiUrl}/notification-deliveries`, {
      headers: this.buildHeaders(), params
    }).pipe(
      mergeMap((data: HydraResponseDto<NotificationDelivery>) => {
        if (!preloadImages) {
          return of(data);
        }
        const imageObs = [];
        const messages = data['hydra:member'];
        messages.forEach(message => {
          if (message.message.coverImageId) {
            const obs = this.fileUploadApiService.getFileByUUID(message.message.coverImageId)
              .pipe(
                catchError((err) => {
                  return of(null);
                }),
                tap(url => Object.assign(message.message, { imageUrl: url }))
              );

            imageObs.push(obs);
          }
        });

        return imageObs.length
          ? forkJoin(imageObs)
            .pipe(
              map(() => data)
            )
          : of(data);
      })
    );
  }

  private getTokenHeader(httpHeaders: HttpHeaders): HttpHeaders {
    const token = this.sessionStorageService.getJwtTokenValue();
    if (token) {
      httpHeaders = httpHeaders.append('Authorization', `Bearer ${token}`);
    }
    return httpHeaders;
  }

  private buildHeaders(httpHeaders = new HttpHeaders()): HttpHeaders {
    httpHeaders = httpHeaders.append('Content-Type', 'application/json');
    httpHeaders = this.getTokenHeader(httpHeaders);
    return httpHeaders;
  }
}
