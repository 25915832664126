import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ImageModel } from 'library-explorer';

@Component({
  selector: 'app-modal-image-dialog',
  templateUrl: './modal-image-dialog.component.html',
  styleUrls: ['./modal-image-dialog.component.scss']
})
export class ModalImageDialogComponent implements OnInit {

  public image: ImageModel;

  constructor(@Inject(MAT_DIALOG_DATA) public data: { image: ImageModel }) { }

  ngOnInit(): void {
    this.image = this.data.image;
  }

}
