import { Component, EventEmitter, Input, Output } from '@angular/core';
import { CourseDTO } from 'src/app/model/courseDTO.model';
import { FieldName } from 'library-explorer';
import { PayableNodeNavigationService } from 'src/app/services/payable-node-navigation.service';

@Component({
  selector: 'app-dashboard-item',
  templateUrl: './dashboard-item.component.html',
  styleUrls: ['./dashboard-item.component.scss']
})
export class DashboardItemComponent {
  @Input() item: any;
  @Input() progressShow = true;
  @Input() itemCountLabel: string;
  @Input() startButton = false;
  @Input() chaptersLeft = false;
  @Input() inCarousel = false;
  @Input() public paymentEnabled = false;
  @Output() public buyCourse: EventEmitter<CourseDTO> = new EventEmitter();

  public readonly fieldNames: typeof FieldName = FieldName;

  constructor(private readonly payableNodeNavigationService: PayableNodeNavigationService) {
  }

  public navigate(event: MouseEvent): void {
    this.payableNodeNavigationService.navigate(this.item, event);
  }

}
