import { Pipe, PipeTransform } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { ChatMessageType } from 'library-explorer';
import { Observable, of } from 'rxjs';
import { FileMessage, UserMessage } from 'sendbird';

@Pipe({
  name: 'chatLastMessage'
})
export class ChatLastMessagePipe implements PipeTransform {
  constructor(private readonly translateService: TranslateService) {

  }

  transform(value: UserMessage | FileMessage): Observable<string> {
    switch (value.customType) {
      case ChatMessageType.AUDIO_MESSAGE:
        return this.translateService.get('CHAT.audio_message');
      case ChatMessageType.VIDEO_MESSAGE:
        return this.translateService.get('CHAT.video_message');
      case ChatMessageType.FILE_MESSAGE:
        return this.translateService.get('CHAT.file_message');
      case ChatMessageType.IMAGE_MESSAGE:
        return this.translateService.get('CHAT.image_message');
      default:
        if (value.messageType === 'file') {
          return of(value.name);
        }

        return of(value.message);
    }
  }

}
