import { Directive, ElementRef, Input } from '@angular/core';
import { tap } from 'rxjs/operators';
import { BackgroundSizeOption, BundleType, EntityTypeId, FieldName, HorizontalPositionOption, ImageModel, VerticalPositionOption } from '../models';
import { MediaPresignApiService, TokenApiService } from '../services';
import { CloudImageService } from '../services/cloud-image.service';
import { SharedLibraryService } from '../services/shared-library.service';
import { MediaBackgroundDirective } from './media-background.directive';


@Directive({
  // tslint:disable-next-line: directive-selector
  selector: '[cardBackground]'
})
export class CardBackgroundDirective extends MediaBackgroundDirective {
  @Input() public override dynamicWidth = true;
  @Input() public override imageWidth = 0;
  @Input() public override dynamicWidthCoeff = 1;
  @Input() public override shouldCreateOverlay = true;
  @Input() public override overlayUseFullHeight = true;
  
  @Input() public set cardBackground(value: { backgroundColor: string, mediaBackground: ImageModel, backgroundPresigned?: boolean }) {
    const { backgroundPresigned, ...data } = value;
    if (!value.backgroundPresigned && (data.mediaBackground?.key || data.mediaBackground?.preUploadedMedia)) {
      this.mediaPresignApiService.getPreSignedUrl(EntityTypeId.PARAGRAPH, BundleType.CARD, FieldName.MEDIA_IMAGE, data.mediaBackground.key || data.mediaBackground.preUploadedMedia?.key || '').pipe(
        tap(signedUrl => Object.assign(data.mediaBackground, { uri: signedUrl }))
      ).subscribe(() => {
        this._cardBackground = data;
        this.setMediaBackground();
      })
    } else {
      this._cardBackground = data;
      this.setMediaBackground();
    }
  }

  public get cardBackground() {
    return this._cardBackground;
  }

  private _cardBackground!: { backgroundColor: string, mediaBackground: ImageModel };

  constructor(
    protected override readonly sharedLibraryService: SharedLibraryService,
    protected override readonly el: ElementRef,
    protected override readonly tokenApiService: TokenApiService,
    private readonly mediaPresignApiService: MediaPresignApiService,
    protected override cloudImageService: CloudImageService) {
    super(sharedLibraryService, el, tokenApiService, cloudImageService);
  }

  private setMediaBackground(): void {
    this.mediaBackground = {
      ...this._cardBackground,
      backgroundSize: BackgroundSizeOption.COVER,
      backgroundPosition: {
        vertical: VerticalPositionOption.CENTER,
        horizontal: HorizontalPositionOption.CENTER
      },
      mobileBackgroundPosition: {
        vertical: VerticalPositionOption.CENTER,
        horizontal: HorizontalPositionOption.CENTER
      }
    };
  }
}
