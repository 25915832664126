<div class="base-warning-dialog">
  <div class="base-warning-dialog__header">
    <img src="/assets/images/warning.png" class="base-warning-dialog__image" alt="">
  </div>

  <div class="d-flex flex-column align-items-center base-warning-dialog__content dialog-content">
    <h4 class="dialog-content__title">
      {{ title | translate}}
    </h4>

    <p class="dialog-content__text">
      {{description | translate}}
    </p>

    <button mat-flat-button class="dialog-content__button primary-button">
      {{btnText | translate}}
    </button>
  </div>
</div>
