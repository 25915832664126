/**
 * Documentation:
 * @see https://medium.com/angular-in-depth/the-new-angular-youtube-player-component-9ce52ecf3dee
 */
import { Component, OnInit, Input, Output, EventEmitter, ViewChild, ElementRef } from '@angular/core';

import { filter } from 'rxjs/operators';

import { CookieConsentService, YoutubeService } from '../../services';
import { CookieCategory } from '../../models';

@Component({
  selector: 'app-youtube-player',
  templateUrl: './youtube-player.component.html',
  styleUrls: ['./youtube-player.component.scss'],
})
export class YoutubePlayerComponent implements OnInit {
  @ViewChild('playerEl', {static: true}) playerEl!: ElementRef;
  @Input() setLoop = false; // Flag to set loop for video.
  @Input() height?: string; // Height of player.
  @Input() width?: string; // Width of player.
  @Input() videoIds?: string[]; // Video ids to set playlist.
  @Input() startTime?: number;

  selectedVideoId!: string;
  isScriptLoaded = false;
  /**
   * Sets selected video id and loads video.
   * @param {string} id selected video id
   */
  @Input() set videoId(id: string) {
    this.selectedVideoId = id;
    this.loadVideoById();
  }

  @Output() started: EventEmitter<void> = new EventEmitter<void>();
  @Output() watched: EventEmitter<void> = new EventEmitter<void>();
  @Output() onVideoPlay = new EventEmitter(); // Emits whenever a video is played.

  player!: YT.Player;

  constructor(
    private readonly cookieConsentService: CookieConsentService,
    private youtubeService: YoutubeService
  ) { }

  ngOnInit() {
    this.youtubeService.isYoutubeApiReady().pipe(
      filter(res => res)
    ).subscribe(() => {
      this.initPlayer();
    });
  }

  private initPlayer() {
    const cookieAgreed = this.cookieConsentService.isCookieConsentAgreed(CookieCategory.ANALYTICS);
    this.player = new YT.Player(this.playerEl.nativeElement, {
      videoId: this.selectedVideoId,
      host: cookieAgreed ? 'https://www.youtube.com' : 'https://www.youtube-nocookie.com',
      events: {
        'onStateChange': (e) => this.onStateChange(e)
      },
      playerVars: {
        rel: 0,
        origin: location.host,
        start: this.startTime
      }
    });
  }

  /**
   * Plays video and sets playlist.
   * @returns {void}
   */
  playVideo(): void {
    if (!this.player || !this.player.playVideo) {
      return;
    }

    this.player.playVideo();

    this.setPlayList();
  }

  /**
   * Reset video time.
   * @returns {void}
   */
   resetVideoTime(): void {
    if (!this.player || !this.player.playVideo) {
      return;
    }

    this.player.seekTo(0, true);
  }

  /**
   * Pauses video.
   * @returns {void}
   */
  pauseVideo(): void {
    if (!this.player || !this.player.pauseVideo) {
      return;
    }

    this.player.pauseVideo();
  }

  /**
   * Sets play list.
   */
  setPlayList() {
    if (this.videoIds && this.videoIds.length) {
      this.player.cuePlaylist(this.videoIds);
      this.player.loadPlaylist(this.videoIds);
    }
  }

  /**
   * Loads and plays video by id.
   * @returns {void}
   */
  loadVideoById(): void {
    if (this.player) {
      this.player.loadVideoById(this.selectedVideoId);
    }
  }

  /**
   * Automatically triggers on video state change.
   * @param {any} event
   */
  onStateChange(event: any) {
    switch (event.data) {
      case 0: // Video ended
        this.watched.emit();
        if (this.setLoop) {
          this.player.playVideo();
        }
        break;

      case 1: // Video played
        this.started.emit();
        this.onVideoPlay.emit(this.selectedVideoId);
        break;

      case 5: // Video cued
        this.player.playVideo();
        break;

      default:
        break;
    }
  }
}
