import { Component, ElementRef, HostBinding, HostListener, Inject, OnInit, ViewChild } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { Router } from '@angular/router';
import { ProfileService } from 'library-explorer';
import { ZoomEventKeyEnum } from 'src/app/model/enums/zoom-event-key.enum';
import { WebinarConnectionDataDTO } from 'src/app/model/webinarConnectionDataDTO';
import { WebinarDTO } from 'library-explorer';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-zoom-meeting-dialog',
  templateUrl: './zoom-meeting-dialog.component.html',
  styleUrls: ['./zoom-meeting-dialog.component.scss']
})
export class ZoomMeetingDialogComponent implements OnInit {
  @ViewChild('frame', { static: true }) frameEl: ElementRef;
  @HostBinding('class.collapsed') collapsed = false;

  @HostListener('window:message', ['$event']) onMessage(e): void {
    if (e.data && e.data.url) {
      this.router.navigate([e.data.url]);
    }

    if (e.data && e.data.status) {
      this.webinarStarted = e.data.status === ZoomEventKeyEnum.WEBINAR_STARTED;

      if (e.data.status === ZoomEventKeyEnum.WEBINAR_ENDED) {
        this.dialogRef.close();
      }
    }
  }

  public zoomHostUrl: SafeResourceUrl;
  public webinarStarted = false;
  
  constructor(
    public dialogRef: MatDialogRef<ZoomMeetingDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: { webinar: WebinarDTO, connection: WebinarConnectionDataDTO },
    private readonly profileService: ProfileService,
    private readonly router: Router,
    private readonly domSanitizer: DomSanitizer) { }

  ngOnInit(): void {
    this.zoomHostUrl = this.domSanitizer.bypassSecurityTrustResourceUrl(environment.zoomHostUrl);
  }

  public navigate(): void {
    this.router.navigate(['/webinars', this.data.webinar.id, 'call']);
  }

  public close(): void {
    this.dialogRef.close();
  }

  public iframeLoaded(): void {
    const user = this.profileService.getCurrentProfileValue();
    const { webinar, connection } = this.data; 
    this.frameEl.nativeElement.contentWindow.postMessage({
      signature: connection.signature,
      meetingNumber: webinar.meetingId,
      userName: user?.formattedProfileName,
      userEmail: user?.email,
      sdkKey: connection.key,
      passWord: webinar.joinPassword,
      leaveUrl: '/webinars',
      origin: window.location.origin
    }, environment.zoomHostUrl);
  }

}
