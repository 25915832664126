import { Pipe, PipeTransform } from '@angular/core';
import { Observable, of } from 'rxjs';
import { map } from 'rxjs/operators';
import { ProfileUserDTO, UserDisplayFormatType } from 'library-explorer';
import { SettingsService } from '@app/services/settings.service';

@Pipe({
  name: 'userName'
})
export class UserNamePipe implements PipeTransform {

  constructor(private readonly settingsService: SettingsService) { }

  transform(value: Partial<ProfileUserDTO>): Observable<string> {
    if (!value) {
      return of('');
    }

    return this.settingsService.getSettings()
      .pipe(
        map(data => {
          return this.transformUserName(value, data.profile && data.profile.userNameDisplayFormat);
        })
      );
  }

  private transformUserName(value: Partial<ProfileUserDTO>, type: UserDisplayFormatType): string {
    const firstName = value.name || '';
    const lastName = value.lastName || '';
    const firstNameInitial = firstName.charAt(0);
    const lastNameInitial = lastName.charAt(0);

    switch (type) {
      case UserDisplayFormatType.INITIALS_ONLY:
        return `${firstNameInitial ? `${firstNameInitial.toLocaleUpperCase()}.` : '' } ${lastNameInitial ? `${lastNameInitial.toLocaleUpperCase()}.` : '' }`;
      case UserDisplayFormatType.SHORTENED_FIRST_NAME:
        return `${firstNameInitial ? `${firstNameInitial.toLocaleUpperCase()}.` : '' } ${lastName}`;
      case UserDisplayFormatType.SHORTENED_LAST_NAME:
        return `${firstName} ${lastNameInitial ? `${lastNameInitial.toLocaleUpperCase()}.` : '' }`;
      case UserDisplayFormatType.FULL_NAME:
      default:
        return `${firstName} ${lastName}`;
    }
  }
}
