import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { NavigationStart, Router, RouterEvent } from '@angular/router';
import { CourseDTO } from '@app/model/courseDTO.model';
import { filter } from 'rxjs/operators';

@Component({
  selector: 'app-course-details-dialog',
  templateUrl: './course-details-dialog.component.html',
  styleUrls: ['./course-details-dialog.component.scss']
})
export class CourseDetailsDialogComponent implements OnInit {

  constructor(
    private readonly router: Router,
    private readonly dialogRef: MatDialogRef<CourseDetailsDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: Partial<CourseDTO>) { }

  ngOnInit(): void {
    if (!this.data) {
      this.closeDialog();
    }

    this.router.events
      .pipe(
        filter(event => event instanceof NavigationStart),
        filter(() => !!this.dialogRef)
      )
      .subscribe(() => {
        this.closeDialog();
      });
  }

  public closeDialog(): void {
    this.dialogRef.close();
  }

}
