<section class="preview-wrapper position-relative" [class.hidden]="modalHidden" [ngClass]="[fileMode]">
  <button mat-fab class="close-button d-block d-lg-none" mat-dialog-close>
    <mat-icon>close</mat-icon>
  </button>

  <app-document-preview 
    [entityId]="data.entityId"
    [fileKey]="data.file?.key || data.file?.uri"
    [insideDialog]="true"
    [fileName]="data.file.filename"
    [uri]="data.presignedUrl"
    [provider]="data.file.provider"
    [searchKey]="data.searchKey"
    (isLoaded)="loaded($event)">
  </app-document-preview>
  <mat-progress-bar *ngIf="!isLoaded" mode="indeterminate" class="progress-bar"></mat-progress-bar>
</section>
