import { Pipe, PipeTransform } from '@angular/core';
import * as moment from 'moment-timezone';

@Pipe({
  name: 'dateWithoutTimezone'
})
export class DateWithoutTimezonePipe implements PipeTransform {

  transform(date: Date, args?: any): any {
    if (!date) {
      return;
    }

    const updatedDate = moment(date).utc(false).format('YYYY/MM/DD HH:mm:ss');

    return updatedDate;
  }

}
