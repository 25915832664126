import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ChatMessageType } from 'library-explorer';

@Component({
  selector: 'app-chat-record-media-dialog',
  templateUrl: './chat-record-media-dialog.component.html',
  styleUrls: ['./chat-record-media-dialog.component.scss']
})
export class ChatRecordMediaDialogComponent implements OnInit {
  public type: ChatMessageType;

  public messageTypes: typeof ChatMessageType = ChatMessageType;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: { type: ChatMessageType},
    private readonly dialogRef: MatDialogRef<ChatRecordMediaDialogComponent>) { }

  ngOnInit(): void {
    this.type = this.data.type;
  }

  public mediaRecorded(data: { file: File, options?: { [key: string]: string } }): void {
    if (!data) {
      return;
    }
  
    this.dialogRef.close({ ...data, type: this.type });
  }

}
