import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ListLayoutType } from '../../../models';

@Component({
  selector: 'lib-list-layout-switcher',
  templateUrl: './list-layout-switcher.component.html',
  styleUrls: ['./list-layout-switcher.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ListLayoutSwitcherComponent  {
  @Input() public layout: ListLayoutType = ListLayoutType.GRID;
  @Output() public change: EventEmitter<ListLayoutType> = new EventEmitter<ListLayoutType>();

  public layouts: typeof ListLayoutType = ListLayoutType;
  
  public changeLayout(): void {
    const updated = this.layout === ListLayoutType.GRID ? ListLayoutType.ROW : ListLayoutType.GRID;

    this.change.emit(updated);
  }

}
