import { Capacitor } from '@capacitor/core';
import { Injectable } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor
} from '@angular/common/http';
import { Observable, Subject } from 'rxjs';

import { ViewportScroller } from '@angular/common';
import { Router } from '@angular/router';
import { debounceTime, finalize, take, tap } from 'rxjs/operators';
import { NavigateService } from '@app/services/navigate.service';

@Injectable()
export class ScrollPositionInterceptor implements HttpInterceptor {

  private requestCompleted$ = new Subject<void>();

  constructor(router: Router, private viewportScroller: ViewportScroller, private navigateService: NavigateService) { }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    if (!Capacitor.isNativePlatform()) {
      return next.handle(request);
    }

    this.requestCompleted$.pipe(
      debounceTime(200), // wait for 200ms after the last request is completed
      tap(() => {
        if (!this.navigateService.currentPageUrl || !this.navigateService.prevPageScrollPos) {
          return;
        }

        //If the user is navigating within the same page
        if (this.navigateService.currentPageUrl === this.navigateService.prevPageUrl) {
          const scrollElement = document.getElementById('full-layout-scroll-section');

          if (scrollElement) {
            scrollElement.scrollTo({ left: this.navigateService.prevPageScrollPos[0], top: this.navigateService.prevPageScrollPos[1], behavior: 'auto' });
          } else {
            this.viewportScroller.scrollToPosition(this.navigateService.prevPageScrollPos)
          }
        }
      }),
      take(1) // Take only the first emission after debounceTime
    ).subscribe();

    return next.handle(request).pipe(
      finalize(() => {
        this.requestCompleted$.next(); // Notify that the request is completed
      })
    );

  }

}
