import { Component, ElementRef, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ImageModel } from '../../../common/models';
import { LibraryItem } from '../../models';
import { FileExtensionService } from '../../services/file-extension/file-extension.service';


@Component({
  selector: 'lib-library-details-sidebar',
  templateUrl: './library-details-sidebar.component.html',
  styleUrls: ['./library-details-sidebar.component.scss']
})
export class LibraryDetailsSidebarComponent implements OnInit {
  @Input() allowDownload: boolean;

  @Input() public set libraryItem(value: LibraryItem) {
    this._libraryItem = value;

    if (value) {
      this.previewImage = this.fileExtensionService.getLibraryItemPreviewImage(value);
    }
  }

  public get libraryItem() {
    return this._libraryItem;
  }

  @Output() public download: EventEmitter<LibraryItem> = new EventEmitter();
  @Output() public preview: EventEmitter<LibraryItem> = new EventEmitter();
  @Output() public closed: EventEmitter<void> = new EventEmitter();

  public previewImage: ImageModel;

  private _libraryItem: LibraryItem;

  constructor(
    private readonly fileExtensionService: FileExtensionService,
    private readonly element: ElementRef) { }

  ngOnInit(): void {
    const rect = this.element.nativeElement.getBoundingClientRect();
    const scrollTop = window.scrollY || window.pageYOffset;
    this.element.nativeElement.style.setProperty('--lib-library-sidebar-top', `${rect.top + scrollTop}px`);
  }

  public close(): void {
    this.libraryItem = null;
    this.closed.emit();
  }

}
