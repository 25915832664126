import { Component, forwardRef, Input, OnInit } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';

@Component({
  selector: 'app-option-toggle-switch',
  templateUrl: './option-toggle-switch.component.html',
  styleUrls: ['./option-toggle-switch.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => OptionToggleSwitchComponent),
      multi: true
    }
  ]
})
export class OptionToggleSwitchComponent implements OnInit, ControlValueAccessor {
  @Input() public options: { key: string, label: string }[] = [];

  public selectedOption = null;
  
  constructor() { }

  ngOnInit(): void {
  }

  onChange = (option: string) => {};
  onTouched = () => {};

  public optionSelected(option: string): void {
    this.selectedOption = option;
    this.onChange(option);
  }

  public writeValue(option: string): void {
    this.selectedOption = option;
  }

  public registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  public registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }
}
