import { Pipe, PipeTransform } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

@Pipe({
  name: 'minutesToTime'
})
export class MinutesToTimePipe implements PipeTransform {

  constructor(private readonly translateService: TranslateService) { }

  transform(minutes: number, format: string = 'hh:mm'): string {
    if (!minutes) {
      return;
    }

    let hh: number | string = Math.floor(minutes / 60);
    let mm: number | string = minutes - (hh * 60);


    switch (format) {
      case 'hh:mm':
        hh = hh <= 9 ? '0' + hh : hh;
        mm = mm <= 9 ? '0' + mm : mm;
        return `${hh}:${mm}`;
      case 'long':
      case 'short':
        const hoursTranslated = this.translateService.instant(`COMMON.hours-${format}-format`, { hours: hh });
        const minutesTranslated = this.translateService.instant(`COMMON.minutes-${format}-format`, { minutes: mm });
  
        if (+hh === 0) {
          return minutesTranslated;
        }

        if (+mm === 0) {
          return hoursTranslated;
        }

        return `${hoursTranslated} ${minutesTranslated}`;
    }
  }

}
