import { Component, OnInit, Input, Output, EventEmitter, QueryList, ViewChildren } from '@angular/core';
import { SlideFlipcardsDTO } from '../../../common/models';
import { FlipCardSideComponent } from '../flipcard-side/flipcard-side.component';
import { AbstractControl } from '@angular/forms';


@Component({
  selector: 'lib-flipcard',
  templateUrl: './flipcard.component.html',
  styleUrls: ['./flipcard.component.scss']
})
export class FlipCardComponent implements OnInit {
  @ViewChildren(FlipCardSideComponent) public flipcardSides: QueryList<FlipCardSideComponent>;
  
  @Input() item!: SlideFlipcardsDTO;
  @Input() slide: any;
  @Input() flipcardFormGroup: AbstractControl;
  @Input() backgroundPresigned = true;
  @Input() canEdit = false;
  @Input() isTranslation?: boolean;
  @Input() isRtl?: boolean;
  @Input() errors?: any;

  @Output() edit: EventEmitter<void> = new EventEmitter();
  @Output() changeOrientation: EventEmitter<void> = new EventEmitter();
  @Output() remove: EventEmitter<void> = new EventEmitter();
  @Output() cardFlipped: EventEmitter<void> = new EventEmitter();

  public flipped = false;
  public allowFlipOnClick = false;
  public mousePosition: any = {};

  constructor() { }

  ngOnInit() {
    this.setAllowFlipOnClick();
  }

  toggleFlip() {
    this.flipped = !this.flipped;
    this.cardFlipped.emit();

    const [front, back] = this.flipcardSides.toArray();
    const currentSide = this.flipped ? back : front;

    this.setAllowFlipOnClick();
    
    setTimeout(() => {
      currentSide?.startMedia();
    }, 100);
  }

  flipOnClick(event: MouseEvent): void {
    if (
      !this.allowFlipOnClick
      || Math.abs(this.mousePosition.x - event.screenX) > 10
      || Math.abs(this.mousePosition.y - event.screenY) > 10
    ) {
      return;
    }

    this.toggleFlip();
  }

  public onMouseDown(event) {
    this.mousePosition.x = event.screenX;
    this.mousePosition.y = event.screenY;
  }

  private setAllowFlipOnClick(): void {
    this.allowFlipOnClick = this.flipped
      ? !!(this.item.backText || this.item.backBackgroundColor || this.item.backMediaBackground)
      : !!(this.item.frontText || this.item.frontBackgroundColor || this.item.frontMediaBackground)
  }

}
