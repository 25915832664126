import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Resolve, RouterStateSnapshot } from '@angular/router';
import { AuthService } from '@app/services/auth.service';
import { Observable } from 'rxjs';


@Injectable({ providedIn: 'root' })
export class AuthResolver implements Resolve<boolean> {
  constructor(private readonly authService: AuthService) { }

  public resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> {
    return this.authService.isInitialized();
  }
}
