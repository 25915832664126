<div 
  class="content-card"
  [ngClass]="item.bundle">
  <div 
    class="content-card__image"
    [entityPlaceholder]="!item.mediaImage ? item.bundle : null"
    [class.default]="!item.mediaImage"
    [cropImage]="item.mediaImage | preSignedUrl | async"
    [crop]="item.mediaImage?.crop"
    [additionalOptions]="{ aspect_ratio: '16:9' }">
  </div>

  <div class="content-card__info">
    <div class="content-bundle-time">
      <span class="content-card-label">
        <lib-icon [icon]="item.bundle | entityBundleIcon" [size]="16"></lib-icon>
        {{ ('ENTITY_TYPE.' + item.bundle) | translate }}
      </span>

      <span class="content-time" *ngIf="item.time">
        <lib-icon icon="clock" [size]="16"></lib-icon>
        {{ item.time | minutesToTime : 'short' }}
      </span>
    </div>

    <h4 class="content-card-title">{{ item?.title }}</h4>

   
  </div>

</div>
