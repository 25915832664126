import { HttpClient } from '@angular/common/http';
import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Input, OnInit } from '@angular/core';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';

@Component({
  selector: 'app-svg-icon',
  templateUrl: './svg-icon.component.html',
  styleUrls: ['./svg-icon.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class SvgIconComponent implements OnInit {
  @Input() public url: string;
  @Input() public size = 24;

  public svgIcon: SafeHtml;

  constructor(
    private readonly cdf: ChangeDetectorRef,
    private readonly httpClient: HttpClient,
    private readonly sanitizer: DomSanitizer,
    ) {
  }

  ngOnInit(): void {
    this.httpClient.get(this.url, { responseType: 'text' }) 
      .subscribe(value => {
        this.svgIcon = this.sanitizer.bypassSecurityTrustHtml(value);
        this.cdf.markForCheck();
      });
  }

}
