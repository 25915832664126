import { Directive, ElementRef, Renderer2, Input, AfterViewInit } from '@angular/core';

@Directive({
    selector: '[appHighlight]'
})
export class HighlightDirective implements AfterViewInit {
    @Input() public searchString: string;

    constructor(
        private el: ElementRef,
        private renderer: Renderer2) { }


    ngAfterViewInit(): void {
        const content = this.el.nativeElement.textContent as string;
        if (this.searchString && content.toLocaleLowerCase().indexOf(this.searchString.toLocaleLowerCase()) !== -1) {
            this.renderer.addClass(this.el.nativeElement, 'highlight');
        } else {
            this.renderer.removeClass(this.el.nativeElement, 'highlight');
        }
    }
}
