import { AfterViewInit, Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import Player from '@vimeo/player';

@Component({
  selector: 'app-vimeo-player',
  templateUrl: './vimeo-player.component.html',
  styleUrls: ['./vimeo-player.component.scss']
})
export class VimeoPlayerComponent implements OnInit, AfterViewInit {

  @ViewChild('vimeoPlayer', { static: true }) playerElement!: ElementRef;
  @Input() videoId!: string;
  @Input() hash?: string;

  private player!: Player;
  @Output() started: EventEmitter<void> = new EventEmitter<void>();
  @Output() watched: EventEmitter<void> = new EventEmitter<void>();

  constructor() { }

  ngOnInit() {
  }

  ngAfterViewInit() {
    this.player = new Player(this.playerElement.nativeElement, { url: `https://player.vimeo.com/video/${this.videoId}${this.hash ? `?h=${this.hash}` : ''}` });

    this.player.on('play', () => {
      this.started.emit();
    });
    this.player.getEnded().then(() => {
      this.watched.emit();
    });
  }

  public pause(): void {
    if (!this.player) {
      return;
    }

    this.player.pause();
  }

  public play(): void {
    if (!this.player) {
      return;
    }

    this.player.play();
  }

  public resetVideoTime(): void {
    this.player.setCurrentTime(0);
  }

}
