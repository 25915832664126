import { Inject, Injectable } from '@angular/core';
import { EntityTypeId, ProfileService, StorageKeys, StorageService, STORAGE_KEYS } from 'library-explorer';
import { BehaviorSubject, forkJoin, interval, Observable, of, Subscription } from 'rxjs';
import { catchError, distinctUntilChanged, filter, map, tap, withLatestFrom } from 'rxjs/operators';
import { UserActivityService } from '../user-activity.service';
import { HttpErrorResponse } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class TimeTrackingService {
  private pause = new BehaviorSubject(false);
  
  private readonly MIN_TIME = 1;

  private readonly TRACK_TIME_INTERVAL = 30;

  private subscription: Subscription;

  private currentEntityId: string;

  private storageKey: string;

  private timeSpendStorage: { [id: string]: { time: number } };

  constructor(
    private readonly profileService: ProfileService,
    private readonly userActivityService: UserActivityService,
    @Inject(STORAGE_KEYS) private readonly storageKeys: StorageKeys,
    private readonly storageService: StorageService,
  ) {
    this.initialize();
  }

  public startTracking(id: string): void {
    this.resetTrackingTimer();
  
    this.currentEntityId = id;
    
    if (!this.timeSpendStorage) {
      this.timeSpendStorage = {};
    }

    Object.assign(this.timeSpendStorage, { [id]: { time: 0 }});
  
    this.initializeTimer();
  }

  public resumeTracking(): void {
    this.pause.next(false);
  }

  public pauseTracking(): void {
    this.pause.next(true);
  }

  public submitTime(): Observable<any> {
    if (!this.timeSpendStorage) {
      return of(null);
    }
  
    const keys = Object.keys(this.timeSpendStorage);

    if (!keys.length) {
      return of(null);
    }

    this.resetTrackingTimer();

    return forkJoin(
      keys.map(id => this.submitTypeForEntityById(id))
    );
  }

  private submitTypeForEntityById(id = this.currentEntityId): Observable<void> {
    const storageValue = this.timeSpendStorage?.[id];

    if (!storageValue) {
      return of(null);
    }

    delete this.timeSpendStorage[id];
    this.storeCurrentStateInStorage();

    return this.userActivityService.logSpentTime({ time: storageValue.time || this.MIN_TIME, entityTypeId: EntityTypeId.NODE, entityId: id })
      .pipe(
        catchError((err: HttpErrorResponse) => {
          if (err?.status !== 400) {
            // revert time back
            this.updateTimeSpendForEntity(id, storageValue.time);
            this.storeCurrentStateInStorage();
          }

          return of(null);
        })
      )
  }

  private resetTrackingTimer(): void {
    this.subscription?.unsubscribe();
  }

  private initializeTimer(): void { 
    let seconds = 0;

    this.subscription = interval(1000)
      .pipe(
        withLatestFrom(this.pause),
        filter(([_, paused]) => !paused),
        map(() => seconds++),
        tap(() => this.updateTimeSpendForEntity(this.currentEntityId, 1)),
        tap(() => {
          this.storeCurrentStateInStorage();
        }),
        tap(() => {
          if (seconds % this.TRACK_TIME_INTERVAL === 0) {
            this.submitTypeForEntityById().subscribe();
          }
        })
      )
      .subscribe();
  };

  private updateTimeSpendForEntity(id: string, seconds: number): void {
    let current = this.timeSpendStorage?.[id];

    if (!current) {
      current = { time: seconds };
      Object.assign(this.timeSpendStorage, { [id]: current });
      return;
    }

    current.time += seconds;
  }

  private initialize(): void {
    this.profileService.getCurrentProfile()
      .pipe(
        distinctUntilChanged((old, current) => (old && current) && old.id === current.id)
      )
      .subscribe(profile => {
        this.storageKey = `${this.storageKeys.USER_SPENT_TIME}-${profile?.id}`;

        if (!profile) {
          return;
        }

        this.timeSpendStorage = this.getStorageState();
        this.submitTime().subscribe();
      });
  }

  private getStorageState(): { [id: string]: { time: number } } {
    const storageValue = this.storageService.getItem<{ [id: string]: { time: number } }>(this.storageKey);

    return storageValue || {};
  }

  private storeCurrentStateInStorage(): void {
    this.storageService.setItem(this.storageKey, JSON.stringify(this.timeSpendStorage));
  }
}
