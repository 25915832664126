import { Injectable } from '@angular/core';
import { forkJoin, Observable, of } from 'rxjs';
import { map } from 'rxjs/operators';
import { LessonsResponseDto } from 'src/app/model/lessons-response-dto.model';
import { LessonDTO } from 'src/app/model/lessonsDTO.model';

import { HttpParams } from '@angular/common/http';
import { HttpService, interceptorsToken, BundleType, SettingsFeedbackField } from 'library-explorer';
import { QuizType } from 'src/app/model/enums/quiz-type.enum';
import { QuizDTO } from 'src/app/model/quizDTO.model';

@Injectable({
  providedIn: 'root'
})
export class LessonService {
  constructor(
    private readonly httpService: HttpService
  ) { }

  public getAllLessons(extraParams?: HttpParams): Observable<LessonDTO[]> {
    let params = extraParams || new HttpParams();
    params = params
      .set('active', 'true')
      .set(interceptorsToken.HANDLE_PERMISSION_DENIED_PARAM, 'true');

    return this.httpService.get<LessonsResponseDto>('lessons', params)
      .pipe(
        map(data => data.items)
      );
  }

  public getLessonByID<T = LessonDTO | QuizDTO>(id: string): Observable<T> {
    const params = new HttpParams()
      .set('active', 'true')
      .set('_format', 'json')
      .set('id', id)
      .set(interceptorsToken.HANDLE_PERMISSION_DENIED_PARAM, 'true');

    return this.httpService.get<T>('node', params);
  }

  public getLessonByParentModule(id: string, withoutContentGroup = true): Observable<LessonDTO[]> {
    const params = new HttpParams()
      .set('parentModule', id)
      .set('active', 'true')
      .set('withoutContentGroup', withoutContentGroup ? '1' : '0')
      .set(interceptorsToken.HANDLE_PERMISSION_DENIED_PARAM, 'true');

    return this.httpService.get<LessonsResponseDto>('lessons', params)
      .pipe(
        map(data => data.items || data as any as LessonDTO[])
      );
  }

  public sendFeedback(entityId: string, data: { rate: number, fields?: SettingsFeedbackField[]}): Observable<void> {
    const body = { entityId };

    if (data) {
      Object.assign(body, data);
    } else {
      Object.assign(body, { isCanceled: true });
    }

    return this.httpService.post<void>('feedback', body);
  }

  public startQuizOrLessonEntity(id: string): Observable<void> {
    return this.httpService.post('entity-start', { id });
  }

  public getLessonIndicatorBundle(lesson: LessonDTO & { quizType?: QuizType }): BundleType {
    if (lesson.hasOnlyWebinarSlide) {
      return BundleType.WEBINAR;
    }

    if (lesson.bundle === BundleType.QUIZ) {
      return lesson.quizType === QuizType.POLL_QUIZ ? BundleType.POLL_QUIZ : BundleType.QUIZ;
    }

    return BundleType.LESSON;
  }

}
