import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'adaptiveFontSize'
})
export class AdaptiveFontSizePipe implements PipeTransform {

  transform(htmlContent: string): string {
    if (!htmlContent) {
      return htmlContent;
    }

    const isPhone = window.matchMedia('(max-width: 640px)').matches;

    if (!isPhone) {
      return htmlContent;
    }

    const regex = /(<[^>]*style="[^"]*font-size\s*:\s*([^"]*)"[^>]*>)/g;

    const modifiedHtml = htmlContent.replace(regex, (_match, element) => {
      return element.replace(/font-size\s*:\s*([^;"]*)/g, 'font-size:clamp(var(--mobile-min-font), $1, var(--mobile-max-font))');
    });

    return modifiedHtml;
  }

}
