import { Component, EventEmitter, Input, Output } from '@angular/core';
import { BaseCardComponent } from '../base-card/base-card.component';
import { OfflineDownloadStatusEnum } from '../../../common/models/enums/offline-download-status.enum';

@Component({
  selector: 'lib-course-card',
  templateUrl: './course-card.component.html',
  styleUrls: ['./course-card.component.scss']
})
export class CourseCardComponent extends BaseCardComponent {

  @Input() paymentEnabled = false;
  @Input() availableForDownload = false;
  @Input() showDetailsButton = true;
  @Input() extended = false;
  @Input() wideImage = false;
  @Input() set downloaded(isDownloaded: boolean) {
    this.status = isDownloaded ? OfflineDownloadStatusEnum.DONE : OfflineDownloadStatusEnum.DEFAULT;
  };

  @Output() buttonClick: EventEmitter<Event> = new EventEmitter();
  @Output() detailsClick: EventEmitter<Event> = new EventEmitter();
  @Output() deleteOfflineData: EventEmitter<boolean> = new EventEmitter();
  @Output() saveForOffline: EventEmitter<void> = new EventEmitter();
  @Output() cancelSaveForOffline: EventEmitter<void> = new EventEmitter();

  public set status(data: OfflineDownloadStatusEnum) {
    this._status = data;
    this.setStatusLabel(data);
  }
  public get status(): OfflineDownloadStatusEnum {
    return this._status;
  }

  public statusLabel: any;
  public OfflineDownloadStatusEnum: typeof OfflineDownloadStatusEnum = OfflineDownloadStatusEnum;

  private _status: OfflineDownloadStatusEnum = OfflineDownloadStatusEnum.DEFAULT;

  public detailsClickEmitter(event: Event): void {
    event.stopPropagation();
    this.detailsClick.emit();
  }

  public offlineDownloadHandler(event: Event): void {
    event.stopPropagation();

    if (this.status === OfflineDownloadStatusEnum.IN_PROGRESS) {
      this.status = OfflineDownloadStatusEnum.DEFAULT;
      this.cancelSaveForOffline.emit();
    } else if (this.status === OfflineDownloadStatusEnum.DONE) {
      // TODO: implement - Let’s ask the user in a popup ‘Would you like to delete this content to free up space?‘ 
      
      this.deleteOfflineData.emit();
    } else {
      this.status = OfflineDownloadStatusEnum.IN_PROGRESS;
      this.saveForOffline.emit();
    }
    return;
  }

  private setStatusLabel(status: OfflineDownloadStatusEnum): void {
    switch (status) {
      case OfflineDownloadStatusEnum.IN_PROGRESS:
        this.statusLabel = {
          text: 'COMMON.downloading',
          icon: 'download-simple'
        }
        break;
      case OfflineDownloadStatusEnum.DONE:
        this.statusLabel = {
          text: 'COMMON.downloaded',
          icon: 'archive-tray'
        }
        break;
      default:
        this.statusLabel = {
          text: 'COMMON.download',
          icon: 'download-simple'
        }
    }
  }

}
