import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-progress',
  templateUrl: './progress.component.html',
  styleUrls: ['./progress.component.scss']
})
export class ProgressComponent implements OnInit {

  @Input() progress = 0;
  @Input() iconColor = 'black';
  @Input() textColor = 'black';
  @Input() progressBarColor = 'black';
  @Input() time: number;
  @Input() progressShow = true;
  @Input() itemCountLabel: string;
  @Input() chaptersLeft = false;
  @Input() progressIndicator: 'bar'|'spinner' = 'bar';
  @Input() progressColor: 'primary'|'secondary' = 'secondary';

  constructor() { }

  ngOnInit() {
  }

}
