import { Directive, ElementRef, Input, Renderer2 } from '@angular/core';

@Directive({
  selector: '[padding]'
})
export class PaddingDirective {

  @Input() set padding(value: PaddingPosition) {
    if (!value) {
      return;
    }

    Object.keys(value).forEach(key => {
      const paddingValue = +value[key as keyof PaddingPosition];
      if (!paddingValue && paddingValue !== 0) {
        return;
      }

      this.renderer.setStyle(this.el.nativeElement, `padding-${key}`, `${paddingValue}px`);
    });
  }

  constructor(
    private readonly el: ElementRef,
    private readonly renderer: Renderer2
  ) { }

}

interface PaddingPosition {
  top: number;
  right: number;
  bottom: number;
  left: number;
}
