import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'app-google-model-viewer',
  templateUrl: './google-model-viewer.component.html',
  styleUrls: ['./google-model-viewer.component.scss']
})
export class GoogleModelViewerComponent implements OnInit {
  @Input() public src: string;
  @Output() public loaded: EventEmitter<void> = new EventEmitter();

  public isReady = false;

  constructor() { }

  ngOnInit(): void {
    this.initGoogleModelScript();
  }

  public modelLoaded(): void {
    this.loaded.emit();
  }

  private initGoogleModelScript(): void {
    const scriptId = 'google-model-viewer-script';

    if (document.getElementById(scriptId)) {
      return;
    }

    const script = document.createElement('script');
    script.type = 'text/javascript';
    script.src = '/assets/model-viewer/model-viewer.min.js';
    script.id = scriptId;
    script.type = 'module';
    document.head.append(script);

    script.onload = () => {
      this.isReady = true;
    };
  }
}
