import { Inject, Injectable } from '@angular/core';
import { MatDialog, MatDialogConfig, MatDialogRef, MatDialogState, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { NavigationEnd, NavigationStart, Router } from '@angular/router';
import { filter, first } from 'rxjs/operators';

import { ChatDialogComponent } from '../shared/components/chat-shared/chat-dialog/chat-dialog.component';
import { ChatListDialogComponent } from '../shared/components/chat-shared/chat-list-dialog/chat-list-dialog.component';
import { Observable } from 'rxjs';
import { of } from 'rxjs';
import { NoopScrollStrategy } from '@angular/cdk/overlay';
import { ChatRecordMediaDialogComponent } from '@app/shared/components/chat-shared/chat-record-media-dialog/chat-record-media-dialog.component';
import { ChatMessageType } from 'library-explorer';
import { LanguageService } from './language.service';

@Injectable({
  providedIn: 'root'
})
export class SendbirdHelperService {
  public chatDialog: MatDialogRef<ChatDialogComponent>;
  public listDialog: MatDialogRef<ChatListDialogComponent>;
  public mediaRecordDialog: MatDialogRef<ChatRecordMediaDialogComponent>;

  constructor(
    private readonly router: Router,
    private readonly matDialog: MatDialog,
    private readonly languageService: LanguageService
  ) {
    this.initialize();
  }

  public openChatDialog(): void {
    if (this.chatDialog) {
      this.closeChatListDialog();
      return;
    }

    const isDesktop = window.innerWidth > 991;
    const isRtl = this.languageService.isRtl();

    // Wait for current dialog to close before opening next one, otherwise
    // the scroll block wont be set due to angular cdk waiting for animation to end
    // and several timeouts before finally removing the class. And if it's not removed
    // the following fails: https://github.com/angular/components/blob/59002e1649123922df3532f4be78c485a73c5bc1/src/cdk/overlay/scroll/block-scroll-strategy.ts#L34
    // and we get no scroll block for next modal.
    this.closeChatListDialog().pipe(first()).subscribe(() => {
      const config = {
        closeOnNavigation: true,
        autoFocus: false,
        hasBackdrop: false,
        panelClass: ['sendbird-chat-panel', 'sendbird-chat', 'dialog-light-box-shadow'],
        position: {
          bottom: '0px',
          right: isRtl ? null : '24px',
          left: isRtl ? '24px' : null
        },
        scrollStrategy: new NoopScrollStrategy()
      };

      if (!isDesktop) {
        Object.assign(config, this.getMobileDialogConfig());
      }
      
      this.chatDialog = this.matDialog.open(ChatDialogComponent, config);
    });
  }


  public minimizeChatDialogToggle(minimize = false): void {
    if (!this.chatDialog) {
      return;
    }

    if (minimize) {
      this.chatDialog.addPanelClass('chat-collapsed');
      return;
    }

    this.chatDialog.removePanelClass('chat-collapsed');
  }

  public openChatRecordMediaDialog(type: ChatMessageType, element: HTMLElement): MatDialogRef<ChatRecordMediaDialogComponent> {
    if (this.mediaRecordDialog) {
      this.mediaRecordDialog.close();
    }
  
    const dialogWidth = Math.min(424, window.innerWidth);
    const { top, right } = element.getBoundingClientRect();
    const bottomPossition = window.innerHeight - (top - 20);
    const rightPosition = right - dialogWidth - 20;

    const config = {
      closeOnNavigation: true,
      autoFocus: false,
      hasBackdrop: false,
      panelClass: ['chat-record-media-dialog', type],
      width: `${dialogWidth}px`,
      position: {
        bottom: `${bottomPossition}px`,
        left: `${rightPosition}px`,
      },
      scrollStrategy: new NoopScrollStrategy(),
      data: {
        type
      }
    };

    const isDesktop = window.innerWidth > 991;

    if (!isDesktop) {
      const fullWidth = type === ChatMessageType.VIDEO_MESSAGE;
      const dialogWidth = fullWidth ? '100vw' : '96vw';

      Object.assign(config, {
        width: dialogWidth,
        maxWidth: dialogWidth,
        position: fullWidth ? null : {
          bottom: `${bottomPossition}px`,
          left: '2vw'
        },
      });
    }

    this.mediaRecordDialog = this.matDialog.open(ChatRecordMediaDialogComponent, config);

    return this.mediaRecordDialog;
  }

  public closeChatRecordMediaDialog(): void {
    if (!this.mediaRecordDialog) {
      return;
    }

    this.mediaRecordDialog.close();
  }

  public openChatListDialog(nativeElement: HTMLElement): void {
    const dialogState = this.listDialog && this.listDialog.getState();

    if (dialogState === MatDialogState.OPEN) {
      this.closeChatListDialog();
      return;
    }

    const isDesktop = window.innerWidth > 991;
    const isRtl = this.languageService.isRtl();

    const config = {
      closeOnNavigation: true,
      hasBackdrop: false,
      panelClass: ['sendbird-chat-panel', 'dialog-light-box-shadow'],
      position: {
        top: `${nativeElement?.parentElement.offsetHeight + 12}px`,
        right: isRtl ? null : `60px`,
        left: isRtl ? `60px` : null,
      },
      scrollStrategy: new NoopScrollStrategy()
    };

    if (!isDesktop) {
      Object.assign(config, this.getMobileDialogConfig());
    }

    this.listDialog = this.matDialog.open(ChatListDialogComponent, config);
  }

  public closeChatDialog(): void {
    if (this.chatDialog) {
      this.chatDialog.close();
      this.chatDialog = null;
    }
  }

  public closeChatListDialog(): Observable<void> {
    if (!this.listDialog) {
      return of(null);
    }

    const obs = this.listDialog.afterClosed();
    this.listDialog.close();
    this.listDialog = null;
    return obs;
  }

  private initialize(): void {
    this.router.events
      .pipe(
        filter(event => event instanceof NavigationStart || event instanceof NavigationEnd)
      )
      .subscribe((event) => {
        this.closeChatListDialog();

        if (event instanceof NavigationEnd && event.urlAfterRedirects === '/chat') {
          this.closeChatDialog();
        }
      });
  }

  private getMobileDialogConfig(): MatDialogConfig {
    return {
      hasBackdrop: true,
      position: {
        bottom: '0px',
        left: '0px'
      },
      minWidth: '100vw',
      scrollStrategy: null,
      height: 'calc(100% - 16px)'
    }
  }
}
