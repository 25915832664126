import { Injectable } from '@angular/core';
import { Capacitor } from '@capacitor/core';
import { AppUpdate } from '@capawesome/capacitor-app-update';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { LiveUpdateModalComponent } from '@app/components/live-update-modal/live-update-modal.component';
import { LiveUpdateDownloadState } from '@app/model/enums/live-update-download-state.enum';
import { LiveUpdateService } from './live-update.service';
import { AppUpdateType } from '@app/model/enums/app-update-type.enum';

@Injectable({
  providedIn: 'root'
})
export class NativeAppUpdateService {
  private updateDialogRef: MatDialogRef<any>;

  constructor(private dialog: MatDialog, private liveUpdateService: LiveUpdateService) { }

  async init() {
    try {
      const currentNativeVersion = await this.getCurrentAppVersion();
      const latestNativeVersion = await this.getAvailableAppVersion();
      const updateType = this.getUpdateType(latestNativeVersion, currentNativeVersion);

      if (latestNativeVersion && updateType === AppUpdateType.MAJOR) {
        this.showNativeUpdateAvailableDialog(latestNativeVersion);
        return;
      }

      this.liveUpdateService.init();
    } catch {
      this.liveUpdateService.init();
    }
  }

  async getCurrentAppVersion() {
    if (!this.isNativePlatformAndPluginAvailable()) {
      return;
    }

    const result = await AppUpdate.getAppUpdateInfo();
    if (Capacitor.getPlatform() === 'android') {
      return result.currentVersionCode;
    }

    return result.currentVersionName;
  };

  async getAvailableAppVersion() {
    if (!this.isNativePlatformAndPluginAvailable()) {
      return;
    }

    const result = await AppUpdate.getAppUpdateInfo();
    if (Capacitor.getPlatform() === 'android') {
      return result.availableVersionCode;
    }

    return result.availableVersionName;
  };

  async openAppStore() {
    if (!this.isNativePlatformAndPluginAvailable()) {
      return;
    }

    await AppUpdate.openAppStore();
  };

  private showNativeUpdateAvailableDialog(version: string): void {
    if (this.updateDialogRef) {
      return;
    };

    this.updateDialogRef = this.dialog.open(LiveUpdateModalComponent, {
      data: {
        content: {
          header: 'Update Available',
          description: `You have an update available ${version}\nTap continue`
        },
        status: LiveUpdateDownloadState.COMPLETED,
        okBtnHandler: async () => {
          this.openAppStore();
          this.updateDialogRef.close();
        },
      },
      disableClose: true,
      minWidth: 300
    });

    this.updateDialogRef.afterClosed()
      .subscribe(() => this.updateDialogRef = null);
  }

  // Helper function to compare versions
  private getUpdateType(latest: string, current: string): AppUpdateType {
    if (!latest || !current) {
      return;
    }

    const latestParts = latest.split('.').map(Number);
    const currentParts = current.split('.').map(Number);

    for (let i = 0; i < Math.max(latestParts.length, currentParts.length); i++) {
      const latestPart = latestParts[i] ?? 0;
      const currentPart = currentParts[i] ?? 0;

      if (latestPart > currentPart) {
        return i === 0 ? AppUpdateType.MAJOR : AppUpdateType.MINOR;
      }

      if (latestPart < currentPart) {
        return AppUpdateType.NONE;
      }
    }

    return AppUpdateType.NONE;
  }

  private isNativePlatformAndPluginAvailable(): boolean {
    return Capacitor.isNativePlatform() && Capacitor.isPluginAvailable('AppUpdate');
  }
}
