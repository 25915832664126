<div *ngIf="newStyle; else oldStyleTemplate" class="input-group mb-3">
  <label *ngIf="!hideLabel" for="password">{{'COMMON.password' | translate}}</label>
  <div class="password-input-wrapper">
    <input class="input input--placeholder-grey" id="password" data-cy="password-input" [formControl]="ngControl?.control" [attr.placeholder]="'COMMON.enter_your_password' | translate" [type]="passwordVisible ? 'text' : 'password'">
    <lib-icon class="password-toggle" data-cy="reset-password-password-visibility" [icon]="passwordVisible ? 'eye-slash' : 'eye'" (click)="changePasswordVisibility()"></lib-icon>
  </div>
  <mat-error *ngIf="showError && ngControl?.control.hasError('required')">
    {{'COMMON.password_required' | translate}}
  </mat-error>
</div>

<ng-template #oldStyleTemplate>
  <mat-form-field class="password-input mb-2" appearance="outline">
    <mat-label *ngIf="!hideLabel">
      {{'COMMON.password' | translate }}
    </mat-label>
  
    <input 
      class="form__input" 
      matInput 
      [formControl]="ngControl?.control" 
      [type]="passwordVisible ? 'text' : 'password'"
      [attr.placeholder]="'COMMON.enter_your_password' | translate"
      autocomplete="new-password"
      data-cy="password-input">
  
    <mat-icon 
      class="password-input__suffix-icon" 
      matSuffix 
      (click)="changePasswordVisibility()"
      [svgIcon]="passwordVisible ? 'lms-visibility-off' : 'lms-visibility'">
    </mat-icon>
  </mat-form-field>
  <mat-error *ngIf="showError && ngControl?.control.hasError('required')">
    {{'COMMON.last_name_required' | translate}}
  </mat-error>
</ng-template>


<div 
  *ngIf="settings?.registration?.passwordStrength as passwordStrength" 
  class="password-validation"
  [class.required]="required"
  [class.has-value]="ngControl?.control.value">
  <ng-container *ngIf="passwordStrength.enabled">
    <div 
      *ngFor="let item of validationItems"
      class="password-validation__rule" 
      [class.password-validation__rule--touched]="ngControl?.control?.touched"
      [class.password-validation__rule--valid]="ngControl?.control.value && !ngControl?.control.hasError(item.errorCode)"
      data-cy="password-input-validation-rule">
      <lib-icon class="password-validation__valid-icon" icon="check-circle"></lib-icon>
      <lib-icon class="password-validation__invalid-icon" icon="x-circle"></lib-icon>

      <span>
        {{ item.label | translate : { count: item.data.value} }}
      </span>
    </div>
  </ng-container>
</div>