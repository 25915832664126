
<div  cdkDrag cdkDragRootElement=".cdk-overlay-pane" cdkDragHandle mat-dialog-title class="chat-dialog-title d-flex align-items-center justify-content-between primary-background">
    <mat-icon class="d-none d-lg-block">
        drag_handle
     </mat-icon>
     <span class="flex-grow-1 mx-3 chat-title">{{chatTitle}}</span>
     <mat-icon matDialogClose>
        close
     </mat-icon>
</div>

<div mat-dialog-content class="chat-dialog-content">
    <disqus #disq2us [identifier]="identifier" [style.opacity]="isLoading ? 0 : 1" class="disqus"></disqus>
    <mat-spinner [diameter]="100" *ngIf="isLoading"></mat-spinner>
</div>
