import { AfterViewInit, Component, ElementRef, EventEmitter, HostListener, Inject, Input, OnInit, Optional, Output, Renderer2, ViewChild } from '@angular/core';
import { SafeUrl } from '@angular/platform-browser';
import { ImageModel, VideoModel } from '../../models';
import Hls from 'hls.js';
import * as yaml from 'js-yaml';
import Plyr from 'plyr';
import { BehaviorSubject, Observable, of, Subscription } from 'rxjs';
import { catchError, delay, take, tap } from 'rxjs/operators';
import { HlsjsPlyrDriver } from '../../drivers/hlsjs-plyr-driver';
import { ProviderType } from '../../models/enums/provider-type.enum';
import { InteractiveVideoModel } from '../../models/interactive-video.model';
import { EmbededVideoHelperService } from '../../services/embeded-video-helper.service';
import { VimeoPlayerComponent } from '../vimeo-player/vimeo-player.component';
import { YoutubePlayerComponent } from '../youtube-player/youtube-player.component';
import { BaseSettingsGetService, LanguageStorageService, ProfileService } from '../../services';
import { TranslateService } from '@ngx-translate/core';
import { PLYR_DEFAULT_DRIVER, REGEX_LIST, SETTINGS_SERVICE } from '../../constants';
import { PlyrDriver } from 'ngx-plyr';

@Component({
  selector: 'lib-video',
  templateUrl: './video.component.html',
  styleUrls: ['./video.component.scss']
})
export class VideoComponent implements OnInit, AfterViewInit {
  @ViewChild('videoContainer', {static: true}) public set videoContainerEl(value: ElementRef) {
    this._videoContainerEl = value;
    this.setVideoPlayerSize();
  }

  public get videoContainerEl() {
    return this._videoContainerEl;
  }

  @ViewChild(YoutubePlayerComponent, {static: false}) youtubePlayer: YoutubePlayerComponent;
  @ViewChild(VimeoPlayerComponent, {static: false}) vimeoPlayer: VimeoPlayerComponent;

  @Input() public set src(value: string) {
    this._src = value;
    this.setupVideoDriverAndSources();
  }

  public get src() {
    return this._src;
  }

  @Input() public video: VideoModel;
  @Input() public autoplay = false;
  @Input() public volume = .8;
  @Input() public type!: string;
  @Input() public poster: Partial<ImageModel>;
  @Input() public clickToPlay = true;
  @Input() public controls: string[] = [
    'play-large', 'play', 'current-time', 'progress', 'duration', 'mute', 'volume', 'settings', 'pip', 'fullscreen'
  ];
  @Input() public requiredToWatch = false;
  @Input() public completed = false;

  @Input() public set interactive(value: InteractiveVideoModel) {
    if (value && value.active) {
      this.interactiveData = {
        active: value.active,
        script: this.parseScript(value.script)
      };
    }
  }
  @Input() public set embedVideo(value: any) {
    if (value) {
      this.embed = this.getVideoDataByUrl(value.url || value);

      if (!this.embed) {
        return;
      }

      this.embedThrumbnail = this.embededVideoHelperService.getVideoThumbnail(this.embed.provider as ProviderType, this.embed.id);
      this.initialized.next(this.settingLoaded);
    }
  }

  @Input() public showThumbnailsForEmbed = false;
  @Input() public useCustomThumbnailForEmbed = false;
  @Input() public hideControls = false;
  @Input() public additionalOptions: Partial<Plyr.Options>;

  @Input() public videoStyles: { [key: string]: string }[] = [];

  @Output() started: EventEmitter<void> = new EventEmitter<void>();
  @Output() watched: EventEmitter<void> = new EventEmitter<void>();
  @Output() videoDurationInitialized: EventEmitter<number> = new EventEmitter<number>();
  @Output() videoCanPlay: EventEmitter<void> = new EventEmitter<void>();

  public videoConversionInProgress = false;
  public maxEmbedWidth!: number;
  public youtubeVideoId!: string;
  public embed!: { id: string; hash?: string; start?: number; provider: string } | undefined;
  public embedThrumbnail!: Observable<SafeUrl>;
  public plyr!: Plyr;
  public videoSources!: Plyr.Source[];
  public videoTracks: Plyr.Track[] = [];
  public driver: HlsjsPlyrDriver | PlyrDriver | any;
  public options!: Plyr.Options;
  public plyrVideoWidth = 0;
  public interactiveData!: InteractiveVideoModel;
  public nextKeyFrame: any;
  public isKeyFrameActive = false;
  public wargingMessage!: string;

  public initialized = new BehaviorSubject(false);

  public isAdmin = false;
  public videoWatched = false;

  public userStartWatchingVideo = false;
  private resizerTimeout!: ReturnType<typeof setTimeout>;
  private nextKeyFrameDelay!: Subscription;
  private nextKeyFrameTimeout!: ReturnType<typeof setTimeout>;

  private _videoContainerEl: ElementRef;
  private _src: string;

  private allowChangingVideoPlaybackSpeed = true;

  private settingLoaded = false;

  private readonly conversionInProgressWarning = 'COMMON.video_conversion_in_progress';

  @HostListener('window:resize') onResize(): void {
    if (this.resizerTimeout) {
      clearTimeout(this.resizerTimeout);
    }

    this.resizerTimeout = setTimeout(() => {
      this.setVideoPlayerSize();
    }, 100);
  }

  constructor(
    @Inject(PLYR_DEFAULT_DRIVER) @Optional() public defaultDriver: PlyrDriver,
    @Inject(REGEX_LIST) public regexList: any,
    public translateService: TranslateService,
    private readonly embededVideoHelperService: EmbededVideoHelperService,
    private readonly languageStorageService: LanguageStorageService,
    private renderer: Renderer2,
    private readonly profileService: ProfileService,
    @Inject(SETTINGS_SERVICE) private readonly settingsService: BaseSettingsGetService,
  ) { }

  async ngOnInit() {
    this.driver = this.defaultDriver;
    this.settingsService.getSettings()
      .pipe(
        catchError(async () => await this.initialize())
      )
      .subscribe(async settings => {
        await this.initialize(settings);
      });
  }

  public setupVideoTracks(): void {
    if (!this.video) {
      return;
    }

    const { subtitles = []} = this.video;

    this.videoTracks = subtitles.map(item => {
      const keys = Object.keys(item);

      if (keys.length === 0) {
        return;
      }

      const lang = keys[0];
      const blob = new Blob([item[lang]], { type: 'text/vtt' });

      return {
        kind: 'captions',
        label: this.languageStorageService.getLanguageLabel(lang),
        srcLang: lang,
        src: URL.createObjectURL(blob),
        default: this.translateService.defaultLang === lang
      } as Plyr.Track;
    }).filter(item => !!item) as Plyr.Track[];
  }

  public videoStarted(): void {
    if (!this.userStartWatchingVideo) {
      this.userStartWatchingVideo = true;

      this.started.emit();
    }
  }

  public end(enableConrols = true): void {
    if (enableConrols) {
      setTimeout(() => {
        this.enableVideoProgressControl();
      }, 1000);
    }

    this.completed = true;
    this.markVideoAsWatched();

    if (this.autoplay) {
      this.startVideo();
    }
  }

  public timeUpdate(): void {
    if (this.completed) {
      return;
    }
    
    if (!this.plyr) {
      return;
    }
  
    const { currentTime, duration } = this.plyr;

    if (!currentTime || !duration) {
      return;
    }

    const threshold = 1;

    if (duration - currentTime <= threshold) {
      this.end(false);
    }
  }

  ngAfterViewInit(): void {
    this.setVideoPlayerSize();
  }

  public loadedData(): void {
    if (!this.plyr) {
      return;
    }

    this.videoDurationInitialized.emit(this.plyr.duration);
  }

  public plyrCanPlay(): void {
    this.videoCanPlay.emit();
  }

  public plyrInit(instance: Plyr): void {
    setTimeout(() => {
      this.plyr = instance;
      
      if (!this.allowChangingVideoPlaybackSpeed) {
        this.plyr.speed = 1;
      }

      if (this.hideControls && !this.autoplay) {
        this.plyr.volume = 1;
        this.plyr.speed = 1;
      }

      if (this.autoplay) {
        this.plyr.muted = true;
        this.plyr.volume = 0;
        this.plyr.speed = 1;
        this.plyr.play();
      }
    });
  }

  public hideWarningMessage(): void {
    this.wargingMessage = '';
  }

  private enableVideoProgressControl(): void {
    const controls: string[] = this.options?.controls as string[];

    if (!controls) {
      return;
    }

    const progressControlIndex = controls.indexOf('progress');

    if (progressControlIndex !== -1) {
      return;
    }

    const index = controls.indexOf('play');

    if (index !== -1) {
      controls.splice(index + 1, 0, 'progress');
      this.options = { ...this.options };
    }
  }

  private markVideoAsWatched(): void {
    if (this.videoWatched) {
      return;
    }
  
    this.videoWatched = true;
    this.watched.emit();
  }

  private setWarningMessage(): void {
    const showWarning = this.video && this.video.checkStreamFile && !this.video.stream;
    this.wargingMessage = (showWarning && this.conversionInProgressWarning) || '';
  }

  private getExtension(url: string): string | undefined {
    return url.split(/[#?]/)[0].split('.').pop()?.trim();
  }

  private getVideoDataByUrl(url: string): { id: string; hash?: string; start?: number, provider: string } | undefined {
    const youtubeRegex = this.isYoutubeUrl(url);
    const vimeoRegex = this.isVimeoUrl(url);

    if (youtubeRegex) {
      const id = (youtubeRegex && youtubeRegex[1].length === 11) ? youtubeRegex[1] : '';
      const queryParams: any = youtubeRegex[0].split('?')[1]?.split('&')?.reduce((prev, param) => {
        const [ name, value ] = param.split('=');
        prev[name] = value;
        return prev;
      }, {});

      const start = queryParams?.start || queryParams?.t;

      return { id, provider: 'youtube', start: start ? Number.parseInt(start): null };
    } else if (vimeoRegex) {
      const id = vimeoRegex[3] || '';
      const hash = vimeoRegex[4] || vimeoRegex[5] || '';

      return { id, hash, provider: 'vimeo' };
    }

    return;
  }

  private parseScript(script: string): any {
    const json: any = yaml.load(script);

    if (!json) {
      return;
    }

    json.videoscript.keyframes.forEach((keyframe: any) => {
      keyframe.timestamp = this.keyFrameTimeToSeconds(keyframe.timestamp);

      if (keyframe.action?.timestamp) {
        keyframe.action.timestamp = this.keyFrameTimeToSeconds(keyframe.action.timestamp);
      }

      keyframe.decisions?.forEach((decision: any) => {
        decision.action.timestamp = this.keyFrameTimeToSeconds(decision.action.timestamp);
      });
    });

    return json.videoscript;
  }

  private setVideoPlayerSize(): void {
    if (!this.embed || !this.videoContainerEl) {
      return;
    }

    setTimeout(() => {
      if (!this.videoContainerEl) {
        return;
      }
    
      const containerEl = this.videoContainerEl.nativeElement;
      this.renderer.removeStyle(containerEl, 'max-width');
      this.maxEmbedWidth = Math.floor(containerEl.offsetHeight * (16 / 9));

      if (containerEl.offsetWidth - this.maxEmbedWidth > 2) {
        this.renderer.setStyle(containerEl, 'max-width', `${this.maxEmbedWidth}px`);
      }
    });
  }
  
  public resetVideoTime(): void {
    if (this.plyr && this.plyr.paused) {
      this.plyr.currentTime = 0;
    }
    
    if (this.youtubePlayer) {
      this.youtubePlayer.resetVideoTime();
    }
    
    if (this.vimeoPlayer) {
      this.vimeoPlayer.resetVideoTime();
    }
  }

  public startVideo(): void {
    if (this.plyr && this.plyr.paused) {
      this.plyr.play();
    } else if (this.youtubePlayer) {
      this.youtubePlayer.playVideo();
    } else if (this.vimeoPlayer) {
      this.vimeoPlayer.play();
    }
  }

  public pauseVideo(): void {
    if (this.plyr && this.plyr.playing) {
      this.plyr.pause();
    } else if (this.youtubePlayer) {
      this.youtubePlayer.pauseVideo();
    } else if (this.vimeoPlayer) {
      this.vimeoPlayer.pause();
    }
  }

  public startOrResume(e: any) {
    if (!this.interactiveData || (this.interactiveData && !this.interactiveData.active)) {
      return;
    }

    this.keyFramesHandler();
  }

  public pauseOrFinish(e: any) {
    if (
      !this.interactiveData
      || (this.interactiveData && !this.interactiveData.active)
      || this.isKeyFrameActive
    ) {
      return;
    }

    if (this.nextKeyFrameDelay) {
      this.nextKeyFrameDelay.unsubscribe();
    }
  }

  public keyFrameAction(keyframe: any): void {
    if (!keyframe) {
      return;
    }

    if (this.nextKeyFrameTimeout) {
      clearTimeout(this.nextKeyFrameTimeout);
    }

    const { action } = keyframe;

    switch (action.type || action) {
      case 'gotoframe':
        this.plyr.currentTime = action.timestamp;
        this.startVideo();
        this.isKeyFrameActive = false;
        this.keyFramesHandler();
        break;
      case 'stop':
        this.pauseVideo();
        this.watched.emit();
        break;
      case 'decision':
        if (keyframe.pause) {
          this.pauseVideo();
        }

        if (keyframe.timeout) {
          this.nextKeyFrameTimeout = setTimeout(() => {
            const defaultDecisionIndex = this.nextKeyFrame.defaultDecision;
            this.keyFrameAction(keyframe.decisions?.[defaultDecisionIndex]?.action);
          }, keyframe.timeout * 1000)
        }
        break;
    }
  }

  private async initialize(settings = null): Promise<void> {
    this.settingLoaded = true;
    this.isAdmin = this.profileService.isCurrentUserIsAdmin();
    this.allowChangingVideoPlaybackSpeed = settings?.lesson?.allowChangingVideoPlaybackSpeed;

    this.setVideoControls();
    this.setupVideoTracks();
    await this.setupVideoDriverAndSources();
    this.setWarningMessage();
  }


  private async setupVideoDriverAndSources(): Promise<void> {
    if (!this.settingLoaded) {
      return;
    }

    if (!this._src) {
      this.initialized.next(!!this.embed);

      return;
    }

    const extension = this.getExtension(this.src);
    let type = this.type;

    if (extension === 'm3u8') {
      if (Hls.isSupported()) {
        await this.initializePlyrHlsjsDriver();
      }

      type = 'application/x-mpegURL';
    }

    this.videoSources = [{
      src: this._src,
      type
    }];

    this.initialized.next(true);
  }

  private async initializePlyrHlsjsDriver(): Promise<void> {
    this.driver = new HlsjsPlyrDriver(true, this.videoTracks);
    await this.driver.setQualityOptions(this.src, this.options);
  }

  private setVideoControls(): void {
    const optionsTemp: any = {
      volume: this.volume,
      clickToPlay: this.clickToPlay,
      settings: ['quality', 'captions'],
      keyboard: {
        focused: false,
        global: false
      },
      fullscreen: {
        iosNative: true
      }
    };

    if (this.allowChangingVideoPlaybackSpeed) {
      optionsTemp.settings.push('speed');
      Object.assign(optionsTemp, {  speed: {
          selected: 1,
          options: [0.5, 0.75, 1, 1.25, 1.5, 1.75, 2]
        }
      });
    }

    if (this.additionalOptions) {
      Object.assign(optionsTemp, this.additionalOptions)
    }

    if (this.isAdmin) {
      this.options = optionsTemp;
      return;
    }

    if (this.requiredToWatch && !this.completed) {
      this.controls = this.controls.filter(item => item !== 'progress');
    }

    if (this.controls !== undefined) {
      optionsTemp.controls = this.controls;
    }

    if (this.interactiveData && this.interactiveData.active) {
      optionsTemp.controls = [];
      optionsTemp.clickToPlay = true;
    }

    this.options = optionsTemp;
  }

  private keyFramesHandler(): void {
    const currentTime = this.plyr.currentTime;
    const keyframes =  this.interactiveData.script?.keyframes || [];
    this.nextKeyFrame = this.getNextKeyFrame(keyframes, currentTime);

    if (this.nextKeyFrameDelay) {
      this.nextKeyFrameDelay.unsubscribe();
    }

    if (this.nextKeyFrame) {
      const secondsUntilNextFrame = this.nextKeyFrame.timestamp - this.plyr.currentTime;
      this.nextKeyFrameDelay = of(true).pipe(
        take(1),
        delay(secondsUntilNextFrame * 1000),
        tap(() => {
          this.isKeyFrameActive = true;
          this.keyFrameAction(this.nextKeyFrame);
        }),
      ).subscribe();
    }
  }

  private getNextKeyFrame(keyFrames: any[], currentTime: number): any {
    for (const item of keyFrames) {
      if (item.timestamp > currentTime) {
        return item;
      }
    }
  }

  private keyFrameTimeToSeconds(time: string): number {
    const [hours, minutes, seconds] = time.split(':');
    return +seconds + (+minutes * 60) + (+hours * 60 * 60);
  }

  private isYoutubeUrl(url: string): RegExpMatchArray | null {
    return url.match(this.regexList.youtube);
  }

  private isVimeoUrl(url: string): RegExpMatchArray | null {
    return url.match(this.regexList.vimeo);
  }
}
