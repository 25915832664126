import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AudioPlayerV2Component } from './components/audio-player-v2/audio-player-v2.component';
import { SharedLibraryModule } from "../common/shared-library.module";
import { MatSliderModule } from '@angular/material/slider';



@NgModule({
  declarations: [
    AudioPlayerV2Component
  ],
  imports: [
    CommonModule,
    SharedLibraryModule,
    MatSliderModule
],
  exports: [
    AudioPlayerV2Component
  ]
})
export class AudioPlayerV2Module { }
