import { HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { from, Observable, Subject } from 'rxjs';
import { HttpService } from '../../../common/services';

@Injectable({
  providedIn: 'root'
})
export class LibraryExplorerService {

  public refetchFolderStructure$: Subject<void> = new Subject();

  constructor(
    private readonly httpService: HttpService
  ) { }

  public getLibraryFolderStructure(id: string): Observable<any[]> {
    const params = { type: 'folder', sort: 'title:ASC' };
    let folderStructure: any[] = [];

    const getParent = async (folderId: string, childId?: string): Promise<any[]> => {
      const folder = await this.getLibraries(params, folderId || undefined).toPromise();

      if (folderStructure?.length) {
        const childList = folderStructure;
        folderStructure = folder.items;

        const childIndex = folderStructure.findIndex(item => item.uuid === childId);
        folderStructure[childIndex].children = childList;
      } else {
        folderStructure = folder.items;
      }

      if (folderId) {
        await getParent(folder._meta?.parent, folderId);
      }

      return folderStructure;
    };

    return from(getParent(id));
  }

  public getLibraries(queryParams: any = {}, parentId: string = 'root'): Observable<any> {
    const params: HttpParams = new HttpParams({ fromObject: queryParams });

    return this.httpService.get<any>(`libraries/${parentId}/children`, params);
  }

  public refetchFolderStructure(): void {
    this.refetchFolderStructure$.next();
  }
}
