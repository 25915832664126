import { Component, OnInit } from '@angular/core';
import { LoadingToastService } from '@app/services/loading-toast.service';
import { Observable } from 'rxjs';

@Component({
  selector: 'app-loading-toast',
  templateUrl: './loading-toast.component.html',
  styleUrls: ['./loading-toast.component.scss']
})
export class LoadingToastComponent implements OnInit {
  public isLoading$: Observable<boolean>;

  constructor(private loadingToastService: LoadingToastService) { }

  ngOnInit(): void {
    this.isLoading$ = this.loadingToastService.isLoading;
  }
}
