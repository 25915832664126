<div 
  class="align-items-center d-flex justify-content-end progress-container" 
  data-cy="progress-bar"
  *ngIf="time || (progressShow && progress !== undefined) || itemCountLabel">
  <div class="align-items-center d-flex time" [class.invisible]="!time">
    <lib-icon class="mr-1" icon="clock"></lib-icon>
    <span class="mr-1" data-cy="progress-bar-time">{{time | minutesToTime : 'short' }}</span>
    <span *ngIf="chaptersLeft !== false" data-cy="progress-bar-left-count"> 
      ({{'PROGRESS.chapter_left_count' | translate : { count: chaptersLeft} }})
    </span>
  </div>
  <div  *ngIf="progressShow && progress !== undefined" class="align-items-center d-flex justify-content-end bar">

    <ng-container [ngSwitch]="progressIndicator">
      <mat-progress-bar
        *ngSwitchCase="'bar'"
        class="w-100"
        [ngClass]="progressColor + '-progress-bar'"
        mode="determinate"
        [value]="progress">
      </mat-progress-bar>

      <div class="spinner-wrapper" *ngSwitchCase="'spinner'">
        <mat-progress-spinner
            diameter="22"
            class="spinner-foreground"
            [ngClass]="progressColor + '-progress-spinner'"
            mode="determinate"
            [strokeWidth]="4"
            [value]="progress">
        </mat-progress-spinner>
        <mat-progress-spinner
            diameter="22"
            class="spinner-background"
            mode="determinate"
            [strokeWidth]="4"
            [value]="100">
        </mat-progress-spinner>
      </div>

    </ng-container>
    
    <span>{{progress}}%</span>
  </div>
  <span class="item-count" *ngIf="itemCountLabel">
   {{itemCountLabel}}
  </span>
</div>
