import { Injectable, Injector } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor
} from '@angular/common/http';
import { Observable } from 'rxjs';
import { TranslateService } from '@ngx-translate/core';

@Injectable()
export class AcceptLanguageInterceptor implements HttpInterceptor {

  private translateService: TranslateService;

  constructor(
    private injector: Injector
  ) {}

  intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    this.translateService = this.injector.get(TranslateService);

    request = request.clone({
      setHeaders: {
        'Accept-Language': this.translateService.currentLang || 'en'
      }
    });

    return next.handle(request);
  }
}
