import { Component, OnInit, ViewChild } from '@angular/core';
import { ChatChannel } from '@app/model/chat-channel';
import { SendbirdService } from '@app/services/sendbird.service';
import { ChatMode } from 'src/app/model/enums/chat-mode.enum';
import { ChatChannelComponent } from '../chat-channel/chat-channel.component';

@Component({
  selector: 'app-chat-dialog',
  templateUrl: './chat-dialog.component.html',
  styleUrls: ['./chat-dialog.component.scss']
})
export class ChatDialogComponent implements OnInit {
  @ViewChild(ChatChannelComponent) chatChannelComponent: ChatChannelComponent;
  public mode = ChatMode.DIALOG;

  public groupChannel: ChatChannel;

  public collapsed = false;

  constructor(
    private readonly sendbirdService: SendbirdService) { }

  ngOnInit() {
    this.sendbirdService.activeChannel.subscribe(data => this.groupChannel = data);
  }

  public changeDialogState(): void {
    this.collapsed = !this.collapsed;
    this.sendbirdService.minimizeChatDialogToggle(this.collapsed);
    this.chatChannelComponent?.chatInput?.closeRecordDialog();

    if (!this.collapsed) {
      this.sendbirdService.markChannelAsRead(this.groupChannel);
    }
  }

  public collapseDialog(): void {
    if (this.collapsed) {
      return;
    }

    this.collapsed = true;
    this.sendbirdService.minimizeChatDialogToggle(this.collapsed);
  }

  public closeDialog(): void {
    this.sendbirdService.closeChatDialog();
  }

}
