import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { TooltipPosition } from '@angular/material/tooltip';

@Component({
  selector: 'app-icon-button',
  templateUrl: './icon-button.component.html',
  styleUrls: ['./icon-button.component.scss']
})
export class IconButtonComponent implements OnInit {
  @Input() public icon!: string;

  @Input() public counter = 0;
  @Input() public hasBorder = false;

  @Input() public disabled = false;
  @Input() public isLoading = false;
  @Input() public active = false;
  @Input() public iconClass = '';

  @Input() public tooltip?: string;
  @Input() public tooltipPosition?: TooltipPosition;

  @Input() public size = 48;

  @Output() public clicked: EventEmitter<void> = new EventEmitter<void>();

  constructor() { }

  ngOnInit(): void {
  }

  public clickHandler(): void {
    if (this.disabled || this.isLoading) {
      return;
    }

    this.clicked.emit();
  }
}
