import { Component, Input, OnInit } from '@angular/core';
import { UserActivityType } from 'src/app/model/enums/user-activity-type.enum';
import { UserActivityService } from 'src/app/services/user-activity.service';

@Component({
  selector: 'app-video-block',
  templateUrl: './video-block.component.html',
  styleUrls: ['./video-block.component.scss']
})
export class VideoBlockComponent implements OnInit {
  @Input() block: any;

  public readonly activityTypes: typeof UserActivityType = UserActivityType;

  constructor(private readonly userActivityService: UserActivityService) { }

  ngOnInit() {
  }

  public logUserActivity(type: UserActivityType): void {
    this.userActivityService.logUserVideoActivity(type, this.block.id, this.block.entityTypeId, this.block.mediaVideo);
  }
}
