import { Component, forwardRef, Input, OnInit } from '@angular/core';
import { ControlValueAccessor, FormControl, NG_VALUE_ACCESSOR } from '@angular/forms';
import { DropdownPosition } from '@ng-select/ng-select';
import { debounceTime } from 'rxjs/operators';

@Component({
  selector: 'lib-advanced-select',
  templateUrl: './advanced-select.component.html',
  styleUrls: ['./advanced-select.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => AdvancedSelectComponent),
      multi: true
    }
  ]
})
export class AdvancedSelectComponent implements OnInit, ControlValueAccessor {
  @Input() public set items(value: any[]) {
    this._items = value;
    this.filterOptions();
  }

  public get items() {
    return this._items;
  }

  @Input() public bindLabel: string;
  @Input() public bindValue?: string;
  @Input() public placeholder: string;
  @Input() public multiple = false;
  @Input() public cssClass = '';
  @Input() public clearable = true;
  @Input() public searchable = true;
  @Input() public appendTo = '';
  @Input() public disableAddNew = false;
  @Input() public dropdownPosition: DropdownPosition  = 'auto';

  public showAddNewButton = false;

  public options = [];

  public selectControl = new FormControl();  

  public searchControl = new FormControl();  

  private _items = [];

  constructor() { }

  ngOnInit(): void {
    this.searchControl.valueChanges
      .subscribe(() => {
        this.filterOptions();
        const controlLengthValid = this.searchControl?.value?.length >= 3 && this.searchControl?.value?.length < 40;
      
        this.showAddNewButton = !this.disableAddNew && controlLengthValid && !this.checkIfOptionExist(this.searchControl.value);
      });

    this.selectControl.valueChanges
      .pipe(
        debounceTime(200)
      )
      .subscribe(() => this.onChange(this.selectControl?.value));
  }

  private checkIfOptionExist(option: string): boolean {
    if (!option) {
      return;
    }

    return this._items.some(item => item[this.bindLabel].trim() === option.trim());
  }

  public selectClosed(): void {
    this.searchControl.reset();
  }

  public addTag(): void {
    const tag = this.searchControl.value;
    if (!tag || tag.length < 3) {
      return;
    }

    const currentControlValue = this.selectControl.value || [];

    const newOption = { [this.bindLabel]: tag };
    if (this.bindValue) {
      Object.assign(newOption, { [this.bindValue]: tag });
    }

    this._items.push(newOption);
    
    currentControlValue.push(this.bindValue ? tag : newOption);

    this.selectControl.patchValue(currentControlValue);
    this.filterOptions();
    this.searchControl.reset();
  }

  public handleOpen(): void {
    if (!this.cssClass) {
      return;
    }

    setTimeout(() => {
      // Dynamic class and appendTo bug fix
      const panel = document.querySelector('.ng-dropdown-panel');
      if (panel) {
        panel.classList.add(this.cssClass);
      }
    }, 0);
  }

  onChange = (option: string | string[]) => {};
  onTouched = () => {};

  public writeValue(option: string | string[]): void {
    this.selectControl.patchValue(option, { emitEvent: false });
  }

  public registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  public registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  private filterOptions(): void {
    const searchKey = this.searchControl.value;

    if (!searchKey) {
      this.options = [...this._items];
      return;
    }
  
    this.options = this._items.filter(item => {
      const label = item[this.bindLabel]?.toLocaleLowerCase();
      return label.indexOf(searchKey.toLocaleLowerCase()) !== -1;
    });
  }

}
