import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef } from '@angular/material/dialog';
import { SettingsService } from '@app/services/settings.service';
import { take } from 'rxjs/operators';
import { SettingsProfileFieldType } from 'library-explorer';

@Component({
  selector: 'app-lesson-feedback-modal',
  templateUrl: './lesson-feedback-modal.component.html',
  styleUrls: ['./lesson-feedback-modal.component.scss']
})
export class LessonFeedbackModalComponent implements OnInit {

  public form: FormGroup;
  public ratings: number[];
  public feedbackSettings: any = {};
  public formVisible = false;

  public readonly fieldTypes: typeof SettingsProfileFieldType = SettingsProfileFieldType;

  constructor(
    private readonly dialogRef: MatDialogRef<LessonFeedbackModalComponent>,
    private readonly formBuilder: FormBuilder,
    private readonly settingsService: SettingsService
  ) { }

  ngOnInit() {
    this.ratings = new Array(5).fill(0).map((item, index) => index + 1);
    this.createForm();
    this.settingsService.getSettings().pipe(
      take(1)
    ).subscribe(settings => {
      this.feedbackSettings = settings.feedback || {};
      this.formVisible = !this.feedbackSettings?.showFormButtonEnabled
      this.addFieldsToForm();
    })
  }

  public showForm(): void {
    this.formVisible = true;
  }

  public submit(): void {
    if (this.form.invalid) {
      this.dialogRef.close();
    }

    const { rate, ...fieldsObject } = this.form.value;
    
    const fields = Object.keys(fieldsObject)?.reduce((prev, key) => {
      prev = [...prev, { id: key, value: fieldsObject[key] }];
      return prev;
    }, []);
    
    this.dialogRef.close({rate, fields});
  }

  public setRating(rate: number): void {
    this.form.patchValue({
      rate
    });
  }

  private createForm(): void {
    this.form = this.formBuilder.group({
      rate: [null, Validators.required],
    });
  }

  private addFieldsToForm(): void {
    if (!this.feedbackSettings?.fields?.length) {
      return;
    }

    this.feedbackSettings.fields.forEach(field => {
      const control = this.formBuilder.control('');
      this.form.addControl(field.id, control);
    });
  }

}
