import { HttpClient } from '@angular/common/http';
import { Injectable, Injector } from '@angular/core';
import { TranslateLoader } from '@ngx-translate/core';
import { Observable, of } from 'rxjs';
import { filter, mergeMap, tap } from 'rxjs/operators';
import { sessionKeys } from '../shared/constants/translation-helper.const';
import { NetworkService } from 'library-explorer';
import { StorageMap } from '@ngx-pwa/local-storage';
import { SettingsService } from './settings.service';


@Injectable()
export class CustomTranslationLoaderService implements TranslateLoader {

  constructor(private readonly http: HttpClient, private readonly injector: Injector, private readonly storage: StorageMap) { }

  public getTranslation(lang: string): Observable<any> {
    const service = this.injector.get(SettingsService);
    const networkService = this.injector.get(NetworkService);
    
    return service.getSettings()
      .pipe(
        filter(data => !!data),
        mergeMap(data => {
          const showTranslationKeys = sessionStorage.getItem(sessionKeys.active) === 'true'
          const filePath = data.translation.files[lang] || data.translation.files.en;
          const cleanFilePath = this.removeQueryParams(filePath);

          if (!filePath || showTranslationKeys) {
            return of({});
          }

          if (!networkService.isOnlineValue()) {
            return this.getOfflineLangData(cleanFilePath);
          }

          return this.http.get(filePath).pipe(
            tap(val => this.storeLangDataOffline(cleanFilePath, val))
          );
        })
      );
  }

  private storeLangDataOffline(path: string, fileData: any) {
    this.storage.set(path, fileData).subscribe();
  }

  private getOfflineLangData(path): Observable<any> {
    const langData = this.storage.get(path);
    return langData || of({});
  }

  private removeQueryParams(url: string): string {
    const urlObject = new URL(url);
    urlObject.search = '';
    return urlObject.toString();
  }

}
