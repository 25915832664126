<ng-scrollbar>
  <div class="notification-popup">
    <h3 class="notification-popup__title">{{data.message.title}}</h3>
    <img
      class="notification-popup__image"
      *ngIf="data.message.imageUrl"
      [src]="data.message.imageUrl"
      alt=""/>
    <span class="notification-popup__content" [innerHTML]="data.message.text | safeHtml"></span>
    <ng-container *ngIf="data.message.callToActionButton">
      <button
        mat-raised-button
        [extendedRouterLink]="data.message.callToActionButton.link"
        (click)="close()"
        class="notification-popup__button secondary-button base-button button-rounded">{{data.message.callToActionButton.buttonName}}
      </button>
    </ng-container>
    <hr>
    <div class="notification-popup__footer">
      <div class="notification-popup__user">
        <!-- <img [src]="data.user.pic.uri" [alt]="data.user.pic.alt" />
        <small>{{data.user.name}}</small> -->
      </div>
      <small class="notification-popup__date">
        {{'NOTIFICATION.notification_sent' | translate }}: {{data.sentAt | date:'dd/MM/yyyy'}}
      </small>
    </div>
    <div class="notification-popup__bottom-bar secondary-background"></div>
  </div>
</ng-scrollbar>
