import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Resolve, RouterStateSnapshot } from '@angular/router';
import { Observable } from 'rxjs';

import { SettingsModel } from 'library-explorer';
import { SettingsService } from '@app/services/settings.service';

@Injectable({ providedIn: 'root' })
export class SettingsResolver implements Resolve<SettingsModel> {
    constructor(private service: SettingsService) { }

  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<any> | Promise<any> | any {
        return this.service.getSettings();
    }
}
