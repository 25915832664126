import { Component, Input, OnInit } from '@angular/core';
import { LanguageService } from '@app/services/language.service';

@Component({
  selector: 'app-carousel-block',
  templateUrl: './carousel-block.component.html',
  styleUrls: ['./carousel-block.component.scss']
})
export class CarouselBlockComponent implements OnInit {
  @Input() public block: any;

  public carouselConfig = {
    slidesToShow: 1,
    slidesToScroll: 1,
    lazyLoad: 'ondemand',
    infinity: true,
    arrows: false,
    autoplay: false,
    centerMode: false,
    adaptiveHeight: true,
    dots: true,
    rtl: false
  };

  constructor(
    private readonly languageService: LanguageService
  ) { }

  ngOnInit(): void {
    const isRtl = this.languageService.isRtl();
    if (isRtl) {
      this.carouselConfig.rtl = true;
    }
  }

}
