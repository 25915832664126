import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { LanguageService } from '@app/services/language.service';
import { SettingsService } from '@app/services/settings.service';

@Component({
  selector: 'app-audio-player',
  templateUrl: './audio-player.component.html',
  styleUrls: ['./audio-player.component.scss']
})
export class AudioPlayerComponent implements OnInit, OnDestroy {
  @Input() public color;
  @Input() public secondaryColor = '#ecf0f4';

  @Input() public hideControls = false;
  @Input() public preventTimeSeek = false;

  @Input() public set src(value: string) {
    if (!value) {
      return;
    }

    this.audio = new Audio(value);
    this.audio.onloadedmetadata = () => this.loadedmetadata();
    this.audio.ondurationchange = () => this.durationChanged();
    this.audio.ontimeupdate = (event) => this.timeUpdated(event);
    this.audio.onended = () => this.audioEnded();
  }

  @Output() public audioDurationInitialized: EventEmitter<number> = new EventEmitter();
  @Output() public audioCompleted: EventEmitter<void> = new EventEmitter();

  public primaryColor: string;

  public audioTotalTime = '0:00';

  public totalTime = 100;
  public currentTime = 0;

  public isPlaying = false;

  public percentage = '0';

  public volume: number;

  public isMuted = false;
  public isRtl = false;

  public audio: HTMLAudioElement;

  public step = 5;

  private previousVolume = 0;


  constructor(
    private readonly settingsService: SettingsService,
    private readonly languageService: LanguageService
  ) { }

  ngOnInit() {
    this.primaryColor = this.color || this.settingsService.primaryColor;
    this.isRtl = this.languageService.isRtl();
  }

  ngOnDestroy() {
    if (!this.audio) {
      return;
    }
  
    this.audio.pause();
    this.audio.remove();
  }

  public onTimeChange(change: number): void {
    this.audio.currentTime = change;
    this.percentage = `${change * 100 / this.totalTime }%`;
  }

  public onVolumeChange(value: number) {
    this.audio.volume = value;
    this.audio.muted = !value;
    this.isMuted = !value;
  }

  public durationChanged(): void {
    this.totalTime = this.audio.duration || 0;
    this.audioTotalTime = this.convertTime(this.totalTime);
  }

  public loadedmetadata(): void {
    this.durationChanged();
    this.volume = this.audio.volume;
    this.isMuted = this.audio.muted;
    this.step = this.calculateStep(this.totalTime);
    this.audioDurationInitialized.emit(this.totalTime);
  }

  public toogleAudio(): void {
    if (this.audio.paused) {
      this.isPlaying = true;
      this.audio.play();
      return;
    }

    this.isPlaying = false;
    this.audio.pause();
  }

  public toogleVolume(): void {
    this.audio.muted = !this.audio.muted;
    this.isMuted = this.audio.muted;

    const currentVolume = this.isMuted ? 0 : this.previousVolume;
    this.previousVolume = this.audio.volume || 1;
    this.audio.volume = this.volume = currentVolume;
  }

  public timeUpdated(time) {
    this.currentTime = time.target.currentTime;

    this.percentage = `${this.currentTime * 100 / this.totalTime }%`;
  }

  public audioEnded(): void {
    this.isPlaying = false;
    this.audioCompleted.emit();
  }

  public changeTime(value: number): void {
    let time = this.currentTime + value;
    if (time < 0) {
      time = 0;
    }
  
    if (time > this.totalTime) {
      time = this.totalTime;
    }

    this.currentTime = time;
    this.onTimeChange(time);
  }

  public convertTime(value: number): string {
    if (!value || !isFinite(value)) {
      return null;
    }

    let time = '';
    const duration = Math.round(value);
    const minutes = Math.floor(duration % 3600 / 60);
    const seconds = Math.ceil(duration % 3600 % 60);

    if (minutes < 10 && seconds < 10) {
      time = '0' + minutes + ':0' + seconds;
    } else if (minutes < 10) {
      time = '0' + minutes + ':' + seconds;
    } else if (seconds < 10) {
      time = minutes + ':0' + seconds;
    } else {
      time = minutes + ':' + seconds;
    }

    return time;
  }

  private calculateStep(item: number): number {
    if (item < 10) {
      return 1;
    }

    const value = item * 0.05;

    if (value < 5) {
      return 5;
    }

    const rouded = Math.min(Math.ceil(value / 10), 3) * 10;

    return rouded;
  }
}
