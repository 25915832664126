import { Directive, ElementRef, Input, OnInit } from '@angular/core';

@Directive({
  selector: '[proportionalScale]'
})
export class ProportionalScaleDirective implements OnInit {
  @Input() public set proportionalScale(value: boolean) {
    this._proportionalScale = value;

    this.calculateElementScale();
  }

  public get proportionalScale() {
    return this._proportionalScale;
  }

  private readonly staticElementWidth = 1920;

  private _proportionalScale: boolean;

  constructor(private readonly element: ElementRef) { }

  ngOnInit(): void {
    const observer = new ResizeObserver(() => {
      this.calculateElementScale();
    });
    
    observer.observe(this.element.nativeElement.parentElement);

    this.calculateElementScale();
  }


  private calculateElementScale(): void {
    const isPhone = window.matchMedia('(max-width: 576px)').matches;

    if (isPhone || !this.proportionalScale) {
      this.element.nativeElement.style.transform = 'none';
      this.element.nativeElement.style.transformOrigin = 'none';
      this.element.nativeElement.style.minWidth = 'unset';
      this.element.nativeElement.style.maxWidth = 'unset';
      return;
    }
  
    const originalWidth = this.element.nativeElement?.parentElement?.offsetWidth;

    if (!originalWidth) {
      return;
    }

    const scale = originalWidth / this.staticElementWidth;

    this.element.nativeElement.style.transform = `scale(${scale})`;
    this.element.nativeElement.style.transformOrigin = 'top left';
    this.element.nativeElement.style.minWidth = `${this.staticElementWidth}px`;
    this.element.nativeElement.style.maxWidth = `${this.staticElementWidth}px`;
    this.element.nativeElement.parentElement.style.overflow = 'hidden';
  }
}