import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SharedModule } from 'src/app/shared/shared.module';
import { ChatListComponent } from './chat-list/chat-list.component';
import { ChatListDialogComponent } from './chat-list-dialog/chat-list-dialog.component';
import { ChatDialogComponent } from './chat-dialog/chat-dialog.component';
import { ChatMessagePipe } from './pipes/chat-message.pipe';
import { SharedLibraryModule, RecordMediaModule } from 'library-explorer';
import { ChatChannelMessagesListComponent } from './chat-channel-messages-list/chat-channel-messages-list.component';
import { ChatChannelMessageComponent } from './chat-channel-message/chat-channel-message.component';
import { ChatChannelComponent } from './chat-channel/chat-channel.component';
import { ChatChannelImageComponent } from './chat-channel-image/chat-channel-image.component';
import { ChatMessageInputComponent } from './chat-message-input/chat-message-input.component';
import { ChatRecordMediaDialogComponent } from './chat-record-media-dialog/chat-record-media-dialog.component';
import { ChatLastMessagePipe } from './pipes/chat-last-message.pipe';

@NgModule({
  declarations: [
    ChatListComponent,
    ChatListDialogComponent,
    ChatDialogComponent,
    ChatMessagePipe,
    ChatChannelMessagesListComponent,
    ChatChannelMessageComponent,
    ChatChannelComponent,
    ChatChannelImageComponent,
    ChatMessageInputComponent,
    ChatRecordMediaDialogComponent,
    ChatLastMessagePipe
  ],
  imports: [
    CommonModule,
    SharedModule,
    SharedLibraryModule,
    RecordMediaModule
  ],
  exports: [
    ChatListComponent,
    ChatChannelComponent,
    ChatChannelImageComponent
  ]
})
export class ChatSharedModule { }
