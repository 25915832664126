import { Directive, EmbeddedViewRef, Input, OnInit, TemplateRef, ViewContainerRef } from "@angular/core";
import { NetworkService } from 'library-explorer';
import { debounceTime, distinctUntilChanged } from 'rxjs/operators';

@Directive({
  selector: '[ngIfOffline]',
  inputs: ['ngIfOffline']
})
export class NgIfOfflineDirective implements OnInit {
  @Input() public set ngIfOffline(value: boolean) {
    this.showElementOnOnline = value;
    this.updateView();
  }

  private showElementOnOnline = true;
  private isOnline = false;

  private viewRef: EmbeddedViewRef<Object> = null;

  constructor(
    private readonly networkService: NetworkService,
    private readonly viewContainer: ViewContainerRef,
    private readonly templateRef: TemplateRef<Object>) { }

  ngOnInit(): void {
    this.networkService.isOnline()
      .subscribe(isOnline => {
        this.isOnline = isOnline;
    
        this.updateView()
      });
  }

  private updateView(): void {
    const shouldDisplayElement = this.showElementOnOnline === this.isOnline;

    if (!shouldDisplayElement) {
      this.viewRef = null;
      this.viewContainer.clear();

      return;
    }

    const viewNotExist = !this.viewRef;
    
    if (viewNotExist) {
      this.viewContainer.clear();
      this.viewRef = this.viewContainer.createEmbeddedView(this.templateRef);
    }
  }
}
