import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class ScriptLoaderService {
  loadScript(src: string, id: string): Promise<void> {
    return new Promise((resolve, reject) => {
      const currentScript = document.head.querySelector(`#${id}`);

      if (id && currentScript) {
        resolve();
        return;
      }

      const script = document.createElement('script');
      script.src = src;
      script.onload = () => resolve();
      script.onerror = reject;
      script.id = id;
      document.head.appendChild(script);
    });
  }
}
