import { Pipe, PipeTransform, SecurityContext } from '@angular/core';
import { Observable, of } from 'rxjs';
import { MediaPresignApiService } from '../services';
import { ImageModel, EntityTypeId, BundleType, FieldName, ProviderType } from '../models';
import { DomSanitizer } from '@angular/platform-browser';

@Pipe({
  name: 'preSignedUrl'
})
export class PreSignedUrlPipe implements PipeTransform {

  constructor(private readonly mediaPresignApiService: MediaPresignApiService, public sanitizer: DomSanitizer) {
  }

  public transform(
    image: Partial<ImageModel>,
    entityType = EntityTypeId.PARAGRAPH,
    bundle = BundleType.IMAGE,
    fieldName = FieldName.MEDIA_IMAGE): Observable<string> {

    if (!image) {
      return of('');
    }

    if (image.isOffline && image.filePath) {
      return of(this.sanitizer.sanitize(
        SecurityContext.RESOURCE_URL,
        this.sanitizer.bypassSecurityTrustResourceUrl(image.uri || image.url)
      ));
    }

    if (typeof(image) === 'string') {
      return of(image);
    }

    const isAws = image.provider === ProviderType.AWS && image.key;

    if (isAws) {
      return this.mediaPresignApiService.getPreSignedUrl(entityType, bundle, fieldName, image.key || '', image.preSignedToken);
    }

    return of(image.uri || image.url || '');
  }

}
