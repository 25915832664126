import { AfterViewInit, Directive, ElementRef, EventEmitter, Input, Output } from '@angular/core';
import { fromEvent, timer } from 'rxjs';
import { debounce, distinctUntilChanged, filter, takeWhile } from 'rxjs/operators';

@Directive({
  // tslint:disable-next-line: directive-selector
  selector: '[debounceTime]'
})
export class InputDebounceDirective implements AfterViewInit {
  @Input() public debounceTime = 1500;
  @Input() public isLoading = false;
  @Input() public debounceMinLength = 0;
  // tslint:disable-next-line: no-output-on-prefix
  @Output() public onDebounce = new EventEmitter<any>();

  constructor(private elementRef: ElementRef) { }

  ngAfterViewInit() {
    fromEvent(this.elementRef.nativeElement, 'keyup')
      .pipe(
        filter(() => {
          const value = this.elementRef.nativeElement.value.length;
          return value > this.debounceMinLength || value === 0;
        }),
        debounce((value: KeyboardEvent) => {
          return timer(value.code === 'Enter' ? 0 : this.debounceTime);
        }),
        takeWhile(() => !this.isLoading),
        distinctUntilChanged()
      )
      .subscribe(x => {
        this.onDebounce.emit(x);
      });
  }
}
