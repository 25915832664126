import { Inject, Injectable } from '@angular/core';
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { shareReplay, switchMap, takeUntil, tap } from 'rxjs/operators';
import { HttpService, NetworkService } from 'library-explorer';
import { ProfileService } from 'library-explorer';
import { StorageKeys, StorageService, BranchModel, STORAGE_KEYS, OfflineModeService } from 'library-explorer';

@Injectable({
  providedIn: 'root'
})
export class BranchService {
  public static GLOBAL_BRANCH_KEY = 'GLOBAL_BRANCH';

  public initialized = new BehaviorSubject(false);

  private branches$: Observable<BranchModel[]>;
  private updatingBranch: Subject<void> = new Subject<void>();

  private _currentBranch: string;

  constructor(
    @Inject(STORAGE_KEYS) public storageKeys: StorageKeys,
    private readonly storageService: StorageService,
    private readonly http: HttpService,
    private readonly profileService: ProfileService,
    private readonly networkService: NetworkService,
    private readonly offlineModeService: OfflineModeService,
  ) {
    this.init();
  }

  public getCurrentBranch() {
    return this._currentBranch;
  }

  public setCurrentBranch(branchName: string) {
    this._currentBranch = branchName || '';
    this.setSelectedBranchToStorage(this._currentBranch);
  }

  public getBranches(force?: boolean): Observable<BranchModel[]> {
    if (!this.branches$ || force) {
      this.branches$ = this.http.get<BranchModel[]>('manage-branches', null).pipe(
        tap((data) => {
          this.offlineModeService.storeRequest<BranchModel[]>('manage-branches', data);
        }),
        shareReplay({ bufferSize: 1, refCount: true }),
      );
    }

    return this.branches$;
  }

  public selectBranch(id: string): void {
    this.getBranches().subscribe((data: BranchModel[]) => {
      const branch = data.find(item => item.branchId === id);
      this.setCurrentBranch(branch?.url || BranchService.GLOBAL_BRANCH_KEY);
      window.location.reload();
    });
  }

  public updateBranchStatus(branchId: string, isMember: boolean): Observable<BranchModel[]> {
    const currentUser = this.profileService.getCurrentProfileValue();
    const userId = currentUser.id;
    this.resetUpdatingBranchSubject();
    return this.http.post('manage-branches', {
      id: userId,
      branchId,
      isMember
    }).pipe(
      takeUntil(this.updatingBranch),
      switchMap(() => this.getBranches(true))
    );
  }

  private resetUpdatingBranchSubject(): void {
    this.updatingBranch.next();
    this.updatingBranch.complete();
    this.updatingBranch = new Subject<void>();
  }

  private init(): void {
    this._currentBranch = this.storageService.getItem(this.storageKeys.BRANCH_NAME);
    this.initialized.next(true);
  }

  private setSelectedBranchToStorage(branchName: string): void {
    this.storageService.setItem(this.storageKeys.BRANCH_NAME, branchName);
  }
}
