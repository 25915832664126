import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SharedModule } from 'src/app/shared/shared.module';
import { CarouselComponent } from '../commons/carousel/carousel.component';
import { DashboardItemComponent } from './dashboard-item/dashboard-item.component';
import { DashboardItemListComponent } from './dashboard-item-list/dashboard-item-list.component';
import { DashboardHighlightsBlockComponent } from './dashboard-highlights-block/dashboard-highlights-block.component';
import { CourseCarouselItemComponent } from '../course-carousel-item/course-carousel-item.component';
import { ContentHighlightBlockComponent } from './content-highlight-block/content-highlight-block.component';
import { PromotionBlockComponent } from './promotion-block/promotion-block.component';
import { SharedLibraryModule, ContentCardModule } from 'library-explorer';

@NgModule({
  declarations: [
    CarouselComponent,
    CourseCarouselItemComponent,
    DashboardItemComponent,
    DashboardItemListComponent,
    DashboardHighlightsBlockComponent,
    ContentHighlightBlockComponent,
    PromotionBlockComponent
  ],
  imports: [
    CommonModule,
    SharedModule,
    SharedLibraryModule,
    ContentCardModule
  ],
  exports: [
    CarouselComponent,
    DashboardItemComponent,
    DashboardItemListComponent,
    DashboardHighlightsBlockComponent,
    CourseCarouselItemComponent,
    ContentHighlightBlockComponent,
    PromotionBlockComponent
  ]
})
export class DashboardModule { }
