import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, of } from 'rxjs';
import { LegalDocumentModel, interceptorsToken, OfflineModeService } from 'library-explorer';
import { DataResponse } from 'src/app/model/data-response.model';
import { HttpService, ProfileService } from 'library-explorer';
import { HttpParams } from '@angular/common/http';
import { shareReplay, switchMap, tap } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class LegalCenterService {

  public hasPendingDocuments: BehaviorSubject<boolean>;
  public redirectUrl = '';

  constructor(
    private readonly profileService: ProfileService,
    private httpService: HttpService,
    private offlineModeService: OfflineModeService
  ) { }

  public getDocuments(queryParams?: any): Observable<DataResponse<LegalDocumentModel>> {
    const params = new HttpParams({ fromObject: queryParams });
    return this.httpService.get<DataResponse<LegalDocumentModel>>('user-legal-center', params).pipe(
      tap((legalDocuments) => {
        this.offlineModeService.storeRequest(`user-legal-center?${params.toString()}`, legalDocuments);
      })
    );
  }

  public getDocumentById(id: string): Observable<LegalDocumentModel> {
    const params = new HttpParams()
      .set('id', id)
      .set(interceptorsToken.HANDLE_PERMISSION_DENIED_PARAM, 'true');

    return this.httpService.get<LegalDocumentModel>('node', params);
  }

  public getDocumentByGroupId(groupId: string): Observable<LegalDocumentModel> {
    const params = new HttpParams()
      .set(interceptorsToken.HANDLE_PERMISSION_DENIED_PARAM, 'true');

    return this.httpService.get<LegalDocumentModel>(`legal-center-public/${groupId}`, params);
  }

  public acceptDocuments(ids: string[]): Observable<any> {
    return this.httpService.post<any>('user-legal-center', { ids }).pipe(
      tap(() => {
        this.hasPendingDocuments?.next(false);
      })
    );
  }

  public checkPendingDocuments(): Observable<boolean> {
    if (!this.hasPendingDocuments) {
      const session = this.profileService.getCurrentProfileValue();
      return this.getDocuments({ userId: session?.id, approved: 0 }).pipe(
        tap(data => {
          const hasPending = !!data?.items.length;
          this.hasPendingDocuments = new BehaviorSubject(hasPending);
        }),
        shareReplay({ bufferSize: 1, refCount: true }),
        switchMap(() => this.hasPendingDocuments)
      );
    }

    return this.hasPendingDocuments;
  }

}
