import { Attribute, Directive, ElementRef, Input, OnInit, Renderer2 } from '@angular/core';
import { ActivatedRoute, Router, RouterLink } from '@angular/router';
import { BasicPageService } from '@app/services/api/basic-page.service';
import { Observable, of } from 'rxjs';
import { map } from 'rxjs/operators';

@Directive({
  selector: '[extendedRouterLink]'
})
export class ExtendedRouterLinkDirective extends RouterLink implements OnInit {
  @Input() public extendedRouterLink: string;
  @Input() public pageId: string;

  private isExternalLink = false;
  private href: string;

  constructor(
    router: Router, 
    route: ActivatedRoute, 
    @Attribute('tabindex') tabIndexAttribute: string | null | undefined, 
    renderer: Renderer2, 
    el: ElementRef,
    private readonly pagesService: BasicPageService) {
    super(router, route, tabIndexAttribute, renderer, el);
  }

  ngOnInit(): void {
    if (this.pageId) {
      this.loadPageAlias().subscribe(alias => {
        this.setRouterLinkAndHref(alias);
      });
        
      return;
    }
  
    this.setRouterLinkAndHref(this.extendedRouterLink);
  }

  private loadPageAlias(): Observable<string> {
    if (!this.pageId) {
      return of(null);
    }
  
    return this.pagesService.getPageById(this.pageId)
      .pipe(
        map(page => page.alias)
      );
  }

  private setRouterLinkAndHref(url: string): void {
    if (!url) {
      return;
    }
  
    this.href = decodeURIComponent(url);
    const regex = /https?:\/\/((?:[\w\d-]+\.)+[\w\d]{2,})/i;
    this.isExternalLink = regex.test(this.href);

    if (!this.isExternalLink) {
      const linkSplitted = url.split('?');
      this.routerLink = linkSplitted[0];
      if (linkSplitted.length > 1) {
        const params = linkSplitted[1].split('&');
        this.queryParams = params.reduce((all, current) => {
          const splittedCurrent = current.split('=');
          return {
            ...all,
            [splittedCurrent[0]]: splittedCurrent[1]
          };
        }, {});
      }
    }
  }

  public onClick(): boolean {
    if (this.isExternalLink) {
      const link = decodeURIComponent(this.href);
      window.open(link, '_blank');
      return false;
    }

    return super.onClick();
  }

}
