<section class="learning-path-block" [style.color]="block.textColor">
  <div class="content">
    <h1 *ngIf="block.title" [innerHtml]="block.title | safeHtml" [rtl]="block.langcode"></h1>
    <div class="learning-path-block__content row flex-column flex-lg-row">
      <div class="col-12 px-0 col-xl-8 col-xxl-9">
        <ngx-slick-carousel
          slickCarouselResize
          class="carousel" 
          #slickModal="slick-carousel"
          (afterChange)="slideChange($event)"
          [config]="carouselConfig">
          <div class="slide" ngxSlickItem *ngFor="let learningPath of learningPathList; index as i">
            <div class="learning-path-block__card">
              <div
                class="learning-path-block__card-image"
                [cropImage]="(learningPath.mediaImage | preSignedUrl : learningPath.entityTypeId : learningPath.bundle) | async" 
                [crop]="learningPath.mediaImage?.crop"
                (click)="navigateToLearningPath(learningPath)">
              </div>
              <div class="learning-path-block__card-content">
                <div class="learning-path-block__card-details">
                  <div class="learning-path-block__card-details-header">
                    <h3>{{'LEARNING_PATH.learning_path' | translate}}</h3>
                    <div class="learning-path-block__card-details-deadline" *ngIf="learningPath.deadline" [class.overdue]="learningPath.overdue">
                      <ng-container *ngIf="!learningPath.overdue; else overdueTextTmp">
                        {{'LEARNING_PATH.gain_points_deadline' | translate:{date: learningPath.deadline | customDate} }}
                      </ng-container>
                      <ng-template #overdueTextTmp>
                        {{'LEARNING_PATH.past_deadline' | translate:{date: learningPath.deadline | customDate} }}
                      </ng-template>
                    </div>
                  </div>
                  <h1 class="learning-path-block__card-details-title" (click)="navigateToLearningPath(learningPath)">{{learningPath.title}}</h1>
                  <div class="learning-path-block__card-details-footer">
                    <app-progress progressColor="primary" [progress]="learningPath.progress" [time]="learningPath.remainingMinutes"></app-progress>
                  </div>
                </div>
                <ng-container *ngIf="currentSlideIndex === i">
                  <ng-container *ngIf="learningPath.nextLesson | async as nextLesson; else nextLessonLoadingTmp">
                    <div *ngIf="nextLesson?.uuid" class="learning-path-block__card-next-path">
                      <div class="learning-path-block__card-next-path-label">Next on your path</div>
                      <div class="learning-path-block__card-next-path-image-wrapper">
                        <div
                          *ngIf="nextLesson.mediaImage else imagePlaceholderTmp"
                          class="learning-path-block__card-next-path-image" 
                          imageWidth="400"
                          [cropImage]="(nextLesson.mediaImage | preSignedUrl : nextLesson.entityTypeId : nextLesson.bundle) | async" 
                          [crop]="nextLesson.mediaImage?.crop">
                        </div>
                        <ng-template #imagePlaceholderTmp>
                          <div class="learning-path-block__card-next-path-image placeholder" [entityPlaceholder]="nextLesson.bundle"></div>
                        </ng-template>
                      </div>
                      <div class="learning-path-block__card-next-path-body">
                        <h4 [class.quiz]="nextLesson.bundle === BundleType.QUIZ || nextLesson.bundle === BundleType.POLL_QUIZ">{{ bundleTypeTextMap[nextLesson.bundle] | translate }}</h4>
                        <h2>{{nextLesson.title}}</h2>
                        <app-progress progressColor="primary" [progressShow]="false" [time]="nextLesson.time"></app-progress>
                        <button 
                          mat-flat-button 
                          class="button button--green button--large" 
                          [disableRipple]="true" 
                          [routerLink]="['/chapters']" 
                          [queryParams]="{lessonID: nextLesson.uuid, courseID: nextLesson.parentCourseId, from: NavigateFrom.LEARNING_PATH, fromId: learningPath.uuid}">
                          {{ (nextLesson.progress > 0 ? 'COMMON.continue_lesson' : 'COMMON.start_lesson') | translate}}
                        </button>
                      </div>
                    </div>
                  </ng-container>
                </ng-container>
              </div>
            </div>
          </div>
        </ngx-slick-carousel>
      </div>
      <div class="col-12 px-0 col-xl-4 col-xxl-3">
        <div class="learning-path-block__counter-list">
          <div class="learning-path-block__counter-item active-learning-paths">
            <div class="learning-path-block__counter-item__icon">
              <mat-progress-spinner class="primary-progress-spinner" diameter="60" mode="determinate" [strokeWidth]="3" [value]="80"></mat-progress-spinner>
            </div>
            <div class="learning-path-block__counter-item__body">
              <div class="learning-path-block__counter-item__value">{{activeLearningPaths}}</div>
              <div class="learning-path-block__counter-item__name">{{'LEARNING_PATH.active_learning_paths' | translate: {count: activeLearningPaths} }}</div>
            </div>
          </div>
          <div class="learning-path-block__counter-item completed-learning-paths">
            <div class="learning-path-block__counter-item__icon">
              <mat-icon svgIcon="lms-cup"></mat-icon>
            </div>
            <div class="learning-path-block__counter-item__body">
              <div class="learning-path-block__counter-item__value">{{completedLearningPaths}}</div>
              <div class="learning-path-block__counter-item__name">{{'LEARNING_PATH.completed_learning_paths' | translate: {count: completedLearningPaths} }}</div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>

<ng-template #nextLessonLoadingTmp>
  <mat-spinner diameter="40"></mat-spinner>
</ng-template>
