<div class="content-highlight" *ngIf="items && items.length" [style.color]="block.textColor">
  <div class="row px-4">
    <h4 class="content-highlight__title" [rtl]="block.langcode">{{title}}</h4>

    <div class="col-12 px-0 w-100" [class.py-3]="!block.sliderEnabled">
      <ng-container *ngIf="block.sliderEnabled; else listView">
        <ngx-slick-carousel
          slickCarouselResize
          class="content-highlight__carousel" 
          [config]="carouselConfig" 
          (init)="slickInit($event)"
          (breakpoint)="breakpoint($event)"
          (afterChange)="afterChange($event)"
          #slickModal>
          <div ngxSlickItem *ngFor="let item of items" class="content-highlight__slide py-3">
            <ng-container *ngTemplateOutlet="slideTmp; context: { item: item }"></ng-container>
          </div>
        </ngx-slick-carousel>

        <div class="d-none d-lg-flex carousel-buttons-wrapper" *ngIf="showCarouselBtns">
          <button color="primary" mat-fab class="carousel-button left" [style.color]="block.textColor" [style.border-color]="block.textColor" [class.disabled]="isFirstSlide | async" (click)="slickModal.slickPrev()">
            <mat-icon>navigate_before</mat-icon>
          </button>
          <button color="primary" mat-fab class="carousel-button right" [style.color]="block.textColor" [style.border-color]="block.textColor" [class.disabled]="isLastSlide | async" (click)="slickModal.slickNext()">
            <mat-icon>navigate_next</mat-icon>
          </button>
        </div>
      </ng-container>
    </div>
  </div>
</div>

<ng-template #listView>
  <div class="content-highlight__list-view">
    <div class="content-highlight__row cards-base-grid">
      <div *ngFor="let item of items" class="content-highlight__slide">
        <ng-container *ngTemplateOutlet="slideTmp; context: { item: item }"></ng-container>
      </div>
    </div>
  </div>
</ng-template>

<ng-template let-item="item" #slideTmp>
  <ng-container [ngSwitch]="item.bundle">
    <lib-class-card
      *ngSwitchCase="bundleTypes.CLASS"
      [data]="item"
      [orientation]="imageOrientation"
      (cardClick)="navigate($event, item)"
      (mousedown)="onMouseDown($event)">
    </lib-class-card>
    <lib-course-card
      *ngSwitchCase="bundleTypes.COURSE"
      [data]="item"
      [paymentEnabled]="isPaymentEnabled"
      (cardClick)="navigate($event, item)"
      (buttonClick)="navigate($event, item)"
      (detailsClick)="openDetails(item)"
      (mousedown)="onMouseDown($event)">
    </lib-course-card>
    <lib-course-card
      *ngSwitchCase="bundleTypes.MODULE"
      [data]="item"
      [showDetailsButton]="false"
      (cardClick)="navigate($event, item)"
      (buttonClick)="navigate($event, item)"
      (mousedown)="onMouseDown($event)">
    </lib-course-card>
    <lib-lesson-card
      *ngSwitchDefault
      [data]="item"
      (cardClick)="navigate($event, item)"
      (mousedown)="onMouseDown($event)">
    </lib-lesson-card>
  </ng-container>
</ng-template>
