import { Pipe, PipeTransform } from '@angular/core';
import { Observable } from 'rxjs';
import { CloudImageService } from '../services/cloud-image.service';

@Pipe({
  name: 'imageCrop'
})
export class CropImagePipe implements PipeTransform {

  constructor(private readonly cloudImageService: CloudImageService) {
  }

  public transform(url: string, crop: string): Observable<string> {
    return this.cloudImageService.generateCloudImageResourceUrl(url, null, null, crop);
  }
}
