import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class LayoutService {

  private contentOnlyMode$: BehaviorSubject<boolean> = new BehaviorSubject(false);
  private topPaddingOnPhone$: BehaviorSubject<boolean> = new BehaviorSubject(false);

  constructor() { }

  public get contentOnlyMode(): Observable<boolean> {
    return this.contentOnlyMode$.asObservable();
  }
  public get topPaddingOnPhone(): Observable<boolean> {
    return this.topPaddingOnPhone$.asObservable();
  }

  public get contentOnlyModeValue(): boolean {
    return this.contentOnlyMode$.value;
  }

  public toggleContentOnlyMode(): void {
    this.contentOnlyMode$.next(!this.contentOnlyMode$.value);
  }

  public enableContentOnlyMode(): void {
    this.contentOnlyMode$.next(true);
  }

  public disableContentOnlyMode(): void {
    this.contentOnlyMode$.next(false);
  }

  public removeTopPaddingOnPhone(): void {
    this.topPaddingOnPhone$.next(true);
  }

  public resetTopPaddingOnPhone(): void {
    this.topPaddingOnPhone$.next(false);
  }
  
}
