export enum EntityTypeId {
  NODE = 'node',
  MEDIA = 'media',
  PARAGRAPH = 'paragraph',
  USER = 'user',
  ASSIGNMENT = 'assignment',
  SETTINGS = 'settings',
  COMMENT = 'comment',
  CHAT_CHANNEL = 'channel'
}
