<div class="chats-list" [class.dialog-mode]="chatMode === chatModes.DIALOG">
  <ng-scrollbar class="chats-scrollbar" [class.has-scroll]="hasScroll" [autoHeightDisabled]="false" (updated)="scrollUpdated()">
    <div class="chats-container" *ngIf="hasChannels && chatListInitialized; else emptyTmp">
      <div 
        class="chat-item" 
        [class.active]="item === activeChannel"
        (click)="setActiveChannel(item)" 
        *ngFor="let item of channels; trackBy: trackByFn">
    
        <app-chat-channel-image [channel]="item"></app-chat-channel-image>
    
        <div class="chat-item-info">
          <span 
            ellipsis
            class="chat-item-title ellipsis"
            [textContent]="item.name">
          </span>

          <div class="chat-item-message" *ngIf="item.lastMessage as lastMessage">
            <span 
              ellipsis 
              class="message-text ellipsis"
              [textContent]="lastMessage | chatLastMessage | async">
            </span>

            <span class="message-new-indicator" *ngIf="item.unreadMessageCount">
              {{ 'CHAT.new' | translate }}
            </span>

             <span class="message-date" *ngIf="item.lastMessage?.createdAt | dateDiff as date">{{date}}</span>
          </div>

        </div>
      </div>
    </div>
  </ng-scrollbar>

  <ng-template #loadingTmp>
    <div class="chats-container">
      <div class="chat-item" *ngFor="let item of placeholders">
        <lib-skeleton-loader height="32px" width="32px" className="circle"></lib-skeleton-loader>

        <div class="chat-item-info">
          <lib-skeleton-loader height="20px" width="60%"></lib-skeleton-loader>
          <lib-skeleton-loader height="16px" width="80%"></lib-skeleton-loader>
        </div>
      </div>
    </div>
  </ng-template>
  
  <ng-template #emptyTmp>
    <ng-container *ngIf="!isLoading; else loadingTmp">
      <app-list-empty-state 
        *ngIf="chatMode === chatModes.DIALOG"
        icon="chat-dots"
        [title]="'CHAT.no_group_chats_yet' | translate"
        [text]="'CHAT.group_chats_will_show_here' | translate">
      </app-list-empty-state>
    </ng-container>    
  </ng-template>
  
</div>
