import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { HttpService, interceptorsToken } from 'library-explorer';
import { ModuleDTO } from 'src/app/model/moduleDTO.model';
import { HttpParams } from '@angular/common/http';
import { map, tap } from 'rxjs/operators';
import { ModuleResponseDto } from 'src/app/model/modules-response-dto.model';
import { LessonDTO } from '@app/model/lessonsDTO.model';
import { DataResponse } from '@app/model/data-response.model';

@Injectable({
  providedIn: 'root'
})
export class ModuleService {

  constructor(
    private httpService: HttpService
  ) { }

  public getAllModules(courseID: string = null, extraParams?: HttpParams): Observable<ModuleDTO[]> {
    let params = extraParams || new HttpParams();

    params = params
      .set('active', 'true')
      .set('format', 'json')
      .set(interceptorsToken.HANDLE_PERMISSION_DENIED_PARAM, 'true');

    if (courseID) {
      params = params.set('parentCourse', courseID);
    }

    return this.httpService.get<ModuleResponseDto>('modules', params)
      .pipe(
        map(data => data.items)
      );
  }

  public getModulesByCourseID(id: string): Observable<ModuleDTO[]> {
    const params = new HttpParams()
      .set('parentCourse', id)
      .set('active', 'true')
      .set(interceptorsToken.HANDLE_PERMISSION_DENIED_PARAM, 'true');
    
    return this.httpService.get<ModuleResponseDto>('modules', params)
      .pipe(
        map(data => data.items || data as any as ModuleDTO[])
      );
  }

  public getModuleByID(id: string): Observable<ModuleDTO> {
    const params = new HttpParams()
      .set('active', 'true')
      .set('_format', 'json')
      .set('id', id)
      .set(interceptorsToken.HANDLE_PERMISSION_DENIED_PARAM, 'true');

    return this.httpService.get<ModuleDTO>('node', params);
  }

  public getModuleContents(id: string, offset = 0, limit = 20): Observable<DataResponse<LessonDTO>> {
    const params = new HttpParams()
      .set('offset', offset.toString())
      .set('limit', limit.toString());

    return this.httpService.get<DataResponse<LessonDTO>>(`module/${id}/contents`, params);
  }
}
