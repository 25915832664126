import { Component, OnInit } from '@angular/core';
import { combineLatest, Observable, of, Subscription, timer } from 'rxjs';
import { catchError, filter, finalize, map, mergeMap, skip, tap } from 'rxjs/operators';
import { BasicPage } from 'src/app/model/basic-page.model';
import { BasicPageService } from 'src/app/services/api/basic-page.service';
import { MaintenanceType, MaintenanceService } from 'library-explorer';
import packageJson from '../../../../package.json';
import { SettingsService } from '@app/services/settings.service';

@Component({
  selector: 'app-maintenance',
  templateUrl: './maintenance.component.html',
  styleUrls: ['./maintenance.component.scss']
})
export class MaintenanceComponent implements OnInit {
  public enabled = false;
  public type: MaintenanceType;
  public version: string;
  public lottieOptions: any = {
    autoplay: true,
    loop: true,
    path: '/assets/lottie/maintenance.json'
  };
  public softMaintenancePage: BasicPage;
  public types: typeof MaintenanceType = MaintenanceType;

  public expectedTimeLeft$: Observable<number>;
  public secondsTilNextRequest = 10;
  private intervalSubscription: Subscription;

  private readonly pollingTimeotInSeconds = 10;
  private readonly expectedMaintenanceTimeInSeconds = 120;

  constructor(
    private readonly basicPageService: BasicPageService,
    private readonly settingsService: SettingsService,
    private readonly maintenanceService: MaintenanceService
  ) { }

  ngOnInit(): void {
    this.initialize();
  }

  public initialize(): void {
    this.version = packageJson.version;
    this.secondsTilNextRequest = this.pollingTimeotInSeconds;
    this.maintenanceService.maintenanceModeEnabled
      .pipe(
        tap(() => {
          this.intervalSubscription && this.intervalSubscription.unsubscribe();
        }),
        tap((data) => {
          this.enabled = data && data.enabled;
          this.type = data && data.type;

          if (!this.enabled) {
            this.secondsTilNextRequest = this.pollingTimeotInSeconds;
          }
        }),
        filter(() => this.enabled)
      )
      .subscribe(() => {
        if (this.type === MaintenanceType.SOFT_MAINTENANCE) {
          this.getSoftMaintenanceSettings();
        }

        this.expectedTimeLeft$ = timer(0, 1000)
          .pipe(
            map((count) => this.expectedMaintenanceTimeInSeconds - count)
          );
        this.startReloadTimeout();
      });
  }

  private startReloadTimeout(): void {
    this.intervalSubscription && this.intervalSubscription.unsubscribe();
    let request: Subscription;

    const $interval = timer(0, 1000);
    this.intervalSubscription = $interval
      .pipe(
        skip(1),
        filter(count => {
          const shouldCallApi = count % this.pollingTimeotInSeconds === 0;
          this.secondsTilNextRequest = shouldCallApi ? 0 : this.pollingTimeotInSeconds - (count % this.pollingTimeotInSeconds);

          return shouldCallApi;
        })
      )
      .subscribe(() => {
        if (request) {
          request.unsubscribe();
        }

        this.intervalSubscription.unsubscribe();
        request = combineLatest([
          this.type === MaintenanceType.BASIC
            ? this.maintenanceService.checkForMaintenanceMode()
            : this.checkSoftMaintenanceStatus(),
          timer(2000)
        ])
          .pipe(
            catchError((err) => {
              this.startReloadTimeout();
              return err;
            }),
            finalize(() => {
              request.unsubscribe();
            })
          )
          .subscribe();
      });
  }

  private checkSoftMaintenanceStatus(): Observable<void> {
    return this.settingsService.fetchSettings()
      .pipe(
        map(data => {
          const maintenanceSettings = data.users && data.users.maintenanceMode;
          const maintenanceModeDisabled = maintenanceSettings && maintenanceSettings.enabled !== true;
  
          if (maintenanceModeDisabled) {
            this.maintenanceService.disableMaintenanceMode();
          }
        })
      )
  }

  private getSoftMaintenanceSettings(): void {
    if (this.settingsService.initialized) {
      this.settingsService.fetchSettings()
        .pipe(
          mergeMap(data => {
            const maintenanceSettings = data.users && data.users.maintenanceMode;
            const maintenanceModeEnabled = maintenanceSettings && maintenanceSettings.enabled === true;
            const maintenancePageEnabled = maintenanceModeEnabled && !!maintenanceSettings.page;
            if (maintenancePageEnabled) {  
              return this.basicPageService.getPageByAlias(maintenanceSettings.page)
            }

            return of(null);
          })
        ).subscribe((data: BasicPage) => this.softMaintenancePage = data);
    }
  }
}
