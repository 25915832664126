import { Injectable } from '@angular/core';
import { TranslateMessageFormatCompiler } from 'ngx-translate-messageformat-compiler';

@Injectable()
export class SharedTranslateCompilerService extends TranslateMessageFormatCompiler {

  public override compile(value: string | { [key: string]: string }, lang: string): (params: any) => string {
    if (typeof value === "string") {
      return super.compile(value, lang);
    }

    // @ts-ignore
    return (param: any) => {
      const paramKey = param && Object.keys(param)[0];
      const firstParam = param && param[paramKey];

      let pluralValue;

      switch (true) {
        // @ts-ignore
        case firstParam === 0:
          if (value['zero']) {
            pluralValue = value['zero'];
            break;
          }
        // @ts-ignore
        case firstParam === 1:
          if (value['one']) {
            pluralValue = value['one'];
            break;
          }
        // @ts-ignore
        case firstParam === 2: 
          if (value['two']) {
            pluralValue = value['two'];
            break;
          }
          
        // @ts-ignore
        case firstParam > 1 && firstParam < 5: 
          if (value['few']) {
            pluralValue = value['few'];
            break;
          }
        default:
          const other = value['other'] || value['many'];
          if (other) {
            pluralValue = other;
            break;
          }
      }

      const translationValue  = pluralValue || Object.keys(value)[0];
      return super.compile(translationValue, lang)(param);
    };
  }

  public override compileTranslations(translations: any, lang: string): any {
    if (typeof translations === "string") {
      return this.compile(translations, lang);
    }

    const groppedKeys = Object.keys(translations).reduce((total, key: string) => {
      const isPlural = key.match(/^(([A-Za-z0-9\-\_]+)\.){2,}(zero|one|two|few|many|other)$/);
      const keyValue = translations[key];
      Object.assign(total, { [key]: keyValue });

      if (isPlural) {
        const keyPreffix = key.substring(0, key.lastIndexOf('.'));
        const keySuffix = key.replace(`${keyPreffix}.`, '');

        const mappedValue = total[keyPreffix] || {};
        Object.assign(mappedValue, { [keySuffix]: keyValue });
        Object.assign(total, { [keyPreffix]: mappedValue });
      }

      return total;
    }, {});

    return Object.keys(groppedKeys).reduce<{ [key: string]: any }>(
      (acc, key) => {
        const value = groppedKeys[key];

        return { ...acc, [key]: this.compile(value, lang) };
      }, {});
  }
}
