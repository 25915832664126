import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpService } from 'library-explorer';

@Injectable({
  providedIn: 'root'
})
export class DashboardService {

  constructor(
    private httpService: HttpService
  ) { }

  public getNextLearningItem(): Observable<any> {
    return this.httpService.get<any>('learning-item-next');
  }

  public getCompletedLearningItems(): Observable<any[]> {
    return this.httpService.get<any>('learning-items-completed');
  }

  public getInProgressLearningItems(): Observable<any[]> {
    return this.httpService.get<any>('learning-items-in-progress');
  }
}
