import {
  HttpEvent, HttpHandler, HttpInterceptor, HttpRequest
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { BranchService } from '@app/services/branch.service';
import { environment } from '@env/environment';
import { filter, take, mergeMap } from 'rxjs/operators';


@Injectable()
export class BranchIdentificationInterceptor implements HttpInterceptor {

  constructor(private readonly branchService: BranchService) { }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return this.branchService.initialized.asObservable()
      .pipe(
        filter(init => init),
        take(1),
        mergeMap(() => {
          const branch = this.branchService.getCurrentBranch();
          const isBaseApiRequest = request.url.indexOf(environment.apiUrl) !== -1;
          const isRendererRequest = request.url.indexOf(environment.renderingToolUrl) !== -1;
          const shouldSendRequest = isBaseApiRequest || isRendererRequest;

          if (shouldSendRequest && branch && branch !== BranchService.GLOBAL_BRANCH_KEY) {
            request = request.clone({
              setHeaders: {
                'LLab-Branch': branch
              }
            });
          }
          return next.handle(request);
        })
      );
  }
}
