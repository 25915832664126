import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { FilePreviewType } from 'src/app/model/enums/file-preview-type.enum';
import { FileModel } from 'library-explorer';


@Component({
  selector: 'app-preview-modal',
  templateUrl: './preview-modal.component.html',
  styleUrls: ['./preview-modal.component.scss']
})
export class PreviewModalComponent implements OnInit {
  public modalHidden = false;
  public isLoaded = false;
  public fileMode: FilePreviewType;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: {
      file: FileModel, presignedUrl: string, mode: FilePreviewType, searchKey: string, entityId: string
    }) {  }

  public ngOnInit(): void {
    this.fileMode = this.data.mode;
    this.modalHidden = this.fileMode === FilePreviewType.VIDEO_VIEWER && !this.isLoaded;
  }

  public loaded(loaded: boolean): void {
    setTimeout(() => {
      this.isLoaded = loaded;
      this.modalHidden = this.fileMode === FilePreviewType.VIDEO_VIEWER && !this.isLoaded;
    }, 100);
  }
}
