import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { ChatChannel } from '@app/model/chat-channel';
import { SendbirdService } from '@app/services/sendbird.service';
import { Subject } from 'rxjs';
import { ChatMessageInputComponent } from '../chat-message-input/chat-message-input.component';

@Component({
  selector: 'app-chat-channel',
  templateUrl: './chat-channel.component.html',
  styleUrls: ['./chat-channel.component.scss']
})
export class ChatChannelComponent implements OnInit {
  @ViewChild(ChatMessageInputComponent) public chatInput: ChatMessageInputComponent;
  @Input() public disabled = false;

  @Input() public set channelUrl(value: string) {
    if (!value) {
      return;
    }

    this.sendbirdService.getChannelByUrl(value).subscribe(data => this.groupChannel = data);
  }

  @Input() public hideBorder = true;

  @Input() public groupChannel: ChatChannel;

  private unsubscribe: Subject<void> = new Subject();

  constructor(private readonly sendbirdService: SendbirdService) { }

  ngOnInit() {
  }

  public markAsRead(): void {
    if (this.disabled) {
      return;
    }
  
    this.sendbirdService.markChannelAsRead(this.groupChannel);
  }

  public editMessage(item: SendBird.UserMessage): void {
    this.chatInput.setEditMessage(item);
  }


  ngOnDestroy(): void {
    this.unsubscribe.next();
    this.unsubscribe.complete();
  }
}
