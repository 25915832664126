import { AfterViewInit, Component, ElementRef, HostListener, Input, Renderer2, ViewChild } from '@angular/core';
import { Capacitor } from '@capacitor/core';

@Component({
  selector: 'app-banner-block',
  templateUrl: './banner-block.component.html',
  styleUrls: ['./banner-block.component.scss']
})
export class BannerBlockComponent implements AfterViewInit {
  @ViewChild('imageWrapperEl', {static: false}) imageWrapperEl: ElementRef;
  @ViewChild('contentWrapperEl', {static: false}) contentWrapperEl: ElementRef;

  @Input() block: any;

  public hidden = false;

  private resizerTimeout;

  @HostListener('window:resize') onResize() {
    if (this.resizerTimeout) {
      clearTimeout(this.resizerTimeout);
    }

    this.resizerTimeout = setTimeout(() => {
      this.setBackgroundHeight();
    }, 100);
  }

  constructor(
    private renderer: Renderer2
  ) { }

  ngAfterViewInit() {
    const isNativePlatform = Capacitor.isNativePlatform();
    this.hidden = this.block?.hideFromMobile && isNativePlatform;
    this.setBackgroundHeight();
  }

  private setBackgroundHeight(): void {
    if (this.imageWrapperEl) {
      const imageWrapper = this.imageWrapperEl.nativeElement;
      const contentWrapper = this.contentWrapperEl && this.contentWrapperEl.nativeElement;
      const contentWrapperHeight = contentWrapper && contentWrapper.offsetHeight;

      this.renderer.setStyle(imageWrapper, 'min-height', `${contentWrapperHeight}px`);
    }

  }

}
