import { Injectable } from "@angular/core";

@Injectable({
  providedIn: 'root'
})
export class CryptoService {

  constructor() { }

  async generateKeyFromString(secret: string): Promise<CryptoKey> {
    const encoder = new TextEncoder();

    // Convert the string to a Uint8Array
    const secretBuffer = encoder.encode(secret);

    // Use a key derivation function (e.g., PBKDF2) to derive a key from the string
    const key = await crypto.subtle.importKey('raw', secretBuffer, { name: 'PBKDF2' }, false, ["deriveBits", "deriveKey"]);
    return crypto.subtle.deriveKey(
      {
        name: 'PBKDF2',
        salt: encoder.encode('salt'), // Add salt for added security
        iterations: 10000,
        hash: 'SHA-256',
      },
      key,
      { name: 'AES-GCM', length: 256 }, // You can adjust the key size as needed
      true,
      ['encrypt', 'decrypt']
    );
  }

  async generateRandomBytes(length: number): Promise<Uint8Array> {
    const buffer = new Uint8Array(length);
    await crypto.getRandomValues(buffer);
    return buffer;
  }

  async encrypt<T>(data: T, key: CryptoKey, iv: BufferSource): Promise<ArrayBuffer> {
    const jsonData = JSON.stringify(data);
    const encoder = new TextEncoder();
    const dataBuffer = encoder.encode(jsonData);

    const encryptedData = await crypto.subtle.encrypt({ name: 'AES-GCM', iv }, key, dataBuffer);
    return encryptedData;
  }

  async decrypt(encryptedData: ArrayBuffer, key: CryptoKey, iv: BufferSource): Promise<object> {
    const decryptedDataBuffer = await crypto.subtle.decrypt({ name: 'AES-GCM', iv }, key, encryptedData);
    const decoder = new TextDecoder();
    const decryptedJson = decoder.decode(decryptedDataBuffer);
    return JSON.parse(decryptedJson);
  }
}
