import { Injectable } from '@angular/core';
import { Network } from '@ngx-pwa/offline';
import { BehaviorSubject, Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class NetworkService {
  private status: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(true);

  constructor(
    private network: Network
  ) {
    this.network.onlineChanges.subscribe((status: any) => {
      this.status.next(status);
    });
  }

  public isOnline(): Observable<boolean> {
    return this.status.asObservable();
  }

  public isOnlineValue(): boolean {
    return this.status.value;
  }
}
