import { Directive, EventEmitter, HostListener, Output } from '@angular/core';


@Directive({
  /* tslint:disable-next-line */
  selector: '[tripleClick]'
})
export class TripleClickDirective {
  @Output() public tripleClick: EventEmitter<void> = new EventEmitter();

  private clearClickInterval;
  private clickCount = 0;
  private readonly clickTimeout = 500;

  @HostListener('click') public onClick() {
    this.clickCount++;
    this.startClearInterval();

    if (this.clickCount === 3) {
      this.tripleClick.emit();
      this.clickCount = 0;
    }
  }

  private startClearInterval(): void {
    if (this.clearClickInterval) {
      clearInterval(this.clearClickInterval);
    }

    this.clearClickInterval = setTimeout(() => {
      this.clickCount = 0;
    }, this.clickTimeout);
  }
}
