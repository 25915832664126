<div class="start-media-overlay toggle-audio" (click)="toggleAudio()">
  <div class="audio-play-button">
    <mat-icon>{{ audioPlaying ? 'pause' : 'play_arrow'}}</mat-icon> {{ audioPlaying ? 'Pause' : 'Play' }} audio
  </div>
  <mat-slider
    class="audio-volume-slider"
    [(ngModel)]="audioVolume"
    (ngModelChange)="onVolumeChange($event)"
    (click)="onAudioVolumeSliderClick($event)"
    [invert]="invert"
    [min]="0"
    [step]="0.01"
    [max]="1">
  </mat-slider>
</div>