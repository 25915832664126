import { Pipe, PipeTransform } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';


@Pipe({
  name: 'duration'
})
export class DurationPipe implements PipeTransform {

  constructor(private readonly translateService: TranslateService) { }

  transform(value: number): string {
    if (!value) {
      return this.translateService.instant('COMMON.minutes-short-format', { minutes: 0 })
    }

    const hours = Math.floor(value / 60);
    const minutes = Math.floor(value % 60);

    if (!hours) {
      return this.translateService.instant('COMMON.minutes-short-format', { minutes });
    }

    if (!minutes) {
      return this.translateService.instant('COMMON.hours_full_format', { hours });
    }

    const hoursTranslated = this.translateService.instant(`COMMON.hours-short-format`, { hours });
    const minutesTranslated = this.translateService.instant(`COMMON.minutes-short-format`, { minutes });

    return `${hoursTranslated} ${minutesTranslated}`;
  }

}
