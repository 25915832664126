import { Injectable } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { BehaviorSubject, concat, forkJoin, Observable, of, timer } from 'rxjs';
import { delayWhen, map, mergeMap, reduce, retryWhen, tap } from 'rxjs/operators';
import * as SparkMD5 from 'spark-md5';
import { UploadFileInfo, UploadProgress, FileChunk, EntityTypeId } from '../models';
import { FileUploadApiService } from './file-upload-api.service';


@Injectable({
  providedIn: 'root'
})
export class UploadService {

  constructor(
    private apiService: FileUploadApiService,
    private toastr: ToastrService
  ) { }

  private $hasError: BehaviorSubject<string[]> = new BehaviorSubject<string[]>([]);

  public get hasError(): Observable<string[]> {
    return this.$hasError.asObservable();
  }

  // AWS Video upload
  public uploadToAws(fileWrapper: UploadFileInfo): Observable<any> {
    const file = fileWrapper.file;

    if (!file) {
      return of('');
    }

    if (typeof file === 'string') {
      return of(null);
    }

    const type = file.type;
    const info: UploadFileInfo = {
      file: fileWrapper.file,
      fileType: type,
      bundle: fileWrapper.bundle,
      entityTypeId: fileWrapper.entityTypeId,
      fieldName: fileWrapper.fieldName
    };

    let uploadId: string;
    let key: string;
    let chunkSize: number;

    if (file.size <= 5242880) {
      const data: UploadProgress = {
        part: 1,
        total: 1,
        percent: -1,
        type,
        file: {
          name: (file as File).name,
          size: file.size
        }
      };

      const upload = this.apiService.getPreSignedPost(info).pipe(
        mergeMap(res => {
          return this.apiService.uploadToS3(res.url, file).pipe(
            map(() => res)
          );
        }),
        mergeMap(res => {
          return this.apiService.awsFinishUpload(res.token).pipe(
            map(() => {
              return { key: res.key, url: res.url, completed: true, ...data, percent: 100 };
            })
          );
        })
      );

      return concat(of(data), upload);
    }

    return new Observable(subscriber => {
      let chunkTotal = 0;
      this.apiService.multipartUploadStart(info).pipe(
        mergeMap(res => {
          uploadId = res.uploadId;
          key = res.key;
          chunkSize = res.calculation.chunk_size;
          chunkTotal = Math.floor(file.size / chunkSize);

          subscriber.next({
            part: 0,
            percent: 0,
            type,
            total: chunkTotal,
            uploadId,
            file: {
              name: file.name,
              size: file.size
            }
          } as UploadProgress);

          return this.sliceFile(file, chunkSize);
        }),
        mergeMap(res => {
          return this.uploadChunk(res, info, uploadId, key, file as File);
        }),
        tap(data => subscriber.next(data)),
        reduce((all: FileChunk[], value: FileChunk) => {
          all.push(value);
          return all;
        }, []),
        mergeMap(() => this.apiService.multipartUploadEnd(info, uploadId, key)),
        mergeMap((data: { token: string}) => {
          return this.apiService.awsFinishUpload(data.token);
        }),
      ).subscribe(() => {
        const data = {
          key,
          completed: true,
          percent: 100,
          total: chunkTotal,
          part: 0,
          file: {
            name: file.name,
            size: file.size
          }
        };

        subscriber.next(data);
        subscriber.complete();
      });
    });
  }

  private sliceFile(file: File, chunkSize: number): Observable<FileChunk> {
    return new Observable(subscriber => {
      let currentChunk = 0;
      const chunkTotal = Math.floor(file.size / chunkSize);
      const reader = new FileReader();

      reader.onload = (e: any) => {
        currentChunk++;

        subscriber.next({
          chunk: chunkTemp,
          hash: SparkMD5.ArrayBuffer.hash(e.target.result as ArrayBuffer),
          total: chunkTotal,
          number: currentChunk,
          percent: Math.ceil((currentChunk / chunkTotal) * 100)
        });

        if (currentChunk < chunkTotal) {
          chunkTemp = this.loadNextChunk(currentChunk, chunkTotal, chunkSize, file, reader);
        } else {
          subscriber.complete();
        }
      };

      reader.onerror = (e) => {
        this.toastr.error('There was an error processing the file.');
        subscriber.error();
      };

      let chunkTemp = this.loadNextChunk(currentChunk, chunkTotal, chunkSize, file, reader);
    });
  }

  private loadNextChunk(currentChunk: number, chunkTotal: number, chunkSize: number, file: File, reader: FileReader): File {
    const start = currentChunk * chunkSize;
    const end = (currentChunk + 1) === chunkTotal ? file.size : start + chunkSize;
    const slice = file.slice(start, end);
    reader.readAsArrayBuffer(slice);

    return slice as any;
  }

  private uploadChunk(chunk: FileChunk, info: UploadFileInfo, uploadId: string, key: string, file: File): Observable<any> {
    return this.apiService.multipartUploadGetPart(chunk, info, uploadId, key).pipe(
      mergeMap(res => {
        return this.apiService.uploadToS3(res.url, chunk.chunk).pipe(
          map(() => {
            if (this.$hasError.getValue().indexOf(uploadId) !== -1) {
              this.$hasError.next(this.$hasError.getValue().filter(errorItem => errorItem !== uploadId));
            }

            return {
              part: chunk.number,
              total: chunk.total,
              percent: chunk.percent,
              type: info.fileType,
              uploadId,
              file: {
                name: file.name,
                size: file.size
              }
            } as UploadProgress;
          })
        );
      }),
      retryWhen(err => err.pipe(
        tap(() => {
          if (this.$hasError.getValue().indexOf(uploadId) === -1) {
            this.$hasError.next([...this.$hasError.getValue(), uploadId]);
          }
        }),
        delayWhen(() => timer(10000))
      ))
    );
  }

  // Upload all media
  public uploadMedia(data: any, uploadFileInfo: UploadFileInfo[], uploadProgress?: BehaviorSubject<UploadProgress>): Observable<any> {
    const observables: Observable<any>[] = [];

    uploadFileInfo.forEach(fileInfo => {
      if (fileInfo.multiple) {
        const itemList = this.getNestedProperty(fileInfo.path || '', data);
        const uploadList: Observable<any>[] = [];

        for (const item of itemList) {
          const uploadObs = this.getUploadObservable(item, fileInfo, uploadProgress);
          if (uploadObs) {
            uploadList.push(uploadObs);
          }
        }

        if (uploadList.length) {
          observables.push(forkJoin(uploadList));
        }
      } else {
        const uploadObs = this.getUploadObservable(data, fileInfo, uploadProgress);
        if (uploadObs) {
          observables.push(uploadObs);
        }
      }
    });

    return observables.length ? forkJoin(observables) : of(null);
  }

  private getUploadObservable(
    data: any, uploadFileInfo: UploadFileInfo, uploadProgress?: BehaviorSubject<UploadProgress>
  ): Observable<any> {
    // check if it's an mp4 video, if it is, remove embedVideo
    if (
      (data.video && (typeof data.video) !== 'string')
      || (data.additionalVideo && (typeof data.additionalVideo) !== 'string')
    ) {
      data.embedVideo = '';
    }

    const filePath = uploadFileInfo.propertyNameOnMultiple || uploadFileInfo.path || '';
    const file = this.getNestedProperty(filePath, data);

    if (file === null || file === '') {
      return of(null);
    }

    return this.uploadToAws(file).pipe(
      tap((res: UploadProgress) => {
        if (res !== null) {
          uploadProgress?.next(res);

          if (res.completed) {
            this.setNestedProperty(filePath, data, res.key);
          }
        } else {
          this.removeProperty(filePath, data);
        }
      })
    );
  }

  private getNestedParent(path: string, obj: any): any {
    if (!obj || !path) {
      return;
    }
    const splitPath = path.split('.');

    for (let i = 0; i < splitPath.length - 1; i++) {
      obj = obj[splitPath[i]];

      if (typeof obj === 'undefined') {
        return;
      }
    }

    return obj;
  }

  private removeProperty(path: string, obj: any): void {
    const splitPath = path.split('.');
    obj = this.getNestedParent(path, obj);
    const propertyName = splitPath.pop();

    if (propertyName) {
      delete obj[propertyName];
    }
  }

  private getNestedProperty(path: string, obj: any): any {
    if (!path) {
      return;
    }

    return path.split('.').reduce((item, prop) => {
      if (item) {
        return item[prop];
      }
    }, obj);
  }

  private setNestedProperty(path: string, obj: any, value: any): void {
    const splitPath = path.split('.');
    obj = this.getNestedParent(path, obj);
    const propertyName = splitPath.pop();

    if (propertyName) {
      obj[propertyName] = value;
    }
  }
}
