<div 
  class="webinar-card" 
  [ngClass]="orientation" 
  [routerLink]="['/webinars', item.id]" 
  [class.past-webinar]="!item.isUpcoming"
  [class.disabled]="disabled"
  [class.inactive]="!item.active">
  <div 
    class="webinar-card__image"
    [class.default]="!item.mediaImage"
    [ngStyle]="!item.mediaImage && { backgroundImage: { startDate: item.date } | offlineActivityImage }"
    [cropImage]="item.mediaImage | preSignedUrl | async"
    [crop]="item.mediaImage?.crop"
    [additionalOptions]="{ aspect_ratio: '16:9' }">

    <div class="webinar-top-indicators">
      <span *ngIf="userAttendee" class="webinar-status">
        <lib-icon icon="check" size="16"></lib-icon>
        {{ (item.isUpcoming ? 'WEBINARS.attending' : 'WEBINARS.attended') | translate }}
      </span>

      <div *ngIf="!item.active" class="hidden-indicator" [matTooltip]="'COMMON.hidden' | translate">
        <lib-icon icon="eye-slash" size="18"></lib-icon>
      </div>
    </div>

    <div class="webinar-users" *ngIf="item?.users?.length">
      <div class="webinar-user" *ngFor="let user of item.users | slice : 0 : 3" [class.opened]="item.users?.length === 1">
        <app-user-avatar [user]="user" [size]="32"></app-user-avatar>

        <span class="webinar-user-name">
          {{ user.formattedProfileName }}
        </span>
      </div>
    </div>
  </div>

  <div class="webinar-card__info">
    <span class="webinar-card-label">
      {{ 'COMMON.webinar' | translate }}
    </span>

    <h4 class="webinar-card-title">{{ item?.title }}</h4>

    <span class="webinar-date-duration">
      <app-date-label-chip [date]="item.date" [timezone]="item.timezone"></app-date-label-chip>

      <span class="webinar-duration">
        <lib-icon icon="clock" [size]="16"></lib-icon>
        {{ item.duration | minutesToTime : 'long' }}
      </span>
    </span>
  </div>

</div>