<div class="carousel-block" *ngIf="block.items && block.items.length">
  <ngx-slick-carousel
    slickCarouselResize
    class="carousel carousel-block"
    #slickModal="slick-carousel"
    [config]="carouselConfig">
    <div ngxSlickItem class="carousel-block__item" *ngFor="let item of block.items">
      <a *ngIf="item.link?.uri; else carouselContentTmp" [extendedRouterLink]="item.link.uri" class="carousel-block__item-link">
        <ng-container *ngTemplateOutlet="carouselContentTmp"></ng-container>
      </a>
      <ng-template #carouselContentTmp>
        <img [cropImage]="item.mediaImage | preSignedUrl | async" [attr.alt]="item.mediaImage?.alt">
        <div class="carousel-block__item-content d-flex flex-column flex-sm-row align-items-start align-items-sm-center justify-content-center justify-content-sm-between">
          <h3 [innerHtml]="item.title | safeHtml" [rtl]="item.langcode"></h3>
          <button mat-flat-button *ngIf="item.link?.title" class="button button--primary button--large mt-4 mt-sm-0">
            <span class="button-text" [rtl]="block.langcode">{{item.link.title}}</span>
            <mat-icon class="secondary-color">navigate_next</mat-icon>
          </button>
        </div>
      </ng-template>
    </div>
  </ngx-slick-carousel>
  
  <ng-container *ngIf="block.items && block.items.length > 1">  
    <button color="primary" mat-fab class="carousel-block__button carousel-button carousel-button--left" (click)="slickModal.slickPrev()">
      <mat-icon svgIcon="lms-carousel-arrow-left"></mat-icon>
    </button>
    <button color="primary" mat-fab class="carousel-block__button carousel-button carousel-button--right" (click)="slickModal.slickNext()">
      <mat-icon svgIcon="lms-carousel-arrow-right"></mat-icon>
    </button>
  </ng-container>
</div>

