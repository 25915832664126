import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { FormControl, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { AuthApiService } from '@app/services/api/auth-api.service';
import { ProfileService } from 'library-explorer';
import { Subject } from 'rxjs';
import { catchError, takeUntil } from 'rxjs/operators';

@Component({
  selector: 'app-delete-profile-modal',
  templateUrl: './delete-profile-modal.component.html',
  styleUrls: ['./delete-profile-modal.component.scss']
})
export class DeleteProfileModalComponent implements OnInit, OnDestroy {
  public control: FormControl;
  public isSSOUser = false;

  public isValid = false;
  public passwordInvalid = false;

  private subscriptions: Subject<void> = new Subject<void>();

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: string,
    private readonly dialogRef: MatDialogRef<DeleteProfileModalComponent>,
    private readonly profileService: ProfileService,
    private readonly authApiService: AuthApiService
  ) { }

  ngOnInit() {
    this.isSSOUser = this.profileService.getCurrentProfileValue()?.ssoUser;
    this.control = new FormControl('', [Validators.required]);

    this.control.valueChanges
      .subscribe((value: string) => {
        this.passwordInvalid = false;
        this.isValid = this.control.valid && (!this.isSSOUser || (value && value.toLocaleLowerCase() === this.data.toLocaleLowerCase()));
      });
  }

  public submit(): void {
    this.passwordInvalid = false;
    if (this.isSSOUser) {
      this.dialogRef.close(true);
      return;
    }

    this.authApiService.checkPassword(this.control.value).pipe(
      catchError(err => {
        this.passwordInvalid = true;
        throw err;
      }),
      takeUntil(this.subscriptions),
    ).subscribe(res => {
      if (res) {
        this.dialogRef.close(true);
        return;
      }
    })
  }

  ngOnDestroy() {
    this.subscriptions.next();
    this.subscriptions.unsubscribe();
  }

}
