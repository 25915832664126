import { environment } from 'src/environments/environment';
import { Injectable } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor,
  HttpResponse
} from '@angular/common/http';
import { Observable, from, of } from 'rxjs';

import { NetworkService, OfflineModeService, HttpService, storageKeys, CryptoService, interceptorsToken } from 'library-explorer';
import { mergeMap, switchMap, tap } from 'rxjs/operators';

@Injectable()
export class OfflineRequestInterceptor implements HttpInterceptor {
  constructor(private networkService: NetworkService, private offlineModeService: OfflineModeService, private cryptoService: CryptoService, private httpService: HttpService) { }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    const skipOfflineCheck = request.headers.has(interceptorsToken.SKIP_OFFLINE_REQUESTS_HANDLER);
    const isOnline = this.networkService.isOnlineValue();

    if (!skipOfflineCheck && !isOnline) {
      // Check if the request method is 'POST'
      if (request.method === 'POST') {
        // Store the request in local storage
        this.storeRequestInLocalStorage(request);

        return of(new HttpResponse({ status: 0, body: 'Offline Mode: Request saved for later.' }));
      }
      
      if (request.method === 'GET') {
        // Handle GET request, and stop processing other interceptors
        return this.handleGetRequest(request, next).pipe(
          switchMap(data => of(new HttpResponse({ body: data })))
        );
      }
    }

    // If the custom header is present, remove it
    request = request.headers.has(interceptorsToken.SKIP_OFFLINE_REQUESTS_HANDLER)
      ? request.clone({ headers: request.headers.delete(interceptorsToken.SKIP_OFFLINE_REQUESTS_HANDLER) })
      : request;

    return next.handle(request);
  }

  private storeRequestInLocalStorage(request: HttpRequest<any>): void {
    const path = request.urlWithParams.replace(this.httpService.baseUrl, '');
    this.offlineModeService.storeOrUpdatePendingRequestArr(storageKeys.PENDING_REQUESTS, {
      method: request.method,
      url: path,
      params: request.params,
      body: request.body,
      withCredentials: request.withCredentials,
      tId: path + Date.now()
    });
  }

  private handleGetRequest(request: HttpRequest<any>, next: HttpHandler) {
    let path = request.urlWithParams.replace(this.httpService.baseUrl, '');
    return this.offlineModeService.getRequest(path).pipe(
      mergeMap((data: any) => {
        if (!data) return of(null);

        if (data && typeof data.data === 'object' && data.ivv && typeof data.ivv === 'object') {
          return from(this.cryptoService.generateKeyFromString(environment.encrytionSecret)).pipe(
            switchMap(key => from(this.cryptoService.decrypt(data.data, key, data.ivv)))
          );
        } else {
          return of(data);
        }
      })
    );
  }
}
