import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { mergeMap } from 'rxjs/operators';
import { FileChunk, UploadFileInfo } from '../models';
import { HttpService } from './http.service';
import { MediaPresignApiService } from './media-presign-api.service';


@Injectable({
  providedIn: 'root'
})
export class FileUploadApiService {

  constructor(
    private readonly mediaPresignApiService: MediaPresignApiService,
    private httpClient: HttpClient,
    private httpService: HttpService) { }

  public getFileByUUID(uuid: string): Observable<string> {
    const params = new HttpParams().set('uuid', uuid);
    return this.httpService.get<any>(`aws/get-file-by-uuid`, params)
      .pipe(
        mergeMap(data => {
          if (data.isNeedPresign) {
            return this.mediaPresignApiService.getPreSignedUrl(data.entityTypeId, data.bundle, data.fieldName, data.key);
          }

          return of(data.url);
        })
      );
  }

  public uploadToS3(url: string, file: File | Blob): Observable<any> {
    return this.httpClient.put(url, file);
  }

  public multipartUploadGetPart(
    chunk: FileChunk, info: UploadFileInfo, uploadId: string, key: string): Observable<{ url: string }> {
    const data = {
      type: info.fileType,
      entityTypeId: info.entityTypeId,
      bundle: info.bundle,
      fieldName: info.fieldName,
      uploadId,
      key,
      partNumber: chunk.number
    };

    return this.httpService.post<{ url: string }>(`multipart-upload-get-part`, data);
  }

  public multipartUploadStart(info: UploadFileInfo): Observable<any> {
    const data = {
      type: info.fileType,
      entityTypeId: info.entityTypeId,
      bundle: info.bundle,
      fieldName: info.fieldName,
      totalSize: info.file?.size,
      fileName: info.file?.name
    };

    return this.httpService.post(`multipart-upload-start`, data);
  }

  public multipartUploadEnd(info: UploadFileInfo, uploadId: string, key: string): Observable<any> {
    const data = {
      type: info.fileType,
      entityTypeId: info.entityTypeId,
      bundle: info.bundle,
      fieldName: info.fieldName,
      uploadId,
      key
    };

    return this.httpService.post(`multipart-upload-end`, data);
  }

  public getPreSignedPost(info: UploadFileInfo): Observable<any> {
    const data = {
      type: info.fileType,
      entityTypeId: info.entityTypeId,
      bundle: info.bundle,
      fieldName: info.fieldName,
      fileName: info.file?.name
    };

    return this.httpService.post(`get-pre-signed-post`, data);
  }

  public addDefaultTagsToS3File(info: UploadFileInfo, key: string): Observable<any> {
    const data = {
      type: info.fileType,
      entityTypeId: info.entityTypeId,
      bundle: info.bundle,
      fieldName: info.fieldName,
      key
    };

    return this.httpService.post(`add-default-tags`, data);
  }

  public awsFinishUpload(token: string): Observable<any> {
    const data = {
      token
    };

    return this.httpService.post(`aws/finish-upload`, data);
  }

  public awsUploadSendSNS(info: UploadFileInfo, key: string): Observable<any> {
    const data = {
      type: info.fileType,
      entityTypeId: info.entityTypeId,
      bundle: info.bundle,
      fieldName: info.fieldName,
      key
    };

    return this.httpService.post(`aws/send_sns`, data);
  }
}
