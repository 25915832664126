import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-notification-list-dialog',
  templateUrl: './notification-list-dialog.component.html',
  styleUrls: ['./notification-list-dialog.component.scss']
})
export class NotificationListDialogComponent implements OnInit {
  public notificationsExist = false;

  constructor() { }

  ngOnInit() {
  }

  public changeButtonVisility(value: boolean): void {
    this.notificationsExist = value;
  }

}
