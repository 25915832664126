import {
  HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest
} from '@angular/common/http';
import { Injectable, Injector } from '@angular/core';

import { Observable, throwError, timer } from 'rxjs';
import { filter, mergeMap, retryWhen, take } from 'rxjs/operators';
import { MaintenanceType } from '../models';
import { MaintenanceService } from '../services';


@Injectable()
export class MaintenanceInterceptor implements HttpInterceptor {
  private readonly maintenanceModeErrorCode = 503;
  private readonly softMaintenanceModeErrorCode = 599;
  private maintenanceService!: MaintenanceService;

  static SKIP_MAINTENANCE_CHECKING_PARAM = 'skipMaintenanceChecking';

  constructor(private injector: Injector) {}

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    const skipMaintenanceChecking = request.params.get(MaintenanceInterceptor.SKIP_MAINTENANCE_CHECKING_PARAM);
    this.maintenanceService = this.injector.get(MaintenanceService);

    request = request.clone({
      params: request.params.delete(MaintenanceInterceptor.SKIP_MAINTENANCE_CHECKING_PARAM)
    });

    if (skipMaintenanceChecking) {
      return next.handle(request);
    }

    const maintenanceModeEnabled = this.maintenanceService.maintenanceModeEnabled.value;

    if (maintenanceModeEnabled.enabled && maintenanceModeEnabled.type === MaintenanceType.BASIC) {
      return this.waitForMaintenanceModeDisabled(request, next);
    }

    return next.handle(request)
      .pipe(
        this.retryAfterMaintenanceModeDisabled(),
      );
  }

  private retryAfterMaintenanceModeDisabled(): any {
    return retryWhen((errors) => {
      return errors.pipe(
        mergeMap((err, count) => {
          if (err instanceof HttpErrorResponse) {
            if (err.status === this.maintenanceModeErrorCode || err.status === this.softMaintenanceModeErrorCode) {
              const type = err.status === this.maintenanceModeErrorCode ? MaintenanceType.BASIC : MaintenanceType.SOFT_MAINTENANCE;
              this.maintenanceService.enableMaintenanceMode(type);

              return this.maintenanceService.maintenanceModeEnabled
                .pipe(
                  filter(value => !value.enabled),
                  take(1),
                  mergeMap(() => timer(200))
                );
            }
          }

          return throwError(err);
        })
      );
    });
  }

  private waitForMaintenanceModeDisabled(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return this.maintenanceService.maintenanceModeEnabled
      .pipe(
        filter(value => !value.enabled),
        take(1),
        mergeMap(() => next.handle(request))
      );
  }
}
