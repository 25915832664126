<div class="slider-container">
  <div class="slider-widget">
    <mat-slider 
      [min]="min" 
      [max]="max" 
      [step]="step" 
      [formControl]="control" 
      [disabled]="disabled"
      [invert]="invert"
      [thumbLabel]="true"
      [displayWith]="formatLabel"
      discrete
      [class.readonly]="readonly"
      class="cdk-mouse-focused slider-control">
      <input matSliderThumb>
    </mat-slider>
  
    <div class="d-flex justify-content-between">
      <span>{{min}} {{unit}}</span>
  
      <span>{{max}} {{unit}}</span>
    </div>
  
    <div class="slider-widget-ticks">
      <div class="slider-widget-tick" *ngFor="let item of steps"></div>
    </div>
  </div>
</div>