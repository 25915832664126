import { storageKeys } from "../constants/storage-keys";


export function warnKeyNotExistInStorageKeys(paramIndex = 0) { 
  return function(target: any, propertyName: string, descriptor: PropertyDescriptor) {
    const originalValue = descriptor.value;

    descriptor.value = function (...args: any[]) {
      const param = args[paramIndex];
      const keys = Object.values(storageKeys);
      
      const exist = keys.some(key => key === param || param.indexOf(key) !== -1);

      if (!exist) {
        console.error(`[${target.constructor.name}] 
          Key is not found in registered storage keys. Please make sure you add ${param} key to cookies consent table. (cookie-consent.service.ts)`)
      }
  
      return originalValue.apply(this, args);
   };
  }
}
