import { HttpBackend, HttpClient } from '@angular/common/http';
import { Injectable, Injector } from '@angular/core';
import { Router } from '@angular/router';
import { Capacitor } from '@capacitor/core';
import { Observable } from 'rxjs';

import { LanguageStorageService, NetworkService, SharedLibraryService, OfflineModeService, HostType } from 'library-explorer';
import { catchError, finalize, mergeMap, tap } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { sessionKeys } from '../shared/constants/translation-helper.const';
import { BranchService } from './branch.service';
import { LanguageService } from './language.service';
import { App } from '@capacitor/app';
import { SecureStorageService } from './secure-storage.service';

@Injectable()
export class InitializeService {
  public init(injector: Injector): Promise<void> {
    const networkService = injector.get(NetworkService);
    const offlineModeService = injector.get(OfflineModeService);

    const router = injector.get(Router);

    return new Promise(async (resolve) => {
      let hostname = window.location.hostname;
      let hostType = HostType.DOMAIN;
      const isCapacitorNativeApp = Capacitor.isNativePlatform();

      if (isCapacitorNativeApp) {
        hostname = (await App.getInfo()).id;
        hostType = HostType.APPID;
      }

      const queryParams = new Proxy(new URLSearchParams(window.location.search), {
        get: (searchParams, prop) => searchParams.get(prop.toString()),
      });

      const translationToggleParam = queryParams[sessionKeys.queryParamName];
      if (translationToggleParam) {
        sessionStorage.setItem(sessionKeys.displayToggle, 'true');
        const showTranslationKeys = sessionStorage.getItem(sessionKeys.active);

        if (!showTranslationKeys) {
          sessionStorage.setItem(sessionKeys.active, 'true');
        }
      }

      if (hostname === 'localhost') {
        this.initializeLanguages(injector).pipe(
          finalize(() => resolve())
        ).subscribe();

        if (!networkService.isOnlineValue()) {
          router.navigate(['/classes'], { skipLocationChange: true });
        }
        return;
      }

      this.getHostLookupData(hostname, hostType, networkService, offlineModeService, injector).pipe(
        catchError(() => {
          this.setupEnvironment(null, injector);
          router.navigate(['/no-instance'], { skipLocationChange: true });
          resolve();
          throw new Error('Wrong hostname, please contact site administrator');
        }),
        tap((data: { apiUrl: string, adminBaseDomain: string, frontBaseDomain: string, branchName: string, apiCoreAppUrl: string }) => {
          this.setupBranch(injector, data.branchName);
          this.setupEnvironment(data, injector);
        }),
        mergeMap(() => this.initializeLanguages(injector)),
        tap(() => {
          if (!networkService.isOnlineValue()) {
            router.navigate(['/classes'], { skipLocationChange: true });
          }
        }),
        finalize(() => resolve())
      ).subscribe();
    });
  }

  private getHostLookupData(hostname: string, type: HostType, networkService: NetworkService, offlineModeService: OfflineModeService, injector: Injector): Observable<{ apiUrl: string, adminBaseDomain: string, frontBaseDomain: string, branchName: string }> {
    const url = `${environment.hostLookupUrl}/${type}/${environment.name}/${hostname}`;

    if (!networkService.isOnlineValue()) {
      return offlineModeService.getRequest(url) as Observable<any>;
    }

    const handler = injector.get(HttpBackend);
    const httpClient = new HttpClient(handler);
    return httpClient.get<any>(url).pipe(
      tap(data => offlineModeService.storeRequest(url, data))
    );
  }

  private setupBranch(injector: Injector, branchName: string): void {
    const branchService = injector.get(BranchService);
    const currentBranch = branchService.getCurrentBranch();

    if (currentBranch) {
      return;
    }

    branchService.setCurrentBranch(branchName);
  }

  private initializeLanguages(injector: Injector): Observable<void> {
    const storage = injector.get(LanguageStorageService);
    return storage.fetchLanguages()
      .pipe(
        tap(() => {
          const languageService = injector.get(LanguageService);
          languageService.initLanguage();
        })
      );
  }

  private setupEnvironment(data: { apiUrl: string, adminBaseDomain: string, frontBaseDomain: string, apiCoreAppUrl: string }, injector: Injector): void {
    if (!data) {
      environment.apiUrl = '/';
      environment.adminBaseDomain = '/';
      environment.frontBaseDomain = '/';

      return;
    }

    const { apiUrl, adminBaseDomain, frontBaseDomain, apiCoreAppUrl } = data;
    const basePath = `${this.setProtocolToUrl(apiUrl)}/`;
    const baseCoreApiPath = `${this.setProtocolToUrl(apiCoreAppUrl)}/api`;

    environment.apiUrl = basePath;
    environment.coreLearningLabApi = baseCoreApiPath;
    environment.adminBaseDomain = this.setProtocolToUrl(adminBaseDomain);
    environment.frontBaseDomain = this.setProtocolToUrl(frontBaseDomain);

    const sharedLibraryService = injector.get(SharedLibraryService);
    sharedLibraryService.updateEnvironments({
      apiUrl: environment.apiUrl
    })
  }

  private async setEncryptionSecret(injector: Injector): Promise<void> {
    const secureStorageService = injector.get(SecureStorageService);
    environment.encrytionSecret = await secureStorageService.getEncryptionSecret();
  }

  private setProtocolToUrl(url: string): string {
    const regex = /^https?:\/\//i;

    if (regex.test(url)) {
      return url;
    }

    return `https://${url}`;
  }
}
