import { Component, EventEmitter, Input, OnInit, Output, TemplateRef } from '@angular/core';
import { ImageModel } from '../../../common/models';
import { LibraryType } from '../../../models';
import { LibraryItem } from '../../models';
import { FileExtensionService } from '../../services/file-extension/file-extension.service';

@Component({
  selector: 'lib-library-item-card',
  templateUrl: './library-item-card.component.html',
  styleUrls: ['./library-item-card.component.scss']
})
export class LibraryItemCardComponent implements OnInit {
  @Input() public enablePinned = false;
  @Input() public item: LibraryItem;
  @Input() public contextTemplate: TemplateRef<HTMLElement>;
  
  public libraryTypes: typeof LibraryType = LibraryType;

  @Output() public open: EventEmitter<void> = new EventEmitter<void>();
  @Output() public pinnedChanged: EventEmitter<void> = new EventEmitter<void>();

  public previewImage: ImageModel;

  constructor(private readonly fileExtensionService: FileExtensionService) { }

  ngOnInit(): void {
    this.previewImage = this.fileExtensionService.getLibraryItemPreviewImage(this.item);
  }

  public togglePinned(event: Event): void {
    event.stopPropagation();
    this.pinnedChanged.emit();
  }

  public openLibraryItem(): void {
    this.open.emit();
  }

  public stopPropagation(event: Event) {
    event.stopPropagation();
    event.preventDefault();
  }

}
