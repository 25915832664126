import { FilePreviewType } from 'src/app/model/enums/file-preview-type.enum';

export const ViewerTypeExtensions = {
  [FilePreviewType.GOOGLE_VIEWER]: [
    'txt', 'css', 'html', 'js', 'pages',
    'ai', 'psd', 'tiff', 'dxf', 'svg',
    'eps', 'ps', 'ttf', 'xps'
  ],
  [FilePreviewType.OFFICE_VIEWER]: ['ppt', 'pptx', 'doc', 'docx', 'xls', 'xlsx'],
  [FilePreviewType.PDF_VIEWER]: ['pdf'],
  [FilePreviewType.MODEL_VIEWER]: ['glb'],
  [FilePreviewType.IMAGE_VIEWER]: ['png', 'jpeg', 'jpg'],
  [FilePreviewType.VIDEO_VIEWER]: ['3gp', 'mpg', 'mpeg', 'mp4', 'm4v', 'm4p', 'ogv', 'ogg', 'mov', 'webm']
};
