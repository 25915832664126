import { Pipe, PipeTransform } from '@angular/core';
import * as moment from 'moment-timezone';

@Pipe({
  name: 'dateToTimezone'
})
export class DateToTimezonePipe implements PipeTransform {

  transform(date: Date, timezone?: string): any {
    if (!timezone || !date) {
      return date;
    }

    return moment(date).utc(false).tz(timezone, true).toDate();
  }

}
