import { ChangeDetectionStrategy, Component, EventEmitter, HostBinding, Input, Output } from '@angular/core';

@Component({
  selector: 'app-loading-button',
  templateUrl: './loading-button.component.html',
  styleUrls: ['./loading-button.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class LoadingButtonComponent {
  @Input() public cssClass: string;
  @Input() public isLoading = false;
  @Input() public disabled = false;
  @Input() public disableRipple = false;
  @Input() public showArrow = false;
  @Input() public loadingClass: string;
  @Input() public progress = 0;
  @Input() public enableProgressIndicator = false;

  @Output() public clicked: EventEmitter<void> = new EventEmitter<void>();

  @HostBinding('attr.data-cy') @Input() dataAttribute: string;

  constructor() { }

  public clickHandler(): void {
    if (this.isLoading || this.disabled) {
      return;
    }

    this.clicked.emit();
  }

}
