import { HttpParams } from '@angular/common/http';
import { Component, EventEmitter, Input, OnDestroy, OnInit, Output, TemplateRef } from '@angular/core';
import { FormControl } from '@angular/forms';
import { TaxonomyType } from '@app/model/enums/taxonomy-type.enum';
import { TaxonomyApiService } from '@app/services/api/taxonomy-api.service';
import { debounceTime, finalize, map, switchMap, takeUntil, tap } from 'rxjs/operators';
import { SearchService } from '@app/services/api/search.service';
import { forkJoin, Observable, of, Subject } from 'rxjs';
import { NodeModel, TaxonomyModel, BundleType } from 'library-explorer';

@Component({
  selector: 'app-quick-search-input',
  templateUrl: './quick-search-input.component.html',
  styleUrls: ['./quick-search-input.component.scss']
})
export class QuickSearchInputComponent implements OnInit, OnDestroy {
  @Input() public bundle: BundleType | BundleType[];
  @Input() public taxonomyType: TaxonomyType;
  
  @Input() public resultTemplate: TemplateRef<any>;
  @Input() public resultsHeaderTemplate: TemplateRef<any>;
  @Input() public enabledSearchByTag = false;

  @Output() public search: EventEmitter<string> = new EventEmitter();
  @Output() public searchByTag: EventEmitter<string> = new EventEmitter();

  public searchControl = new FormControl('');

  public tags = [];
  public tagsLoading = true;

  public data = [];
  public dataLoading = true;

  public loadingPlaceholers = new Array(3);

  public expanded = false;

  private unsubscribe: Subject<void> = new Subject();

  constructor(
    private readonly searchService: SearchService,
    private readonly taxonomyApiService: TaxonomyApiService) { }

  ngOnInit(): void {
    this.searchControl.valueChanges
      .pipe(
        debounceTime(200),
        switchMap(value => {
          this.expanded = value?.length > 0;

          if (!value) {
            this.data = [];
            this.tags = [];
            return of(null);
          }

          return forkJoin([
            this.loadTags(),
            this.loadData()
          ]);
        }),
        takeUntil(this.unsubscribe)
      )
      .subscribe();
  }

  ngOnDestroy(): void {
    this.unsubscribe.next();
    this.unsubscribe.complete();
  }

  public expandSearchPanel(): void {
    this.expanded = this.searchControl?.value?.length > 0;
  }

  public colapseSearchPanel(): void {
    this.expanded = false;
  }

  public clearSearch(): void {
    this.searchControl.setValue('');
  }

  private loadTags(): Observable<any> {
    this.tagsLoading = true;

    const params = new HttpParams({ fromObject: { search: this.searchControl?.value, limit: 3 } });

    return this.taxonomyApiService.getTaxonomiesList(TaxonomyType.PUBLIC_TAGS, params)
      .pipe(
        tap(() => this.tagsLoading = false),
        map(data => this.tags = data)
      );
  }

  private loadData(): Observable<any> {
    this.dataLoading = true;
    this.data = [];

    const obs: Observable<Array<NodeModel | TaxonomyModel>>[] = [];

    if (this.bundle) {
      obs.push(
      this.searchService.searchBy(this.searchControl?.value, this.bundle, 0, 3)
        .pipe(
          map(data => this.data = data.items)
        )
      );
    }

    if (this.taxonomyType) {
      const params = new HttpParams({ fromObject: { search: this.searchControl?.value, limit: 3 } });

      obs.push(
        this.taxonomyApiService.getTaxonomiesList(this.taxonomyType, params)
      );
    }

    if (obs.length === 0) {
      this.dataLoading = false;
      return of([]);
    }

    return forkJoin(obs)
      .pipe(
        tap(() => this.dataLoading = false),
        map(([data, taxonomy = []]) => this.data = [...data, ...taxonomy])
      );
  }

}
