export enum QuestionType {
  MATCHING_QUESTION = 'matching_question',
  ORDER_QUESTION = 'order_question',
  BOOLEAN_QUESTION = 'statement_question',
  FILL_BLANKS = 'fill_in_the_blanks_question',
  QUESTION_MULTICHOICE_TEXT = 'question_multichoice_text',
  QUESTION_ASSIGNMENT = 'question_assignment',
  QUESTION_FREE_TEXT = 'question_free_text',
  QUESTION_SLIDER = 'question_slider',
  QUESTION_PRONUNCIATION = 'question_pronunciation',
  QUESTION_SCALE = 'question_scale'
}
