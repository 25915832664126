import { HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { AccessTokenType } from '../models/enums/access-token-type.enum';
import { HttpService } from './http.service';

@Injectable({
  providedIn: 'root'
})
export class TokenApiService {

  constructor(
    private readonly httpService: HttpService,
  ) { }

  public getTokenForFileAccess(type: AccessTokenType, id?: string): Observable<string> {
    let params = new HttpParams()
      .set('type', type);

    if (id) {
      params = params.set('id', id);
    }

    return this.httpService.get<{ token: string }>(`token`, params)
      .pipe(
        map((data: any) => data.token)
      );
  }
}
