import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { HttpService, interceptorsToken, WebinarDTO } from 'library-explorer';
import { CourseDTO } from 'src/app/model/courseDTO.model';
import { PaymentService } from 'src/app/services/payment.service';
import { HttpParams } from '@angular/common/http';
import { Router } from '@angular/router';
import { ModuleDTO } from '@app/model/moduleDTO.model';
import { DataResponse } from '@app/model/data-response.model';

@Injectable({
  providedIn: 'root'
})
export class CourseService {

  constructor(
    private httpService: HttpService,
    private paymentService: PaymentService,
    private router: Router
  ) { }

  public getAllCourses(classID: string = null, extraParams?: HttpParams): Observable<CourseDTO[]> {
    let params = extraParams || new HttpParams();

    params = params
      .set('active', 'true')
      .set('format', 'json')
      .set(interceptorsToken.HANDLE_PERMISSION_DENIED_PARAM, 'true');

    if (classID) {
      params = params.set('parentClass', classID);
    }

    return this.httpService.get<CourseDTO[]>('courses', params);
  }

  public getStartedCourses(classID: string = null, inProgress = true): Observable<CourseDTO[]> {    
    let params = new HttpParams()
      .set('active', 'true')
      .set('_format', 'json')
      .set('inProgress', inProgress ? '1' : '0')
      .set(interceptorsToken.HANDLE_PERMISSION_DENIED_PARAM, 'true');

    if (classID) {
      params = params.set('parentClass', classID);
    }

    return this.httpService.get<CourseDTO[]>('courses', params);
  }

  public getCoursesOfClass(classID: string): Observable<CourseDTO[]> {
    const params = new HttpParams()
      .set('parentClass', classID)
      .set('active', 'true')
      .set(interceptorsToken.HANDLE_PERMISSION_DENIED_PARAM, 'true');

    return this.httpService.get<CourseDTO[]>('courses', params);
  }

  public getCourseByID(courseID: string): Observable<CourseDTO> {
    const params = new HttpParams()
      .set('active', 'true')
      .set('_format', 'json')
      .set('id', courseID)
      .set(interceptorsToken.HANDLE_PERMISSION_DENIED_PARAM, 'true');

    return this.httpService.get<CourseDTO>('node', params);
  }

  public getCourseByAlias(alias: string): Observable<CourseDTO> {
    const params = new HttpParams()
      .set('alias', alias)
      .set(interceptorsToken.HANDLE_PERMISSION_DENIED_PARAM, 'true');

    return this.httpService.get<CourseDTO>('node', params);
  }

  public getCourseDetailInfo(id: string): Observable<CourseDTO> {
    return this.httpService.get<CourseDTO>(`course/${id}/detail-info`);
  }

  public getCourseModulesList(id: string, offset = 0, limit = 20): Observable<DataResponse<ModuleDTO>> {
    const params = new HttpParams()
      .set('offset', offset.toString())
      .set('limit', limit.toString());

    return this.httpService.get<DataResponse<ModuleDTO>>(`course/${id}/modules`, params);
  }

  public getCourseWebinarsList(id: string, offset = 0, limit = 20): Observable<DataResponse<WebinarDTO>> {
    const params = new HttpParams()
      .set('offset', offset.toString())
      .set('limit', limit.toString());

    return this.httpService.get<DataResponse<WebinarDTO>>(`course/${id}/webinars`, params);
  }
}
