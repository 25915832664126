import { Injectable } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { ActivatedRoute, ActivatedRouteSnapshot, NavigationEnd, Router } from '@angular/router';
import { BehaviorSubject, Observable, of } from 'rxjs';
import { catchError, filter, map, tap } from 'rxjs/operators';
import {
  NotificationListDialogComponent
} from 'src/app/shared/components/notification-list-dialog/notification-list-dialog.component';

import { NotificationDelivery } from '../model/notification-delivery';
import { NotificationPopupComponent } from '../shared/components/notification-popup/notification-popup.component';
import { NotificationApiService } from './api/notification-api.service';
import { FileUploadApiService, ProfileService } from 'library-explorer';

@Injectable({
  providedIn: 'root'
})
export class NotificationService {
  public hasUnreadNotifications: BehaviorSubject<boolean> = new BehaviorSubject(false);
  public notifications: BehaviorSubject<NotificationDelivery[]> = new BehaviorSubject([]);
  public listDialog: MatDialogRef<NotificationListDialogComponent>;

  private blockedNotificationPopups: NotificationDelivery[] = [];
  private eventSourceUrlsConnectionError = [];
  private pollingNotificationInterval: any;
  private eventSources: EventSource[] = [];

  constructor(
    private readonly profileService: ProfileService,
    private readonly fileUploadApiService: FileUploadApiService,
    private readonly route: ActivatedRoute,
    private readonly notificationApiService: NotificationApiService,
    private readonly matDialog: MatDialog,
    private readonly router: Router,
  ) {
    this.initialize();
  }

  public checkForUnreadNotifications(): void {
    const items = this.notifications.value;
    const hasUnread = items.some(item => !item.popupNotificationSeenAt && !item.regularNotificationSeenAt);

    this.hasUnreadNotifications.next(hasUnread);
  }

  public openNotificationPopup(notification: NotificationDelivery): void {
    const snaphot = this.getLastActivatedRouteSnapshot(this.route.snapshot);
    const popupDisabled = snaphot.data.blockNotifications;

    if (popupDisabled) {
      this.blockedNotificationPopups.push(notification);
      return;
    }

    const dialog = this.matDialog.open(NotificationPopupComponent, {
      width: '700px',
      maxWidth: '90vw',
      maxHeight: 'auto',
      panelClass: 'notification-popup-dialog',
      autoFocus: false,
      data: notification
    });

    dialog.afterClosed()
      .pipe(
        filter(() => !notification.popupNotificationSeenAt)
      )
      .subscribe(() => this.markNotificationAsSeen(notification));
  }

  public markNotificationAsSeen(notification: NotificationDelivery): void {
    const current = this.notifications.value.find(item => item.notificationId === notification.notificationId) || notification;
    const isSeen = current.popupNotificationSeenAt || current.regularNotificationSeenAt;

    if (isSeen) {
      return;
    }

    this.notificationApiService.markNotificationAsSeen(current.notificationId)
      .subscribe(() => {
        current.popupNotificationSeenAt = current.regularNotificationSeenAt = new Date();
        this.checkForUnreadNotifications();
      });
  }

  public loadNotifications(): void {
    this.notificationApiService.getNotifications()
      .pipe(
        map(data => data['hydra:member'])
      )
      .subscribe((messages: NotificationDelivery[]) => {
        this.notifications.next(messages);
        this.checkForUnreadNotifications();
        messages
          .filter(item => item.popup)
          .filter(item => !item.popupNotificationSeenAt)
          .forEach(item => {
            this.openNotificationPopup(item);
          });
      });
  }

  public openListDialog(nativeElement: Element): void {
    if (this.listDialog) {
      this.closeListDialog();
      return;
    }

    if (this.router.url === '/notifications') {
      return;
    }

    const isDesktop = window.innerWidth > 991;

    this.listDialog = this.matDialog.open(NotificationListDialogComponent, {
      closeOnNavigation: true,
      hasBackdrop: !isDesktop,
      panelClass: 'notification-list-dialog',
      position: isDesktop && {
        top: `${nativeElement.parentElement.offsetHeight + 5}px`,
        right: `30px`
      },
      maxWidth: '90vw',
    });

    this.listDialog.afterClosed().subscribe(() => this.listDialog = undefined);
  }

  public closeListDialog(): void {
    if (this.listDialog) {
      this.listDialog.close();
      this.listDialog = null;
    }
  }

  public clearEventSources(): void {
    this.eventSources.forEach((eventSource: EventSource) => {
      eventSource.close();
    });

    this.eventSources = [];
  }

  public clearNotifications(): void {
    this.notifications.next([]);
    this.hasUnreadNotifications.next(false);
  }

  private notificationReceived(notification: NotificationDelivery): void {
    const current = this.notifications.value;
    const isExist = current.some(item => item.notificationId === notification.notificationId);

    if (!isExist) {
      const imageExist = notification.message.coverImageId && notification.message.coverImageId;
      const obs = imageExist ?
        this.fileUploadApiService.getFileByUUID(notification.message.coverImageId)
          .pipe(
            catchError((err) => {
              return of(null);
            }),
            tap(url => Object.assign(notification.message, { imageUrl: url }))
          )
        : of(null);

      obs.subscribe(() => {
        current.push(notification);
        this.notifications.next(current);
        this.checkForUnreadNotifications();

        if (notification.popup) {
          this.openNotificationPopup(notification);
        }
      });
    }
  }

  private authorize(userId: string): Observable<any> {
    return this.notificationApiService.authorize()
      .pipe(
        tap((data) => {
          const linkValue = data.headers.get('Link');
          const url = linkValue.match(/<([^>]+)>;\s+rel=(?:mercure|"[^"]*mercure[^"]*")/)[1];
          this.addEventSource(url, userId);
        })
      );
  }

  private addEventSource(url: string, userId: string): void {
    const hub = new URL(url);
    hub.searchParams.append('topic', userId);
    hub.searchParams.append('ngsw-bypass', 'true');
    const eventSource = new EventSource(hub as any, { withCredentials: true });
    this.eventSources.push(eventSource);
    eventSource.onmessage = event => {
      const notification = JSON.parse(event.data);
      this.notificationReceived(notification);
    };
    eventSource.onerror = event => {
      this.onEventSourceFail(eventSource, url);
    };
    eventSource.onopen = event => {
      const index = this.eventSourceUrlsConnectionError.indexOf(url);
      if (index !== -1) {
        this.eventSourceUrlsConnectionError.splice(index, 1);
      }
    };
  }

  private initialize(): void {
    this.router.events
      .pipe(
        filter(event => event instanceof NavigationEnd),
        tap(() => {
          const snaphot = this.getLastActivatedRouteSnapshot(this.route.snapshot);
          const popupDisabled = snaphot.data.blockNotifications;

          if (!popupDisabled && this.blockedNotificationPopups.length) {
            this.openBlockedPopup();
          }
        }),
        filter(() => !!this.listDialog)
      )
      .subscribe(() => {
        this.listDialog.close();
      });
  }

  private startPollingInterval(): void {
    if (this.pollingNotificationInterval) {
      return;
    }
    
    this.pollingNotificationInterval = setInterval(() => {
      this.loadNotifications();
    }, 30000);
  }

  private openBlockedPopup(): void {
    this.blockedNotificationPopups.forEach(item => this.openNotificationPopup(item));
    this.blockedNotificationPopups = [];
  }

  private onEventSourceFail(eventSource: EventSource, eventSourceUrl: string): void {
    this.disconectFromEventSource(eventSource);
    const isConnectionError = this.eventSourceUrlsConnectionError.indexOf(eventSourceUrl) !== -1;

    if (isConnectionError) {
      this.startPollingInterval();
    } else {
      this.loadNotifications();
      this.eventSourceUrlsConnectionError.push(eventSourceUrl);

      // try to reconect
      this.addEventSource(eventSourceUrl, this.profileService.getCurrentProfileValue()?.id);
    }
  }

  private disconectFromEventSource(eventSource: EventSource): void {
    eventSource.close();
    const index = this.eventSources.indexOf(eventSource);
    if (index !== -1) {
      this.eventSources.splice(index, 1);
    }
  }

  private getLastActivatedRouteSnapshot(route: ActivatedRouteSnapshot): ActivatedRouteSnapshot {
    if (route.firstChild) {
      return this.getLastActivatedRouteSnapshot(route.firstChild);
    }

    return route;
  }

}
