<ng-container *ngIf="block" [style.color]="block.textColor">

  <section 
    class="login-promo" 
    *ngIfMediaQuery="'(min-width: 640px)'; else mobileImageTmp"
    [cropImage]="block.mediaImage | preSignedUrl | async" 
    [additionalOptions]="{width: 1920}">
    <ng-container *ngTemplateOutlet="content"></ng-container>
  </section>

  <ng-template #mobileImageTmp>
    <ng-container *ngIf="(block.mediaImagePhone || block.mediaImage) as mobileImage">
      <section 
        class="login-promo" 
        [cropImage]="mobileImage | preSignedUrl | async" 
        [additionalOptions]="{ width: 600 }">
        <ng-container *ngTemplateOutlet="content"></ng-container>
      </section>
    </ng-container>
  </ng-template>

</ng-container>

<ng-template #content>
  <div class="content block-container">
    <h1 [innerHtml]="block.title | safeHtml" [rtl]="block.langcode"></h1>
    <p *ngIf="block.subTitle" class="subtitle" [innerHtml]="block.subTitle | safeHtml" [rtl]="block.langcode"></p>
    <div class="buttons-wrapper">
      <button mat-flat-button class="register button button--large" *ngIf="registrationButtonVisible" [routerLink]="['/access', 'register']">
        <span>{{'COMMON.register' | translate }}</span>
        <mat-icon>navigate_next</mat-icon>
      </button>
      <button mat-flat-button class="sign-in button button--primary button--large" [routerLink]="['/access', 'login']">
        <span>{{'COMMON.sign_in' | translate }}</span>
        <mat-icon>navigate_next</mat-icon>
      </button>
    </div>
  </div>
</ng-template>
