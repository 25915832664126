<ng-container *ngIf="isFileSupportedByViewer; else unsupportedTmp">
  <app-fullscreen 
    class="document-preview"
    [ngClass]="mode"
    [showDownloadBtn]="allowDownloadFile"
    [showFullScreenBtn]="showFullScreenBtn"
    [isDownloadLoading]="isDownloadLoading"
    [template]="targetTmp" 
    (fullscreenToggle)="reloadIFrame()"
    (downloadFile)="downloadFile()">
  </app-fullscreen>
</ng-container>


<ng-template #targetTmp>
  <ng-container [ngSwitch]="mode">
    <ng-container *ngSwitchCase="modes.MODEL_VIEWER">
      <app-google-model-viewer [src]="fileSrc" (loaded)="loaded()"></app-google-model-viewer>
    </ng-container>
    <ng-container *ngSwitchCase="modes.IMAGE_VIEWER">
      <img class="document-preview__image" [src]="fileSrc" alt="">
    </ng-container>
    <ng-container *ngSwitchCase="modes.VIDEO_VIEWER">
      <lib-video 
        class="document-preview__video" 
        [src]="fileSrc"
        (started)="logUserVideoActivity(activityTypes.USER_START_WATCHING_VIDEO)"
        (watched)="logUserVideoActivity(activityTypes.USER_WATCHED_VIDEO)"
        (videoCanPlay)="loaded()">
      </lib-video>
    </ng-container>
    <ng-container *ngSwitchCase="modes.PDF_VIEWER">
      <app-pdf-viewer 
        [src]="fileSrc" 
        [watermarkEnabled]="watermarkEnabled" 
        [allowDownloadFile]="allowDownloadFile" 
        [forceFullScreen]="forceFullScreen" 
        (loaded)="loaded()">
      </app-pdf-viewer>
    </ng-container>
    <ng-container *ngSwitchCase="modes.OFFICE_VIEWER">
      <ngx-doc-viewer 
        [class.inside-dialog]="insideDialog"
        [url]="fileSrc"
        [viewer]="mode"
        (loaded)="loaded()">
      </ngx-doc-viewer>  
    </ng-container>  
    <ng-container *ngSwitchCase="modes.GOOGLE_VIEWER">
      <ngx-doc-viewer 
        [class.inside-dialog]="insideDialog"
        [url]="fileSrc"
        [viewer]="mode"
        [googleCheckContentLoaded]="true"
        (loaded)="loaded()">
      </ngx-doc-viewer>  
    </ng-container>  
  </ng-container>
  <div *ngIf="isLoading && !insideDialog" class="loader document-preview-loader"></div>
</ng-template>

<ng-template #unsupportedTmp>
  <div class="d-flex flex-column align-items-center justify-content-center unsupported-template">
    <span class="unsupported-template__title">{{fileName}}</span>
    <button class="primary-button mt-2" mat-flat-button (click)="downloadFile()">
      {{ 'COMMON.download' | translate }}
    </button>
  </div>
</ng-template>
