import { HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { BasicPage } from 'src/app/model/basic-page.model';
import { HttpService, interceptorsToken } from 'library-explorer';

@Injectable({
  providedIn: 'root'
})
export class BasicPageService {

  constructor(
    private httpService: HttpService
  ) { }

  public getPageById(id: string): Observable<BasicPage> {
    const params = new HttpParams()
      .set('id', id)
      .set('active', 'true')
      .set(interceptorsToken.HANDLE_PERMISSION_DENIED_PARAM, 'true');

    return this.httpService.get<BasicPage>(`page`, params);
  }

  public getPageByAlias(alias: string): Observable<BasicPage> {
    const params = new HttpParams()
      .set('alias', alias)
      .set('active', 'true')
      .set(interceptorsToken.HANDLE_PERMISSION_DENIED_PARAM, 'true');

    return this.httpService.get<BasicPage>('page', params);
  }
}
