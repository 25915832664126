import { Component, Input } from '@angular/core';
import { BundleType } from '../../../common/models/enums/bundle-type.enum';
import { EntityItemLayoutEnum } from '../../../common/models/enums/entity-item-layout.enum';
import { BaseCardComponent } from '../base-card/base-card.component';

@Component({
  selector: 'lib-lesson-card',
  templateUrl: './lesson-card.component.html',
  styleUrls: ['./lesson-card.component.scss']
})
export class LessonCardComponent extends BaseCardComponent {

  @Input() layout: EntityItemLayoutEnum = EntityItemLayoutEnum.GRID;

  public readonly bundleType: typeof BundleType = BundleType;

}
