import { Component, OnInit, Input, EventEmitter, Output, OnDestroy, ViewChildren, QueryList } from '@angular/core';
import { CardOrienationOption } from '../../../common/models';
import { CdkDragDrop } from '@angular/cdk/drag-drop';
import { FormArray } from '@angular/forms';
import { FlipcardService } from '../../services/flipcard.service';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { FlipCardComponent } from '../flipcard/flipcard.component';

@Component({
  selector: 'app-flipcard-group',
  templateUrl: './flipcard-group.component.html',
  styleUrls: ['./flipcard-group.component.scss']
})
export class FlipcardGroupComponent implements OnInit, OnDestroy {

  @ViewChildren(FlipCardComponent) flipcardList: QueryList<FlipCardComponent>;

  @Input() public animationEnabled = true;
  @Input() public canEdit = false;
  @Input() public isTranslation?: boolean;
  @Input() public isRtl?: boolean;
  @Input() public backgroundPresigned = true;
  @Input() public formArray?: FormArray;
  @Input() public set slideData(data: any) {
    if (!data) {
      this._slideData = null;
      return;
    }

    this._slideData = JSON.parse(JSON.stringify(data));
  }
  public get slideData(): any {
    return this._slideData;
  }

  @Output() public edit: EventEmitter<number> = new EventEmitter();
  @Output() public changeOrientation: EventEmitter<number> = new EventEmitter();
  @Output() public remove: EventEmitter<number> = new EventEmitter();
  @Output() public reorder: EventEmitter<any> = new EventEmitter();
  @Output() public interactionCompleted: EventEmitter<number> = new EventEmitter();

  public orientations: typeof CardOrienationOption = CardOrienationOption;

  public trackFlipcardBy = (index: number, item: any) => item.id;
  
  private completedCards!: boolean[];
  private _slideData: any;

  private unsubscribe: Subject<void> = new Subject();

  constructor(
    private readonly flipcardService: FlipcardService
  ) { }

  ngOnInit() {
    this.completedCards = new Array(this.slideData.flipcards?.length).fill(false);
    this.flipcardService.onFlip.pipe(
      takeUntil(this.unsubscribe)
    ).subscribe(({id, side}) => {
      const index = this.slideData.flipcards?.findIndex(item => item.uuid === id);
      if (index === -1 || this.flipcardList.length <= index) {
        return;
      }

      const flipcard = this.flipcardList.get(index);

      if (!side || (flipcard.flipped && side === 'front') || (!flipcard.flipped && side === 'back')) {
        flipcard.toggleFlip();
      }
    })
  }

  public cardFlipped(index: number): void {
    this.completedCards[index] = true;

    const completedLength = this.completedCards.filter(item => item === true).length;

    this.interactionCompleted.emit(completedLength / this.slideData.flipcards?.length);
  }

  public drop(event: CdkDragDrop<any>) {
    const current = event.container.data.order;
    const prev = event.previousContainer.data.order;
    this.reorder.emit({ previousIndex: prev, currentIndex: current });
  }

  ngOnDestroy(): void {
    this.unsubscribe.next();
    this.unsubscribe.complete();
  }

}
