<div class="chat">
  <div class="chat-messages">
    <ng-container *ngIf="groupChannel; else loaderTmp">
      <app-chat-channel-messages-list 
        [groupChannel]="groupChannel" 
        (markAsRead)="markAsRead()"
        (edit)="editMessage($event)">
      </app-chat-channel-messages-list>
    </ng-container>
  </div>

  <div class="chat-channel-control" [class.has-border]="!hideBorder">
    <app-chat-message-input [groupChannel]="groupChannel"></app-chat-message-input>
  </div>
</div>

<ng-template #loaderTmp>
  <div class="chat-message-placeholder">
    <lib-skeleton-loader height="32px" width="32px" className="circle"></lib-skeleton-loader>

    <lib-skeleton-loader height="40px" width="40%"></lib-skeleton-loader>
  </div>

  <div class="chat-message-placeholder">
    <lib-skeleton-loader height="32px" width="32px" className="circle"></lib-skeleton-loader>

    <lib-skeleton-loader height="40px" width="40%"></lib-skeleton-loader>
  </div>

  <div class="chat-message-placeholder">
    <lib-skeleton-loader height="32px" width="32px" className="circle"></lib-skeleton-loader>

    <lib-skeleton-loader height="40px" width="60%"></lib-skeleton-loader>
  </div>
</ng-template>