<button 
  mat-icon-button 
  class="button button--icon" 
  [ngClass]="{ active: active, disabled: disabled, 'button--outline': hasBorder }"
  [matTooltip]="tooltip"
  [style.width.px]="size"
  [style.height.px]="size"
  [matTooltipPosition]="tooltipPosition"
  matTooltipClass="dark-tooltip"
  (click)="clickHandler()">
    <ng-container *ngIf="!isLoading; else loadingTmp">
      <ng-container *ngIf="icon">
        <lib-icon [icon]="icon" class="icon" [ngClass]="iconClass"></lib-icon>
      </ng-container>
  
      <ng-content></ng-content>
  
      <div class="button-badge" *ngIf="counter">
        {{ counter > 9 ? '9+' : counter }}
      </div>
    </ng-container>

    <ng-template #loadingTmp>
      <mat-spinner color="primary" [diameter]="16"></mat-spinner>
    </ng-template>
</button>
