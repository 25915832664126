import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { FilePreviewType } from 'src/app/model/enums/file-preview-type.enum';
import { FileModel } from 'library-explorer';
import { PreviewModalComponent } from '../components/preview-modal/preview-modal.component';
import { ViewerTypeExtensions } from '../document-viewer-extensions';


@Injectable({
  providedIn: 'root'
})
export class DocumentPreviewService {

  constructor(private readonly dialog: MatDialog) { }

  public openPreviewDialog(file: FileModel, presignedUrl = null, searchKey = '', entityId = null): void {
    const fileName = file.filename;
    const fileExtension = fileName.split('.').reverse()[0].toLocaleLowerCase();
    const modes = Object.values(FilePreviewType);
    const mode = modes.find(key => ViewerTypeExtensions[key].indexOf(fileExtension) !== -1);

    this.dialog.open(PreviewModalComponent, {
      panelClass: ['preview-file-dialog', mode],
      data: {
        file,
        presignedUrl,
        mode,
        searchKey,
        entityId
      },
      autoFocus: false
    });
  }
}
