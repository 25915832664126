import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { CloudImageService } from './cloud-image.service';
import { mergeMap } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class MediaHelperService {

  constructor(private readonly cloudImageService: CloudImageService) { }

  public preloadCloudImage(src: string, width = 0, additionalOptions = {}, timeout = 0): Observable<void> {
    if (!src) {
      return of(null);
    }

    return this.cloudImageService.generateCloudImageResourceUrl(src, width, additionalOptions)
      .pipe(
        mergeMap(cloudImageSrc => {
          return new Observable<void>(subscriber => {
            const img = new Image();
            let completed = false;

            const complete = () => {
              if (completed) {
                return;
              }

              completed = true;
              subscriber.next();
              subscriber.complete();
            };

            img.onload = complete;
            img.onerror = complete;

            img.src = cloudImageSrc;

            if (timeout) {
              setTimeout(complete, timeout);
            }
          });
        })
      )
  }
}
