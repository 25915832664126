<div class="webinars-block" [style.color]="block.textColor">
  <div class="content px-0">
    <div class="px-4">
      <div class="title-wrapper">
        <h1 [rtl]="block.langcode">{{
          block.title || ('MENU.webinars' | translate)}}
        </h1>

        <button 
          mat-button 
          class="button button--transparent button--outline" 
          [style.border-color]="block.textColor"
          [style.color]="block.textColor"
          [disableRipple]="true" 
          [routerLink]="['/webinars']">{{'View all webinars' | translate}}</button>
      </div>

      <div class="webinar-carousel">
        <app-carousel 
          type="webinar" 
          [items]="webinars$ | async" 
          [config]="carouselConfig"></app-carousel>
        
        <ng-container *ngIf="isLoading">
          <div class="placeholders-grid">
            <lib-skeleton-loader *ngFor="let item of placeholders" class="placeholder" height="392px" width="420px"></lib-skeleton-loader>
          </div>
        </ng-container>
      </div>
    </div>
  </div>
</div>
