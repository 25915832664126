import { Directive, ElementRef, NgZone, OnInit } from '@angular/core';
import { SlickCarouselComponent } from 'ngx-slick-carousel';

@Directive({
  selector: '[slickCarouselResize]'
})
export class SlickCarouselResizeDirective implements OnInit {
  constructor(
    private readonly zone: NgZone,
    private readonly elementRef: ElementRef,
    private readonly carouselComponent: SlickCarouselComponent) {
  }

  ngOnInit(): void {
    this.zone.runOutsideAngular(() => {
      const throttle = (fn, delay = 0) => {
        let timer;
        return function (...args) {
          clearTimeout(timer);
          timer = setTimeout(() => fn.apply(this, args), delay);
        }
      };
    
      const observer = new ResizeObserver(throttle(() => {
        this.carouselComponent.$instance?.slick('setPosition');
      }, 20));
      
      observer.observe(this.elementRef.nativeElement);
    });
  }
  
}
