<div *ngFor="let block of pageBlocks" [ngClass]="[block.bundle, 'block']" [bundle]="block.bundle" [blockBackground]="block">
  <ng-container [ngSwitch]="block.bundle">
    <app-text-image *ngSwitchCase="blockTypes.BLOCK_IMAGE_TEXT" [block]="block"></app-text-image>
    <app-login-promo *ngSwitchCase="blockTypes.BLOCK_LOGIN_PROMO" [block]="block"></app-login-promo>
    <app-feature-highlight *ngSwitchCase="blockTypes.BLOCK_FEATURE_HIGHLIGHT" [block]="block"></app-feature-highlight>
    <app-video-block *ngSwitchCase="blockTypes.BLOCK_VIDEO" [block]="block"></app-video-block>
    <app-banner-block *ngSwitchCase="blockTypes.BLOCK_FULL_SCREEN_BANNER" [block]="block"></app-banner-block>
    <app-testimonials *ngSwitchCase="blockTypes.BLOCK_TESTIMONIALS" [block]="block"></app-testimonials>
    <app-discover-content-block *ngSwitchCase="blockTypes.BLOCK_DISOVER_CONTENT" [block]="block"></app-discover-content-block>
    <app-carousel-block *ngSwitchCase="blockTypes.BLOCK_CAROUSEL" [block]="block"></app-carousel-block>
    <ng-container *ngSwitchCase="blockTypes.BLOCK_LEARNING_PATH">
      <app-learning-path-block *ngIf="block.contents?.length" [block]="block"></app-learning-path-block>
    </ng-container>
    <ng-container *ngSwitchCase="blockTypes.BLOCK_CONTENT_HIGHTLIGHT">
      <app-content-highlight-block [block]="block"></app-content-highlight-block>
    </ng-container>

    <ng-container *ngSwitchCase="blockTypes.BLOCK_DASHBOARD_HIGHLIGHTS">
      <app-dashboard-highlights-block [block]="block"></app-dashboard-highlights-block>
    </ng-container>
    <ng-container *ngSwitchCase="blockTypes.BLOCK_PROMOTION">
      <app-promotion-block [block]="block"></app-promotion-block>
    </ng-container>
    <ng-container *ngSwitchCase="blockTypes.BLOCK_WEBINARS">
      <app-webinars-block [block]="block"></app-webinars-block>
    </ng-container>

  </ng-container>
</div>