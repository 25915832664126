import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FlipCardComponent } from './components/flipcard/flipcard.component';
import { FlipCardSideComponent } from './components/flipcard-side/flipcard-side.component';
import { SharedLibraryModule } from '../common/shared-library.module';
import { NgScrollbarModule } from 'ngx-scrollbar';
import { MatSliderModule } from '@angular/material/slider';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { FlipcardGroupComponent } from './components/flipcard-group/flipcard-group.component';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { FlipcardAudioComponent } from './components/flipcard-audio/flipcard-audio.component';



@NgModule({
  declarations: [
    FlipCardComponent,
    FlipCardSideComponent,
    FlipcardGroupComponent,
    FlipcardAudioComponent
  ],
  exports: [
    FlipCardComponent,
    FlipCardSideComponent,
    FlipcardGroupComponent
  ],
  imports: [
    CommonModule,
    SharedLibraryModule,
    NgScrollbarModule,
    FormsModule,
    MatSliderModule,
    DragDropModule,
    MatProgressSpinnerModule,
    ReactiveFormsModule
  ]
})
export class FlipcardModule {
}
