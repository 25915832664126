import { OfflineModeService, HostType } from 'library-explorer';
import { Injectable, NgZone } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { environment } from 'src/environments/environment';
import { BundleInfo, CapacitorUpdater } from '@capgo/capacitor-updater';
import { App } from '@capacitor/app';
import { Capacitor } from '@capacitor/core';
import { BehaviorSubject } from 'rxjs';
import { LiveUpdateModalComponent } from '@app/components/live-update-modal/live-update-modal.component';
import { LiveUpdateDownloadState } from '@app/model/enums/live-update-download-state.enum';

const pj = require('package.json');

@Injectable({
  providedIn: 'root'
})
export class LiveUpdateService {
  cordovaDeploy: any;
  currentVersion: string = pj.version;

  private downloadDialogRef: MatDialogRef<any>;
  private updateDialogRef: MatDialogRef<any>;

  private downloadProgressSubject = new BehaviorSubject<number>(0);
  public downloadProgress$ = this.downloadProgressSubject.asObservable();

  private liveUpdateModalContent = new BehaviorSubject<{ header: string; description: string }>(null);
  public liveUpdateModalContent$ = this.liveUpdateModalContent.asObservable();

  private downloadStateSubject = new BehaviorSubject<LiveUpdateDownloadState>(LiveUpdateDownloadState.DOWNLOADING);
  public downloadState$ = this.downloadStateSubject.asObservable();

  constructor(private dialog: MatDialog, private ngZone: NgZone, private offlineModeService: OfflineModeService) { }

  async init() {
    if (!this.isNativePlatformAndUpdaterAvailable()) {
      return;
    };

    await CapacitorUpdater.notifyAppReady();
    this.addCapUpdaterListeners();

    await this.performManualNativeAppUpdate();
  }

  async addCapUpdaterListeners() {
    await CapacitorUpdater.addListener('download', data => {
      this.ngZone.run(() => {
        this.updateProgress(data.percent);
      });
    });

    await CapacitorUpdater.addListener('updateFailed', () => this.stopLiveUpdateDownload());
    await CapacitorUpdater.addListener('downloadFailed', () => this.stopLiveUpdateDownload());
  }

  async performManualNativeAppUpdate(): Promise<void> {
    const shouldSkipUpdateCheck = !this.isNativePlatformAndUpdaterAvailable() || this.updateDialogRef || this.downloadDialogRef;

    if (shouldSkipUpdateCheck) {
      return;
    }

    const { id: hostname } = await App.getInfo();
    const hostLookupUrl = `${environment.hostLookupUrl}/${HostType.APPID}/${environment.name}/${hostname}`;

    try {
      const hostLookupData = await this.offlineModeService.getRequest(hostLookupUrl).toPromise() as { frontBaseDomain: string };
      const versionFileUrl = `https://${hostLookupData.frontBaseDomain}/current.version`;

      const latestVersion = await this.fetchLatestVersion(versionFileUrl);
      if (!latestVersion || !this.isLatestVersionGreater(latestVersion, this.currentVersion)) {
        return;
      }

      this.showUpdateAvailableDialog(latestVersion);
    } catch (error) { }
  }

  private async fetchLatestVersion(url: string): Promise<string | null> {
    try {
      const response = await fetch(url);
      if (!response.ok) {
        return null;
      }

      return (await response.text()).trim();
    } catch (error) {
      return null;
    }
  }

  private async findOrDownloadUpdate(version: string): Promise<any> {
    const bundleList = await CapacitorUpdater.list();
    let appUpdate = this.findAppUpdateByVersion(bundleList.bundles, version);

    if (!appUpdate) {
      appUpdate = await this.downloadLatestVersion(version);
    }

    await CapacitorUpdater.set(appUpdate);
  }

  private async downloadLatestVersion(version: string): Promise<any> {
    try {
      this.showDownloadDialog(version);

      const appUpdate = await CapacitorUpdater.download({
        url: `${environment?.buildArtifactsUrl}${version}.zip`,
        version
      });

      return appUpdate;
    } catch (error) {
      return null;
    }
  }

  private async showDownloadDialog(version: string) {
    if (this.downloadDialogRef) {
      return;
    }

    this.downloadDialogRef = this.dialog.open(LiveUpdateModalComponent, {
      data: {
        content: {
          header: 'Updating the app',
          description: `Downloading new app version ${version}\nIt may take a few seconds.`
        },
        downloadProgress: this.downloadProgress$,
        status: LiveUpdateDownloadState.DOWNLOADING,
      },
      disableClose: true,
      minWidth: 300
    });

    this.downloadDialogRef.afterClosed()
      .subscribe(() => this.downloadDialogRef = null);
  }

  private showUpdateAvailableDialog(version: string): void {
    if (this.updateDialogRef) {
      return;
    };

    this.updateDialogRef = this.dialog.open(LiveUpdateModalComponent, {
      data: {
        content: {
          header: 'Update Available',
          description: `You have an update available ${version}\nTap continue`
        },
        status: LiveUpdateDownloadState.COMPLETED,
        okBtnHandler: async () => {
          this.findOrDownloadUpdate(version);

          this.updateDialogRef.close();
        },
      },
      disableClose: true,
      minWidth: 300
    });

    this.updateDialogRef.afterClosed()
      .subscribe(() => this.updateDialogRef = null);
  }

  private findAppUpdateByVersion(arr: BundleInfo[], targetVersion: string): BundleInfo | null {
    return arr.find(obj => obj.version === targetVersion) || null;
  }

  // Helper function to compare versions
  private isLatestVersionGreater(latest: string, current: string): boolean {
    const latestParts = this.parseVersion(latest);
    const currentParts = this.parseVersion(current);

    for (let i = 0; i < Math.max(latestParts.length, currentParts.length); i++) {
      const latestPart = latestParts[i] ?? 0;
      const currentPart = currentParts[i] ?? 0;
      if (latestPart > currentPart) return true;
      if (latestPart < currentPart) return false;
    }

    return false;
  };

  // Helper function to convert version string to numeric array
  private parseVersion(version: string): number[] {
    return version.split('.').map(Number);
  }

  private isNativePlatformAndUpdaterAvailable(): boolean {
    return Capacitor.isNativePlatform() && Capacitor.isPluginAvailable('CapacitorUpdater');
  }

  setDownloadState(state: LiveUpdateDownloadState) {
    this.downloadStateSubject.next(state);
  }

  updateProgress(progress: number): void {
    this.downloadProgressSubject.next(progress);

    if (progress >= 100 && this.downloadDialogRef) {
      this.downloadDialogRef.close();
    }
  }

  stopLiveUpdateDownload(): void {
    this.downloadProgressSubject.next(0);

    if (this.downloadDialogRef) {
      this.downloadDialogRef.close();
    }
  }
}
