import { Component, Input, OnInit } from '@angular/core';
import { Capacitor } from '@capacitor/core';

@Component({
  selector: 'app-text-image',
  templateUrl: './text-image.component.html',
  styleUrls: ['./text-image.component.scss']
})
export class TextImageComponent implements OnInit {
  
  @Input() block: any;

  public hidden = false;

  constructor() { }

  ngOnInit() {
    const isNativePlatform = Capacitor.isNativePlatform();
    this.hidden = this.block?.hideFromMobile && isNativePlatform;
  }

}
