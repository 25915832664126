<div class="achievement-modal-dialog" data-cy="achievement-modal-dialog">
  <ng-container *ngIf="!achievement.unlockedAchievement; else showAchievement">
    <h4 mat-dialog-title
        class="text-bold text-center">{{'ACHIEVEMENTS.achievement_unlocked' | translate}}</h4>
    <mat-dialog-content class="modal-achievement">
      <p class="mt-sm-4">
        <img *ngIf="achievement.mediaImage" 
          class="modal-achievement__image" 
          [cropImage]="achievement.mediaImage | preSignedUrl | async" 
          [additionalOptions]="{width: 200, height: 150, func: 'bound'}" 
          alt="achievement">
        <img *ngIf="!achievement.mediaImage" class="modal-achievement__image" src="assets/images/achievement.png" alt="achievement">
      </p>
      <h4 class="mt-4 text-center text-bold modal-achievement__title">{{achievement.title}}</h4>
      <div class="modal-achievement__description" *ngIf="achievement.description">
        <ng-scrollbar>
          <p class="text-grey4 ck-text" [innerHtml]="achievement.description | safeHtml"></p>
        </ng-scrollbar>
      </div>
    </mat-dialog-content>
    <mat-dialog-actions class="align-self-center modal-achievement__button">
      <button mat-stroked-button [disableRipple]="true"
              mat-dialog-close
              class="button button--primary button--large my-3 w-100">
        <mat-icon class="modal-achievement__icon" svgIcon="lms-check-square"></mat-icon>
        <span>{{'ACHIEVEMENTS.claim_now' | translate}}</span>
      </button>
    </mat-dialog-actions>
  </ng-container>
  <ng-template #showAchievement>
    <mat-dialog-content class="modal-achievement">
      <p class="mt-sm-4 w-100">
        <img
          *ngIf="achievement.mediaImage" 
          class="modal-achievement__image" 
          [cropImage]="achievement.mediaImage | preSignedUrl | async" 
          [additionalOptions]="{width: 200, height: 150, func: 'bound'}" 
          alt="achievement">
        <img *ngIf="!achievement.mediaImage" class="modal-achievement__image" src="assets/images/achievement.png" alt="achievement">
      </p>
      <h4 class="mt-4 text-center text-bold modal-achievement__title">{{achievement.title}}</h4>
      <div class="modal-achievement__description ck-text" *ngIf="achievement.description" [innerHtml]="achievement.description | safeHtml">
      </div>
    </mat-dialog-content>
    <mat-dialog-actions class="align-self-center modal-achievement__button">
      <button mat-stroked-button [disableRipple]="true"
              mat-dialog-close
              class="button button--primary button--large my-3 w-100">
        <span>{{'COMMON.close' | translate | titlecase }}</span>
      </button>
    </mat-dialog-actions>
  </ng-template>
  
  <div class="mat-dialog-close" mat-dialog-close><mat-icon>close</mat-icon></div>
  
</div>