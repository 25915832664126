import { Component, Input, OnInit } from '@angular/core';
import { LibraryExtensionType, LibraryItem } from '../../models';
import { FileExtensionService } from '../../services/file-extension/file-extension.service';

@Component({
  selector: 'lib-library-item-icon',
  templateUrl: './library-item-icon.component.html',
  styleUrls: ['./library-item-icon.component.scss']
})
export class LibraryItemIconComponent implements OnInit {
  @Input() public extensionType: LibraryExtensionType;

  @Input() public item: LibraryItem;

  @Input() public size = 64;

  public iconCode: string;

  constructor(private readonly fileExtensionService: FileExtensionService) { }

  ngOnInit(): void {
    if (this.extensionType) {
      this.iconCode = this.fileExtensionService.getFileIconForExtensionType(this.extensionType);
      return;
    }

    this.iconCode = this.fileExtensionService.getFileIcon(this.item);
  }
}
