import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { SettingsService } from '@app/services/settings.service';
import { ProfileService } from 'library-explorer';
import { extractHTMLContent } from 'library-explorer';

@Injectable({
  providedIn: 'root'
})
export class WatermarkService {
  private minWatermarkWidth = 400;

  constructor(
    private readonly settingsService: SettingsService,
    private readonly profileService: ProfileService) {
  }

  public appendWatermark(element: HTMLElement, width: number, height: number, id = '', zIndex = 9): void {
    const watermarkId = `watermark-${id}`;

    const currentWatermarkEl = element.querySelector(`#${watermarkId}`);

    if (currentWatermarkEl) {
      return;
    }  

    this.getWatermarkText()
      .subscribe(watermark => {
        if (!watermark) {
          return;
        }
    
        const overlayDiv = document.createElement('div');
        overlayDiv.innerHTML = watermark;

        overlayDiv.setAttribute('style', `display: inline-block`);
        document.body.appendChild(overlayDiv);
        const { offsetWidth: watermarkWidth, offsetHeight: watermarkHeight } = overlayDiv;
        overlayDiv.remove();
    
        let scale = 1;

        width = Math.max(width, this.minWatermarkWidth);
    
        if (width) {
          const [elementWidth, elementHeight] = [width, height || width];
      
          if (elementHeight < watermarkHeight || elementWidth < watermarkWidth) {
            const padding = Math.max(40, width * .4);
            const widthScale = (elementWidth - padding) / watermarkWidth;
            const heightScale = (elementHeight - padding) / watermarkHeight;
            scale = Math.min(widthScale, heightScale);
          }
       }
    
        overlayDiv.setAttribute('style', `z-index: ${zIndex}; position: absolute; left: 50%; top: 50%; 
          transform: translate(-50%, -50%) rotate(-20deg) scale(${scale}); transform-origin: center; opacity: .6;`);
        overlayDiv.id = watermarkId;
    
        element.appendChild(overlayDiv);
      });
  }

  private getWatermarkText(): Observable<string> {
    return this.settingsService.getSettings()
      .pipe(
        map(data => {
          const { id = '', formattedProfileName = '', email = '' } = this.profileService.getCurrentProfileValue();
          const watermark = data.security.watermark?.value;

          if (!watermark) {
            return null;
          }

          return extractHTMLContent(watermark)
            .replace(/\[user_id\]/g, id)
            .replace(/\[user_name\]/g, formattedProfileName)
            .replace(/\[user_email\]/g, email)
        })
      )
  }
}
