<div class="scale-widget" [class.readonly]="readonly">
  <div class="d-flex flex-column align-items-center option" (click)="selectOption(option)" *ngFor="let option of options">
    <div class="option-radio">
      <ng-container *ngIf="multiselect; else singleTmp">
        <mat-radio-group>
          <mat-radio-button 
            class="d-flex" 
            [checked]="selectedOptionsHashes.indexOf(option.hash) !== -1" 
            [value]="option.hash">
           </mat-radio-button>
        </mat-radio-group>
      </ng-container>

      <ng-template #singleTmp>
        <mat-radio-button 
          class="d-flex" 
          [checked]="selectedOptionsHashes.indexOf(option.hash) !== -1" 
          [value]="option.hash">
        </mat-radio-button>
      </ng-template>
    </div>

    <span class="option-text" [autoTextDirection]="enableAutoTextDirection" [autoTextDirectionValue]="option.text">{{ option.text }}</span>
  </div>
</div>
