import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class LoadingToastService {
  private _isLoading: BehaviorSubject<boolean> = new BehaviorSubject(false);
  private timeout: any;

  constructor() { }

  public start(): void {
    clearTimeout(this.timeout);
    this._isLoading.next(true);
  }

  public stop(): void {
    this.timeout = setTimeout(() => {
      this._isLoading.next(false);
    }, 200);
  }

  public get isLoading(): Observable<boolean> {
    return this._isLoading.asObservable();
  }
}
