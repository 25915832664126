import { Directive, ElementRef, Input, OnInit } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { fromEvent } from 'rxjs';
import { filter, tap } from 'rxjs/operators';
import { ImageModel } from 'library-explorer';
import { ModalImageDialogComponent } from '../shared/components/modal-image-dialog/modal-image-dialog.component';

@Directive({
  selector: '[modalImage]'
})
export class ModalImageDirective implements OnInit {
  @Input() public modalImage: ImageModel;
  @Input() public mobileOnly = true;

  private scaling = false;
  private dialogRef: MatDialogRef<any>;

  constructor(
    private readonly dialog: MatDialog,
    private readonly elementRef: ElementRef) {
  }

  ngOnInit(): void {
    const isPhone = window.matchMedia('(max-width: 768px)').matches;

    if (!isPhone && this.mobileOnly) {
      return;
    }

    this.addEventListeners();
  }

  private openModal(): void {
    if (this.dialogRef) {
      return;
    }


    this.dialogRef = this.dialog.open(ModalImageDialogComponent, {
      panelClass: ['dialog-no-padding', 'dialog-overflow-visible'],
      backdropClass: ['custom-overlay-backdrop'],
      maxWidth: '100vw',
      data: {
        image: this.modalImage
      }
    });

    this.dialogRef.afterClosed()
      .subscribe(() => this.dialogRef = null);
  }

  private addEventListeners(): void {
    fromEvent(this.elementRef.nativeElement, 'touchstart')
      .pipe(
        filter((event: TouchEvent) => event.touches.length === 2),
        tap((event: TouchEvent) => event?.preventDefault())
      )
      .subscribe(() => this.scaling = true);

    fromEvent(this.elementRef.nativeElement, 'touchmove')
      .pipe(
        filter(() => this.scaling === true),
        tap((event: TouchEvent) => event?.preventDefault())
      )
      .subscribe(() => this.openModal());

    fromEvent(this.elementRef.nativeElement, 'touchend')
      .subscribe(() => this.scaling = false);

    fromEvent(this.elementRef.nativeElement, 'dblclick')
      .subscribe(() => this.openModal());
  }

}

