import {
  HttpEvent, HttpHandler, HttpInterceptor, HttpRequest
} from '@angular/common/http';
import { Injectable, Injector } from '@angular/core';
import { AuthService } from '@app/services/auth.service';
import { interceptorsToken, NetworkService, SessionStorageService } from 'library-explorer';
import { BehaviorSubject, EMPTY, Observable } from 'rxjs';
import { filter, finalize, switchMap, take, tap } from 'rxjs/operators';

@Injectable()
export class LoadingTokenInterceptor implements HttpInterceptor {

  // The BehaviorSubject to manage the loading state
  public loadingRefreshToken: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

  constructor(private sessionStorageService: SessionStorageService, private injector: Injector, private networkService: NetworkService) {
  }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    if (!this.networkService.isOnlineValue()) {
      return next.handle(request);
    }

    const token = this.sessionStorageService.getJwtTokenValue();
    const isAuthorized = request.headers.has('Authorization');
    const isRefreshTokenRequest = request.headers.has(interceptorsToken.SKIP_LOADING_TOKEN_HANDLER);
    request = request.clone({ headers: request.headers.delete(interceptorsToken.SKIP_LOADING_TOKEN_HANDLER) });

    if (!token && !isRefreshTokenRequest) {
      return next.handle(request);
    }

    if (isRefreshTokenRequest) {
      if (this.loadingRefreshToken.value) {
        return EMPTY;
      }

      this.loadingRefreshToken.next(true);

      return next.handle(request)
        .pipe(
          finalize(() => this.loadingRefreshToken.next(false))
        );
    }

    if (!isAuthorized) {
      return next.handle(request);
    }

    return this.loadingRefreshToken
      .pipe(
        filter(loading => !loading),
        take(1),
        switchMap(() => {
          if (!this.sessionStorageService.isTokenExpired(token)) {
            return next.handle(this.addAuthorizationHeader(request))
          }
      
          return this.refreshTokenAndProceed(request, next);
        })
      );
  }

  /**
   * Retrieves a new access token using a refresh token, updates the authentication service with the new token,
   * and proceeds with the original HTTP request with the updated authorization header.
   * @param request - The HTTP request to be processed.
   * @param next - The HTTP handler to proceed with the request.
   * @returns An Observable of type HttpEvent<any>.
   */
  private refreshTokenAndProceed(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    const authService = this.injector.get(AuthService);

    return authService.getRefreshToken()
      .pipe(
        tap((data) => authService.setJwtToken(data)),
        switchMap(() => next.handle(this.addAuthorizationHeader(request)))
      )
  }

  /**
   * Adds the Authorization header with the JWT token to the provided HTTP request.
   * @param request - The HTTP request to which the Authorization header will be added.
   * @returns A new HttpRequest<any> object with the Authorization header.
   */
  private addAuthorizationHeader(request: HttpRequest<any>): HttpRequest<any> {
    const token = this.sessionStorageService.getJwtTokenValue();
    return request.clone({
      headers: request.headers.set('Authorization', `Bearer ${token}`)
    });
  }
}
