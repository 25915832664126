import { CdkDrag, CdkDragDrop, CdkDropList } from '@angular/cdk/drag-drop';
import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges, TemplateRef } from '@angular/core';
import { Sort } from '@angular/material/sort';
import { ListLayoutType, LibraryType } from '../../../models';
import { LibraryItem } from '../../models';

@Component({
  selector: 'lib-library-list',
  templateUrl: './library-list.component.html',
  styleUrls: ['./library-list.component.scss']
})
export class LibraryListComponent implements OnInit, OnChanges {
  @Input() public readonly placeholders = new Array(8).fill(null);
  @Input() public enablePinned = false;
  @Input() layout: ListLayoutType = ListLayoutType.GRID;
  @Input() items: LibraryItem[] = [];
  @Input() allowDownload = true;
  @Input() disabled = false;
  @Input() loading = false;
  @Input() defaultSort: string;
  @Input() viewDetailsButton = false;

  @Input() canEditAndDelete = false;

  @Input() moreMenuTemplate?: TemplateRef<any>;

  @Output() openDetails: EventEmitter<LibraryItem> = new EventEmitter();
  @Output() pinnedChanged: EventEmitter<LibraryItem> = new EventEmitter();
  @Output() open: EventEmitter<LibraryItem> = new EventEmitter();
  @Output() download: EventEmitter<LibraryItem> = new EventEmitter();
  @Output() edit: EventEmitter<LibraryItem> = new EventEmitter();
  @Output() delete: EventEmitter<LibraryItem> = new EventEmitter();
  @Output() sortChanged: EventEmitter<Sort> = new EventEmitter();
  @Output() moveFileInsideFolder: EventEmitter<{ folder: LibraryItem, item: LibraryItem }> = new EventEmitter();

  public readonly layoutTypes: typeof ListLayoutType = ListLayoutType;
  public readonly libraryTypes: typeof LibraryType = LibraryType;

  public contextButtonEnabled = false;

  constructor() { }

  public ngOnInit(): void {
    this.contextButtonEnabled = this.allowDownload || this.viewDetailsButton || this.canEditAndDelete;
  }

  public ngOnChanges(changes: SimpleChanges): void {
    const { allowDownload, viewDetailsButton, canEditAndDelete} = changes;
   
    if (!allowDownload && !viewDetailsButton && !canEditAndDelete) {
      return;
    }

    this.allowDownload = allowDownload?.currentValue || this.allowDownload;
    this.viewDetailsButton = viewDetailsButton?.currentValue  || this.viewDetailsButton;
    this.canEditAndDelete = canEditAndDelete?.currentValue  || this.canEditAndDelete;

    this.contextButtonEnabled = this.allowDownload || this.viewDetailsButton || this.canEditAndDelete;
  }

  public tableSortChanged(sort: Sort): void {
    this.sortChanged.emit(sort);
  }

  public canDrop(): (drag: CdkDrag, drop: CdkDropList) => boolean {
    return (_drag: CdkDrag, drop: CdkDropList) => {
      return drop?.data?.type === LibraryType.FOLDER;
    };
  }

  public drop(event: CdkDragDrop<any>): void {
    const newParentFolder = event.container.data;
    const currentLibraryItem = event.previousContainer.data;

    if (newParentFolder?.id === currentLibraryItem?.id) {
      return;
    }

    this.moveFileInsideFolder.emit({ folder: newParentFolder, item: currentLibraryItem});
  }
  

}
