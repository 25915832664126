import { Pipe, PipeTransform } from '@angular/core';
import { OfflineActivity } from 'src/app/model/offline-activity';


@Pipe({
  name: 'offlineActivityImage'
})
export class OfflineActivityImagePipe implements PipeTransform {

  private readonly images = [
    '/assets/images/offline-activity/offline-activity-1.jpg',
    '/assets/images/offline-activity/offline-activity-2.jpg',
    '/assets/images/offline-activity/offline-activity-3.jpg',
    '/assets/images/offline-activity/offline-activity-4.jpg',
  ];

  constructor() {
  }

  public transform(activity: Partial<OfflineActivity>): string {
    if (!activity) {
      return;
    }

    const date = activity.startDate ? new Date(activity.startDate) : new Date();
    const imageIndex = (date.getDay() + date.getHours()) % this.images.length;
  
    return `url(${this.images[imageIndex]})`;
  }

}
