import { Directive, ElementRef, HostListener, Input } from '@angular/core';
import { WatermarkService } from '@app/services/watermark.service';

@Directive({
  selector: '[appWatermark]'
})
export class WatermarkDirective {
  @Input() public set appWatermark(value: boolean) {
    if (!value) {
      return;
    }

    this.appendWatermark();
  }

  @Input() public id: string;

  constructor(
    private readonly el: ElementRef,
    private readonly watermarkService: WatermarkService
  ) { }

  private appendWatermark(): void {
    const [width, height] = [this.el.nativeElement.offsetWidth, this.el.nativeElement.offsetHeight]
    this.el.nativeElement.style.position = 'relative';
    this.watermarkService.appendWatermark(this.el.nativeElement, width, height, this.id);
  }
}
