<div mat-dialog-content [exclude]="'app-language-switcher, .cdk-overlay-container'" (clickOutside)="onOutsideClick()">
  <div class="language-select-dialog">
    <div class="language-select-row">
      <div class="label">
        <b>{{'COMMON.platform_language' | translate}}</b> <mat-icon class="primary-color" [matTooltip]="'COMMON.platform_language_tooltip' | translate">help_outline</mat-icon>
      </div>
      <button class="primary-background language-select-button" mat-button [matMenuTriggerFor]="platformLanguageMenu">
        {{platformLanguage?.name}}
        <mat-icon>keyboard_arrow_down</mat-icon>
      </button>
    </div>
    <div class="language-select-row" *ngIf="languages?.content?.length">
      <div class="label">
        {{'COMMON.content_language' | translate}} <mat-icon class="primary-color" [matTooltip]="'COMMON.content_language_tooltip' | translate">help_outline</mat-icon>
      </div>
      <button *ngIf="!isContentLanguageLoading; else loadingTemplate" class="primary-background language-select-button" mat-button [matMenuTriggerFor]="contentLanguageMenu" [disabled]="isContentLanguageSwitchLoading">
        <mat-spinner *ngIf="isContentLanguageSwitchLoading" [diameter]="16"></mat-spinner>
        {{contentLanguage?.name}}
        <mat-icon>keyboard_arrow_down</mat-icon>
      </button>
    </div>
  </div>
</div>

<mat-menu #platformLanguageMenu="matMenu" class="language-menu primary-menu">
  <button 
    mat-menu-item 
    *ngFor="let language of languages?.platform"
    class="primary-menu-button primary-button-dark-hover mat-menu-item"
    [ngClass]="{'primary-button-dark': language.code === platformLanguage.code}"
    (click)="changePlatformLanguage(language.code)">
    <span class="align-middle">{{language.name}}</span>
  </button>
</mat-menu>

<mat-menu #contentLanguageMenu="matMenu" class="language-menu primary-menu">
  <button
    mat-menu-item 
    *ngFor="let language of languages?.content"
    class="primary-menu-button primary-button-dark-hover mat-menu-item"
    (click)="changeContentLanguage(language.code)">
    <span class="align-middle">{{language.name}}</span>
  </button>
</mat-menu>

<ng-template #loadingTemplate>
  <mat-spinner [diameter]="24"></mat-spinner>
</ng-template>
