import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-list-empty-state',
  templateUrl: './list-empty-state.component.html',
  styleUrls: ['./list-empty-state.component.scss']
})
export class ListEmptyStateComponent implements OnInit {
  @Input() public title: string;
  @Input() public text: string;
  @Input() public icon: string;
  
  @Input() public pageMode = false;
  
  constructor() { }

  ngOnInit(): void {
  }

}
