import { HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { ContentGroup } from 'src/app/model/content-group';
import { DataResponse } from 'src/app/model/data-response.model';

import { NetworkService } from 'library-explorer';
import { HttpService } from 'library-explorer';

@Injectable({
  providedIn: 'root'
})
export class ContentGroupService {
  constructor(
    private readonly httpService: HttpService
  ) { }

  public getContentGroupsByParentId(parentId: string): Observable<DataResponse<ContentGroup>> {
    const params = new HttpParams()
      .set('parentId', parentId);

    return this.httpService.get<DataResponse<ContentGroup>>('content-groups', params);
  }


  public getContentGroupEntities<T>(contentGroupId: string, offset = 0, limit = 10): Observable<DataResponse<T>> {
    const params = new HttpParams()
      .set('active', 'true')
      .set('contentGroupId', contentGroupId)
      .set('offset', offset.toString())
      .set('limit', limit.toString());

    return this.httpService.get<DataResponse<T>>('content-group-items', params);
  }
}
