import { Pipe, SecurityContext } from '@angular/core';
import { Observable, of } from 'rxjs';
import { FieldName, PreSignedUrlPipe, EntityTypeId, MediaPresignApiService, BundleType, ImageModel, OfflineModeService, NetworkService, OFFLINE_MEDIA_PREFIX } from 'library-explorer';
import { DomSanitizer } from '@angular/platform-browser';
import { map, tap } from 'rxjs/operators';
import { Capacitor } from '@capacitor/core';
import { FileSystemService } from '@app/services/file-system.service';

@Pipe({
  name: 'settingsMediaPresignedUrl'
})
export class SettingsMediaPresignedUrl extends PreSignedUrlPipe {
  
  constructor(mediaPresignApiService: MediaPresignApiService, sanitizer: DomSanitizer, private fileSystemService: FileSystemService, private networkService: NetworkService, private offlineModeService: OfflineModeService) {
    super(mediaPresignApiService, sanitizer);
  }

  public transform(image: ImageModel): Observable<string> {
    if (!image) {
      return of('');
    }

    if (!this.networkService.isOnlineValue()) {
      return this.offlineImage(image);
    }

    if (typeof (image) === 'string') {
      return of(image);
    }

    return super.transform(image, EntityTypeId.SETTINGS, BundleType.SETTINGS, FieldName.MEDIA_IMAGE).pipe(
      tap((mediaPresignedUrl) => this.downloadAndStoreImage(image, mediaPresignedUrl))
    );
  }

  private offlineImage(image: ImageModel) {
    return this.offlineModeService.getRequest((image.uri || image.url)).pipe(
      map((fileUri: string) => {
        if (!fileUri) return '';
        return this.sanitizer.sanitize(
          SecurityContext.RESOURCE_URL,
          this.sanitizer.bypassSecurityTrustResourceUrl(fileUri)
        )
      })
    );
  }

  private async downloadAndStoreImage(image: ImageModel, url: string) {
    const path = 'settings/media/' + image.filename;
    const data = await this.fileSystemService.downloadBlob(url, () => { });

    const blob = new Blob([data]);
    const filePath = await this.fileSystemService.writeBlobFile(path, blob);

    if (filePath) {
      const fileUri = OFFLINE_MEDIA_PREFIX + Capacitor.convertFileSrc(filePath);
      this.offlineModeService.storeRequest((image.uri || image.url), fileUri);
    }
  }
}
