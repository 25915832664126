import { Injectable } from '@angular/core';
import { ImageModel } from '../../../common/models';

import { LibraryType } from '../../../models';
import { LibraryExtensionType, LibraryItem } from '../../models';

@Injectable({
  providedIn: 'root'
})
export class FileExtensionService {

  private readonly extensionIconsMap = {
    'file-image': [
      'jpg', 'jpeg', 'bmp', 'png', 'gij'
    ],
    'file-pdf': ['pdf'],
    'file-xls': ['xls', 'xlsx'],
    'file-zip': ['zip', 'rar', 'tar', '7z'],
    'file-ppt': ['ppt', 'pptx'],
    'file-doc': ['dot', 'dotx', 'doc', 'docx'],
    'file-video': ['mp4', 'mov', 'wmv', 'mkv', 'webm'],
    'file-audio': ['mp3', 'wav']
  };

  private readonly extensionTypeIconMap = {
    'file-image': LibraryExtensionType.IMAGE,
    'file-pdf': LibraryExtensionType.PDF,
    'file-xls': LibraryExtensionType.SPREADSHEET,
    'file-doc': LibraryExtensionType.DOCUMENT,
    'file-video': LibraryExtensionType.VIDEO,
    'file-audio': LibraryExtensionType.AUDIO,
    'file-zip': LibraryExtensionType.ARCHIVE,
    'folder': LibraryExtensionType.FOLDER
  };

  constructor() { }

  public getLibraryItemPreviewImage(item: LibraryItem): ImageModel {
    if (!item) {
      return null;
    }

    if (item.mediaImage) {
      return item.mediaImage;
    }
  
    if (item.type === LibraryType.FOLDER) {
      return null;
    }

    const extension = item.file?.extension?.toLowerCase();

    if (!extension) {
      return null;
    }

    const isImage = this.extensionIconsMap['file-image'].indexOf(extension) !== -1;

    if (isImage) {
      return item.file;
    }

    return null;
  }

  public getFileIcon(item: LibraryItem): string {
    if (item.type === LibraryType.FOLDER) {
      return 'folder';
    }

    const extension = item.file?.extension?.toLowerCase();

    if (!extension) {
      return 'file';
    }

    const [iconKey] = Object.entries(this.extensionIconsMap).find(([_, values]) => values.indexOf(extension) !== -1);

    return iconKey || 'file'
  }

  public getFileIconForExtensionType(type: LibraryExtensionType): string {
    if (!type) {
      return 'file';
    }

    const [iconKey] = Object.entries(this.extensionTypeIconMap).find(([_, value]) => value === type);

    return iconKey || 'file';
  }
  
}
