import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'secondsToTime'
})
export class SecondsToTimePipe implements PipeTransform {

  constructor() { }

  transform(seconds: number = 0, format: string = 'hh:mm'): string {
    const isNegative = seconds < 0;
    seconds = Math.abs(seconds);
    let mm: number | string = Math.floor(seconds / 60);
    let ss: number | string = Math.floor(seconds - (mm * 60));


    mm = mm <= 9 ? '0' + mm : mm;
    ss = ss <= 9 ? '0' + ss : ss;
    return `${isNegative ? '-' : ''}${mm}:${ss}`;
  }

}
