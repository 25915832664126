import { Component, forwardRef, Input, OnInit } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { QuestionType } from '../../../common/models';

@Component({
  selector: 'lib-scale-widget',
  templateUrl: './scale-widget.component.html',
  styleUrls: ['./scale-widget.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => ScaleWidgetComponent),
      multi: true
    }
  ]
})
export class ScaleWidgetComponent implements OnInit, ControlValueAccessor {
  @Input() questionHash: string;
  @Input() public readonly = false;
  @Input() public multiselect = false;
  @Input() public enableAutoTextDirection = false;

  
  @Input() public options: { text: string, hash: string }[] = [];

  public disabled = false;

  public selectedOptionsHashes = [];

  constructor() { }

  ngOnInit(): void {
  }

  onChange = (data: any) => { };
  onTouched = () => { };

  public selectOption(option: { hash: string }): void {
    if (this.multiselect) {
      const selectedIndex = this.selectedOptionsHashes.indexOf(option.hash);
      
      selectedIndex === -1
        ? this.selectedOptionsHashes.push(option.hash)
        : this.selectedOptionsHashes.splice(selectedIndex, 1);

      this.emitValue();
      return
    }

    this.selectedOptionsHashes = [option.hash];
    this.emitValue();
  }

  public writeValue(value: string | string[]): void {
    if (!value) {
      return;
    }

    this.selectedOptionsHashes = Array.isArray(value) ? value.map(item => item?.toString()) : [value?.toString()];
  }

  public registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  public registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  public setDisabledState?(isDisabled: boolean): void {
    this.disabled = isDisabled;
  }

  private emitValue(): void {
    this.onChange({
      type: QuestionType.QUESTION_SCALE,
      questionHash: this.questionHash,
      answersHash: this.selectedOptionsHashes
    });
  }

}
