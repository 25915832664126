import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'lib-chips',
  templateUrl: './chips.component.html',
  styleUrls: ['./chips.component.scss']
})
export class ChipsComponent implements OnInit {
  @Input() public items: { title: string }[] = [];
  @Input() public count = 0;

  public chips: { title: string }[] = [];

  constructor() { }

  ngOnInit(): void {
    this.chips = this.count ? this.items.slice(0, this.count) : this.items;
  }

}
