import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';
import { HttpService } from 'library-explorer';

@Injectable({
  providedIn: 'root'
})
export class IngenicoService {

  constructor(
    private httpService: HttpService
  ) { }

  public sendPayment(data: any): Observable<any> {
    return this.httpService.post('ingenico/payment', data).pipe(
      tap(res => {
        window.location.href = res.redirectUrl;
      })
    );
  }

  public sendWebhook(data: {hosted_checkout_id: any, return_mac: any}): Observable<any> {
    return this.httpService.post('ingenico/webhook', data);
  }
}
