import { Component, ElementRef, Input, OnDestroy, ViewChild } from '@angular/core';
import * as WaveSurfer from 'wavesurfer.js';

@Component({
  selector: 'lib-audio-waveform-player',
  templateUrl: './audio-waveform-player.component.html',
  styleUrls: ['./audio-waveform-player.component.scss']
})
export class AudioWaveformPlayerComponent implements OnDestroy {
  @Input() public theme: 'default' | 'primary' = 'default';

  @ViewChild('waveform') public container: ElementRef;

  @Input() set src(value: string) {
    if (!value) {
      return;
    }

    if (this.waveSurfer) {
      this.waveSurfer.load(value);
      return;
    }

    this.initWaveSurfer(value);
  }

  public isPlaying = false;
  public duration = 0;

  public init = false;

  private waveSurfer;

  private initWaveSurfer(src: string) {
    requestAnimationFrame(() => {
      this.waveSurfer = WaveSurfer.create({
        container: this.container.nativeElement,
        waveColor: this.theme === 'default' ? '#222224' : '#ffffff',
        progressColor: 'transparent',
        cursorColor: 'transparent',
        responsive:true,
        barWidth: 1,
        barRadius: 1,
        cursorWidth: 1,
        height: 24,
        width: 160,
        barGap: 4,
        minPxPerSec: .05,
        fillParent: true,
        barMinHeight: 3
      });

      this.waveSurfer.load(src);

      this.waveSurfer.on('finish', () => {
        this.isPlaying = false;
      });

      this.waveSurfer.on('ready', () => {
        this.duration = this.waveSurfer.getDuration();
      });
  
      this.waveSurfer.on('redraw', () => {
        this.init = true;
      });
    });
  }

  ngOnDestroy(): void {
    if (!this.isPlaying) {
      return;
    }

    this.waveSurfer.stop();
  }

  public togglePlay() {
    this.waveSurfer.playPause();
    this.isPlaying = this.waveSurfer.isPlaying();
  }

}
