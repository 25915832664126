import { Component, Input, OnInit } from '@angular/core';
import { LoginType } from 'src/app/model/enums/login-type.enum';
import { SettingsService } from '@app/services/settings.service';

@Component({
  selector: 'app-login-promo',
  templateUrl: './login-promo.component.html',
  styleUrls: ['./login-promo.component.scss']
})
export class LoginPromoComponent implements OnInit {

  @Input() block: any;

  public registrationButtonVisible: boolean;

  constructor(
    private settingsService: SettingsService
  ) { }

  ngOnInit() {
    this.settingsService.getSettings().subscribe(value => {
      this.registrationButtonVisible = value.registration.enabled !== false
        && !value.registration.hide && value.login.type !== LoginType.SSO;
    });
  }

}
