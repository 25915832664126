import { InjectionToken } from "@angular/core";
import { StorageKeys } from "../models/storage-keys";
import { DateFormatType } from "../models/date-format.model";
import { BaseSettingsGetService } from '../services/base-settings.service';
import { PlyrDriver } from 'ngx-plyr';

export const STORAGE_KEYS = new InjectionToken<StorageKeys>('');
export const DATE_FORMATS = new InjectionToken<DateFormatType>('');
export const API_URL = new InjectionToken<string>('apiUrl');
export const CLOUD_IMG_URL = new InjectionToken<string>('cloudImgUrl');
export const REGEX_LIST = new InjectionToken('REGEX_LIST');
export const SETTINGS_SERVICE = new InjectionToken<BaseSettingsGetService>('');
export const AUDIO_PLAYER_WAVE_SURFER_CONFIG = new InjectionToken<{ backend: string, preload: string, peaks: string[] }>('');
export const BUNDLE_TYPE_TEXT_MAP = new InjectionToken<any>('');
export const PLYR_DEFAULT_DRIVER = new InjectionToken<PlyrDriver>('');
