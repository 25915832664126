<div mat-dialog-content class="notification-list-dialog-content">
  <h5 class="notification-list-dialog-content__title">{{'NOTIFICATIONS.notification' | translate }}</h5>
  <ng-scrollbar>
    <app-notification-list [isDialog]="true" (notificationsExist)="changeButtonVisility($event)"></app-notification-list>
  </ng-scrollbar>
  <div class="notification-list-dialog-content__footer" *ngIf="notificationsExist">
    <button
      mat-raised-button
      [routerLink]="['/notifications']"
      class="primary-button base-button button-rounded">
      <mat-icon svgIcon="lms-notification"></mat-icon>
      {{'NOTIFICATIONS.see_all' | translate }}
    </button>
  </div>
</div>

<div class="mat-dialog-close" mat-dialog-close><mat-icon>close</mat-icon></div>
