import { HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { DataResponse } from 'src/app/model/data-response.model';
import { SearchItemDTO } from 'src/app/model/searchItemDTO.model';
import { HttpService, BundleType } from 'library-explorer';

@Injectable({
  providedIn: 'root'
})
export class SearchService {

  constructor(private httpService: HttpService) { }

  public searchBy(search: string, bundle: BundleType | BundleType[], offset = 0, limit = 20): Observable<DataResponse<SearchItemDTO>> {
    let params = new HttpParams()
      .set('offset', offset.toString())
      .set('limit', limit.toString());

    if (bundle) {
      const bundleParam = Array.isArray(bundle) ? bundle.join(',') : bundle;
      params = params.set('bundle', bundleParam);
    }

    if (search) {
      params = params.set('string', search);
    }

    return this.httpService.get<DataResponse<SearchItemDTO>>('search', params);
  }
}
