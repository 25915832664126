<div class="row" [style.display]="state.value === 'inactive' ? 'none' : ''">
  <div class="col-12 d-flex align-items-center p-0">
    <span class="icon-container"  [ngSwitch]="toastPackage.toastType">
      <svg-icon *ngSwitchCase="'toast-info'" src="/assets/icons/exclamation.svg" [svgStyle]="{ 'fill': '#4075FF' }"></svg-icon>
      <svg-icon *ngSwitchCase="'toast-success'" src="/assets/icons/check.svg" [svgStyle]="{ 'fill': '#41C87F' }"></svg-icon>
      <svg-icon *ngSwitchCase="'toast-error'" src="/assets/icons/close.svg" [svgStyle]="{ 'fill': '#ff7d51' }"></svg-icon>
      <svg-icon *ngSwitchCase="'toast-warning'" src="/assets/icons/exclamation.svg" [svgStyle]="{ 'fill': '#ff7d51' }"></svg-icon>
    </span>
    <h2 class="title" *ngIf="title">{{title}}</h2>
    <div class="message" *ngIf="!title && message" [innerHtml]="message"></div>
  </div>
  <div class="message with-title" *ngIf="title && message" [innerHtml]="message"></div>
</div>
