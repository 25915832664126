import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ClassCardComponent } from './components/class-card/class-card.component';
import { SharedLibraryModule } from '../common/shared-library.module';
import { CourseCardComponent } from './components/course-card/course-card.component';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { LessonCardComponent } from './components/lesson-card/lesson-card.component';
import { MatTooltipModule } from '@angular/material/tooltip';
import { BaseCardComponent } from './components/base-card/base-card.component';


@NgModule({
  declarations: [
    ClassCardComponent,
    CourseCardComponent,
    LessonCardComponent,
    BaseCardComponent
  ],
  imports: [
    CommonModule,
    SharedLibraryModule,
    MatProgressBarModule,
    MatTooltipModule
  ],
  exports: [
    ClassCardComponent,
    CourseCardComponent,
    LessonCardComponent
  ]
})
export class ContentCardModule { }
