<div class="live-update-dialog d-flex flex-column justify-content-center align-items-center">
  <div mat-dialog-content class="live-update-dialog__content">
    <div class="header d-flex flex-column">
      <svg-icon [src]="biometricType | biometricIcon" class="primary-color header-icon"></svg-icon>
      <span>Would you like to enable<br>{{ biometricTypeDescription }} to sign in?</span>
    </div>
    <div class="description">
      <span>Get quick and secure access to your<br>account with {{ biometricTypeDescription }}.</span>
    </div>
  </div>
  <div mat-dialog-actions class="live-update-dialog__actions">
      <button mat-flat-button class="button button--primary" (click)="okBtnHandler()">
        Enable {{ biometricTypeDescription }}
      </button>
      <button mat-flat-button class="button button--transparent button--outline" (click)="cancelBtnHandler()">
        Not now
      </button>
  </div>
</div>
