import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { LiveUpdateDownloadState } from '@app/model/enums/live-update-download-state.enum';
import { Observable } from 'rxjs';


@Component({
  selector: 'app-live-update-modal',
  templateUrl: './live-update-modal.component.html',
  styleUrls: ['./live-update-modal.component.scss']
})
export class LiveUpdateModalComponent implements OnInit {
  content: { header: string; description: string };
  updateStatus: LiveUpdateDownloadState;
  progress$: Observable<number>;

  LiveUpdateDownloadState = LiveUpdateDownloadState;

  public okBtnHandler = () => {
    this.dialogRef.close(true);
  }

  constructor(public dialogRef: MatDialogRef<LiveUpdateModalComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any) { }

  ngOnInit(): void {
    this.content = this.data.content;
    this.progress$ = this.data.downloadProgress;
    this.updateStatus = this.data.status;
    this.okBtnHandler = this.data.okBtnHandler || this.okBtnHandler;
  }

}
