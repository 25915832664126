import { Injectable } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor
} from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from '@env/environment';
const pj = require('package.json');

@Injectable()
export class ApplicationVersionInterceptor implements HttpInterceptor {

  constructor() {}

  intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    const isApiUrl = request.url.indexOf(environment.apiUrl) !== -1;

    if (!isApiUrl) {
      return next.handle(request);
    }

    const requestWithVersionHeader = request.clone({
      setHeaders: {
        'X-App-Version': pj.version
      }
    });
    return next.handle(requestWithVersionHeader);
  }
}
