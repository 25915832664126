import {
  Component,
  ElementRef,
  EmbeddedViewRef,
  EventEmitter,
  Input,
  OnInit,
  Output,
  Renderer2,
  TemplateRef,
  ViewChild,
  ViewContainerRef
} from '@angular/core';
import * as screenfull from 'screenfull';
import { Screenfull } from 'screenfull';

@Component({
  selector: 'app-fullscreen',
  templateUrl: './fullscreen.component.html',
  styleUrls: ['./fullscreen.component.scss']
})
export class FullscreenComponent implements OnInit {
  @Input() public isDownloadLoading = false;
  @Input() public showFullScreenBtn = true;
  @Input() public template: TemplateRef<any>;
  @Input() public showDownloadBtn = false;

  @Output() public fullscreenToggle: EventEmitter<boolean> = new EventEmitter();
  @Output() public downloadFile: EventEmitter<void> = new EventEmitter();

  @ViewChild('targetTmp', {static: false})  public targetTmp: ElementRef<any>;
  @ViewChild('fullscreenTmp', {static: false}) fullscreenTmp: TemplateRef<any>;

  public fulscreenMode = false;

  public isFullscreenDisabled = false;

  private embeddedViewRef: EmbeddedViewRef<any>;
  private bodyClass = 'enable-fullscreen-mode';

  constructor(
    private readonly element: ElementRef,
    private readonly viewContainerRef: ViewContainerRef,
    private readonly renderer2: Renderer2) { }

  ngOnInit() {
  }

  public download(): void {
    this.downloadFile.emit();
  }

  public fullScreenModelToggle(): void {
    if (this.fulscreenMode && this.isFullscreenDisabled) {
      this.exitFromFullScreenMode();
      return;
    }

    this.fulscreenMode = true;
    const sf = screenfull as Screenfull;

    if (sf.isEnabled) {
      sf.toggle(this.targetTmp.nativeElement).then(() => {
        this.fulscreenMode = false;
      });
      
      return;
    }

    this.isFullscreenDisabled = true;

    this.embeddedViewRef = this.viewContainerRef.createEmbeddedView(this.fullscreenTmp);
    this.embeddedViewRef.detectChanges();

    const node = this.embeddedViewRef.rootNodes[0];
    node.appendChild(this.targetTmp.nativeElement);
    this.renderer2.appendChild(node, this.targetTmp.nativeElement);
    this.renderer2.appendChild(document.body, node);
    this.renderer2.addClass(document.body, this.bodyClass);

    this.fullscreenToggle.emit(true);
  }

  public exitFromFullScreenMode(): void {
    if (!this.fulscreenMode) {
      return;
    }
  
    this.fulscreenMode = false;
    const sf = screenfull as Screenfull;
  
    if (sf.isEnabled) {
      sf.exit();
      return;
    }

    this.renderer2.removeClass(document.body, this.bodyClass);
    this.renderer2.appendChild(this.element.nativeElement, this.targetTmp.nativeElement);
    this.embeddedViewRef.destroy();
    this.fullscreenToggle.emit(false);
  }

}
