import { Injectable } from '@angular/core';
import { ChatChannelType } from '@app/model/enums/chat-channel-type.enum';
import { Observable, of } from 'rxjs';
import { HttpService } from 'library-explorer';

@Injectable({
  providedIn: 'root'
})
export class ChatApiService {

  constructor(private readonly httpService: HttpService) { }

  public openGroupChannels(): Observable<void> {
    return this.httpService.post('chat/open-my-group-channels', null);
  }

  public getSendbirdAppId(): Observable<{ applicationId: string }> {
    return this.httpService.get(`chat/application`);
  }

  public getSendbirdAccessToken(): Observable<{ token: string, expires_at: Date }> {
    return this.httpService.get(`chat/token`);
  }

  public joinChatRoom(entityId: string, customType: string = null): Observable<any> {
    return this.httpService.post(`chat/room/join`, { entityId, custom_type: customType });
  }

  public leaveChatRoom(entityId: string): Observable<any> {
    return this.httpService.post(`chat/room/leave`, { entityId });
  }

  public postChatSendMessage(type: ChatChannelType, entityId?: string, mediaId = null): Observable<void> {
    return this.httpService.post(`chat/send-message`, { type, entityId, mediaId });
  }

  public postChatDeleteMessage(type: ChatChannelType, messageId: number, entityId?: string): Observable<void> {
    return this.httpService.post(`chat/delete-message`, { type, entityId, messageId });
  }
}
