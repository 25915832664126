import { Component, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { MatButton } from '@angular/material/button';
import { MatMenuTrigger } from '@angular/material/menu';
import { LanguageSelectDialogService } from '@app/services/language-select-dialog.service';
import { TranslateService } from '@ngx-translate/core';
import { LangChangeEvent } from '@ngx-translate/core/lib/translate.service';
import { Subject } from 'rxjs';
import { LanguageStorageService, Language, ProfileService } from 'library-explorer';
import { takeUntil } from 'rxjs/operators';
import { LanguageService } from '../../../services/language.service';

@Component({
  selector: 'app-language-switcher',
  templateUrl: './language-switcher.component.html',
  styleUrls: ['./language-switcher.component.scss']
})
export class LanguageSwitcherComponent implements OnInit, OnDestroy {
  @ViewChild(MatMenuTrigger) languageMenuTrigger: MatMenuTrigger;

  @Input() showSelected = false;

  public languages: Language[] = [];
  public languagesMap: {[key: string]: Language} = {};
  public contentLanguages: Language[] = [];
  public currentLanguage: string;
  private subscriptions: Subject<unknown> = new Subject<unknown>();

  constructor(
    private readonly languageService: LanguageService,
    private readonly languageStorageService: LanguageStorageService,
    private readonly translateService: TranslateService,
    private readonly profileService: ProfileService,
    private readonly languageSelectDialogService: LanguageSelectDialogService
  ) { }

  ngOnInit() {
    this.getLanguage();
  }

  public changeCurrentLanguage(language: string): void {
    if (this.currentLanguage === language) {
      return;
    }

    this.languageService.updateUserInterfaceLanguage(language);
  }

  public openLanguageSwitcher(button: MatButton): void {
    const showExtendedSwitcher = this.profileService.getCurrentProfileValue() && this.contentLanguages?.length;

    if (showExtendedSwitcher) {
      const buttonElement = button._elementRef.nativeElement;
      this.languageSelectDialogService.open(buttonElement, this.languages, this.contentLanguages);
      return;
    }

    this.languageMenuTrigger.openMenu();
  }

  private getLanguage() {
    this.currentLanguage = this.translateService.currentLang;

    const isAdmin = this.profileService.isCurrentUserIsAdmin();
    this.languages = this.languageStorageService.getLanguages().filter(item => isAdmin || item.enabled);
    this.languagesMap = this.languages.reduce((prev, item) => { prev[item.code] = item; return prev }, {})
    this.contentLanguages = this.languageStorageService.getContentLanguages().filter(item => isAdmin || item.enabled);

    const currentLanguageSupported = this.languages.findIndex(lang => lang.code === this.currentLanguage) !== -1;

    if (!currentLanguageSupported) {
      this.currentLanguage = this.languages[0]?.code || this.languageStorageService.defaultLanguage;
      this.languageService.updateUserInterfaceLanguage(this.currentLanguage);
    }


    this.translateService.onLangChange
      .pipe(
        takeUntil(this.subscriptions)
      )
      .subscribe((langEvent: LangChangeEvent) => this.currentLanguage = langEvent.lang);
  }

  ngOnDestroy() {
    this.subscriptions.next();
    this.subscriptions.unsubscribe();
  }
}
