import { Directive, ElementRef, Input, Renderer2 } from '@angular/core';
import { rtlLanguageCodeList } from '../constants/rtl-language-code-list.const';

@Directive({
  selector: '[rtl]'
})
export class RtlDirective {

  @Input() set rtl(langcode: string) {
    if (!langcode) {
      return;
    }

    const el = this.elementRef.nativeElement;
    const isRtl = rtlLanguageCodeList.indexOf(langcode) !== -1;
    this.renderer.setAttribute(el, 'dir', isRtl ? 'rtl' : 'ltr');
  }

  constructor(
    private readonly elementRef: ElementRef,
    private readonly renderer: Renderer2
  ) { }

}
