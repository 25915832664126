import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { MatExpansionPanel } from '@angular/material/expansion';
import { PayableNode } from '@app/model/payable-node';
import { ModuleService } from '@app/services/api/module.service';
import { ImageModel} from 'library-explorer';
import { finalize } from 'rxjs/operators';

@Component({
  selector: 'app-module-content-expansion-panel',
  templateUrl: './module-content-expansion-panel.component.html',
  styleUrls: ['./module-content-expansion-panel.component.scss']
})
export class ModuleContentExpansionPanelComponent implements OnInit {
  @ViewChild(MatExpansionPanel) set matPanel(value: MatExpansionPanel) {
    if (!value) {
      return;
    }

    if (this.autoOpen) {
      value.open();
    }
  }

  @Input() public id: string;
  @Input() public title: string;
  @Input() public titleHidden: boolean;
  @Input() public autoOpen: boolean;
  @Input() public lessonsCount = 0;
  @Input() public quizzesCount = 0;

  public isLoading = true;

  public placeholders = new Array(2);

  public contents: Array<PayableNode & { mediaImage: ImageModel, time: number }> = [];

  constructor(private readonly modulesService: ModuleService) { }

  ngOnInit(): void {
    if (this.titleHidden) {
      this.loadContents();
    }
  }

  private loadContents(): void {
    this.isLoading = true;
  
    this.modulesService.getModuleContents(this.id)
      .pipe(
        finalize(() => this.isLoading = false)
      )
      .subscribe(data => this.contents = data.items);
  }


}
