import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Resolve, RouterStateSnapshot } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { Observable } from 'rxjs';


@Injectable({ providedIn: 'root' })
export class LanguageResolver implements Resolve<void> {
  constructor(private readonly translateService: TranslateService) { }

  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<any> | Promise<any> | any {
    return this.translateService.getTranslation(this.translateService.currentLang);
  }
}
