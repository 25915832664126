<div class="audio-player-wrapper" [class.disable-time-seek]="preventTimeSeek">
  <div class="d-flex flex-column align-items-center audio-player" [class.mb-4]="!hideControls">
    <mat-slider
      class="main-audio-slider"
      thumbLabel
      [displayWith]="convertTime"
      min="0"
      [max]="totalTime"
      [step]="0.1"
      [invert]="isRtl"
      [(ngModel)]="currentTime"
      (ngModelChange)="onTimeChange($event)">
    </mat-slider>
    <svg-icon class="filled" src="/assets/images/audio-wave.svg" [svgStyle]="{ 'fill': primaryColor, 'width': percentage }">
    </svg-icon>
    <svg-icon src="/assets/images/audio-wave.svg" [svgStyle]="{ 'fill': secondaryColor }"></svg-icon>
  </div>
  <ng-container *ngIf="!hideControls">
    <div class="d-flex justify-content-end audio-time text-muted mb-2 mb-sm-5">
      <span>{{audioTotalTime}}</span>
    </div>
    <div
      class="d-flex flex-column flex-sm-row flex-wrap align-items-center justify-content-between
      justify-content-lg-center audio-controls mt-2 mt-sm-5">
      <div class="d-flex align-items-center w-100">
        <div class="time-arrow left-time-arrow" (click)="changeTime(-step)">
          <span class="text-muted value">-{{step}}s</span>
        </div>
        <button mat-fab color="primary" class="primary-background play-button mx-4" (click)="toogleAudio()">
          <mat-icon>{{ isPlaying ? 'pause' : 'play_arrow'}}</mat-icon>
        </button>
        <div class="time-arrow right-time-arrow" (click)="changeTime(step)">
          <span class="text-muted value">+{{step}}s</span>
        </div>
      </div>
      <div class="d-flex align-items-center audio-volume">
        <button mat-mini-fab color="primary" class="primary-color volume-button" (click)="toogleVolume()">
          <mat-icon>{{ isMuted ? 'volume_off' : 'volume_mute' }}</mat-icon>
        </button>
        <div class="d-none d-md-flex align-items-center">
          <mat-slider
            [(ngModel)]="volume"
            (ngModelChange)="onVolumeChange($event)"
            class="mx-2"
            min="0"
            [invert]="isRtl"
            [step]="0.01"
            [max]="1">
          </mat-slider>
          <span class="audio-volume__value">{{ volume | percent: '1.0-0' }}</span>
        </div>
      </div>
    </div>
  </ng-container>
</div>
