export enum TaxonomyType {
  AUDIENCE = 'audience',
  GLOSSARY = 'glossary',
  LEADERBOARD_GROUP = 'leaderboard_group',
  BADGE_CATEGORY = 'badge_category',
  WEBINAR_SERIES = 'webinar_serie',
  TAGS = 'tags',
  USER_ROLE = 'user_role',
  CONTENT_STATUS = 'content_status',
  TOPIC_CATEGORY = 'topic_category',
  PUBLIC_TAGS = 'public_tags'
}
