<div *ngIf="!loading; else loadingTmp" class="library-list">

  <ng-container [ngSwitch]="layout">
    <ng-container *ngSwitchCase="layoutTypes.GRID">
      <div 
        class="library-list-grid" 
        cdkDropListGroup 
        [cdkDropListGroupDisabled]="!canEditAndDelete" 
        data-cy="library-list-grid">
        <div 
          *ngFor="let item of items"
          class="library-drop-list"
          cdkDropList
          [cdkDropListData]="item"
          [cdkDropListDisabled]="!canEditAndDelete"
          (cdkDropListDropped)="drop($event)"
          [cdkDropListEnterPredicate]="canDrop()">
          <lib-library-item-card 
            cdkDrag
            class="library-card"
            [cdkDragDisabled]="!canEditAndDelete"
            [cdkDragData]="item"
            [contextTemplate]="contextButtonEnabled ? contextMenuTmp : null"
            [enablePinned]="enablePinned"
            (open)="open.emit(item)"
            (pinnedChanged)="pinnedChanged.emit(item)"
            [item]="item">
            <ng-template cdkDragPlaceholder>
              <div class="library-drag-placeholder"></div>
            </ng-template>
          </lib-library-item-card>
        </div>
      </div>
    </ng-container>

    <ng-container *ngSwitchDefault>
      <lib-library-list-table 
        [items]="items" 
        [defaultSort]="defaultSort"
        [enablePinned]="enablePinned"
        (open)="open.emit($event)" 
        (pinnedChanged)="pinnedChanged.emit($event)"
        (sortChange)="tableSortChanged($event)" 
        [contextTemplate]="contextButtonEnabled ? contextMenuTmp : null">
      </lib-library-list-table>
    </ng-container>
  </ng-container>


  <ng-template #contextMenuTmp let-item="item">
    <ng-container *ngIf="viewDetailsButton
      || (allowDownload && item.type !== libraryTypes.FOLDER)
      || (canEditAndDelete && (item._meta?.operations?.edit || item._meta?.operations?.delete))
      || moreMenuTemplate; else noContextMenuTmp">
      <button class="library-context-menu-item" mat-menu-item (click)="openDetails.emit(item)" *ngIf="viewDetailsButton">
        <lib-icon icon="info" [size]="24"></lib-icon>
        {{ 'LIBRARY_PAGE.view_details' | translate }}
      </button>
  
      <button class="library-context-menu-item" mat-menu-item *ngIf="allowDownload && item.type !== libraryTypes.FOLDER" (click)="download.emit(item)">
        <lib-icon icon="download-simple" [size]="24"></lib-icon>
        {{ 'LIBRARY_PAGE.download' | translate }}
      </button>
  
      <ng-container *ngIf="canEditAndDelete">
        <button *ngIf="item._meta?.operations?.edit" class="library-context-menu-item" mat-menu-item (click)="edit.emit(item)" data-cy="library-item-edit">
          <lib-icon icon="pencil" [size]="24"></lib-icon>
          {{ 'COMMON.edit' | translate }}
        </button>
    
        <button *ngIf="item._meta?.operations?.delete" class="library-context-menu-item" mat-menu-item (click)="delete.emit(item)" data-cy="library-item-delete">
          <lib-icon icon="trash" [size]="24"></lib-icon>
          {{ 'COMMON.delete' | translate }}
        </button>
      </ng-container>
  
      <ng-container *ngTemplateOutlet="moreMenuTemplate; context: {item: item}"></ng-container>
    </ng-container>

    <ng-template #noContextMenuTmp>
      <button class="library-context-menu-item" disabled mat-menu-item data-cy="library-item-no-actions">
        {{ 'COMMON.no_actions' | translate }}
      </button>
    </ng-template>
  </ng-template>
</div>


<ng-template #loadingTmp>
  <div class="library-list skeleton-view">
    <ng-container [ngSwitch]="layout">
      <ng-container *ngSwitchCase="layoutTypes.GRID">
        <div class="skeleton-view-grid">
          <div class="skeleton-card" *ngFor="let item of placeholders">
            <lib-skeleton-loader class="skeleton-card-image"></lib-skeleton-loader>
            <lib-skeleton-loader class="skeleton-card-title" height="20px" width="60%"></lib-skeleton-loader>
            <lib-skeleton-loader class="skeleton-card-info" height="16px" width="90%"></lib-skeleton-loader>
          </div>
        </div>
      </ng-container>
  
      <ng-container *ngSwitchDefault>
        <div class="skeleton-view-list">
          <div class="skeleton-view-list-header">
            <div>
              <lib-skeleton-loader height="18px" width="80px"></lib-skeleton-loader>
            </div>
            <div>
              <lib-skeleton-loader height="18px" width="60px"></lib-skeleton-loader>
            </div>
            <div>
              <lib-skeleton-loader height="18px" width="60px"></lib-skeleton-loader>
            </div>
            <div>
              <lib-skeleton-loader height="18px" width="60px"></lib-skeleton-loader>
            </div>
          </div>

          <div class="skeleton-row" *ngFor="let item of placeholders; let index = index">
            <lib-skeleton-loader height="60px" [style.opacity]="1 - index * .2"></lib-skeleton-loader>
          </div>
        </div>
      </ng-container>
    </ng-container>
  </div>
</ng-template>
