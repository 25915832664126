import { Injectable } from '@angular/core';
declare let gtag: Function;

@Injectable({
  providedIn: 'root'
})
export class GoogleAnalyticsService {

  constructor() { }

  public initGoogleAnalytics(trackingId: string, tagManagerId: string): void {
    if (!trackingId && !tagManagerId) {
      return;
    }

    if (tagManagerId) {
      this.injectTagManagerScripts(tagManagerId);
      return;
    }

    this.injectTrackingIdScript(trackingId);
  }

  private injectTrackingIdScript(trackingId: string): void {
    const script = document.createElement('script');
    script.async = true;
    script.src = `https://www.googletagmanager.com/gtag/js?id=${trackingId}`;
    document.body.append(script);
    gtag('config', trackingId, { send_page_view: false });
  }

  private injectTagManagerScripts(tagManagerId: string): void {
    const headScript = this.buildTagManagerScript(tagManagerId);
    document.head.insertBefore(headScript, document.head.firstChild);

    const noScriptEl = this.buildTagManagerNoscript(tagManagerId);
    document.body.insertBefore(noScriptEl, document.body.firstChild);
  }

  private buildTagManagerScript(tagManagerId: string): HTMLScriptElement {
    const scriptId = 'google-tag-manager-script-id';
    const existingScript = document.getElementById(scriptId) as HTMLScriptElement;

    if (existingScript) {
      return existingScript;
    }

    const script = document.createElement('script');
    script.id = scriptId;
    script.innerHTML = `(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
      new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
      j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
      'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
      })(window,document,'script','dataLayer', '${tagManagerId}');`;

    return script;
  }

  private buildTagManagerNoscript(tagManagerId: string): HTMLElement {
    const noscriptId = 'google-tag-manager-noscript-id';
    const existingNoscript = document.getElementById(noscriptId) as HTMLScriptElement;

    if (existingNoscript) {
      return existingNoscript;
    }

    const noscript = document.createElement('noscript');
    noscript.id = noscriptId;

    const iframe = document.createElement('iframe');
    iframe.src = `https://www.googletagmanager.com/ns.html?id=${tagManagerId}`;
    iframe.height = '0';
    iframe.width = '0';
    iframe.style.display = 'none';
    iframe.style.visibility = 'hidden';

    noscript.appendChild(iframe);

    return noscript;
  }
}
