import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { BiometryType } from '@aparajita/capacitor-biometric-auth';

@Component({
  selector: 'app-biometric-auth-modal',
  templateUrl: './biometric-auth-modal.component.html',
  styleUrls: ['./biometric-auth-modal.component.scss']
})
export class BiometricAuthModalComponent implements OnInit {
  biometricType: BiometryType;
  biometricTypeDescription: string;

  public okBtnHandler = () => {
  }

  public cancelBtnHandler = () => {
    this.dialogRef.close(true);
  }

  constructor(public dialogRef: MatDialogRef<BiometricAuthModalComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any) { }

  ngOnInit(): void {
    this.biometricTypeDescription = this.data.biometricTypeDescription;
    this.biometricType = this.data.biometricType;
    this.okBtnHandler = this.data.okBtnHandler || this.okBtnHandler;
    this.cancelBtnHandler = this.data.cancelBtnHandler || this.cancelBtnHandler;
  }
}
