import { Pipe, PipeTransform } from '@angular/core';
import { BundleType, EntityTypeId, FieldName, ProviderType, VideoModel } from '../models';
import { MediaPresignApiService } from '../services';
import { Observable, of } from 'rxjs';

@Pipe({
  name: 'videoPresign'
})

export class VideoMediaPresignPipe implements PipeTransform {
  constructor(private readonly mediaPresignApiService: MediaPresignApiService) {}

  transform(video: Partial<VideoModel>, settingsVideo = false): Observable<string> {
    if (!video) {
      return of('');
    }

    const [entityType, bundle] = settingsVideo 
      ? [EntityTypeId.SETTINGS, BundleType.SETTINGS] : [EntityTypeId.MEDIA, BundleType.VIDEO];
  
    if (video.stream) {
      return this.mediaPresignApiService.getPreSignedUrlCookies(entityType, bundle, FieldName.AWS_VIDEO_STREAM, video.streamKey);
    }

    if (video.provider === ProviderType.AWS) {
      return this.mediaPresignApiService.getPreSignedUrl(entityType, bundle, FieldName.AWS_VIDEO, video.key);
    }

    return of(video.uri || video.url);
  }
}
