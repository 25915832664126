import { Component, ElementRef, Input, OnChanges, OnDestroy, OnInit, SimpleChanges, ViewChild } from '@angular/core';
import { FormControl } from '@angular/forms';
import { ChatChannel } from '@app/model/chat-channel';
import { MediaApiService } from '@app/services/api/media-api.service';
import { SendbirdService } from '@app/services/sendbird.service';
import { BundleType, ChatMessageType, EntityTypeId, FieldName, FileModel, UploadFileInfo, UploadService } from 'library-explorer';
import { Observable } from 'rxjs';
import { filter, finalize, map, mergeMap, tap } from 'rxjs/operators';

@Component({
  selector: 'app-chat-message-input',
  templateUrl: './chat-message-input.component.html',
  styleUrls: ['./chat-message-input.component.scss']
})
export class ChatMessageInputComponent implements OnInit, OnDestroy, OnChanges {
  @ViewChild('fileInput') public fileInputRef: ElementRef<HTMLInputElement>;

  @Input() public groupChannel: ChatChannel;

  public messageControl = new FormControl('');

  public messageToEdit: SendBird.UserMessage;

  public messageTypes: typeof ChatMessageType = ChatMessageType;

  public minified = false;

  constructor(
    private readonly element: ElementRef,
    private readonly sendbirdService: SendbirdService,
    private readonly mediaService: MediaApiService,
    private readonly uploadService: UploadService) { }

  ngOnInit(): void {
    this.minified = this.element.nativeElement.offsetWidth < 300;

    const observer = new ResizeObserver(() => {
      this.minified = this.element.nativeElement.offsetWidth < 300;
    });
    
    observer.observe(this.element.nativeElement);
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.groupChanner?.currentValue?.url !== changes.groupChannel?.previousValue?.url) {
      this.sendbirdService.closeChatRecordMediaDialog();
    }
  }

  ngOnDestroy(): void {
    this.sendbirdService.closeChatRecordMediaDialog();
  }

  public openRecordDialog(type: ChatMessageType): void {
    const recordDialog = this.sendbirdService.openChatRecordMediaDialog(type, this.element.nativeElement);

    recordDialog.afterClosed()
      .pipe(
        filter(data => data?.file),
        mergeMap(({ file, type, options }) => this.sendFileMessage(file, type, options))
      )
      .subscribe();
  }

  private sendFileMessage(file: File, type: ChatMessageType, options = null): Observable<void> {
    this.sendbirdService.loadingIndicatorId.next(this.groupChannel.url);

    const [bundle, fieldName] = this.getMediaBundleAndFieldName(type);

    return this.uploadMediaToAws(file, fieldName, bundle).pipe(
      mergeMap(media => this.sendbirdService.sendAwsFileMessage(this.groupChannel, media, type, options)),
      finalize(() => this.sendbirdService.loadingIndicatorId.next(null)),
    );
  }

  private getMediaBundleAndFieldName(type: ChatMessageType): [BundleType, FieldName] {
    switch (type) {
      case ChatMessageType.IMAGE_MESSAGE:
        return [BundleType.IMAGE, FieldName.MEDIA_IMAGE];
      case ChatMessageType.VIDEO_MESSAGE:
        return [BundleType.VIDEO, FieldName.MEDIA_VIDEO];
      default:
        return [BundleType.FILE, FieldName.MEDIA_FILE];
    }
  }

  public closeRecordDialog(): void {
    this.sendbirdService.closeChatRecordMediaDialog();
  }

  public cancelMessageEditting(): void {
    this.messageToEdit = null;
    this.messageControl.patchValue('');
  }

  public setEditMessage(item: SendBird.UserMessage): void {
    this.messageToEdit = item;
    this.messageControl.patchValue(item.message);
  }

  public openFileSelector(accept = '*'): void {
    const input = this.fileInputRef?.nativeElement;
    
    if (!input) {
      return;
    }
  
    input.accept = accept;
    input.click();
  }

  public sendMessage(): void {
    const message = this.messageControl.value;
  
    if (!message) {
      return;
    }

    this.messageControl.patchValue('');

    if (this.messageToEdit) {
      this.sendbirdService.updateMessage(this.groupChannel, this.messageToEdit, message);
      this.messageToEdit = null;
      return;
    }
    
    this.sendbirdService.sendMessage(this.groupChannel, message);
  }

  public fileSelected(event): void {
    if (!event.target.files || event.target.files.length === 0) {
      return;
    }

    const [file] = event.target.files;

    if (!file) {
      return;
    }
  
    event.target.value = '';
    
    const type = this.getMessageTypeByFile(file);
    this.sendFileMessage(file, type).subscribe();
  }

  private getMessageTypeByFile(file: File): ChatMessageType {
    const fileMimeType = file.type.split('/')[0];

    switch (fileMimeType) {
      case 'image':
        return ChatMessageType.IMAGE_MESSAGE;
      case 'video':
        return ChatMessageType.VIDEO_MESSAGE;
      case 'audio':
        return ChatMessageType.AUDIO_MESSAGE;
      default:
        return ChatMessageType.FILE_MESSAGE;
    }
  }
 
  private uploadMediaToAws(file: File, fieldName: FieldName, mediaBundle = BundleType.FILE): Observable<Partial<FileModel>> {
    const info: UploadFileInfo = {
      bundle: BundleType.CHAT_CHANNEL,
      fieldName,
      entityTypeId: EntityTypeId.CHAT_CHANNEL,
      file
    };
  
    return this.uploadService.uploadToAws(info)
      .pipe(
        filter((res: { completed: boolean, key: string }) => res && res.completed),
        map(res => ({ key: res.key })),
        mergeMap(({ key }) => {
          return this.mediaService.createMediaEntity(key, mediaBundle)
            .pipe(
              map(data => ({ key, id: data.id, filename: data.filename }))
            )
        })
      );
  }

}
