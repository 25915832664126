import { Component, forwardRef, Input, OnInit } from '@angular/core';
import { ControlValueAccessor, FormControl, NG_VALUE_ACCESSOR } from '@angular/forms';
import { QuestionType } from '../../../common/models';

@Component({
  selector: 'lib-slider-widget',
  templateUrl: './slider-widget.component.html',
  styleUrls: ['./slider-widget.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => SliderWidgetComponent),
      multi: true
    }
  ]
})
export class SliderWidgetComponent implements OnInit, ControlValueAccessor {
  @Input() public min = 0;
  @Input() public max = 100;
  @Input() public unit = '%';
  @Input() public readonly = false;
  @Input() public invert = false;
    
  @Input() public set stepsCount(value: number) {
    this._stepsCount = value;

    if (!value) {
      return;
    }

    value = Math.max(value, 0);
    value = Math.min(value, 1000);
  
    this.steps = new Array(value).fill(null);

    this.step = (this.max - this.min) / (value - 1);
    this.step = Math.max(this.step, 1);
  }

  public get stepsCount() {
    return this._stepsCount;
  }


  @Input() questionHash: string;

  public disabled = false;

  public steps = [];

  public step = 1;

  public control = new FormControl(0);

  private _stepsCount: number;

  constructor() { }

  ngOnInit(): void {
    this.steps = new Array(this._stepsCount < 0 ? 0 : this._stepsCount).fill(null);
  
    this.control.valueChanges
      .subscribe((value: number) => {
        this.onChange({
          type: QuestionType.QUESTION_SLIDER,
          questionHash: this.questionHash,
          answer: value?.toString()
        });
      });
  }

  public formatLabel(value: number) {
    return Math.round(value * 1000) / 1000;
  }

  onChange = (data: any) => { };
  onTouched = () => { };

  public writeValue(value: number): void {
    this.control.patchValue(value, { emitEvent: false });
  }

  public registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  public registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  public setDisabledState?(isDisabled: boolean): void {
    this.disabled = isDisabled;
  }

}
