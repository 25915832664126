import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { Observable, of, Subject } from 'rxjs';
import { filter, takeUntil } from 'rxjs/operators';
import { EntityTypeId } from 'library-explorer';
import { BundleType } from 'library-explorer';
import { ImageModel, SettingsUserAvatarType, ProfileUserDTO, MediaPresignApiService, FieldName, ProviderType } from 'library-explorer';
import { SettingsService } from '@app/services/settings.service';

@Component({
  selector: 'app-user-avatar',
  templateUrl: './user-avatar.component.html',
  styleUrls: ['./user-avatar.component.scss']
})
export class UserAvatarComponent implements OnInit, OnDestroy {
  @Input() public crop = true;
  @Input() public size = 50;
  @Input() public set user(value: Partial<ProfileUserDTO> & { fullname?: string, metadata?: string }) {
    if (!value) {
      return;
    }
  
    if (value.metadata) {
      const meta = this.isJson(value.metadata) ? JSON.parse(value.metadata) : value.metadata;

      if (meta.mediaImage) {
        Object.assign(value, { mediaImage: { key: meta.mediaImage }});
      }

      if (meta.mediaAvatar) {
        Object.assign(value, { mediaAvatar: { key: meta.mediaAvatar }});
      }
    }

    this.color = this.colors[0];
    this._user = value;
    this.setUserAvatar();

    if (value) {
      const parts = value.fullname && value.fullname.split(' ') || [];
      const firstName = value.name || parts[0] || '';
      const lastName =  value.lastName || parts.reverse()[0] || '';

      this.initials = `${firstName.charAt(0).toLocaleUpperCase()}${lastName.charAt(0).toLocaleUpperCase()}`;

      const colorIndex = this.getLettersCodeCombination(firstName) + this.getLettersCodeCombination(lastName);
      this.color = this.colors[colorIndex % this.colors.length];
    }
  }

  public get user() {
    return this._user;
  }


  public url: Observable<string>;
  public initials: string;
  public color: string;
  public isBase64 = false;

  private _user: Partial<ProfileUserDTO>;
  private avatarMode: SettingsUserAvatarType = SettingsUserAvatarType.DISABLED;
  private avatars = [];
  private unsubscribe: Subject<void> = new Subject();

  private readonly colors = [
    '#0aafff',
    '#4075FF',
    '#2dca73',
    '#7551e9',
    '#8964FF',
    '#D3E0FF',
    '#ff7d51',
    '#f44336'
  ];


  constructor(
    private readonly mediaPresignApiService: MediaPresignApiService,
    private readonly settingsService: SettingsService) {  }

  public ngOnInit(): void {
    this.settingsService.getSettings()
      .pipe(
        filter(data => !!data),
        takeUntil(this.unsubscribe)
      )
      .subscribe(data => {
        this.avatars = data.profile.avatars || [];
        this.avatarMode = data.profile.avatarMode || SettingsUserAvatarType.DISABLED;
        this.setUserAvatar();
      });
  }

  public ngOnDestroy(): void {
    this.unsubscribe.next();
    this.unsubscribe.complete();
  }

  private setUserAvatar(): void {
    if (this.user) {
      const avatar = this.getProfileAvatar();
      const profileImage = this.user.mediaImage;

      switch (this.avatarMode) {
        case SettingsUserAvatarType.AVATAR_ONLY:
          this.setAvatar(avatar, FieldName.MEDIA_AVATAR);

          break;
        case SettingsUserAvatarType.PHOTO_ONLY:
          this.setAvatar(profileImage, FieldName.MEDIA_IMAGE);
          break;
        case SettingsUserAvatarType.AVATAR_AND_PHOTO:
          if (profileImage) {
            this.setAvatar(profileImage, FieldName.MEDIA_IMAGE);
          } else {
            this.setAvatar(avatar, FieldName.MEDIA_AVATAR);
          }
          break;
        default:
          this.url = null;
          return;
      }
    }
  }

  private setAvatar(image: ImageModel, fieldName: FieldName): void {
    if (image && image.provider === ProviderType.AWS) {
      this.url = this.mediaPresignApiService.getPreSignedUrl(EntityTypeId.USER, BundleType.USER, fieldName, image.key);
    } else {
      this.url = image && image.url ? of(image.url) : null;
    }
  }

  private getProfileAvatar(): ImageModel {
    const avatar = this.avatars && this.avatars.find(item => {
      return (this.user.mediaAvatar && item.key === this.user.mediaAvatar.key) || item.uri === this.user.avatarUrl;
    });

    return avatar;
  }

  private isJson(str: string) {
    try {
      JSON.parse(str);
    } catch (e) {
      return false;
    }

    return true;
  }


  private getLettersCodeCombination(value: string): number {
    return value ? value.charCodeAt(0) + value.charCodeAt(value.length - 1) : 0;
  }
}
