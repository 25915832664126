import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { BlockListComponent } from './block-list/block-list.component';
import { BannerBlockComponent } from './blocks/banner-block/banner-block.component';
import { DiscoverContentBlockComponent } from './blocks/discover-content-block/discover-content-block.component';
import { FeatureHighlightComponent } from './blocks/feature-highlight/feature-highlight.component';
import { LoginPromoComponent } from './blocks/login-promo/login-promo.component';
import { TestimonialsComponent } from './blocks/testimonials/testimonials.component';
import { TextImageComponent } from './blocks/text-image/text-image.component';
import { VideoBlockComponent } from './blocks/video-block/video-block.component';
import { SharedModule } from 'src/app/shared/shared.module';
import { DashboardModule } from '../dashboard/dashboard.module';
import { CarouselBlockComponent } from './blocks/carousel-block/carousel-block.component';
import { WebinarsBlockComponent } from './blocks/webinars-block/webinars-block.component';
import { LearningPathBlockComponent } from './blocks/learning-path-block/learning-path-block.component';
import { SharedLibraryModule } from 'library-explorer';
import { environment } from '@env/environment';


@NgModule({
  declarations: [
    BlockListComponent,
    TextImageComponent,
    LoginPromoComponent,
    FeatureHighlightComponent,
    VideoBlockComponent,
    BannerBlockComponent,
    TestimonialsComponent,
    DiscoverContentBlockComponent,
    CarouselBlockComponent,
    WebinarsBlockComponent,
    LearningPathBlockComponent
  ],
  imports: [
    CommonModule,
    SharedModule,
    SharedLibraryModule,
    DashboardModule
  ],
  exports: [
    BlockListComponent
  ]
})
export class BasicPageBlocksModule { }
