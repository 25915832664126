<div class="chat-message-input" [class.minified]="minified">
  <button mat-icon-button class="button button--icon button--primary button--icon-extra-small attach-file-button" [matMenuTriggerFor]="attachMenu">
    <lib-icon icon="plus"></lib-icon>
    <input type="file" class="file-input" (change)="fileSelected($event)" #fileInput>
  </button>

  <mat-menu #attachMenu="matMenu" class="base-context-menu">
    <button class="base-menu-button" mat-menu-item (click)="openRecordDialog(messageTypes.AUDIO_MESSAGE)">
      <lib-icon icon="microphone"></lib-icon>
      {{'CHAT.audio_message' | translate}}
    </button>

    <button class="base-menu-button" mat-menu-item (click)="openRecordDialog(messageTypes.VIDEO_MESSAGE)">
      <lib-icon icon="video-camera"></lib-icon>
      {{'CHAT.video_message' | translate}}
    </button>
    
    <button class="base-menu-button" mat-menu-item (click)="openFileSelector()">
      <lib-icon icon="file-text"></lib-icon>
      {{'CHAT.attach_document' | translate}}
    </button>

    <button class="base-menu-button" mat-menu-item (click)="openFileSelector('video/*')">
      <lib-icon icon="file-video"></lib-icon>
      {{'CHAT.attach_video' | translate}}
    </button>

    <button class="base-menu-button" mat-menu-item (click)="openFileSelector('image/*')">
      <lib-icon icon="file-image"></lib-icon>
      {{'CHAT.attach_image' | translate}}
    </button>
  </mat-menu>

  <div class="message-input">
    <input 
      class="input input--rounded input--placeholder-grey" 
      type="text" 
      (keydown.enter)="sendMessage();false"
      [placeholder]="'CHAT.add_comment' | translate"
      [formControl]="messageControl">

    <div class="message-input-actions">
      <button 
        mat-icon-button 
        class="button button--icon button--icon-extra-small action-button record-message-icon" 
        (click)="openRecordDialog(messageTypes.VIDEO_MESSAGE)">
        <lib-icon icon="video-camera" [size]="24"></lib-icon>
      </button>

      <button 
        mat-icon-button 
        class="button button--icon button--icon-extra-small action-button record-message-icon" 
        (click)="openRecordDialog(messageTypes.AUDIO_MESSAGE)">
        <lib-icon icon="microphone" [size]="24"></lib-icon>
      </button>

      <button 
        mat-icon-button 
        class="button button--icon button--icon-extra-small action-button action-secondary" 
        *ngIf="messageToEdit" 
        (click)="cancelMessageEditting()">
        <lib-icon icon="x"></lib-icon>
      </button>
  
      <button 
        mat-icon-button 
        class="button button--icon button--primary button--icon-extra-small action-button" 
        [class.disabled]="!messageControl.value" 
        (click)="sendMessage()">
        <lib-icon [icon]="messageToEdit ? 'check' : 'arrow-up'"></lib-icon>
      </button>
    </div>
    
  </div>
</div>