<div class="chat-dialog">
  <div class="chat-dialog-header">
    <div class="chat-title">
      <app-chat-channel-image [channel]="groupChannel"></app-chat-channel-image>
  
      <h4 class="chat-channel-title" ellipsis [ellipsis-content]="groupChannel?.name"></h4>
    </div>

    <div class="chat-header-actions">
      <app-icon-button [icon]="collapsed ? 'arrow-line-up' : 'minus'" class="collapse-button" (click)="changeDialogState()"></app-icon-button>
      <app-icon-button icon="x" (clicked)="closeDialog()"></app-icon-button>
    </div>
  </div>

  <div class="chat-channel">
    <app-chat-channel [groupChannel]="groupChannel" [hideBorder]="false" [disabled]="collapsed"></app-chat-channel>
  </div>
</div>
