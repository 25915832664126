import { Pipe, PipeTransform } from '@angular/core';
import { BiometryType } from '@aparajita/capacitor-biometric-auth';

@Pipe({
  name: 'biometricIcon'
})
export class BiometricIconPipe implements PipeTransform {

  transform(biometricType: BiometryType): string {
    switch (biometricType) {
      case BiometryType.irisAuthentication:
        return '/assets/icons/iris.svg';
      case BiometryType.faceId:
      case BiometryType.faceAuthentication:
        return '/assets/icons/face-id.svg';
      case BiometryType.fingerprintAuthentication:
      case BiometryType.touchId:
        return '/assets/icons/fingerprint.svg';
      default:
        return '';
    }
  }
}
