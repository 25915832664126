import { Component, ElementRef, Input, OnInit, ViewChild } from '@angular/core';
import { SafeResourceUrl } from '@angular/platform-browser';
import { PlyrComponent } from 'ngx-plyr';
import { VideoComponent } from '../../../common/components';
import { VideoMessageLayout } from '../../models/video-layout.enum';

@Component({
  selector: 'lib-recorded-video-message',
  templateUrl: './recorded-video-message.component.html',
  styleUrls: ['./recorded-video-message.component.scss']
})
export class RecordedVideoMessageComponent implements OnInit {
  @ViewChild(VideoComponent) public videoElement: VideoComponent;

  @Input() public video: any;
  @Input() public set src(value: string | SafeResourceUrl) {
    if (!value) {
      return;
    }
  
    this.videoUrl = value.toString();
  }

  @Input() public theme: 'default' | 'primary' = 'default';
  @Input() public videoShape: VideoMessageLayout = VideoMessageLayout.SQUARE;

  @Input() public hidePlayButton = false;

  public isPlaying = false;

  public isLoaded = false;

  public videoUrl: string;

  public options = {
    fullscreen: { 
      enabled: false 
    }
  };
  
  constructor() { }

  ngOnInit(): void {
  }

  public videoLoaded(): void {
    this.isLoaded = true;
  }

  public togglePlayPause(): void {
    const video = this.videoElement?.plyr;

    if (!video) {
      return;
    }

    this.isPlaying = !this.isPlaying;

    if (video.paused) {
      video.play();
      return;
    }

    video.pause();
  }

}
