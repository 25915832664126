import { Injectable } from '@angular/core';
import { LocaleEnum } from '../models';
import { BehaviorSubject, Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class LocaleService {

  private _locale: BehaviorSubject<LocaleEnum> = new BehaviorSubject(LocaleEnum.EU);

  constructor() { }

  public get locale(): Observable<LocaleEnum> {
    return this._locale.asObservable();
  }

  public get localeValue(): LocaleEnum {
    return this._locale.value;
  }

  public setLocale(value: LocaleEnum): void {
    if (!value) {
      return;
    }

    this._locale.next(value);
  }
}
