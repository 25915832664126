import { Component, OnInit, Inject } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'app-discuss-chat-dialog',
  templateUrl: './discuss-chat-dialog.component.html',
  styleUrls: ['./discuss-chat-dialog.component.scss']
})
export class DiscussChatDialogComponent implements OnInit {
  public identifier: string;
  public chatTitle: string;
  public isLoading = false;

  constructor(@Inject(MAT_DIALOG_DATA) public data: any) { }

  ngOnInit() {
    this.identifier = this.data.identifier;
    this.chatTitle = this.data.title;
  }
}
