import { BrowserStorage } from "../models/browser-storage"

export class LocalStorageService extends BrowserStorage {
  public getItem<T = string>(key: string): T | undefined {
    const data = localStorage.getItem(key);

    if (!data) {
      return undefined;
    }

    return this.isJson(data) ? JSON.parse(data) : data;
  }

  public removeItem(key: string): void {
    localStorage.removeItem(key);
  }

  public setItem<T = any>(key: string, item: T): void {
    const data = this.isJson(item) ? item : JSON.stringify(item);

    localStorage.setItem(key, data as string);
  }

  private isJson(data: string | any): boolean {
    try {
      JSON.parse(data);
    } catch (e) {
      return false;
    }

    return true;
  }
}
