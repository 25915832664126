<div class="chat-list-dialog">
  <div class="chat-dialog-header">
    <h3 class="chat-dialog-title" cdkFocusInitial>{{'CHAT.group_chats' | translate}}</h3>

    <div class="dialog-header-buttons">
      <app-icon-button class="dialog-close-button" icon="x" (clicked)="closeDialog()"></app-icon-button>
    </div>
  </div>
  
  <app-chat-list 
    class="chat-list" 
    [chatMode]="mode" 
    (channelsIntialized)="channelsLoaded($event)">
  </app-chat-list>

  <div class="chat-dialog-footer" *ngIf="userHasChannels">
    <button
      mat-flat-button
      [routerLink]="['/chat']"
      class="button button--transparent button--small chat-dialog-button">
      <lib-icon class="mr-2" icon="chats" size="24"></lib-icon>
      {{ 'CHAT.see_all_group_chats' | translate }}
    </button>
  </div>

</div>

