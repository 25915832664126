import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { interceptorsToken } from 'library-explorer';

@Injectable()
export class SkipTokenInterceptor implements HttpInterceptor {

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    const skipToken = request.params.get(interceptorsToken.SKIP_TOKEN);

    request = request.clone({
      params: request.params.delete(interceptorsToken.SKIP_TOKEN)
    });

    if (skipToken) {
      request = request.clone({
        headers: request.headers.delete('Authorization')
      });
    }

    return next.handle(request);
  }
}
