import { HttpParams } from '@angular/common/http';
import { Component, Input, OnInit } from '@angular/core';
import { Observable } from 'rxjs';
import { finalize } from 'rxjs/operators';
import { CourseDTO } from 'src/app/model/courseDTO.model';
import { ModuleDTO } from 'src/app/model/moduleDTO.model';
import { CourseService } from 'src/app/services/api/course.service';
import { ModuleService } from 'src/app/services/api/module.service';
import { PaymentService } from 'src/app/services/payment.service';
import { fadeStaggerAnimation } from 'src/app/shared/constants/animations.const';

@Component({
  selector: 'app-dashboard-item-list',
  templateUrl: './dashboard-item-list.component.html',
  styleUrls: ['./dashboard-item-list.component.scss'],
  animations: [fadeStaggerAnimation]
})
export class DashboardItemListComponent implements OnInit {
  @Input() public carouselConfig?: any;

  @Input()
  public set skipModule(value: boolean) {
    if (value !== undefined) {
      this._skipModule = value;
    }
  }

  public get skipModule() {
    return this._skipModule;
  }

  @Input() public set selectedId(value: string) {
    this._selectedId = value;
    this.fetchItems();
  }

  public get selectedId() {
    return this._selectedId;
  }

  public itemList: (ModuleDTO | CourseDTO)[];
  public isLoading = true;
  public paymentEnabled = false;
  private itemParams: HttpParams;

  private _skipModule: boolean;
  private _selectedId: string;

  constructor(
    private paymentService: PaymentService,
    private modulesService: ModuleService,
    private сourseService: CourseService
  ) {
  }

  ngOnInit() {
    this.paymentService.getPaymentConfig().subscribe(res => {
      this.paymentEnabled = !!res.enabled;
    });
  }

  private fetchItems() {
    if (!this.itemParams) {
      this.itemParams = new HttpParams();
    }

    this.isLoading = true;
    this.itemParams = this.itemParams.set('sort', 'weight:ASC');

    const obs: Observable<(ModuleDTO | CourseDTO)[]> = !this.skipModule ?
      this.modulesService.getAllModules(this.selectedId, this.itemParams)
      : this.сourseService.getAllCourses(this.selectedId, this.itemParams);

    obs
      .pipe(
        finalize(() => this.isLoading = false)
      )
      .subscribe((res: (ModuleDTO | CourseDTO)[]) => {
        this.itemList = res;
      });
  }

}
