import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'chatMessage'
})
export class ChatMessagePipe implements PipeTransform {
  transform(value: string): any {
    const escapedValue = this.escapeHtml(value);
    const regex = new RegExp(/(((https?:\/\/)|(www\.))[^\s]+)/, 'g');

    return escapedValue.replace(regex, url => {
      let hyperlink = url;
      if (!hyperlink.match('^https?:\/\/')) {
        hyperlink = 'http://' + hyperlink;
      }

      return `<a class="color-inherit" href="${hyperlink}" target="_blank">${url}</a>`;
    });
  }

  private escapeHtml(unsafe: string): string {
    if (!unsafe) {
      return '';
    }

    return unsafe
      .replace(/&/g, "&amp;")
      .replace(/</g, "&lt;")
      .replace(/>/g, "&gt;")
      .replace(/"/g, "&quot;")
      .replace(/'/g, "&#039;");
  }
}
