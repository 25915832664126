import { Component, ElementRef, EventEmitter, Input, OnInit, Output, TemplateRef } from '@angular/core';
import { Sort, SortDirection } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { LibraryItem } from '../../models';

@Component({
  selector: 'lib-library-list-table',
  templateUrl: './library-list-table.component.html',
  styleUrls: ['./library-list-table.component.scss']
})
export class LibraryListTableComponent implements OnInit {
  @Input() public set items(value: LibraryItem[]) {
    this._items = value || [];

    this.dataSource = new MatTableDataSource(this._items);
  }

  public get items() {
    return this._items;
  }

  @Input() public enablePinned = false;
  @Input() contextTemplate: TemplateRef<HTMLElement>;
  @Input() public defaultSort: string;

  @Output() public sortChange: EventEmitter<Sort> = new EventEmitter<Sort>();
  @Output() public open: EventEmitter<LibraryItem> = new EventEmitter<LibraryItem>();
  @Output() public pinnedChanged: EventEmitter<LibraryItem> = new EventEmitter<LibraryItem>();

  public startSort: Sort = null;

  public minifiedView = false;

  public dataSource: MatTableDataSource<LibraryItem> = new MatTableDataSource([]);

  public readonly displayedColumns = ['title', 'tags', 'updated', 'fileSize', 'actions'];

  private readonly minWidth = 800;

  private mediaQuery = window.matchMedia('(max-width: 992px)');

  private _items: LibraryItem[] = [];
  
  constructor(private readonly element: ElementRef) { }

  ngOnInit(): void {
    const [active, direction] = this.defaultSort ? this.defaultSort.split(':') : [];
    this.startSort = this.defaultSort ? {
      active,
      direction: direction.toLocaleLowerCase() as SortDirection
    }: null;

    this.detectIfViewShouldBeMinified();
  }

  public togglePinned(item: LibraryItem, event: Event): void {
    event.stopPropagation();
    this.pinnedChanged.emit(item);
  }

  public onSortChange(sort: Sort) {
    this.sortChange.emit(sort);
  }

  public openLibraryItem(item: LibraryItem): void {
    this.open.emit(item);
  }

  public stopPropagation(event: Event) {
    event.stopPropagation();
    event.preventDefault();
  }

  private detectIfViewShouldBeMinified(): void {
    if (!this.element.nativeElement) {
      return;
    }

    const observer = new ResizeObserver(() => {
      this.minifiedView = this.element.nativeElement.offsetWidth < this.minWidth || this.mediaQuery.matches;
    });
    
    observer.observe(window.document.body);

    this.minifiedView = this.element.nativeElement.offsetWidth < this.minWidth || this.mediaQuery.matches;
  }

}
