import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { BundleType, FileModel, HttpService } from 'library-explorer';

@Injectable({
  providedIn: 'root'
})
export class MediaApiService {

  constructor(private readonly httpService: HttpService) { }

  public createMediaEntity<T = FileModel>(key: string, bundle: BundleType): Observable<T> {
    return this.httpService.post<T>(`media`, { key, bundle });
  }

  public getMediaEntity<T = FileModel>(id: string): Observable<T> {
    return this.httpService.get<T>(`media/${id}`);
  }

}