import { ChangeDetectionStrategy, Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Achievement } from 'src/app/model/achievement';

@Component({
  selector: 'app-achievement-modal',
  templateUrl: './achievement-modal.component.html',
  styleUrls: ['./achievement-modal.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class AchievementModalComponent {
  constructor(@Inject(MAT_DIALOG_DATA) public achievement: Achievement) {}
}
