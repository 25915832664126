import { Component, Inject, Input, OnDestroy, TemplateRef, ViewChild } from '@angular/core';
import { finalize, takeUntil } from 'rxjs/operators';
import { ContentGroup } from 'src/app/model/content-group';
import { EntityItemLayoutEnum, StorageKeys, STORAGE_KEYS } from 'library-explorer';
import { ContentGroupService } from 'src/app/services/api/content-group.service';
import { MatExpansionPanel } from '@angular/material/expansion';
import { Subject } from 'rxjs';

@Component({
  selector: 'app-content-group',
  templateUrl: './content-group.component.html',
  styleUrls: ['./content-group.component.scss']
})
export class ContentGroupComponent implements OnDestroy {
  @ViewChild(MatExpansionPanel, { static: false }) public matExpansionPanel: MatExpansionPanel;
  @Input() public group: ContentGroup;
  @Input() public template: TemplateRef<any>;
  @Input() public layout: EntityItemLayoutEnum = EntityItemLayoutEnum.GRID;
  @Input() public label: string;

  public items = [];
  public itemsCount = 0;

  public isLoading = true;
  public initialized = false;

  public readonly layouts: typeof EntityItemLayoutEnum = EntityItemLayoutEnum;

  private readonly limit = 9;

  private unsubscribe: Subject<void> = new Subject();

  constructor(
    @Inject(STORAGE_KEYS) private readonly storageKeys: StorageKeys,
    private readonly contentGroupService: ContentGroupService,
  ) { }

  public groupOpened(): void {
    const savedToLocalStorage = this.isGroupSavedToStorage(this.group.id);
    if (!savedToLocalStorage) {
      this.addGroupToStorage(this.group.id);
    }

    if (this.initialized) {
      return;
    }

    this.loadData();
  }

  public groupClosed(): void {
    const savedToLocalStorage = this.isGroupSavedToStorage(this.group.id);
    if (!savedToLocalStorage) {
      return;
    }

    this.removeGroupFromStorage(this.group.id);
  }

  public loadData(reset = false): void {
    this.isLoading = true;
    const offset = reset ? 0 : this.items.length;

    this.contentGroupService.getContentGroupEntities(this.group.id, offset, this.limit)
      .pipe(
        finalize(() => this.isLoading = false),
        takeUntil(this.unsubscribe)
      )
      .subscribe(data => {
        if (reset) {
          this.items = [];
        }

        const cards = data.items;
        this.items.push(...cards);
        this.itemsCount = data._meta.maxcount;
        this.initialized = true;

        if (this.itemsCount > this.items.length) {
          setTimeout(() => {
            this.loadData();
          })
        }
      });
  }

  private isGroupSavedToStorage(groupId: string): boolean {
    const openedGroups = sessionStorage.getItem(this.storageKeys.OPENED_LESSON_GROUPS);
    if (!openedGroups || !openedGroups?.length) {
      return false;
    }

    return openedGroups.indexOf(groupId) !== -1;
  }

  private addGroupToStorage(groupId: string): void {
    const sessionStorageValue = sessionStorage.getItem(this.storageKeys.OPENED_LESSON_GROUPS);
    const openedGroups = sessionStorageValue ? JSON.parse(sessionStorageValue) : [];
    openedGroups.push(groupId);
    sessionStorage.setItem(this.storageKeys.OPENED_LESSON_GROUPS, JSON.stringify(openedGroups));
  }

  private removeGroupFromStorage(groupId: string): void {
    const sessionStorageValue = sessionStorage.getItem(this.storageKeys.OPENED_LESSON_GROUPS);
    let openedGroups = sessionStorageValue ? JSON.parse(sessionStorageValue) : [];
    openedGroups = openedGroups.filter(id => id !== groupId);
    sessionStorage.setItem(this.storageKeys.OPENED_LESSON_GROUPS, JSON.stringify(openedGroups));
  }

  ngOnDestroy(): void {
    this.unsubscribe.next();
    this.unsubscribe.complete();
  }

}
