<div class="live-update-dialog">
  <div mat-dialog-content class="live-update-dialog__content d-flex flex-column justify-content-center align-items-center">
    <lib-icon 
      *ngIf="updateStatus === LiveUpdateDownloadState.COMPLETED || updateStatus === LiveUpdateDownloadState.FAILED"
      [icon]="updateStatus === LiveUpdateDownloadState.COMPLETED ? 'check-circle' : 'x-circle'"
      size="50"
      class="primary-color">
    </lib-icon>

    <div class="spinner-container d-flex justify-content-center align-items-center" 
        *ngIf="updateStatus === LiveUpdateDownloadState.DOWNLOADING">
        <mat-progress-spinner [value]="data.downloadProgress | async" 
          [diameter]="40" mode="determinate">
        </mat-progress-spinner>
        <div class="spinner-bg"></div>
    </div>
   <span class="header">{{ content.header }}</span>
   <span class="description">{{ content.description }}</span>
  </div>
  <div mat-dialog-actions 
      *ngIf="updateStatus !== LiveUpdateDownloadState.DOWNLOADING" 
      class="live-update-dialog__actions">
    <button mat-flat-button class="button button--primary" (click)="okBtnHandler()">
        Continue
      </button>
  </div>
</div>
