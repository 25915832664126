<button 
  [disabled]="disabled" 
  [ngClass]="cssClass + (isLoading ? ' in-progress ' : '')" 
  [class.has-arrow]="showArrow"
  mat-flat-button
  class="loading-button button" 
  [disableRipple]="disableRipple" 
  (click)="clickHandler()">

  <div class="content">
    <ng-content></ng-content>
    <div *ngIf="showArrow && !disabled" class="primary-text next-question-icon"></div>

    <div class="button-progress-indicator primary-background-gray" *ngIf="enableProgressIndicator" [style.width.%]="100 - progress"></div>
  </div>

  <div class="loading-indicator-wrapper">
    <mat-spinner color="white" [diameter]="20"></mat-spinner>
  </div>
</button>
