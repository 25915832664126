import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { LeaderboardNotification } from 'src/app/model/leaderboard-notification';

@Component({
  selector: 'app-leaderboard-points-popup',
  templateUrl: './leaderboard-points-popup.component.html',
  styleUrls: ['./leaderboard-points-popup.component.scss']
})
export class LeaderboardPointsPopupComponent implements OnInit {
  public notification: LeaderboardNotification;

  constructor(@Inject(MAT_DIALOG_DATA) public data: LeaderboardNotification) { }

  ngOnInit() {
    this.notification = this.data;
  }

}
