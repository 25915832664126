import { Directive, ElementRef, Input } from '@angular/core';
import { BundleType } from 'library-explorer';

@Directive({
  selector: '[entityPlaceholder]'
})
export class EntityPlaceholderDirective {

  private readonly bgMap = {
    [BundleType.CLASS]: '/assets/images/quiz_placeholder.svg',
    [BundleType.COURSE]: '/assets/images/course_placeholder.svg',
    [BundleType.MODULE]: '/assets/images/module_placeholder.svg',
    [BundleType.LESSON]: '/assets/images/lesson_placeholder.svg',
    [BundleType.QUIZ]: '/assets/images/quiz_placeholder.svg',
    [BundleType.POLL_QUIZ]: '/assets/images/quiz_placeholder.svg',
    [BundleType.ASSIGNMENT]: '/assets/images/quiz_placeholder.svg',
  }

  @Input() set entityPlaceholder(bundle: BundleType) {
    this.setPlaceholderBackground(bundle);
  }

  constructor(
    private readonly el: ElementRef
  ) { }

  private setPlaceholderBackground(bundle: BundleType): void {
    if (!bundle) {
      return;
    }
  
    this.el.nativeElement.style.backgroundImage = `url(${this.bgMap[bundle]})`;
  }

}
