import { Component, Input, OnInit } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { FieldName, NodeModel } from 'library-explorer';
import { PayableNodeNavigationService } from 'src/app/services/payable-node-navigation.service';
import { PaymentService } from 'src/app/services/payment.service';
import { LanguageService } from '@app/services/language.service';

@Component({
  selector: 'app-discover-content-block',
  templateUrl: './discover-content-block.component.html',
  styleUrls: ['./discover-content-block.component.scss']
})
export class DiscoverContentBlockComponent implements OnInit {

  @Input() block: any;

  public mousePosition: any = {};
  public isFirstSlide: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(true);
  public isLastSlide: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  public carouselConfig = {
    lazyLoad: 'ondemand',
    infinite: false,
    touchMove: true,
    swipeToSlide: true,
    variableWidth: true,
    arrows: false,
    autoplay: false,
    centerMode: false,
    slidesToShow: 4,
    rtl: false,
    responsive: [
      {
        breakpoint: 1440,
        settings: {
          slidesToShow: 3,
        }
      },
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 2,
        }
      },
      {
        breakpoint: 670,
        settings: {
          slidesToShow: 1,
        }
      }
    ]
  };

  public showCarouselBtns = false;
  public fieldNames: typeof FieldName = FieldName;
  public paymentEnabled = false;

  constructor(
    private readonly payableNodeNavigationService: PayableNodeNavigationService,
    private readonly paymentService: PaymentService,
    private readonly languageService: LanguageService
  ) { }

  ngOnInit() {
    this.paymentService.getPaymentConfig().subscribe(res => {
      this.paymentEnabled = !!res.enabled;
    });

    const isRtl = this.languageService.isRtl();
    if (isRtl) {
      this.carouselConfig.rtl = true;
    }
  }

  public breakpoint(event) {
    const slidesToShow = event.slick.slickGetOption('slidesToShow');
    const slideCount = event.slick.slideCount;

    this.showCarouselBtns = slidesToShow < slideCount;
  }

  public afterChange(event): void {
    setTimeout(() => {
      this.isFirstSlide.next(event.slick.currentSlide === 0);
      this.isLastSlide.next(event.slick.currentSlide === event.slick.slideCount - 1);

      const slidesToShow = event.slick.slickGetOption('slidesToShow');
      const slideCount = event.slick.slideCount;

      this.showCarouselBtns = slidesToShow < slideCount;
    });
  }

  public slickInit(event): void {
    this.afterChange(event);
  }

  public navigate(event: MouseEvent, item: NodeModel): void {
    this.payableNodeNavigationService.navigate(item, event, this.mousePosition);
  }

  public onMouseDown(event): void {
    this.mousePosition.x = event.screenX;
    this.mousePosition.y = event.screenY;
  }

}

