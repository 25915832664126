export const interceptorsToken = {
  HANDLE_PERMISION_DENIED: 'handlePermissionDeniedResponses',
  SKIP_TOAST_ERROR_HANDLERS: 'skipToastNotificationsErrorHandlers',
  HANDLE_PERMISSION_DENIED_PARAM: 'handlePermissionDeniedResponses',
  ENABLE_MAINTENANCE_IN_CASE_SERVER_ERROR_PARAM: 'enableMaintenanceModeInCaseServerError',
  SKIP_MAINTENANCE_CHECK: 'skipMaintenanceChecking',
  SKIP_TOKEN: 'skipToken',
  SKIP_OFFLINE_REQUESTS_HANDLER: 'LLAB-Skip-Offline-Check',
  SKIP_LOADING_TOKEN_HANDLER: 'LLAB-Skip-Loading-Token-Check'
}
