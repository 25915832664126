import { HttpParams } from '@angular/common/http';
import { Component, ElementRef, Inject, Input, OnInit } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { BasicPage } from '@app/model/basic-page.model';
import { DataResponse } from '@app/model/data-response.model';
import { LoginType } from '@app/model/enums/login-type.enum';

import { LearningPathModel } from '@app/model/learning-path.model';
import { LearningPathService } from '@app/services/api/learning-path.service';
import { AuthService } from '@app/services/auth.service';
import { LanguageSelectDialogService } from '@app/services/language-select-dialog.service';
import { SettingsService } from '@app/services/settings.service';

import { SidebarSharedService } from '@app/services/sidebar-shared.service';
import { 
  ChatTypeEnum, 
  HttpService, 
  LanguageStorageService, 
  OfflineModeService, 
  ProfileService, 
  REGEX_LIST,
  SectionStatus, 
  SettingsModel, 
  ColorHelperService,
  NavigationMenuItem,
  SessionDTO
} from 'library-explorer';
import { forkJoin } from 'rxjs';
import { filter, tap } from 'rxjs/operators';

@Component({
  selector: 'app-sidebar-menu',
  templateUrl: './sidebar-menu.component.html',
  styleUrls: ['./sidebar-menu.component.scss']
})
export class SidebarMenuComponent implements OnInit {
  @Input() public currentUser: SessionDTO;
  @Input() public set isLoggedIn(value: boolean) {
    if (value === this._isLoggedIn && this.isInitialized) {
      return;
    }

    this._isLoggedIn = value;

    if (value) {
      this.loadData();
      
      if (!this.isMobile) {
        this.changeOpenState(true);
      }

      return;
    }

    this.loadSettingsOnly();
  }

  public get isLoggedIn() {
    return this._isLoggedIn;
  }

  public opened = false;

  public darkTheme = false;

  public textColor: string;
  public backgroundColor: string;

  public menuItems = [];

  public isInitialized = false;

  public supportUrl: string;

  public chatEnabled = false;

  public showSearchLink = false;
  public registrationEnabled = false;

  private get isMobile() {
    return window.matchMedia('(max-width: 991px)').matches;;
  }

  private _isLoggedIn: boolean;

  constructor(
    private readonly router: Router,
    private readonly authService: AuthService,
    private readonly learningPathService: LearningPathService,
    private readonly sharedSidebarService: SidebarSharedService,
    private readonly profileService: ProfileService,
    private readonly languageSelectDialogService: LanguageSelectDialogService,
    private readonly languageStorageService: LanguageStorageService,
    @Inject(REGEX_LIST) public regexList: any,
    private readonly element: ElementRef,
    private readonly colorHelperService: ColorHelperService,
    private readonly httpService: HttpService,
    private readonly settingsService: SettingsService,
    private readonly offlineModeService: OfflineModeService) { }

  ngOnInit(): void {
    this.sharedSidebarService.navigationSidebarOpened
      .subscribe(data => {
        this.opened = data;

        if (this.opened && this.isMobile) {
          this.scrollToTop();
        }
      });
  }

  public closeMenuIfMobile(): void {
    if (!this.isMobile) {
      return;
    }

    if (!this.opened) {
      return;
    }

    this.changeOpenState(false);
  }

  public logout() {
    this.authService.logout();
  }

  public changeOpenState(value = !this.opened): void {
    this.opened = value;

    this.sharedSidebarService.mainSidebarTrigger(value);
  }

  public openLanguageDialog(event: Event): void {
    if (!this.isMobile) {
      return;
    }

    event.preventDefault();
    event.stopPropagation();

    const isAdmin = this.profileService.isCurrentUserIsAdmin();

    const languages = this.languageStorageService.getLanguages().filter(item => isAdmin || item.enabled);
    const contentLanguages = this.languageStorageService.getContentLanguages().filter(item => isAdmin || item.enabled);
    this.languageSelectDialogService.open(null, languages, contentLanguages);
  }

  private isEmail(url: string): RegExpMatchArray {
    return url.match(this.regexList.email);
  }

  private loadData(): void {
    const params = new HttpParams().set('menuName', 'header');
    const userId = this.profileService.getCurrentProfileValue()?.id;;

    forkJoin([
      this.httpService.get<DataResponse<BasicPage>>('menu-items', params),
      this.settingsService.getSettings(),
      this.learningPathService.getLearningPathsForUser(userId),
    ]).pipe(
      tap(([data, _settings, _learingPaths]) => this.offlineModeService.storeRequest(`menu-items?${params.toString()}`, data))
    ).subscribe(([data, settings, learingPaths]) => {
      this.initializeSettings(settings);
      this.setMenuItems(settings, data.items, learingPaths.items);

      this.chatEnabled = settings.chat.chatType == ChatTypeEnum.INTERNAL_MESSAGING;
      this.showSearchLink = settings.header.showSearchMenu;

      this.isInitialized = true;
    });
  }

  private loadSettingsOnly(): void {
    this.settingsService.getSettings().subscribe(settings => {
      this.initializeSettings(settings);
      this.isInitialized = true;
    })
  }

  private initializeSettings(settings: SettingsModel): void {
    this.initializeColors(settings);
    this.registrationEnabled = this.settingsService.isRegistrationEnabled(settings);
  }

  private initializeColors(settings: SettingsModel): void {
    const { backgroundColor, textColor } = settings.colors.sidebarMenu || {};
    this.darkTheme = this.colorHelperService.isColorDark(backgroundColor);

    if (backgroundColor) {
      this.element.nativeElement.style.setProperty('--lms-sidebar-bg-color', backgroundColor);
    }

    if (textColor) {
      this.element.nativeElement.style.setProperty('--lms-sidebar-text-color', textColor);
    }
  }

  private setMenuItems(settings: SettingsModel, additionalMenuItems: BasicPage[] = [], learingPaths: LearningPathModel[] = []): void {
    const showSitemapMenu = settings.header.showSitemapMenu !== false;
    const showLibraryMenu = settings.header.showLibraryMenu !== false;
    const showWebinarMenu = settings.webinars?.status === SectionStatus.VISIBLE || settings.webinars?.status === SectionStatus.COMING_SOON_PAGE;
    const showMyAchievementsMenu = settings?.achievements.enabled;
    const showLeaderboardMenu = settings.leaderboard && !!settings.leaderboard.type;
    const showForumMenu = settings.header.showForumMenu === true && settings.forum?.enabled;
    const showCalendarMenu = settings.learnersCalendar?.status === SectionStatus.VISIBLE;

    const supportSettings = settings.legal.support;

    if (supportSettings?.enabled && supportSettings?.url) {
      this.supportUrl = this.isEmail(supportSettings.url) ? `mailto:${supportSettings.url}` : supportSettings.url;
    }

    const catalogMenuItem = {
      title: 'MENU.courses',
      path: '/classes',
      icon: 'book-open-text',
      availableOffline: true
    };

    const items: NavigationMenuItem[] = [
      learingPaths?.length && {
        title: 'LEARNING_PATH.learning_paths',
        path: '/learning-paths',
        icon: 'path'
      },
      catalogMenuItem,
      showWebinarMenu && {
        title: 'MENU.webinars',
        path: '/webinars',
        icon: 'monitor-play',
        pageId: settings.webinars?.status === SectionStatus.COMING_SOON_PAGE && settings.webinars?.comingSoonPageId
      },
      showCalendarMenu && {
        title: 'MENU.calendar',
        path: '/calendar',
        icon: 'calendar',
      },
      showLibraryMenu && {
        title: 'MENU.library',
        path: '/library',
        icon: 'books'
      },
      showForumMenu && {
        title: 'MENU.community',
        path: '/forum',
        icon: 'chats'
      },
      showSitemapMenu && {
        title: 'MENU.sitemap',
        path: '/sitemap',
        icon: 'tree-structure'
      },
      showMyAchievementsMenu && {
        title: 'ACHIEVEMENTS.my_achievements',
        path: '/my-achievements',
        icon: 'graduation-cap'
      },
      showLeaderboardMenu && {
        title: 'LEADERBOARD.leaderboard',
        path: '/leaderboard',
        icon: 'trophy'
      },
    ];

    items.push(...additionalMenuItems.map(item => ({
      title: item.title,
      path: item.url || item.alias,
      iconUrl: item.additionalFields && item.additionalFields.svgIcon?.url
    })));

    this.menuItems = items.filter(item => item);
  
    const catalogUrls = ['/classes', '/courses', '/modules', '/lessons', '/chapters'];
    const catalogActive = catalogUrls.some(url => this.router.url.indexOf(url) === 0);
    this.setActiveStateForMenuItem(catalogMenuItem, catalogActive);

    this.router.events
      .pipe(
        filter(event => event instanceof NavigationEnd),
      )
      .subscribe((event: NavigationEnd) => {
        const catalogActive = catalogUrls.some(url => event.url.indexOf(url) === 0);
        this.setActiveStateForMenuItem(catalogMenuItem, catalogActive);
      });
  }

  private setActiveStateForMenuItem(item: NavigationMenuItem, active = false): void {
    item.cssClass = active ? 'active' : null;
  }

  private scrollToTop(): void {
    setTimeout(() => {
      this.element.nativeElement.getElementsByClassName('sidebar-navigation')[0]?.scrollTo({ top: 0 });
    })
  }


}
