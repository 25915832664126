import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { ChatChannel } from '@app/model/chat-channel';
import { SendbirdService } from '@app/services/sendbird.service';
import { SettingsService } from '@app/services/settings.service';
import { ImageModel, ProfileService } from 'library-explorer';
import { NgScrollbar } from 'ngx-scrollbar';
import { Subject, Subscription } from 'rxjs';
import { delay, filter, map, takeUntil, tap } from 'rxjs/operators';

@Component({
  selector: 'app-chat-channel-messages-list',
  templateUrl: './chat-channel-messages-list.component.html',
  styleUrls: ['./chat-channel-messages-list.component.scss']
})
export class ChatChannelMessagesListComponent implements OnInit {
  @Output() public edit: EventEmitter<SendBird.UserMessage> = new EventEmitter();
  @Output() public markAsRead: EventEmitter<void> = new EventEmitter();
  @Input() public set groupChannel(groupChannel: ChatChannel) {
    this._groupChannel = groupChannel;
    this.initializeChannel();
  }

  public get groupChannel() {
    return this._groupChannel;
  }

  @ViewChild(NgScrollbar, { static: false }) public set scrollbarRef(value: NgScrollbar) {
    if (value && value !== this._scrollbarRef) {
      this._scrollbarRef = value;
      this.addScrollbarSubscription();
    }
  }

  public get scrollbarRef() {
    return this._scrollbarRef;
  }

  public messages: Array<SendBird.UserMessage | SendBird.FileMessage | SendBird.AdminMessage> = [];

  public currentUserId: string;

  public canAdministerChannel = false;

  public isLoading = true;

  public globalChatLogo: ImageModel;

  public hasScroll = false;

  private scrollPosition = 0;
  private scrollSub: Subscription;
  private unsubscribe: Subject<void> = new Subject();
  private messagesQuery: SendBird.PreviousMessageListQuery;

  private _scrollbarRef: NgScrollbar;

  public _groupChannel: ChatChannel;

  public loadingIndicator = false;

  constructor(
    private readonly settingsService: SettingsService,
    private readonly profileService: ProfileService,
    private readonly sendbirdService: SendbirdService) { }

  ngOnInit() {
    this.currentUserId = this.profileService.getCurrentProfileValue()?.id;
    this.canAdministerChannel = this.profileService.isCurrentUserIsAdmin();

    this.addChannelSubscriptions();

    this.settingsService.getSettings()
      .subscribe(data => this.globalChatLogo = data.general.platformLogo);
  }

  ngOnDestroy(): void {
    this.unsubscribe.next();
    this.unsubscribe.complete();
  }

  public deleteMessage(item: SendBird.UserMessage): void {
    this.sendbirdService.deleteMessage(this.groupChannel, item);
  }

  public editMessage(item: SendBird.UserMessage): void {
    this.edit.emit(item);
  }

  public scrollUpdated() {
    if (!this.scrollbarRef) {
      return;
    }
  
    this.scrollbarRef.scrollTo({ bottom: this.scrollPosition, duration: 0 });
    this.hasScroll = this.scrollbarRef.state.isVerticallyScrollable;
  }

  public closeChannel(): void {
    this.sendbirdService.activeChannel.next(null);
    this.sendbirdService.closeChatDialog();
  }

  public scrollToBottom(): void {
    setTimeout(() => {
      this.scrollbarRef.scrollTo({ bottom: 0, duration: 0 });
      this.scrollbarRef.update();
    }, 50);
  }

  private addChannelSubscriptions(): void {
    this.sendbirdService.loadingIndicatorId
      .subscribe(url => this.loadingIndicator = url && url === this.groupChannel?.url);

    this.sendbirdService.messageReceived
      .pipe(
        takeUntil(this.unsubscribe),
        filter(data => data.channelUrl === this.groupChannel?.url)
      )
      .subscribe(data => {
        this.messages.push(data.message);

        this.markAsRead.emit();
      });

    this.sendbirdService.messageDeleted
      .pipe(
        takeUntil(this.unsubscribe),
        filter(data => data.channelUrl === this.groupChannel.url),
        map(data => this.messages.findIndex(message => message.messageId === data.messageId)),
        filter(index => index !== -1)
      )
      .subscribe(index => {
        this.messages.splice(index, 1);
      });

    this.sendbirdService.messageUpdated
      .pipe(
        takeUntil(this.unsubscribe),
        filter(data => data.channelUrl === this.groupChannel.url)
      )
      .subscribe(data => {
        const index = this.messages.findIndex(message => message.messageId === data.message.messageId);

        if (index !== -1) {
          this.messages.splice(index, 1, data.message);
        }
      });

    this.sendbirdService.messageSent
      .pipe(
        takeUntil(this.unsubscribe),
        delay(200)
      ).subscribe(() => {
        this.scrollbarRef.scrollTo({ bottom: 0 })
      });
  }

  private addScrollbarSubscription(): void {
    if (this.scrollSub) {
      this.scrollSub.unsubscribe();
    }

    this.scrollSub = this.scrollbarRef.scrolled
      .pipe(
        takeUntil(this.unsubscribe),
        tap(event => {
          const targetElement = event.target as HTMLElement;
          this.scrollPosition = targetElement.scrollHeight - targetElement.offsetHeight - targetElement.scrollTop
        }),
        filter(event => {
          const targetElement = event.target as HTMLElement;
          return !this.isLoading && this.messagesQuery && this.messagesQuery.hasMore && targetElement.scrollTop < 100
        })
      )
      .subscribe(() => {
        this.loadMessages();
      });
  }

  private initializeChannel(): void {
    this.messages = [];
    this.scrollPosition = 0;

    this.markAsRead.emit();
    this.messagesQuery = this.sendbirdService.getPreviousMessagesQuery(this.groupChannel);

    this.loadMessages();
  }

  private loadMessages(): void {
    if (this.messagesQuery.hasMore) {
      this.isLoading = true;

      this.messagesQuery.load(data => {
        if (data && data.length) {
          this.messages.unshift(...data);
        }

        this.isLoading = false;
      });
    }
  }
}
