<div class="library-card" [class.folder]="item.type === libraryTypes.FOLDER" (click)="openLibraryItem()" [attr.data-cy]="'library-item-' + item.id">
  <div 
    class="library-card-preview" 
    [cropImage]="previewImage | preSignedUrl | async"
    [imageWidth]="400">

    <ng-container *ngIf="!previewImage">
      <lib-library-item-icon class="library-icon" [item]="item"></lib-library-item-icon>
    </ng-container>

    <div class="library-card-pinned-indicator" [class.pinned]="item.userPin" (click)="togglePinned($event)" *ngIf="enablePinned">
      <lib-icon icon="push-pin" [size]="16" [fill]="item.userPin"></lib-icon>
    </div>
  </div>

  <div class="library-card-footer">
    <div class="library-card-title-container">
      <h6 class="library-card-title">
        {{ item.name }}
      </h6>

      <ng-container *ngIf="contextTemplate">
        <button 
          mat-icon-button 
          class="button button--icon button--icon-extra-small library-card-context-button" 
          (click)="stopPropagation($event)" 
          [matMenuTriggerFor]="matMenu"
          data-cy="library-card-context-menu">
          <lib-icon class="context-menu-icon" icon="dots-three-vertical" [bold]="true" [size]="16"></lib-icon>
        </button>
  
        <mat-menu class="lib-panel-menu" #matMenu="matMenu">
          <ng-container *ngTemplateOutlet="contextTemplate; context: { item: item }"></ng-container>
        </mat-menu>
      </ng-container>
    </div>

    <div class="library-card-info">
      <div class="library-card-info__item library-card-info__parent" *ngIf="item.parent as parentFolder">
        <lib-icon [size]="16" icon="folder-notch-open"></lib-icon>

        <span class="parent-folder" ellipsis [ellipsis-content]="parentFolder.title"></span>
      </div>


      <div class="library-card-info__item" *ngIf="item.type === libraryTypes.FOLDER">
        {{ 'LIBRARY_PAGE.folder_files' | translate : { count: item.childrenAmount } }}
      </div>

      <div class="library-card-info__item">
        <lib-icon [size]="16" icon="clock-clockwise"></lib-icon>
        {{ item.updated | date: 'mediumDate' }}
      </div>
    </div>
  </div>
</div>
