import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

@Component({
  selector: 'app-load-more-btn',
  templateUrl: './load-more-btn.component.html',
  styleUrls: ['./load-more-btn.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class LoadMoreBtnComponent {
  @Input() loading: boolean;
  @Input() size: 'default'|'large' = 'default';
}
