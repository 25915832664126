import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { AuthService } from '@app/services/auth.service';
import { LayoutService } from '@app/services/layout.service';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { BasicPage } from 'src/app/model/basic-page.model';
import { PageBlockType } from 'src/app/model/enums/page-block-type.enum';

@Component({
  selector: 'app-block-list',
  templateUrl: './block-list.component.html',
  styleUrls: ['./block-list.component.scss']
})
export class BlockListComponent implements OnInit, OnDestroy {

  @Input() set page(value: BasicPage) {
    this._page = value;
    this.filterPageBlocks();
  }

  public get page() {
    return this._page;
  }

  public isLoggedIn = false;

  public pageBlocks = [];

  public readonly blockTypes: typeof PageBlockType = PageBlockType;

  private readonly loggedInBlocks = [
    PageBlockType.BLOCK_DASHBOARD_HIGHLIGHTS, PageBlockType.BLOCK_PROMOTION, PageBlockType.BLOCK_WEBINARS
  ]

  private unsubscribe: Subject<void> = new Subject();

  private _page: BasicPage;

  constructor(
    private readonly authService: AuthService,
    private readonly layoutService: LayoutService
  ) { }

  ngOnInit() {
    this.layoutService.removeTopPaddingOnPhone();
    this.authService.isAuthorized()
      .pipe(
        takeUntil(this.unsubscribe)
      )
      .subscribe(res => {
        this.isLoggedIn = !!res;
        this.filterPageBlocks();
      });
  }

  ngOnDestroy() {
    this.layoutService.resetTopPaddingOnPhone();
    this.unsubscribe.next();
    this.unsubscribe.complete();
  }

  private filterPageBlocks(): void {
    if (!this._page?.blocks?.items) {
      this.pageBlocks = [];
      return;
    }

    if (this.isLoggedIn) {
      this.pageBlocks = [...this._page.blocks?.items] || [];
      return;
    }

    this.pageBlocks = [
      ...this._page.blocks?.items.filter(item => this.loggedInBlocks.indexOf(item.bundle) === -1)
    ];
  }

}

