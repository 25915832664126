<section class="testimonials" *ngIf="block" [style.color]="block.textColor">
  <div class="content block-container">
    <h1 *ngIf="block.title" [innerHtml]="block.title | safeHtml" [rtl]="block.langcode"></h1>
    <ngx-slick-carousel
        slickCarouselResize
        class="carousel" 
        #slickModal="slick-carousel"
        (init)="slickInit($event)"
        (afterChange)="afterChange($event)"
        (breakpoint)="breakpoint($event)"
        [config]="carouselConfig">
      <div ngxSlickItem *ngFor="let slide of block.items" class="slide">
        <div class="testimonial-item">
          <div class="image" [cropImage]="slide.mediaImage | preSignedUrl | async" [additionalOptions]="{width: 100}"></div>
          <div class="text-wrapper">
            <div class="description ck-text" [innerHtml]="slide.text | safeHtml" [rtl]="slide.langcode"></div>
            <div class="name" [class.primary-color]="!block.textColor" *ngIf="slide.author">- {{slide.author}}</div>
          </div>
        </div>
      </div>
    </ngx-slick-carousel>
    <div class="d-none d-lg-flex carousel-buttons-wrapper" *ngIf="showCarouselBtns">
      <button color="primary" [style.color]="block.textColor" [style.border-color]="block.textColor" mat-fab class="carousel-button left" [class.disabled]="isFirstSlide | async" (click)="slickModal.slickPrev()">
        <mat-icon>navigate_before</mat-icon>
      </button>
      <button color="primary" [style.color]="block.textColor" [style.border-color]="block.textColor" mat-fab class="carousel-button right" [class.disabled]="isLastSlide | async" (click)="slickModal.slickNext()">
        <mat-icon>navigate_next</mat-icon>
      </button>
    </div>
  </div>
</section>