<div class="item-list-wrapper">
  <ng-container *ngIf="!isLoading">
    <ng-scrollbar class="d-none d-md-block" thumbClass="custom-scrollbars" appearance="standard">
      <div class="item-list" [@fadeAnimation]="itemList?.length">
        <app-dashboard-item
          *ngFor="let item of itemList" 
          [item]="item" 
          [paymentEnabled]="paymentEnabled">
        </app-dashboard-item>
      </div>
    </ng-scrollbar>
    <div class="d-block d-md-none item-list overflow-hidden">
      <app-carousel 
        type="dashboard-item"
        [items]="itemList"
        [config]="carouselConfig"></app-carousel>
    </div>
  </ng-container>
  <mat-spinner [diameter]="40" *ngIf="isLoading"></mat-spinner>
</div>