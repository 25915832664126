import {
  HttpEvent, HttpHandler, HttpInterceptor, HttpRequest
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { ProfileService, UserRole } from 'library-explorer';

@Injectable()
export class ContentVisibilityInterceptor implements HttpInterceptor {

  constructor(private readonly profileService: ProfileService) { }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    const manageContentRoles = [UserRole.ADMIN, UserRole.CONTENT_MANAGER, UserRole.GENERAL_MANAGER];
    const profileRole = this.profileService.getCurrentProfileValue()?.role;
    const canManageContent = manageContentRoles.indexOf(profileRole) !== -1;

    if (canManageContent) {
      request = request.clone({
        params: request.params.delete('active')
      });
    }

    return next.handle(request);
  }
}
