import { Directive, ElementRef, Input, OnInit } from '@angular/core';
import Panzoom, { PanzoomObject } from '@panzoom/panzoom';
import { fromEvent } from 'rxjs';

@Directive({
  selector: '[panzoom]'
})
export class PanzoomDirective implements OnInit {
  @Input() public mobileOnly = true;

  private panzoom: PanzoomObject;

  constructor(
    private readonly elementRef: ElementRef) {
  }

  ngOnInit(): void {
    const isPhone = window.matchMedia('(max-width: 768px)').matches;

    if (!isPhone && this.mobileOnly) {
      return;
    }

    this.panzoom = Panzoom(this.elementRef.nativeElement, {
      maxScale: 10,
      minScale: 1,
      panOnlyWhenZoomed: true,
      contain: 'outside',
      duration: 100,
      step: 1
    });
    this.addEventListeners();
  }


  private addEventListeners(): void {
    fromEvent(this.elementRef.nativeElement, 'dblclick')
      .subscribe(() => {
        this.panzoom?.zoom(2, { animate: true })
      });
  }

}
