<div class="audio-player-container" [class.compact]="compact" [style.background-color]="backgroundColor" [style.color]="textColor">
  <ng-container *ngIf="title && showPlayer; then titleTmp"></ng-container>

  <div class="audio-player__wrapper" [class.player-inited]="!firstStart">
    <ng-container *ngIf="firstStart; then startButtonTmp; else playbackControlsTmp"></ng-container>
    <ng-container *ngIf="title && !showPlayer; then titleTmp"></ng-container>
    <ng-container *ngIf="!firstStart; then playbackRateTmp"></ng-container>
    
    <div #waveform class="audio-wave" [class.init]="init" [class.hidden]="!showPlayer">
    </div>

    <ng-container *ngTemplateOutlet="durationTmp"></ng-container>
    <ng-container *ngIf="!firstStart; then volumeControlTmp"></ng-container>

    <div class="audio-player__wrapper--phone">
      <div class="audio-player__wrapper--phone-section">
        <ng-container *ngIf="firstStart; then startButtonTmp; else playbackControlsTmp"></ng-container>
        <ng-container *ngIf="!firstStart; then playbackRateTmp"></ng-container>
      </div>
      <div class="audio-player__wrapper--phone-section">
        <ng-container *ngTemplateOutlet="durationTmp"></ng-container>
        <ng-container *ngIf="!firstStart; then volumeControlTmp"></ng-container>
      </div>
    </div>
  </div>
</div>

<ng-template #startButtonTmp>
  <div class="audio-player__start-button" [class.blue]="!showPlayer && !textColor" [style.background-color]="textColor ? 'transparent' : null" (click)="togglePlay()">
    <lib-icon [icon]="isPlaying ? 'pause' : 'play'"></lib-icon>
  </div>
</ng-template>

<ng-template #playbackControlsTmp>
  <div class="audio-player__playback-controls">
    <div *ngIf="!preventTimeSeek" class="audio-player__playback-controls-button" (click)="rewind()">
      <lib-icon icon="rewind"></lib-icon>
    </div>
    <div class="audio-player__playback-controls-button" (click)="togglePlay()">
      <lib-icon [icon]="isPlaying ? 'pause' : 'play'"></lib-icon>
    </div>
    <div *ngIf="!preventTimeSeek" class="audio-player__playback-controls-button" (click)="fastForward()">
      <lib-icon icon="fast-forward"></lib-icon>
    </div>
  </div>
</ng-template>

<ng-template #volumeControlTmp>
  <div class="audio-player__volume-control">
    <div class="audio-player__playback-controls-button" (click)="mute()">
      <lib-icon [icon]="isMuted ? 'speaker-simple-slash' : (volume > 50 ? 'speaker-simple-high' : 'speaker-simple-low')"></lib-icon>
    </div>
    <div class="audio-player__volume-control--popup">
      <mat-slider [vertical]="true" [min]="0" [max]="100" [step]="1" [value]="volume" (change)="setVolume($event)"></mat-slider>
    </div>
  </div>
</ng-template>

<ng-template #playbackRateTmp>
  <div class="audio-player__playback-rate" (click)="togglePlaybackRate()">
    {{playbackRate}}X
  </div>
</ng-template>

<ng-template #durationTmp>
  <div class="audio-player__duration">{{remainingTimeString}}</div>
</ng-template>

<ng-template #titleTmp>
  <div class="audio-player__title">
    {{title}}
  </div>
</ng-template>
