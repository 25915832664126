export enum PageBlockType {
  BLOCK_FEATURE_HIGHLIGHT = 'block_feature_highlight',
  BLOCK_DISOVER_CONTENT = 'block_discover_content',
  BLOCK_FULL_SCREEN_BANNER = 'block_full_screen_banner',
  BLOCK_TESTIMONIALS = 'block_testimonials',
  BLOCK_VIDEO = 'block_video',
  BLOCK_IMAGE_TEXT = 'block_image_and_text',
  BLOCK_LOGIN_PROMO = 'block_login_promo',
  FEATURE_HIGHLIGHT_ITEM = 'feature_highlight_item',
  BLOCK_DASHBOARD_HIGHLIGHTS = 'block_dashboard_highlights',
  BLOCK_CONTENT_HIGHTLIGHT = 'block_content_highlight',
  BLOCK_CAROUSEL = 'block_carousel',
  BLOCK_PROMOTION = 'block_promotion',
  BLOCK_LEARNING_PATH = 'block_learning_paths_list',
  BLOCK_WEBINARS = 'block_webinars'
}
