<ng-select 
  class="ng-select-advanced-dialog" 
  [ngClass]="cssClass"
  [formControl]="selectControl" 
  [multiple]="multiple"
  [closeOnSelect]="!multiple"
  [items]="options" 
  [searchable]="false" 
  [bindLabel]="bindLabel" 
  [bindValue]="bindValue"
  [placeholder]="placeholder"
  [clearable]="clearable"
  [appendTo]="appendTo"
  [dropdownPosition]="dropdownPosition"
  (open)="handleOpen()"
  (close)="selectClosed()">

  <ng-container *ngIf="searchable">
    <ng-template ng-header-tmp>
      <input 
        type="text" 
        class="input" 
        [formControl]="searchControl"
        placeholder="{{'COMMON.search' | translate | titlecase }}">
    </ng-template>
  </ng-container>

  <ng-container *ngIf="multiple">
    <ng-template ng-option-tmp let-item="item" let-index="index">
      <div class="d-flex align-items-center">
        <mat-checkbox class="ng-option-checkbox" color="primary" [checked]="selectControl.value && selectControl.value?.indexOf(item[bindValue] || item) !== -1">
          {{ item[bindLabel] || item }}
        </mat-checkbox>
      </div>
    </ng-template>

    <ng-container *ngIf="showAddNewButton">
      <ng-template ng-footer-tmp>
        <button mat-flat-button class="ng-footer-button" (click)="addTag()">
          {{ 'FORUM.add_tag' | translate : { tag: searchControl?.value } }} 
        </button>
      </ng-template>
    </ng-container>

    <ng-template ng-multi-label-tmp let-items="items" let-clear="clear">
      <div class="ng-value" *ngFor="let item of items | slice:0:2">
        <span class="ng-value-icon left" (click)="clear(item)" aria-hidden="true">×</span>
        <span class="ng-value-label">
          {{ item[bindLabel] || item }}
        </span>
      </div>
  
      <div class="ng-more-options" *ngIf="items.length > 2">
          <span>+{{items.length -  2}}</span>
      </div>
    </ng-template>
  </ng-container>
</ng-select>
