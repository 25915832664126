import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Language } from 'library-explorer';
import { LanguageSelectDialogService } from '@app/services/language-select-dialog.service';
import { LanguageService } from '@app/services/language.service';
import { LangChangeEvent, TranslateService } from '@ngx-translate/core';
import { Subject } from 'rxjs';
import { filter, map, startWith, takeUntil, tap } from 'rxjs/operators';

@Component({
  selector: 'app-language-select-dialog',
  templateUrl: './language-select-dialog.component.html',
  styleUrls: ['./language-select-dialog.component.scss']
})
export class LanguageSelectDialogComponent implements OnInit {

  public platformLanguage: Language;
  public contentLanguage: Language;
  public isContentLanguageLoading = true;
  public isContentLanguageSwitchLoading = false;

  private defaultLanguage: Language = { code: 'en', name: 'English' };

  private unsubscribe: Subject<void> = new Subject();

  constructor(
    private readonly translateService: TranslateService,
    private readonly languageService: LanguageService,
    private readonly languageSelectDialogService: LanguageSelectDialogService,
    private readonly dialogRef: MatDialogRef<LanguageSelectDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public languages: { platform: Language[], content: Language[] }
  ) { }

  ngOnInit(): void {
    this.languageSelectDialogService.getContentLanguage().pipe(
      takeUntil(this.unsubscribe),
      filter((res) => res !== undefined),
      map(lang => this.languages.content.find(item => item.code === lang) || this.defaultLanguage),
      tap(() => this.isContentLanguageLoading = false)
    ).subscribe(lang => {
      this.contentLanguage = lang;
    });

    this.translateService.onLangChange.pipe(
      startWith({lang: this.translateService.currentLang} as LangChangeEvent),
      takeUntil(this.unsubscribe)
    ).subscribe((langEvent: LangChangeEvent) => {
      this.platformLanguage = this.languages.platform.find(item => item.code === langEvent.lang)
    });
  }

  public onOutsideClick(): void {
    this.dialogRef.close();
  }

  public changePlatformLanguage(language: string): void {
    if (this.platformLanguage.code === language) {
      return;
    }

    this.languageService.updateUserInterfaceLanguage(language);
    this.dialogRef.close();
  }

  public changeContentLanguage(language: string): void {
    if (language === this.contentLanguage?.code) {
      return;
    }

    this.isContentLanguageSwitchLoading = true;
    this.languageService.updateUserContentLangauge(language);
  }

  ngOnDestroy() {
    this.unsubscribe.next();
    this.unsubscribe.complete();
  }
}
