import { Directive, HostListener, Input } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { ToastrService } from 'ngx-toastr';

@Directive({
  selector: '[appDisableCopyPaste]'
})
export class DisableCopyPasteDirective {
  @Input() public pasteWarningMsg: string;

  constructor(
    private readonly toastrService: ToastrService
  ) { }

  @HostListener('paste', ['$event']) public blockPaste(e: KeyboardEvent) {
    if (this.pasteWarningMsg) {
      this.toastrService.warning(this.pasteWarningMsg);
    }
    e.preventDefault();
  }

  @HostListener('copy', ['$event']) public blockCopy(e: KeyboardEvent) {
    e.preventDefault();
  }

  @HostListener('cut', ['$event']) public blockCut(e: KeyboardEvent) {
    e.preventDefault();
  }
}
