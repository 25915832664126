import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class ColorHelperService {
  public isColorDark(color: string): boolean {
    if (!color) {
      return false;
    }

    return this.getColorLuminance(color) < 200;
  }

  /*
  * Get the luminance (0..255) of an hex color
  * Source: https://stackoverflow.com/questions/12043187/how-to-check-if-hex-color-is-too-black
  */
  public getColorLuminance(color: string): number {
    /* tslint:disable */
    if (!color) { return; }
    const c = color.substring(1);  // strip #
    const rgb = parseInt(c, 16);   // convert rrggbb to decimal
    const r = (rgb >> 16) & 0xff;  // extract red
    const g = (rgb >>  8) & 0xff;  // extract green
    const b = (rgb >>  0) & 0xff;  // extract blue

    return 0.2126 * r + 0.7152 * g + 0.0722 * b; // per ITU-R BT.709
    /* tslint:enable */
  }
}
