import { Pipe, PipeTransform } from '@angular/core';
import { BundleType } from 'library-explorer';

@Pipe({
  name: 'entityBundleIcon'
})
export class EntityBundleIconPipe implements PipeTransform {

  transform(value: BundleType): string {
    if (!value) {
      return '';
    }

    switch (value) {
      case BundleType.QUIZ:
        return 'game-controller';
      case BundleType.LESSON:
      default:
        return 'book-open-text';
    }
  }

}
