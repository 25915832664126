import { Component, Input, OnInit } from '@angular/core';
import { LanguageService } from '@app/services/language.service';
import { BehaviorSubject } from 'rxjs';

@Component({
  selector: 'app-testimonials',
  templateUrl: './testimonials.component.html',
  styleUrls: ['./testimonials.component.scss']
})
export class TestimonialsComponent implements OnInit {

  @Input() block: any;

  public isFirstSlide: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(true);
  public isLastSlide: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

  public showCarouselBtns = false;

  public carouselConfig = {
    lazyLoad: 'ondemand',
    infinite: false,
    touchMove: true,
    swipeToSlide: true,
    variableWidth: true,
    arrows: false,
    autoplay: false,
    centerMode: true,
    slidesToShow: 1,
    rtl: false
  };

  constructor(
    private readonly languageService: LanguageService
  ) { }

  ngOnInit() {
    const isRtl = this.languageService.isRtl();
    if (isRtl) {
      this.carouselConfig.rtl = true;
    }
  }

  public breakpoint(event) {
    const slidesToShow = event.slick.slickGetOption('slidesToShow');
    const slideCount = event.slick.slideCount;

    this.showCarouselBtns = slidesToShow < slideCount;
  }

  public afterChange(event): void {
    this.isFirstSlide.next(event.slick.currentSlide === 0);
    this.isLastSlide.next(event.slick.currentSlide === event.slick.slideCount - 1);

    setTimeout(() => {
      const slidesToShow = event.slick.slickGetOption('slidesToShow');
      const slideCount = event.slick.slideCount;

      this.showCarouselBtns = slidesToShow < slideCount;
    });
  }

  public slickInit(event): void {
    this.afterChange(event);
  }

}
