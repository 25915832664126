<ng-container *ngIf="enabled">
  <div class="maintenance" id="maintenance">
    <ng-container [ngSwitch]="type">
      <ng-container *ngSwitchCase="types.NO_INSTANCE">
        <div class="no-instance">
          <img alt="Penceo" class="logo" src="assets/images/logo.jpeg">
          <h1>Sorry, this instance does not exist.</h1>
        </div>
      </ng-container>
      <ng-container *ngSwitchCase="types.SOFT_MAINTENANCE">
        <section class="maintenance__page" id="basic-page" *ngIf="softMaintenancePage; else basicMaintenanceTmp">
          <app-block-list [page]="softMaintenancePage"></app-block-list>
        </section>
      </ng-container>
      <ng-container *ngSwitchDefault>
          <ng-container *ngTemplateOutlet="basicMaintenanceTmp"></ng-container>
      </ng-container>
    </ng-container>

    <span class="version">
      Version: {{ version }}
    </span>  
  </div>
</ng-container>

<ng-template #basicMaintenanceTmp>
  <div class="basic">
    <ng-lottie [options]="lottieOptions"></ng-lottie>
    <div class="wrapper">
      <h1>
        We are just publishing changes,<br>
        that will make <strong>easier</strong> for you to use<br>
        the platform.
      </h1>
      <h2>We will automatically redirect you when the system is back up</h2>
    </div>
 
    <div class="maintenance__status">
      <ng-container *ngIf="secondsTilNextRequest; else statusLoadingTmp">
        Site is still in maintenance mode. Next retry in: {{secondsTilNextRequest}} sec
      </ng-container>
      <ng-template #statusLoadingTmp>
        <span class="d-flex align-items-center maintenance__status-loading">
          Checking maintenance mode status
          <mat-spinner class="ml-2" [diameter]="16" color="primary"></mat-spinner>
        </span>
      </ng-template>
    </div>  
    
    <ng-container *ngIf="type !== types.SOFT_MAINTENANCE">
      <ng-container *ngIf="expectedTimeLeft$ | async as expectedTimeLeft">
        <div class="maintenance__time-left" *ngIf="expectedTimeLeft > 0">
          Expected mainenance time {{expectedTimeLeft | secondsToTime}}
        </div> 
      </ng-container>
    </ng-container>
  </div>
</ng-template>
