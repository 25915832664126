import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { MaintenanceComponent } from './maintenance.component';
import { LottieModule } from 'ngx-lottie';
import player from 'lottie-web';
import { BasicPageBlocksModule } from '../basic-page-blocks/basic-page-blocks.module';
import { SharedModule } from 'src/app/shared/shared.module';

export function playerFactory() {
  return player;
}

@NgModule({
  declarations: [
    MaintenanceComponent
  ],
  imports: [
    CommonModule,
    BasicPageBlocksModule,
    LottieModule.forRoot({ player: playerFactory }),
    SharedModule
  ],
  exports: [
    MaintenanceComponent
  ]
})
export class MaintenanceModule { }
