import { Directive, EmbeddedViewRef, Input, TemplateRef, ViewContainerRef } from "@angular/core";


@Directive({
  selector: '[ngIfMediaQuery]',
  inputs: ['ngIfMediaQuery']
})
export class NgIfMediaQuery {
  @Input() public set ngIfMediaQuery(newMediaQuery: string) {
    this.mql = window.matchMedia(newMediaQuery);
    this.updateView();

    this.mqlListener = (mq) => {
      this.updateView();
    };

    this.mql.addEventListener('change', this.mqlListener);
  }

  @Input() public set ngIfMediaQueryElse(template: TemplateRef<Object>) {
    if (!template) {
      return;
    }

    this.elseTemplateRef = template;
    this.updateView();
  }

  private mql: MediaQueryList;
  private mqlListener: (mql: MediaQueryListEvent) => void;

  private elseTemplateRef: TemplateRef<Object>;

  private thenViewRef: EmbeddedViewRef<Object> = null;
  private elseViewRef: EmbeddedViewRef<Object> = null;

  constructor(
    private viewContainer: ViewContainerRef,
    private templateRef: TemplateRef<Object>) { }

  ngOnDestroy() {
    this.mql.removeEventListener('change', this.mqlListener);
  }

  private updateView(): void {
    if (this.mql.matches) {
      if (!this.thenViewRef) {
        this.viewContainer.clear();
        this.elseViewRef = null;

        if (this.templateRef) {
          this.thenViewRef = this.viewContainer.createEmbeddedView(this.templateRef);
        }
      }
    } else {
      if (!this.elseViewRef) {
        this.viewContainer.clear();
        this.thenViewRef = null;

        if (this.elseTemplateRef) {
          this.elseViewRef = this.viewContainer.createEmbeddedView(this.elseTemplateRef);
        }
      }
    }
  }
}