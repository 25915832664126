import { Inject, Injectable } from '@angular/core';
import { API_URL, CLOUD_IMG_URL } from '../constants';

@Injectable({
  providedIn: 'root'
})
export class SharedLibraryService {

  public environments: any = {
    apiUrl: this.apiUrl,
    cloudImgUrl: this.cloudImgUrl
  };

  constructor(
    @Inject(API_URL) private apiUrl: string,
    @Inject(CLOUD_IMG_URL) private cloudImgUrl: string,
  ) { }

  public updateEnvironments(data: any): void {
    this.environments = { ...this.environments, ...data };
  }
}
