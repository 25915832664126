import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class DateFormatService {

  constructor() { }

  public formatDateToAtomISOString(date: Date, includeTime = true): string {
    if (!date) {
      return null;
    }
  
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, '0');
    const day = String(date.getDate()).padStart(2, '0');

    const dateStr = `${year}-${month}-${day}`;

    if (includeTime) {
      const hours = String(date.getHours()).padStart(2, '0');
      const minutes = String(date.getMinutes()).padStart(2, '0');
      const seconds = String(date.getSeconds()).padStart(2, '0');
      const milliseconds = String(date.getMilliseconds()).padStart(3, '0');
      const timezone = this.getDateTimezoneOffset(date);

      const timeStr = `${hours}:${minutes}:${seconds}.${milliseconds}${timezone}`;
    
      return `${dateStr}T${timeStr}`;
    }

    return dateStr;
  }

  private getDateTimezoneOffset(date: Date): string {
    const timezoneOffset = date.getTimezoneOffset();
    const timezoneOffsetHours = Math.abs(Math.floor(timezoneOffset / 60));
    const timezoneOffsetMinutes = Math.abs(timezoneOffset % 60);
    const timezoneSign = timezoneOffset >= 0 ? '-' : '+';

    const hours = String(timezoneOffsetHours).padStart(2, '0');
    const minutes = String(timezoneOffsetMinutes).padStart(2, '0');

    const timezoneOffsetString = `${timezoneSign}${hours}:${minutes}`;
  
    return timezoneOffsetString;
  }

}
