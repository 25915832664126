import { HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { SessionDTO, LoginProvider, ProfileUserDTO } from 'library-explorer';
import { TwoFALoginResponse } from '@app/model/two-fa-login-response';
import { environment } from '@env/environment';
import { Observable } from 'rxjs';
import { HttpService, interceptorsToken } from 'library-explorer';
import { TaxonomiesModel } from '@app/model/taxonomies.model';

@Injectable({
  providedIn: 'root'
})
export class AuthApiService {

  constructor(private readonly httpService: HttpService) { }

  public loginWithToken(data: { token: string, userId?: string, timestamp?: string }, errorHander = false): Observable<SessionDTO | TwoFALoginResponse> {
    const headers = errorHander ? null : this.getSkipNotificationErrorHandlerHeaders();
    return this.httpService.post<SessionDTO>('login', data, true, false, headers);
  }

  public login(data: { [key: string]: string, password: string }): Observable<SessionDTO | TwoFALoginResponse> {
    return this.httpService.post<SessionDTO>('login', data, true, false, this.getSkipNotificationErrorHandlerHeaders());
  }

  public validate2FACode(data: { id: string, code: string }): Observable<Partial<SessionDTO>> {
    return this.httpService.post<Partial<SessionDTO>>('tfa/validate-code', data, true, false, this.getSkipNotificationErrorHandlerHeaders());
  }

  public resend2FACode(id: string): Observable<void> {
    return this.httpService.post<void>('tfa/resend-code', { id }, false, false, this.getSkipNotificationErrorHandlerHeaders());
  }

  public confirmEmail(token: string): Observable<string> {
    return this.httpService.post<string>('user/verify-email', { token }, false, false, this.getSkipNotificationErrorHandlerHeaders());
  }

  public logoutRequest(id: string): Observable<{ message: string, ssoLogOutUrl: string }> {
    const params = new HttpParams().set('id', id);

    return this.httpService.get<{ message: string, ssoLogOutUrl: string }>('logout', params, false, true);
  }

  public loginWithSso(provider: Partial<LoginProvider>, origin: string, redirect: string, errorHander = false): Observable<{ redirect_uri: string }> {
    const headers = errorHander ? null : this.getSkipNotificationErrorHandlerHeaders();

    return this.httpService.post<{ redirect_uri: string }>('sso/login', {
      front_redirect_url: `${origin}/access/login`,
      target_url: redirect,
      providerName: provider.name,
      uuid: provider.uuid
    }, false, false, headers);
  }

  public register(profileDTO: ProfileUserDTO): Observable<string> {
    return this.httpService.post<string>('register', profileDTO, false, false, this.getSkipNotificationErrorHandlerHeaders());
  }

  public getRefreshToken(refreshTokenData?: { [key: string]: string }): Observable<{ [key: string]: string }> {
    if (refreshTokenData) {
      const additionalHeaders = this.getSkipNotificationErrorHandlerHeaders()
        .set(interceptorsToken.SKIP_LOADING_TOKEN_HANDLER, 'true')
        .set(interceptorsToken.SKIP_OFFLINE_REQUESTS_HANDLER, 'true')
      
      return this.httpService.postThirdParty(`${environment.apiUrl}jwt/token`, refreshTokenData, true, false, additionalHeaders);
    }

    return this.httpService.getThirdParty(`${environment.apiUrl}jwt/token`, null, true, true);
  }

  public checkPassword(password: string): Observable<any> {
    return this.httpService.post<any>('validate/password', { password }, false, false, this.getSkipNotificationErrorHandlerHeaders());
  }

  public checkRegistrationCodeValidity(code: string): Observable<{ message: string; status: number, assignmentGroups?: { id: string, name: string }[] }> {
    return this.httpService.post<{ message: string; status: number, assignmentGroups?: { id: string, name: string }[] }>(
        'self-assignment/validate-code', { code }, false, false, this.getSkipNotificationErrorHandlerHeaders());
  }

  private getSkipNotificationErrorHandlerHeaders(): HttpHeaders {
    return new HttpHeaders().set(interceptorsToken.SKIP_TOAST_ERROR_HANDLERS, 'true');
  }
}
