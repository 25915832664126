import { Injectable } from '@angular/core';
import { MatomoRouterInterceptor } from '@ngx-matomo/router';
import { MatomoTracker } from '@ngx-matomo/tracker';
import { NavigationEnd } from '@angular/router';

@Injectable()
export class MatomoCustomRouteInterceptor implements MatomoRouterInterceptor {

  constructor(private readonly tracker: MatomoTracker) {}

  beforePageTrack(event: NavigationEnd): void {
    const content = document.body;

    this.tracker.setDocumentTitle(document.title);
    this.tracker.setReferrerUrl(event.url);
    this.tracker.setCustomUrl(event.url);
    this.tracker.deleteCustomVariables('page');
    this.tracker.trackContentImpressionsWithinNode(content);    
  }
}
