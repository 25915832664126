import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { SettingsService } from '@app/services/settings.service';
import { TranslateService } from '@ngx-translate/core';
import { UserCertificate } from 'src/app/model/user-certificate.model';
import { CertificateService } from '../../services/certificate.service';

@Component({
  selector: 'app-certificate-received-modal',
  templateUrl: './certificate-received-modal.component.html',
  styleUrls: ['./certificate-received-modal.component.scss']
})
export class CertificateReceivedModalComponent implements OnInit {
  public certificate: UserCertificate;
  public certificateMessage: string;

  public shareOnLinkedInEnabled = false;

  constructor(
    private readonly settingsService: SettingsService,
    private readonly certificateService: CertificateService,
    private readonly translateService: TranslateService,
    @Inject(MAT_DIALOG_DATA) public data: UserCertificate) { }

  ngOnInit() {
    this.certificate = this.data;
    this.certificateMessage = this.translateService.instant(`CERTIFICATE.received_certificate_${this.certificate.bundle}`);

    this.settingsService.getSettings()
      .subscribe(data => {
        this.shareOnLinkedInEnabled = data.certification?.linkedInSharing?.enableSharingOnLinkedIn;
      });
  }

  public shareOnLinkedIn(): void {
    this.certificateService.shareCertificateOnLinkedIn(this.certificate);
  }

  public download(): void {
    this.certificateService.downloadCerfiticate(this.certificate).subscribe();
  }

}
