<div class="audio-waverform" [ngClass]="theme">
  <ng-container *ngIf="init; else spinnerTmp">
    <lib-icon [icon]="isPlaying ? 'pause' : 'play'" (click)="togglePlay()" class="play-icon"></lib-icon>
  </ng-container>

  <div #waveform class="audio-wave" [class.init]="init">
    <div class="audio-wave-placeholder"></div>
  </div>

  <span>{{ duration  | secondsToTime }} </span>
</div>

<ng-template #spinnerTmp>
  <mat-spinner [diameter]="16"></mat-spinner>
</ng-template>