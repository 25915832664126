<div class="profile-delete-warning-dialog">
  <button class="profile-delete-warning-dialog__close-btn" mat-mini-fab [mat-dialog-close]="false">
    <mat-icon>close</mat-icon>
  </button>
  <div mat-dialog-content class="profile-delete-warning-dialog__content">
    <span class="profile-delete-warning-dialog__subtitle">{{'PROFILE.delete_profile' | translate}}</span>
    <h3 class="profile-delete-warning-dialog__title">{{'PROFILE.are_you_sure_you_want_to_delete' | translate }}</h3>
    <span class="profile-delete-warning-dialog__info">{{'PROFILE.you_will_lose_data' | translate }}</span>
    <span class="profile-delete-warning-dialog__info">{{(isSSOUser ? 'PROFILE.type_your_last_name' : 'PROFILE.type_your_password') | translate }}</span>
    <mat-form-field appearance="outline" class="mt-5 d-block">
      <mat-label>{{(isSSOUser ? 'COMMON.last_name' : 'COMMON.password') | translate }}</mat-label>
      <input matInput [type]="isSSOUser ? 'text' : 'password'" [formControl]="control">
    </mat-form-field>
    <mat-error *ngIf="passwordInvalid" class="mt-3">{{'ERRORS.invalid_password' | translate}}</mat-error>
  </div>
  <div mat-dialog-actions class="profile-delete-warning-dialog__actions d-flex flex-column-reverse flex-sm-row justify-content-between">
    <button class="primary-background" color="primary" mat-button [mat-dialog-close]="false">{{'COMMON.cancel' | translate }}</button>
    <button class="profile-delete-warning-dialog__delete-btn" mat-button (click)="submit()" [disabled]="!isValid">
      <mat-icon svgIcon="lms-delete"></mat-icon>
      {{'PROFILE.yes_delete' | translate }}
    </button>
  </div>
</div>
