import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { EntityItemLayoutEnum } from 'library-explorer';
import { Subject, timer } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

@Component({
  selector: 'app-cards-list-placeholder',
  templateUrl: './cards-list-placeholder.component.html',
  styleUrls: ['./cards-list-placeholder.component.scss']
})
export class CardsListPlaceholderComponent implements OnInit, OnDestroy {
  @Input() public layout: EntityItemLayoutEnum;

  public layouts: typeof EntityItemLayoutEnum = EntityItemLayoutEnum;

  public readonly placeholders = new Array(6).fill(null);

  public isHighLoadingTime = false;

  private readonly HIGH_LOADING_TIME_SECONDS = 3;

  private unsubscribe: Subject<void> = new Subject();

  constructor() { }

  ngOnInit(): void {
    timer(this.HIGH_LOADING_TIME_SECONDS * 1000)
      .pipe(
        takeUntil(this.unsubscribe)
      ).subscribe(() => {
        this.isHighLoadingTime = true;
      })
  }

  ngOnDestroy(): void {
    this.unsubscribe.next();
    this.unsubscribe.complete();
  }

}
