import { trigger, transition, query, style, stagger, animate } from "@angular/animations";

export const fadeStaggerAnimation = [
  trigger('fadeAnimation', [
    transition('* => *', [
      query(':enter', style({opacity: 0, transform: 'translateY(-20px)', height: 0}), {optional: true}),
      query(':enter', [
        stagger(30, [
          animate('0.2s ease', style({opacity: 1, transform: 'translateY(0)', height: '*'}))
        ])
      ], {optional: true}),
      query(':leave', style({opacity: 1, transform: 'translateY(0)', height: '*'}), {optional: true}),
      query(':leave', [
        stagger(30, [
          animate('0.2s ease', style({opacity: 0, transform: 'translateY(-20)', height: 0}))
        ])
      ], {optional: true}),
    ])
  ])
]