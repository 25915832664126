<div class="chat-channel">

  <ng-scrollbar class="chat-channel-scrollbar" *ngIf="messages.length; else emptyTemplate" [class.has-scroll]="hasScroll" (updated)="scrollUpdated()">
    <div class="chat-messages">
      <mat-spinner color="primary" class="loading-spinner" [diameter]="24" *ngIf="isLoading"></mat-spinner>

      <ng-container>
        <app-chat-channel-message
          class="channel-message"
          *ngFor="let item of messages"
          [item]="item"
          (edit)="editMessage(item)"
          (mediaLoaded)="scrollToBottom()"
          (delete)="deleteMessage(item)"
          [currentUserMessage]="item.sender?.userId === currentUserId"
          [canDelete]="canAdministerChannel || item.sender?.userId === currentUserId">
        </app-chat-channel-message>
      </ng-container>

      <div class="channel-message" *ngIf="loadingIndicator">
        <div class="chat-loading-message">
          {{ 'COMMON.uploading' | translate }}

          <mat-spinner class="progress-spinner-white" [diameter]="16" color="white"></mat-spinner>
        </div>
      </div>
    </div>
  </ng-scrollbar>

  <ng-template #emptyTemplate>
    <app-list-empty-state 
      *ngIf="!isLoading"
      icon="chat-dots"
      [title]="'CHAT.no_messages' | translate">
    </app-list-empty-state>
  </ng-template>

</div>
