export enum FieldName {
  PARAGRAPH_VIDEO = 'field_paragraph_video',
  PARAGRAPH_IMAGE = 'field_paragraph_image',
  PARAGRAPH_AUDIO = 'field_paragraph_audio',
  BACKGROUND_IMAGE = 'field_paragraph_background_image',
  ACCORDION_IMAGE = 'field_paragraph_accordion_image',
  PACKAGE = 'field_package',
  LESSON_IMAGE = 'field_lesson_image',
  MODULE_IMAGE = 'field_module_image',
  COURSE_IMAGE = 'field_course_image',
  CLASS_IMAGE = 'field_class_image',
  ARTICLE_IMAGE = 'field_image',
  VIDEO = 'field_video',
  QUIZ_IMAGE = 'field_quiz_image',
  LIBRARY_FILE = 'field_library_file',
  ACHIEVEMENT_IMAGE = 'field_image',
  MEDIA_IMAGE = 'field_media_image',
  MEDIA_BACKGROUND = 'field_media_background',
  AWS_FILE = 'field_aws_file',
  AWS_VIDEO = 'field_aws_video',
  AWS_VIDEO_STREAM = 'field_aws_video_stream',
  MEDIA_AVATAR = 'field_media_avatar',
  MEDIA_VIDEO = 'field_media_video',
  MEDIA_FILE = 'field_media_file',
  FIELD_LEFT_MEDIA = 'field_left_media',
  FIELD_RIGHT_MEDIA = 'field_right_media',
  FIELD_LEFT_MOBILE_MEDIA = 'field_left_mobile_media',
  FIELD_RIGHT_MOBILE_MEDIA = 'field_right_mobile_media',
  FIELD_EXPORT_FILE = 'field_export_file',
  FIELD_COVER_IMAGE = 'field_cover_image'
}
