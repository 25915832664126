import { Directive, ElementRef, Input } from '@angular/core';
import { FileUploadApiService, MediaPresignApiService, SharedLibraryService, TokenApiService } from '../services';
import { CloudImageService } from '../services/cloud-image.service';
import { CropImageDirective } from './crop-image.directive';
import { BundleType, EntityTypeId, FieldName, ImageModel, ProviderType } from '../models';

@Directive({
  // tslint:disable-next-line: directive-selector
  selector: '[textMask]'
})
export class TextImageMaskDirective extends CropImageDirective {
  @Input() public set textMask(value: { enableTextMask: boolean, textMaskImage: ImageModel & { file?: File } }) {
    this.initializeMask(value);
    this._textMask = value;
  }

  public get textMask() {
    return this._textMask;
  }

  private _textMask: { enableTextMask: boolean, textMaskImage: ImageModel & { file?: File } };
  private imageData: ImageModel;

  constructor(
    private readonly mediaPresignApiService: MediaPresignApiService,
    protected override readonly sharedLibraryService: SharedLibraryService,
    protected override el: ElementRef,
    protected override tokenApiService: TokenApiService,
    protected override cloudImageService: CloudImageService) {
    super(sharedLibraryService, el, tokenApiService, cloudImageService);
  }

  private initializeMask(value: { enableTextMask: boolean, textMaskImage: ImageModel & { file?: File } }): void {

    if (!value || !value.enableTextMask || !value.textMaskImage) {
      this.el.nativeElement.style.color = null;
      this.el.nativeElement.style.backgroundImage = null;
      this.cropImage = null;
      return;
    }

    this.el.nativeElement.style.backgroundRepeat = 'no-repeat';
    this.el.nativeElement.style.backgroundSize = 'cover';
    this.el.nativeElement.style.backgroundPosition = 'center';
    this.el.nativeElement.style.backgroundClip = 'text';
    this.el.nativeElement.style.color = 'transparent';


    if (value.textMaskImage.file instanceof File) {
      if (value.textMaskImage.file === this._textMask?.textMaskImage?.file) {
        return;
      }

      const result = URL.createObjectURL(value.textMaskImage.file);
      this.el.nativeElement.style.backgroundImage = `url('${result}')`;

      return;
    }

    if (value.textMaskImage.provider === ProviderType.AWS) {
      if (this.imageData?.key === value.textMaskImage.key) {
        return;
      }

      this.imageData = value.textMaskImage;

      this.mediaPresignApiService.getPreSignedUrl(EntityTypeId.PARAGRAPH, BundleType.IMAGE, FieldName.MEDIA_IMAGE, value.textMaskImage.key)
        .subscribe(presignedUrl => {
          this.cropImage = presignedUrl;
        });

      return;
    }

    this.cropImage = value.textMaskImage.url;
  }
}
