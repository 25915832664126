import { Injectable } from '@angular/core';
import { DomSanitizer, SafeUrl } from '@angular/platform-browser';
import { Observable, of } from 'rxjs';
import { map } from 'rxjs/operators';

import { ProviderType } from '../models/enums/provider-type.enum';

@Injectable({
  providedIn: 'root'
})
export class EmbededVideoHelperService {
  private readonly youtubeImageUrl = 'https://img.youtube.com/vi';
  private readonly vimeoImageUrl = 'https://vimeo.com/api/v2/video';
  private readonly youtubeNotFoundThrumbnailWidth = 120;


  constructor(private readonly sanitizer: DomSanitizer) { }

  public getVideoThumbnail(type: ProviderType, id: string): Observable<SafeUrl> {
    switch (type) {
      case ProviderType.YOUTUBE:
        return this.getYotubeVideoThumbnail(id)
          .pipe(
            map(url => this.sanitizer.bypassSecurityTrustResourceUrl(url))
          );
      case ProviderType.VIMEO:
        return this.getVimeoVideoThumbnail(id)
          .pipe(
            map(url => this.sanitizer.bypassSecurityTrustResourceUrl(url))
          );
      default:
        return of(this.sanitizer.bypassSecurityTrustResourceUrl(''));
    }
  }

  private getYotubeVideoThumbnail(id: string): Observable<string> {
    const thumbnailResolution = 'maxresdefault';

    return new Observable(subscriber => {
      const maxResImage = `${this.youtubeImageUrl}/${id}/${thumbnailResolution}.jpg`;
      const defaultImage = `${this.youtubeImageUrl}/${id}/0.jpg`;

      const img = new Image();
      img.onload = (data: any) => {
        if (data.target.width === this.youtubeNotFoundThrumbnailWidth) {
          subscriber.next(defaultImage);
        } else {
          subscriber.next(maxResImage);
        }
        subscriber.complete();
      };
      img.onerror = () => {
        subscriber.next(defaultImage);
        subscriber.complete();
      };
      img.src = maxResImage;
    });
  }

  private getVimeoVideoThumbnail(id: string): Observable<string> {
    return new Observable(subscriber => {
      fetch(`${this.vimeoImageUrl}/${id}.json`)
        .then((response) => response.json())
        .then(imagesData => {
          const image = imagesData && imagesData[0];
          const url = image && image.thumbnail_large || image.thumbnail_url;
          subscriber.next(url);
          subscriber.complete();
        });
    });
  }
}
