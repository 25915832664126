import { Injectable } from "@angular/core";
import { warnKeyNotExistInStorageKeys } from "../decorators/warn-storage-key";
import { BrowserStorage } from "../models/browser-storage";


@Injectable({
  providedIn: 'root'
})
export class StorageService {
  constructor(
    private readonly storage: BrowserStorage) {
    }

  public getItem<T = string>(key: string): T | undefined {
    return this.storage.getItem(key); 
  }

  public removeItem(key: string): void {
    this.storage.removeItem(key);
  }

  @warnKeyNotExistInStorageKeys()
  public setItem<T = any>(key: string, item: T): void {
    this.storage.setItem(key, item);
  }
}
