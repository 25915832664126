import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { SettingsService } from '@app/services/settings.service';
import { WatermarkService } from '@app/services/watermark.service';
import { PdfJsViewerComponent } from 'ng2-pdfjs-viewer';
import { v4 as uuidv4 } from 'uuid';
import { PdfViewerAppearanceMode} from 'library-explorer';

@Component({
  selector: 'app-pdf-viewer',
  templateUrl: './pdf-viewer.component.html',
  styleUrls: ['./pdf-viewer.component.scss']
})
export class PdfViewerComponent implements OnInit {
  @ViewChild(PdfJsViewerComponent) public set pdfJsViewerComponent(value: PdfJsViewerComponent) {
    this._pdfJsViewerComponent = value;

    if (!value) {
      return;
    }
  
    this.setViewerThemeCss();
  }

  public get pdfJsViewerComponent() {
    return this._pdfJsViewerComponent;
  }


  @Input() public allowDownloadFile = false;
  @Input() public src: string;
  @Input() public watermarkEnabled: boolean;
  @Input() public forceFullScreen: boolean;
  @Output() public loaded: EventEmitter<void> = new EventEmitter();

  public viewerId = uuidv4();

  public simplifiedMode = true;

  private _pdfJsViewerComponent: PdfJsViewerComponent;

  constructor(
    private readonly settingsService: SettingsService,
    private readonly watermarkService: WatermarkService) {
  }

  ngOnInit(): void {
    this.settingsService.getSettings().subscribe(data => {
      this.simplifiedMode = data?.pdfViewer?.appearanceMode === PdfViewerAppearanceMode.SIMPLIFIED;
    })
  }
  
  public pdfLoaded(): void {
    this.loaded.emit();

    this.setWatermark();
  }

  public onPageChange(): void {
    this.setWatermark();
  }

  private setWatermark(): void {
    if (!this.watermarkEnabled) {
      return;
    }

    const { PDFViewerApplication} = this.pdfJsViewerComponent;

    PDFViewerApplication.pdfViewer._pages.forEach((page, index) => {
      const pageView = PDFViewerApplication.pdfViewer.getPageView(index);
      const { height, width } = page?.viewport || {};

      this.watermarkService.appendWatermark(pageView.div, width, height, `${this.viewerId}-${index}`, 9999);
    });
  }

  private setViewerThemeCss(): void {
    if (!this.simplifiedMode) {
      return;
    }

    setTimeout(() => {
      const iframeDoc = this.pdfJsViewerComponent.iframe?.nativeElement?.contentDocument;
      
      if (iframeDoc) {
        const simplifiedCss = `
          #mainContainer .toolbar, #sidebarContainer, #secondaryToolbar, #findbar { display: none !important; }
          #outerContainer { background-color: white; !important }
          #viewerContainer { top: 8px; }`;
        const styleElement = document.createElement('style');
        styleElement.appendChild(document.createTextNode(simplifiedCss));

        iframeDoc.documentElement.children[0].appendChild(styleElement);
      }
    }, 50);
  }
}
