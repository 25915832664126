import { HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { DataResponse } from '@app/model/data-response.model';
import { TaxonomyType } from '@app/model/enums/taxonomy-type.enum';
import { TaxonomiesModel } from '@app/model/taxonomies.model';
import { HttpService } from 'library-explorer';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class TaxonomyApiService {

  constructor(private readonly httpService: HttpService) { }

  public getTaxonomiesList(type: TaxonomyType, params = new HttpParams()): Observable<TaxonomiesModel[]> {
    params = params.set('vocabulary', type);

    return this.httpService.get<DataResponse<TaxonomiesModel>>('v2/taxonomies', params).pipe(
      map(res => res.items)
    );
  }
}
