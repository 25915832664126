import { Directive, HostListener, Input } from '@angular/core';
import { CalendarEvent } from '@app/model/calendar-event';
import { CalendarExportService } from '@app/services/calendar-export.service';

@Directive({
  selector: '[exportCalendar]'
})
export class ExportCalendarButtonDirective {
  @Input() exportCalendar: CalendarEvent;

  constructor(private readonly calendarExportService: CalendarExportService) { }

  @HostListener('click') public openDialog() {
    this.calendarExportService.openCalendarExport(this.exportCalendar);
  }
}

