import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { CalendarEvent } from '@app/model/calendar-event';
import 'add-to-calendar-button';
import * as moment from 'moment';

@Component({
  selector: 'app-export-calendar-dialog',
  templateUrl: './export-calendar-dialog.component.html',
  styleUrls: ['./export-calendar-dialog.component.scss']
})
export class ExportCalendarDialogComponent implements OnInit {
  public exportOptions: string;
  public eventDates: { startDate: string, startTime: string, endDate: string, endTime: string };

  public EXPORT_TO = ['Google', 'iCal', 'Microsoft365', 'MicrosoftTeams', 'Outlook.com', 'Yahoo'];

  constructor(@Inject(MAT_DIALOG_DATA) public event: CalendarEvent) { }

  ngOnInit(): void {
    const { date, duration } = this.event;
    
    this.eventDates = date && this.getEventDates(date, duration);
  
    this.exportOptions = this.EXPORT_TO.map(item => `'${item}'`).join(',')
  }

  private getEventDates(date: Date | string, durationInMinutes: number): any {
    const start = moment(date).utc(false);
    const end = moment(date).utc(false).add(durationInMinutes, 'minutes');
    
    return {
      startDate: start.format('YYYY-MM-DD'),
      startTime: start.format('HH:mm'),
      endDate: end.format('YYYY-MM-DD'),
      endTime: end.format('HH:mm')
    };
  }
}
