import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-steps-progress',
  templateUrl: './steps-progress.component.html',
  styleUrls: ['./steps-progress.component.scss']
})
export class StepsProgressComponent implements OnInit {

  @Input() steps = 1;
  @Input() set current(value: number) {
    this._current = value;
    this.sliderWidth = Math.ceil((value / this.steps) * 100);
  }
  get current(): number {
    return this._current;
  }

  public sliderWidth = 0;

  private _current: number = 1;

  constructor() { }

  ngOnInit(): void {
  }

}
