import { Injectable } from '@angular/core';
import { HttpService } from 'library-explorer';
import { from, Observable, of } from 'rxjs';
import { catchError, map, switchMap, tap } from 'rxjs/operators';
import { ToastrService } from 'ngx-toastr';
import { TranslateService } from '@ngx-translate/core';
import { loadStripe } from '@stripe/stripe-js/pure';
import { PaymentResultEnum } from 'src/app/model/enums/payment-result.enum';

@Injectable({
  providedIn: 'root'
})
export class StripeService {
  private apiKey: string;
  private stripe: any;

  constructor(
    private translateService: TranslateService,
    private httpService: HttpService,
    private toastr: ToastrService
  ) { }

  private getApiKey(): Observable<any> {
    return this.httpService.get<any>('stripe/get-key');
  }

  public sendPayment(data: any): Observable<any> {
    let observable: Observable<any>;
    const payment$ = this.httpService.post('stripe/payment', data);

    if (!this.apiKey) {
      observable = this.getApiKey().pipe(
        switchMap(res => {
          return from(loadStripe(res.apiKey)).pipe(
            tap(data => this.stripe = data),
            switchMap(() => payment$)
          );
        })
      );
    } else {
      observable = payment$;
    }

    return observable.pipe(
      map(res => {
        if (res.alreadyPaid) {
          return PaymentResultEnum.ALREADY_PAID;
        }
    
        this.stripe.redirectToCheckout({
          sessionId: res.sessionId
        });

        return PaymentResultEnum.IN_PROGRESS;
      }),
      catchError((err) => {
        const error = err && err.error;
        const message = (error && error.message) || error || 'ERRORS.server_error';
        this.showError(message);
        return err;
      })
    );
  }

  private showError(label: string): void {
    this.translateService.get(label)
      .subscribe(translation => {
        this.toastr.error(translation);
      });
  }
}
