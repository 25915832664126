import { Injectable } from '@angular/core';
import jwtDecode from 'jwt-decode';
import { BehaviorSubject, Observable } from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class SessionStorageService {
  private jwtToken: BehaviorSubject<string> = new BehaviorSubject('');

  private tokenIssuedTime: number

  public getTokenExpTime(token = this.jwtToken?.value): number {
    if (!token) {
      return;
    }
  
    const decodedToken: { exp: number, iat: number } = jwtDecode(token);
    const ttl = decodedToken.exp - decodedToken.iat;
    return this.tokenIssuedTime + (ttl * 1000);
  }

  public setJwtToken(token: string): void {
    this.tokenIssuedTime = new Date().getTime();
    this.jwtToken.next(token);
  }

  public getJwtToken(): Observable<string> {
    return this.jwtToken.asObservable();
  }

  public getJwtTokenValue(): string {
    return this.jwtToken?.value;
  }

  public isTokenExpired(token: string, timeout = 20000): boolean {
    const tokenExpTime = this.getTokenExpTime(token);
    const currentTime = new Date().getTime();

    return currentTime >= tokenExpTime - timeout;
  }

  public isUserLoggedIn(): Observable<boolean> {
    return this.jwtToken.asObservable()
      .pipe(
        map(token => {
          if (!token) {
            return false;
          }

          return !this.isTokenExpired(token);
        })
      )
  }
}
