import { Injectable } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { DiscussChatDialogComponent } from '../components/discuss-chat-dialog/discuss-chat-dialog.component';

@Injectable({
  providedIn: 'root'
})
export class DisqusChatService {
  public identifier: string;
  public title: string;

  private chatDialog: MatDialogRef<DiscussChatDialogComponent>;

  constructor(private dialog: MatDialog) { }


  public openDialog(nativeElement: any, closed = false): void {
    if (!closed && this.chatDialog && this.chatDialog.componentInstance) {
      if (this.chatDialog.componentInstance.identifier === this.identifier) {
        return;
      }

      this.chatDialog.close();
      this.chatDialog.afterClosed().subscribe(() => {
        this.openDialog(nativeElement, true);
      });
      return;
    }

    const isDesktop = window.innerWidth > 991;

    this.chatDialog = this.dialog.open(DiscussChatDialogComponent, {
      closeOnNavigation: true,
      hasBackdrop: !isDesktop,
      panelClass: 'disqus-chat-panel',
      data: {
        identifier: this.identifier,
        title: this.title
      },
      position: isDesktop && {
        top: `${nativeElement.parentElement.offsetHeight + 5}px`,
        right: `30px`
      }
    });
  }

  public closeChatDialog(): void {
    if (this.chatDialog) {
      this.chatDialog.close();
    }
  }
}
