export enum SettingsProfileFieldType {
  TEXT = 'text',
  FORMATTED_TEXT = 'formatted_text',
  URL = 'url',
  EMAIL = 'email',
  COUNTRY = 'country',
  SELECT_OPTION = 'select_option',
  DATE = 'date',
  CHECKBOX = 'checkbox',
}
