<div class="modal-image">
  <mat-icon class="modal-image__close" mat-dialog-close>close</mat-icon>

  <div class="modal-image__container">
    <div panzoom>
      <img 
        class="modal-image__image"
        [cropImage]="image?.uri || image?.url" 
        [crop]="image?.crop"
        [additionalOptions]="image?.additionalMediaProps"
        [imageWidth]="1920"
        [dynamicWidth]="false">
    </div>
  </div>
</div>
