import { Inject, Pipe, PipeTransform } from '@angular/core';
import { DateFormatEnum } from '../models/enums/date-format.enum';
import { DATE_FORMATS } from '../constants';
import { DatePipe } from '@angular/common';
import { DateFormatType, DateFormatTypeLocales } from '../models/date-format.model';
import { LocaleEnum } from '../models/enums/locale.enum';
import { LocaleService } from '../services';

@Pipe({
  name: 'customDate'
})
export class CustomDatePipe implements PipeTransform {

  constructor (
    @Inject(DATE_FORMATS) private dateFormats: DateFormatType,
    private readonly datePipe: DatePipe,
    private readonly localeService: LocaleService,
  ) { }

  transform(value: string | Date, format: DateFormatEnum | string | DateFormatTypeLocales = DateFormatEnum.BASIC, timezone?: string): string {
    const locale = this.localeService.localeValue;
    const dateFormat = this.dateFormats[format as DateFormatEnum]
    const dateFormatLocale = dateFormat?.[locale] || format?.[locale];

    if (dateFormatLocale) {
      return this.datePipe.transform(value, dateFormatLocale);
    }

    return this.datePipe.transform(value, format as string, timezone);
  }

}
