export enum BundleType {
  LESSON = 'lesson',
  QUIZ = 'quiz',
  MODULE = 'module',
  COURSE = 'course',
  CLASS = 'class',
  WEBINAR = 'webinar',
  POLL_QUIZ = 'poll',
  OFFLINE_ACTIVITY = 'offline_activity',
  ASSIGNMENT = 'assignment',
  LIBRARY = 'library',
  IMAGE = 'image',
  VIDEO = 'video',
  USER = 'user',
  CARD = 'card',
  SETTINGS = 'settings',
  FLIPCARD = 'flipcard',
  SLIDE_SCORM = 'slide_scorm_package',
  LEARNING_PATH = 'learning_path',
  CHAT_CHANNEL = 'channel',
  FILE = 'file',
  PAGE = 'page',
  EVENT = 'event'
}
