import DOMPurify from 'dompurify';

export function extractHTMLContent(value: string): string {
  if (!value) {
    return '';
  }

  const textarea = document.createElement('textarea');
  textarea.innerHTML = value;

  const decodedString = textarea.value;

  const cleanHTML = DOMPurify.sanitize(decodedString);

  const span = document.createElement('textarea');
  span.innerHTML = cleanHTML;

  return span.textContent || span.innerText || '';
}
