import { Component, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output, SimpleChanges, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { NgScrollbar } from 'ngx-scrollbar';
import { Subject, Subscription } from 'rxjs';
import { filter, mergeMap, skip, take, takeUntil, tap } from 'rxjs/operators';
import { GroupChannel } from 'sendbird';
import { ChatChannel } from 'src/app/model/chat-channel';
import { ChatChannelType } from 'src/app/model/enums/chat-channel-type.enum';
import { ChatMode } from 'src/app/model/enums/chat-mode.enum';
import { ImageModel } from 'library-explorer';
import { SettingsService } from '@app/services/settings.service';
import { SendbirdService } from 'src/app/services/sendbird.service';

@Component({
  selector: 'app-chat-list',
  templateUrl: './chat-list.component.html',
  styleUrls: ['./chat-list.component.scss']
})
export class ChatListComponent implements OnInit, OnDestroy, OnChanges {
  @Input() public shouldSetDefaultChannel = false;
  @Input() public chatMode = ChatMode.DEFAULT;
  @Output() public channelsIntialized: EventEmitter<boolean> = new EventEmitter();

  @ViewChild(NgScrollbar, { static: false }) public set scrollbarRef(value: NgScrollbar) {
    if (value && value !== this._scrollbarRef) {
      this._scrollbarRef = value;
      this.addScrollbarSubscription();
    }
  }

  public get scrollbarRef() {
    return this._scrollbarRef;
  }

  @Input() public search = '';

  public channels: Partial<ChatChannel>[];
  public activeChannel: Partial<ChatChannel>;

  public readonly isMobile =  window.innerWidth < 992;

  public isLoading = false;
  public hasChannels = false;
  public globalChatLogo: ImageModel;

  public readonly chatChannelTypes: typeof ChatChannelType = ChatChannelType;
  public readonly chatModes: typeof ChatMode = ChatMode;

  public chatListInitialized = false;

  public placeholders = new Array(4).fill(null);

  public hasScroll = false;

  private scrollSub: Subscription;
  private _scrollbarRef: NgScrollbar;
  private unsubscribe: Subject<void> = new Subject();

  private channelsQuery: SendBird.GroupChannelListQuery;

  constructor(
    private readonly router: Router,
    private readonly settingsService: SettingsService,
    private readonly sendbirdService: SendbirdService) { }

  ngOnInit() {
    this.isLoading = true;

    this.sendbirdService.activeChannel
      .pipe(
        takeUntil(this.unsubscribe),
        skip(1)
      )
      .subscribe(data => {
        this.activeChannelChanged(data);
      });

    this.sendbirdService.openGroupChannels()
      .pipe(
        mergeMap(() => this.sendbirdService.initialized),
        tap(data => {
          this.hasChannels = this.isLoading = data;
          this.channelsIntialized.emit(this.hasChannels);
        }),
        filter(data => data),
        take(1)
      ).subscribe(() => {
        this.setChannelQuery(true);

        this.addSubscriptionOnLeaveChannel();
        this.addSubscriptionOnNewMessage();
      });

    this.settingsService.getSettings()
      .subscribe(data => this.globalChatLogo = data.general.platformLogo);
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.search && !changes.search.firstChange && changes.search.currentValue !== changes.search.previousValue) {
      this.setChannelQuery();
    }
  }

  ngOnDestroy(): void {
    this.unsubscribe.next();
    this.unsubscribe.complete();
  }

  public scrollUpdated(): void {
    this.hasScroll = this.scrollbarRef.state.isVerticallyScrollable;
  }

  public leaveChannel(groupChannel: ChatChannel): void {
    this.sendbirdService.leaveChannel(groupChannel);
  }

  public stopPropagation(event: Event): void {
    event.stopPropagation();
  }

  public trackByFn(index: number, item: ChatChannel): string {
    return item.url;
  }

  public setChannelQuery(init = false): void {
    this.isLoading = true;
    this.channelsQuery = this.sendbirdService.getGroupChannelsQuery(this.search);

    this.channelsQuery.next(data => {
      data = data || [];
      data = data.map(channel => this.sendbirdService.mapToChatChannel(channel));
      this.channels = data;
      this.isLoading = false;
      this.chatListInitialized = true;

      if (init) {
        this.hasChannels = data && data.length !== 0;
        this.channelsIntialized.emit(this.hasChannels);
      }

      if (!this.activeChannel && this.shouldSetDefaultChannel) {
        this.setDefaultActiveChannel();
      }
    });
  }

  public setActiveChannel(channel: ChatChannel): void {
    if (!channel) {
      this.activeChannel = null;
      return;
    }

    this.sendbirdService.activeChannel.next(channel);
  }

  public clearSearch(): void {
    this.search = '';
    this.setChannelQuery();
  }

  public setDefaultActiveChannel(): void {
    if (this.isMobile || !this.channels) {
      return;
    }

    const channel = this.channels.find(chat => chat.type !== ChatChannelType.ASSIGNMENT) as GroupChannel;
    this.setActiveChannel(channel);
  }

  private loadMore(): void {
    if (this.channelsQuery.hasNext) {
      this.isLoading = true;
      this.channelsQuery.next(data => {
        data = data.map(channel => this.sendbirdService.mapToChatChannel(channel));
        this.channels.push(...data);

        this.isLoading = false;
      });
    }
  }

  private addScrollbarSubscription(): void {
    if (this.scrollSub) {
      this.scrollSub.unsubscribe();
    }

    this.scrollSub = this.scrollbarRef.scrolled
      .pipe(
        takeUntil(this.unsubscribe),
        filter(() => !this.isLoading && this.channelsQuery.hasNext),
        filter((event: Event) => {
          const targetElement = event.target as HTMLElement;
          return targetElement.scrollHeight - targetElement.offsetHeight - targetElement.scrollTop < 100
        })
      )
      .subscribe(() => {
        this.loadMore();
      });
  }

  private addSubscriptionOnLeaveChannel(): void {
    this.sendbirdService.leaveChannelSub
      .pipe(
        takeUntil(this.unsubscribe)
      )
      .subscribe(channelUrl => {
        this.channels = this.channels.filter(channel => channel.url !== channelUrl);

        if (this.activeChannel.url === channelUrl) {
          const channel = this.channels && this.channels.find(chat => chat.type !== ChatChannelType.ASSIGNMENT) as ChatChannel;
          this.setActiveChannel(channel);
        }

        this.channelsIntialized.emit(this.channels.length !== 0);
      });
  }

  private addSubscriptionOnNewMessage(): void {
    this.sendbirdService.messageReceived
      .pipe(
        takeUntil(this.unsubscribe)
      )
      .subscribe(data => {
        const existingChannelIndex = this.channels.findIndex(channel => channel.url === data.channelUrl);

        if (existingChannelIndex !== -1) {
          const temp = this.channels[existingChannelIndex];
          this.channels.splice(existingChannelIndex, 1);
          this.channels.unshift(temp);
        } else {
          this.setChannelQuery();
        }
      });
  }

  private activeChannelChanged(channel): void {
    if(!channel) {
      return;
    }

    if (channel.type === ChatChannelType.ASSIGNMENT) {
      this.router.navigate(['assessment-review'], { queryParams: { id: channel.entityId || channel.data }});
      this.sendbirdService.closeChatDialog();
      return;
    }

    this.activeChannel = channel;

    if (this.chatMode === ChatMode.DIALOG) {
      this.sendbirdService.openChatDialog();
    }
  }

}
