<button class="mat-tab-header-pagination mat-tab-header-pagination-before mat-elevation-z4"
     #previousPaginator
     aria-hidden="true"
     type="button"
     mat-ripple
     tabindex="-1"
     [matRippleDisabled]="_disableScrollBefore || disableRipple"
     [class.mat-tab-header-pagination-disabled]="_disableScrollBefore"
     [disabled]="_disableScrollBefore || null"
     (click)="_handlePaginatorClick('before')"
     (mousedown)="_handlePaginatorPress('before', $event)"
     (touchend)="_stopInterval()">
  <div class="mat-tab-header-pagination-chevron"></div>
</button>

<div class="mat-tab-label-container" #tabListContainer (keydown)="_handleKeydown($event)">
  <div
    #tabList
    class="mat-tab-list"
    [class._mat-animation-noopable]="_animationMode === 'NoopAnimations'"
    role="tablist"
    (cdkObserveContent)="_onContentChanges()">
    <div class="mat-tab-labels" #tabListInner>
      <ng-content></ng-content>
    </div>
    <mat-ink-bar></mat-ink-bar>
  </div>
</div>

<button class="mat-tab-header-pagination mat-tab-header-pagination-after mat-elevation-z4"
     #nextPaginator
     aria-hidden="true"
     type="button"
     mat-ripple
     [matRippleDisabled]="_disableScrollAfter || disableRipple"
     [class.mat-tab-header-pagination-disabled]="_disableScrollAfter"
     [disabled]="_disableScrollAfter || null"
     tabindex="-1"
     (mousedown)="_handlePaginatorPress('after', $event)"
     (click)="_handlePaginatorClick('after')"
     (touchend)="_stopInterval()">
  <div class="mat-tab-header-pagination-chevron"></div>
</button>
