import { Injectable } from '@angular/core';
import { ChatMessageType } from '../../common/models';

import * as Wavesurfer from 'videojs-wavesurfer/dist/videojs.wavesurfer.js';
import * as WaveSurfer from 'wavesurfer.js';
import * as MicrophonePlugin from 'wavesurfer.js/dist/plugin/wavesurfer.microphone.js';
WaveSurfer.microphone = MicrophonePlugin;

@Injectable()
export class RecordMediaService {
  private wavesurferPlugin = Wavesurfer;

  constructor() {
    this.handleVideoRecordIssueOnIOSDevices();
  }

  public getVideoJSConfigBasedOnType(type: ChatMessageType, maxLength = 300): { [key: string]: any } {
    switch (type) {
      case ChatMessageType.AUDIO_MESSAGE:
        const microphonePluginCreate = WaveSurfer.microphone.create && typeof WaveSurfer.microphone.create === 'function' 
           ? WaveSurfer.microphone.create : WaveSurfer.microphone.default.create;
        return {
          controls: false,
          autoplay: false,
          loop: false,
          bigPlayButton: false,
          controlBar: {
            volumePanel: false
          },
          width: 600,
          height: 80,
          plugins: {
            wavesurfer: {
              backend: 'WebAudio',
              debug: false,
              cursorWidth: 1,
              displayMilliseconds: true,
              hideScrollbar: true,
              plugins: [
                // enable microphone plugin
                microphonePluginCreate({
                  bufferSize: 4096,
                  numberOfInputChannels: 1,
                  numberOfOutputChannels: 1,
                  constraints: {
                    video: false,
                    audio: true
                  }
                })
              ]
            },
            record: {
              audio: true,
              video: false,
              recordIndicator: false,
              deviceButton: false,
              frameHeight: 80,
              debug: false,
              timeSlice: 200,
              maxLength
            }
          }
        };
      default:
        
        return {
          controls: false,
          autoplay: false,
          fluid: true,
          loop: false,
          bigPlayButton: false,
          controlBar: {
            volumePanel: false
          },
          plugins: {
            record: {
              screen: false,
              video: {
                width: { ideal: 1080 },
                height: { ideal: 840 }
              },
              frameWidth: 1080,
              frameHeight: 840,
              maxLength,
              audio: true,
              recordIndicator: false,
              deviceButton: false,
              debug: false,
              timeSlice: 500,
              videoMimeType: this.isChrome() ? 'video/webm;codecs=vp9' : undefined
            }
          }
        };
    }
  }

  private isIOS() {
    return [
      'iPad Simulator',
      'iPhone Simulator',
      'iPod Simulator',
      'iPad',
      'iPhone',
      'iPod'
    ].includes(navigator.platform)
    // iPad on iOS 13 detection
    || (navigator.userAgent.includes("Mac") && "ontouchend" in document)
  }

  private isChrome(): boolean {
    return /Chrome/.test(navigator.userAgent) && /Google Inc/.test(navigator.vendor);
  }
  
  /** 
   * On some of the iOS devices, the video gets too big so that Safari fails to process the file and returns 0-byted one without failing (which is undocumented and, obviously, an error).
   * As a workaround, we reduce the quality of a video in the native recording API (which is used by the `videojs-record` and `RecordRTC` internally) so that it won't get to this size.
   * 
   * TODO: remove it when https://bugs.webkit.org/show_bug.cgi?id=85851 is resolved by Apple.
   */
  private handleVideoRecordIssueOnIOSDevices(): void {
    if (!this.isIOS()) {
      return;
    }

    const DefaultMediaRecorder = window.MediaRecorder;
      const kb = 8 * 1024;
      const preferredBitRatePerSecond = 100 * kb;
      window.MediaRecorder = class extends DefaultMediaRecorder {
        constructor(stream, options) {
          super(stream, {
            ...options, 
            audioBitsPerSecond: preferredBitRatePerSecond,
            videoBitsPerSecond: preferredBitRatePerSecond,
          });
        }
      }
  }
}