import { Component, HostBinding, Input, OnInit } from '@angular/core';

@Component({
  selector: 'lib-icon',
  templateUrl: './icon.component.html',
  styleUrls: ['./icon.component.scss']
})
export class IconComponent {
  @Input() public icon: string;
  @Input() public size = 24;
  @Input() public bold = false;
  @Input() public duotone = false;
  @Input() public fill = false;

  @Input() @HostBinding('class.inline') inline = false;
}
