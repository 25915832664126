import { AfterViewInit, Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild, ViewEncapsulation } from '@angular/core';
import { FormControl } from '@angular/forms';
import { DomSanitizer, SafeUrl } from '@angular/platform-browser';
import { TranslateService } from '@ngx-translate/core';

import { ToastrService } from 'ngx-toastr';
import videojs from 'video.js';
import * as Record from 'videojs-record/dist/videojs.record.js';
import { ChatMessageType, MediaRecordStatus } from '../../../common/models';
import { VideoMessageLayout } from '../../models/video-layout.enum';
import { RecordMediaService } from '../../services/record-media.service';
import { RecordedVideoMessageComponent } from '../recorded-video-message/recorded-video-message.component';

@Component({
  selector: 'lib-media-message-recorder',
  templateUrl: './media-message-recorder.component.html',
  styleUrls: ['./media-message-recorder.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class MediaMessageRecorderComponent implements OnInit, AfterViewInit {
  @Input() public type: ChatMessageType;

  @ViewChild('mediaElement') public mediaElement: ElementRef<HTMLMediaElement>;

  @ViewChild('recordedAudioElement') public recordedAudioElement: ElementRef<HTMLAudioElement>;
  @ViewChild(RecordedVideoMessageComponent) public recordedVideoElement: RecordedVideoMessageComponent;

  @Output() public mediaRecorded: EventEmitter<{ file: File, options?: { [key: string]: string} }> = new EventEmitter();

  public readonly maxDuration = 300;

  public status: MediaRecordStatus = MediaRecordStatus.INIT;
  public statuses: typeof MediaRecordStatus = MediaRecordStatus;

  public readonly messageTypes: typeof ChatMessageType = ChatMessageType;

  public timestamp = 0;

  public recordedData: Blob = null;
  public recordDataUrl: string | SafeUrl;

  public titleLabel: string;
  public startLabel: string;
  public readyLabel: string;
  public icon: string;

  public videoLayoutControl = new FormControl(VideoMessageLayout.ROUNDED);

  public readonly videoLayouts: typeof VideoMessageLayout = VideoMessageLayout;

  public videoLayoutOptions = [
    {
      icon: 'user-rectangle',
      type: VideoMessageLayout.LANDSCAPE
    },
    {
      icon: 'user-circle',
      type: VideoMessageLayout.ROUNDED
    },
    {
      icon: 'user-square',
      type: VideoMessageLayout.SQUARE
    },
  ]

  public deviceReady = false;

  public paused = false;

  public playing = true;

  private player = null;
  private plugin = Record;

  constructor(
    protected readonly sanitizer: DomSanitizer,
    private readonly recordMediaService: RecordMediaService,
    private readonly translateService: TranslateService,
    private readonly toastr: ToastrService) {
    this.player = null;
    this.plugin = Record;
  }

  ngOnInit() {
    this.setLabels();
    this.checkBrowserSupport();
  }

  public setVideoLayout(value: VideoMessageLayout): void {
    this.videoLayoutControl.patchValue(value);
  }

  async ngAfterViewInit() {
    await this.initVideoJs();
  }

  ngOnDestroy() {
    if (!this.player) {
      return;
    }

    this.player.dispose();
    this.player = false;
  }

  public emitMediaMessage(): void {
    const data = { file: this.recordedData as File };
    if (this.type === ChatMessageType.VIDEO_MESSAGE) {
      Object.assign(data, { options: { layout: this.videoLayoutControl?.value } });
    }

    this.mediaRecorded.emit(data);
  }

  public togglePlayPause(media: { paused: boolean, play: () => void, pause: () => void }): void {
    if (!media) {
      return;
    }

    if (media.paused) {
      media.play();
      return;
    }

    media.pause();
  }


  public resetPlayer(): void {
    this.player.record().reset();
    this.player.deviceButton.handleClick();
    this.status = MediaRecordStatus.INIT;
    this.recordedData = null;
    this.recordDataUrl = null;
    this.paused = false;
    this.timestamp = 0;

    this.mediaElement.nativeElement.currentTime = 0;
  }

  public async startRecording(): Promise<void> {
    if (!this.player) {
      await this.initVideoJs();
    }
    
    if (this.paused) {
      this.player.record().resume();
      this.paused = false;

      return;
    }

    this.status = MediaRecordStatus.RECORDING;
    this.player.record().start();
  }

  public stopRecording(): void {
    this.player.record().stop();
  }

  public toggleRecordingPause(): void {
    if (this.paused) {
      this.startRecording();
      return;
    }

    this.paused = true;
    this.player.record().pause();
  }

  private async initVideoJs(): Promise<void> {
    return new Promise<void>((resolve, reject) => {
      const config = this.recordMediaService.getVideoJSConfigBasedOnType(this.type, this.maxDuration);
      this.player = videojs(this.mediaElement.nativeElement, config, () => {
        setTimeout(() => {
          this.player.deviceButton.handleClick();
        }, 50)
      });

      this.player.on('deviceReady', () => {
        this.deviceReady = true;
        resolve();
      });

      this.player.on('startRecord', () => {
        this.status = MediaRecordStatus.RECORDING;
      });

      this.player.on('finishRecord', () => {
        this.status = MediaRecordStatus.FINISHED;
        this.recordedData = this.player.recordedData;

        const shouldSanitize = this.type === ChatMessageType.AUDIO_MESSAGE;
        const url = URL.createObjectURL(this.recordedData);
        this.recordDataUrl = shouldSanitize ? this.sanitizer.bypassSecurityTrustResourceUrl(url) : url;
      });

      this.player.on('error', (_, error) => {
        this.toastr.error(error);
      });

      this.player.on('deviceError', () => {
        const error = this.player.deviceErrorCode || 'COMMON.browser_not_support_recording';
        this.toastr.error(error);
      });

      this.player.on('timestamp', () => {
        this.timestamp = this.player.record().getDuration();
      });
    });
  }

  private checkBrowserSupport(): void {
    if (this.type === ChatMessageType.VIDEO_MESSAGE && navigator.userAgent.match('CriOS')) {
      this.toastr.warning('Video recording may not work on Chrome / iOS due to a known error.');
      return;
    }

    if (navigator.mediaDevices && navigator.mediaDevices.enumerateDevices) {
      return;
    }

    const error = this.translateService.instant('COMMON.browser_not_support_recording');
    this.toastr.error(error);
  }

  private setLabels(): void {
    switch (this.type) {
      case ChatMessageType.AUDIO_MESSAGE:
        this.titleLabel = 'CHAT.audio_message';
        this.startLabel = 'CHAT.record_instant_audio_message';
        this.readyLabel = 'CHAT.your_audio_message_ready';
        this.icon = 'microphone';
        break;
      default:
        this.titleLabel = 'CHAT.video_message';
        this.startLabel = 'CHAT.record_instant_video_message';
        this.readyLabel = 'CHAT.your_video_message_ready';
        this.icon = 'video-camera';
    }
  }
}
