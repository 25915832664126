import { Component, Input, OnInit } from '@angular/core';
import { PayableNode } from '@app/model/payable-node';
import { ImageModel } from 'library-explorer';

@Component({
  selector: 'app-content-card',
  templateUrl: './content-card.component.html',
  styleUrls: ['./content-card.component.scss']
})
export class ContentCardComponent implements OnInit {
  @Input() public item: PayableNode & { mediaImage: ImageModel, time: number };
  
  constructor() { }

  ngOnInit(): void {
  }

}
