<div class="cards-placeholder">
  <small class="warning-loading-message" *ngIf="isHighLoadingTime">{{'COMMON.higher_loading_time_than_expected' | translate}}</small>

  <div class="cards-base-grid" [class.row-view]="layout === layouts.ROW">
    <div 
      class="skeleton-card" 
      *ngFor="let item of placeholders">
      <lib-skeleton-loader class="skeleton-card-image"></lib-skeleton-loader>
      <lib-skeleton-loader class="skeleton-card-title" height="22px" width="60%"></lib-skeleton-loader>
      <lib-skeleton-loader class="skeleton-card-info" height="18px" width="20%"></lib-skeleton-loader>
    </div>
  </div>
</div>
