import { Inject, Injectable } from '@angular/core';
import * as Sentry from '@sentry/browser';
import * as jwtDecode from 'jwt-decode';
import { HttpService, NetworkService, OfflineModeService, ProfileService, ProfileUserDTO, RefreshTokenType, REGEX_LIST, SessionDTO, SessionStorageService, StorageKeys, StorageService, STORAGE_KEYS, SettingsModel } from 'library-explorer';
import { BehaviorSubject, from, MonoTypeOperatorFunction, Observable, of, Subscription, throwError, timer } from 'rxjs';
import { catchError, delay, filter, finalize, map, mergeMap, pairwise, retryWhen, take, tap } from 'rxjs/operators';
import { AuthApiService } from './api/auth-api.service';
import { UserService } from './api/user.service';
import { BranchService } from './branch.service';

import { Router } from '@angular/router';
import { StorageMap } from '@ngx-pwa/local-storage';
import { SettingsService } from './settings.service';
import { BiometricAuthService } from './biometric-auth.service';
import { Capacitor } from '@capacitor/core';
import { LanguageService } from './language.service';
import { TranslateService } from '@ngx-translate/core';
import { ToastrService } from 'ngx-toastr';
import { PushNotificationService } from './push-notification.service';

type TokenData = { token: string; refreshToken?: string; };

@Injectable({
  providedIn: 'root'
})
export class AuthService {
  public redirectUrl = '';
  public redirectUrlParams: any;
  public refreshTokenSub: Subscription;
  public refreshTokenKey: string;

  private initialized: BehaviorSubject<boolean> = new BehaviorSubject(false);

  private readonly REFRESH_TOKEN_TIMEOUT_BEFORE_EXP = 30000;

  public shouldStoreRefreshToken = true;

  constructor(
    @Inject(STORAGE_KEYS) private readonly storageKeys: StorageKeys,
    @Inject(REGEX_LIST) private readonly regexList: any,
    private readonly storageService: StorageService,
    private readonly branchService: BranchService,
    private readonly userService: UserService,
    private readonly authApiService: AuthApiService,
    private readonly sessionStorageService: SessionStorageService,
    private readonly settingsService: SettingsService,
    private readonly profileService: ProfileService,
    private readonly httpService: HttpService,
    private readonly bioAuthService: BiometricAuthService,
    private networkService: NetworkService,
    private offlineModeService: OfflineModeService,
    private languageService: LanguageService,
    private translateService: TranslateService,
    private toastrService: ToastrService,
    private pushNotificationService: PushNotificationService,
    private router: Router,
    private storage: StorageMap,
  ) {
    this.initialize();
  }

  public isAuthorized(): Observable<boolean> {
    return this.initialized
      .pipe(
        filter(init => init),
        mergeMap(() => this.sessionStorageService.isUserLoggedIn())
      )
  }

  public isInitialized(): Observable<boolean> {
    return this.initialized
      .pipe(
        filter(init => init),
        take(1)
      )
  }

  public logout(accountDeleted = false, url = '', urlParams = {}, clearBiometricData = false) {
    this.setLoggedUserIdToStorage(null);
    const userId = this.profileService.getCurrentProfileValue()?.id;

    const redirectUrl = new URL(`${window.location.origin}/${url}`);
    Object.entries(urlParams).forEach(([key, value]: [string, string]) => {
      if (!value) {
        return;
      }

      redirectUrl.searchParams.append(key, value);
    });

    if (Capacitor.isNativePlatform() && clearBiometricData) {
      this.bioAuthService.clearBiometricData();
    }

    if (userId && !accountDeleted) {
      this.authApiService.logoutRequest(userId)
        .subscribe(data => {
          this.branchService.setCurrentBranch(null);
          this.storageService.removeItem(this.storageKeys.REFRESH_TOKEN);
          window.location.href = data?.ssoLogOutUrl || redirectUrl.toString();
        });

      return;
    }

    this.branchService.setCurrentBranch(null);
    this.storageService.removeItem(this.storageKeys.REFRESH_TOKEN);
    window.location.href = redirectUrl.toString();
  }

  public setUsetSession(data: SessionDTO): void {
    this.branchService.setCurrentBranch(data.authenticatedBranch);
    this.setJwtToken({ token: data?.token, refreshToken: data?.[this.refreshTokenKey] });
    this.setLoggedUser(data);
  }

  public checkForSessionChanged(): boolean {
    const currentId = this.profileService?.getCurrentProfileValue()?.id;
    const lastLoggedIdFromStorage = this.storageService.getItem(this.storageKeys.LOGGED_IN_USER);

    if (!lastLoggedIdFromStorage && !currentId) {
      return false;
    }

    return currentId !== lastLoggedIdFromStorage;
  }

  public async loginHandler(data: Partial<SessionDTO>, redirectUrl = this.redirectUrl, branchId?: string): Promise<void> {
    this.setUsetSession(data as SessionDTO);
    const updatedPreferedLanguage = await this.languageService.initUserPreferedContentLanguage();

    if (updatedPreferedLanguage) {
      const language = updatedPreferedLanguage?.name;
      const message = this.translateService.instant('PROFILE.preferred_content_language_automatically_changed', { language });
      this.toastrService.success(message);
    }

    this.pushNotificationService.registerNotifications();
    this.router.navigateByUrl(redirectUrl, { state: { switchToBranch: branchId } });
  }

  private setLoggedUser(user: ProfileUserDTO): void {
    this.profileService.setCurrentProfile(user);

    this.setSentryUser(user);
    this.storeUserDatatOffline(user);
    this.setLoggedUserIdToStorage(user?.id);
    this.loadStoredRequests(user);
  }

  private setLoggedUserIdToStorage(id: string): void {
    if (!id) {
      this.storageService.removeItem(this.storageKeys.LOGGED_IN_USER);
      return;
    }

    this.storageService.setItem(this.storageKeys.LOGGED_IN_USER, id);
  }

  public initialize(): void {
    this.setupNetworkWatcher();

    if (!this.networkService.isOnlineValue()) {
      this.initOfflineMode();
      return;
    }

    this.initAuthentication();
  }

  public setJwtToken(data: TokenData): void {
    this.sessionStorageService.setJwtToken(data?.token);
    this.startRefreshTokenTimer(data?.token);
    this.storageService.removeItem(this.storageKeys.REFRESH_TOKEN);

    if (data?.refreshToken) {
      this.storageService.setItem(this.storageKeys.REFRESH_TOKEN, data?.refreshToken);
    }
  }

  public getRefreshToken(): Observable<TokenData> {
    let refreshTokenData = null;

    if (this.shouldStoreRefreshToken) {
      const token = this.storageService.getItem(this.storageKeys.REFRESH_TOKEN);

      if (!token) {
        return of(null);
      }

      refreshTokenData = { [this.refreshTokenKey]: token };
    }

    return this.authApiService.getRefreshToken(refreshTokenData)
      .pipe(
        retryWhen(this.retryWhenStatusNot401()),
        map(data => ({ token: data.token, refreshToken: data[this.refreshTokenKey] })),
        catchError((err) => {
          this.logout();
          throw err;
        })
      );
  }

  /**
   * Initializes the authentication process.
   * Retrieves settings, configures the refresh token, sets user data and token,
   * and finalizes the authentication process.
   */
  private initAuthentication(): void {
    this.settingsService.getSettings()
      .pipe(
        tap(data => this.configureRefreshToken(data)),
        mergeMap(() => this.getRefreshToken()),
        mergeMap(data => this.getUserDataAndSetToken(data)),
        tap(user => this.setLoggedUser(user)),
        finalize(() => {
          if (this.initialized.value) {
            return;
          }

          this.initialized.next(true);
        }),
        catchError((err) => {
          this.setLoggedUser(null);
          throw err;
        })
      )
      .subscribe();
  }

  private setupNetworkWatcher(): void {
    this.networkService.isOnline()
      .pipe(pairwise())
      .subscribe(async ([previous, current]) => {
        if (!previous && current) {
          await this.handleOnlineTransition();
        }
      });
  }

  private getUserDataAndSetToken(data: any): Observable<ProfileUserDTO | null> {
    if (!data) {
      return of(null);
    }

    const decodedToken: { sub: string } = jwtDecode(data?.token);
    this.setJwtToken(data);

    return this.userService.getUserById(decodedToken.sub);
  }

  private configureRefreshToken(settings: SettingsModel): void {
    const { parameterType = RefreshTokenType.COOKIE, parameterName } = settings?.private?.accessIdentity?.refreshToken || {};
    this.shouldStoreRefreshToken = parameterType === RefreshTokenType.REQUEST_RESPONSE;
    this.refreshTokenKey = parameterName;
  }

  private initOfflineMode() {
    this.settingsService.getSettings().pipe(
      mergeMap(() => {
        return this.userOfflineData()
      }),
      tap(user => {
        if (!user) {
          this.router.navigate(['access/login']);
          return;
        }
        this.setLoggedUser(user);
      }),
      finalize(() => this.initialized.next(true))
    ).subscribe();
  }

  private async handleOnlineTransition() {
    const token = this.sessionStorageService.getJwtTokenValue();

    if (token && !this.sessionStorageService.isTokenExpired(token)) {
      const decodedToken: { sub: string } = jwtDecode(token);
      this.userService.getUserById(decodedToken.sub)
        .subscribe((user: ProfileUserDTO) => this.setLoggedUser(user));
      return;
    }

    await this.settingsService.clearCache();
    this.initAuthentication();
  }

  private startRefreshTokenTimer(token: string) {
    if (this.refreshTokenSub) {
      this.refreshTokenSub.unsubscribe();
    }

    if (!token || !this.networkService.isOnlineValue()) return;

    const tokenRefreshTimeout = this.calculateTokenRefreshTimeout(token);

    if (tokenRefreshTimeout < 0) {
      this.logout();
      return;
    }

    this.refreshTokenSub = timer(tokenRefreshTimeout)
      .pipe(
        mergeMap(() => this.refreshTokenAndCheckOnline()),
        tap(data => {
          if (!data.isOnline) {
            return;
          }

          if (!data?.token) {
            return;
          }

          this.setJwtToken(data?.token);
        })
      )
      .subscribe();
  }

  private calculateTokenRefreshTimeout(token: string): number {
    const tokenExpTime = this.sessionStorageService.getTokenExpTime(token);
    const currentTime = new Date().getTime();

    return tokenExpTime - currentTime - this.REFRESH_TOKEN_TIMEOUT_BEFORE_EXP;
  }

  private refreshTokenAndCheckOnline(): Observable<{ token: TokenData, isOnline: boolean }> {
    return from(this.networkService.isOnline())
      .pipe(
        mergeMap(isOnline => {
          if (!isOnline) {
            return of({ token: null, isOnline });
          }

          return this.getRefreshToken()
            .pipe(
              map((token: TokenData) => {
                return { token, isOnline }
              })
            );
        })
      );
  }

  retryWhenStatusNot401(maxRetries: number = 3, delayValue: number = 1000): (errors: Observable<any>) => Observable<any> {
    return (errors: Observable<any>) => {
      let retryCount = 0;

      return errors.pipe(
        mergeMap((res) => {
          if (res.status !== 401) {
            return throwError(res); // Stop retrying for non-401 errors
          }

          retryCount++;
          if (retryCount >= maxRetries) {
            return throwError(res); // Retry count exceeded, stop retrying
          }

          // Retry for 401 errors
          return of(res).pipe(
            delay(delayValue)
          );
        }),
        catchError((error: any) => {
          return throwError(error); // Rethrow the error if maximum retries exceeded or for non-401 errors
        })
      );
    };
  }

  private async loadStoredRequests(user: ProfileUserDTO) {
    if (!this.networkService.isOnlineValue() || !user) {
      return;
    }

    //Get all pending requests from indexedDB
    const reqs = await this.storage.get(this.storageKeys.PENDING_REQUESTS).toPromise() as any[];

    if (reqs && reqs.length) {
      reqs.forEach(req => {
        const isAbsolute = req.url.match(this.regexList.url);

        const request = isAbsolute
          ? this.httpService.postThirdParty(req.url, req.body, req.withCredentials)
          : this.httpService.post(req.url, req.body, req.withCredentials);

        request
          .pipe(
            finalize(() => {
              // TODO: improve
              // Remove request from list when done
              this.offlineModeService.removeAndUpdatePendingArr(this.storageKeys.PENDING_REQUESTS, req)
            })
          )
          .subscribe();
      })
    }
  }

  private setSentryUser(user: ProfileUserDTO): void {
    if (!user) {
      Sentry.configureScope(scope => scope.setUser(null));
      return;
    }

    Sentry.setUser({ id: user.id, username: `${user.name} ${user.lastName}`, email: user.email });
  }

  private storeUserDatatOffline(user: ProfileUserDTO): void {
    this.storage.set(this.storageKeys.OFFLINE_USER_DATA, user).subscribe();
  }

  private userOfflineData(): Observable<ProfileUserDTO | null> {
    const userData = this.storage.get(this.storageKeys.OFFLINE_USER_DATA) as Observable<ProfileUserDTO>;
    return userData || of(null);
  }
}
