import { Injectable } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { ToastrService } from 'ngx-toastr';
import { BehaviorSubject, EMPTY, Observable, of, Subject } from 'rxjs';
import { catchError, debounceTime, distinctUntilChanged, filter, map, mergeMap, take, tap } from 'rxjs/operators';
import * as SendBird from 'sendbird';
import { ChatChannel } from '../model/chat-channel';

import { SettingsModel, ChatTypeEnum, FileModel } from 'library-explorer';
import { ChatListDialogComponent } from '../shared/components/chat-shared/chat-list-dialog/chat-list-dialog.component';
import { ChatApiService } from './api/chat-api.service';
import { ProfileService, ChatMessageType} from 'library-explorer';
import { SendbirdHelperService } from './sendbird-helper.service';
import { ChatRecordMediaDialogComponent } from '@app/shared/components/chat-shared/chat-record-media-dialog/chat-record-media-dialog.component';
import { SettingsService } from './settings.service';


@Injectable({
  providedIn: 'root'
})
export class SendbirdService {
  public messageReceived: Subject<{ message, channelUrl }> = new Subject();
  public messageDeleted: Subject<{ messageId, channelUrl }> = new Subject();
  public messageUpdated: Subject<{ message, channelUrl }> = new Subject();

  public messageSent: Subject<void> = new Subject();

  public initialized: BehaviorSubject<boolean> = new BehaviorSubject(false);

  public loadingIndicatorId: BehaviorSubject<string> = new BehaviorSubject(null);

  public activeChannel: BehaviorSubject<ChatChannel> = new BehaviorSubject(null);

  public unreadMessagesCount: BehaviorSubject<number> = new BehaviorSubject(0);
  public leaveChannelSub: Subject<string> = new Subject();

  public openChannels: SendBird.OpenChannel[];

  public chatDialog: MatDialogRef<ChatListDialogComponent>;
  public listDialog: MatDialogRef<ChatListDialogComponent>;

  private sendBird: SendBird.SendBirdInstance;

  private set currentUser(value: SendBird.User) {
    const userNotChanged = this._currentUser && value && this._currentUser.userId === value.userId;
    this._currentUser = value;

    if (value && !userNotChanged) {
      this.getTotalUnreadMessageCount();
      this.addUserEventHandler();
      this.addChannelEventHandler();
      this.initialized.next(true);
    }
  }

  private get currentUser() {
    return this._currentUser;
  }

  private _currentUser: SendBird.User;
  private refreshTokenTimeout: NodeJS.Timer;
  private settings: SettingsModel;

  private readonly channelHandlerId = 'channelHandlerId';
  private readonly userHandlerId = 'userHandlerId';

  constructor(
    private readonly sendbirdHelperService: SendbirdHelperService,
    private readonly chatApiService: ChatApiService,
    private readonly settingsService: SettingsService,
    private readonly toastrService: ToastrService,
    private readonly profileService: ProfileService) {
      this.initializeSendbirdService();
  }

  public openGroupChannels(): Observable<void> {
    return this.chatApiService.openGroupChannels()
      .pipe(
        mergeMap(() => this.checkSendingApplicationIsInitialized()),
        catchError(err => {
          this.closeChatListDialog();
          this.closeChatDialog();
          throw err;
        })
      );
  }

  public getPreviousMessagesQuery(channel: ChatChannel, limit = 10): SendBird.PreviousMessageListQuery {
    const listQuery = channel.createPreviousMessageListQuery();
    listQuery.limit = limit;
    listQuery.includeReplies = true;

    return listQuery;
  }

  public markChannelAsRead(channel: ChatChannel): void {
    if (!channel || channel.unreadMessageCount === 0) {
      return;
    }

    channel.unreadMentionCount = 0;
    channel.markAsRead();
  }

  public leaveChannel(channel: ChatChannel): void {
    this.chatApiService.leaveChatRoom(channel.entityId || channel.data)
      .subscribe(() => {
        channel.leave(() => {
          this.leaveChannelSub.next(channel.url);
        });
      });
  }

  public deleteMessage(channel: ChatChannel, message: SendBird.UserMessage): void {
    const { type, entityId } = channel;

    this.chatApiService.postChatDeleteMessage(type, message.messageId, entityId)
      .subscribe(() => {
        this.messageDeleted.next({ channelUrl: channel.url, messageId: message.messageId });
      });
  }

  public updateMessage(channel: ChatChannel, message: SendBird.UserMessage, text: string): void {
    const params = new this.sendBird.UserMessageParams();
    params.message = text;

    channel.updateUserMessage(message.messageId, params, (updatedMessage: SendBird.UserMessage) => {
      this.messageUpdated.next({ channelUrl: channel.url, message: updatedMessage });
    });
  }

  public sendFile(channel: ChatChannel, file: File, type?: ChatMessageType): void {
    const params = new this.sendBird.FileMessageParams();
    params.file = file;
    params.fileName = file.name;

    if (type) {
      params.customType = type;
    }

    channel.sendFileMessage(params, (message, error) => {
      if (error) {
        this.toastrService.error(error.message);
        return;
      }
      this.messageReceived.next({ channelUrl: channel.url, message });
      this.trackChatMessageSent(channel);
      this.messageSent.next();
    });
  }

  public sendAwsFileMessage(channel: ChatChannel, media: Partial<FileModel>, type: ChatMessageType, options = {}): Observable<void> {
    if (!channel) {
      return;
    }
  
    const params = new this.sendBird.UserMessageParams();
    params.message = type;
    params.customType = type;
    params.data = JSON.stringify({ mediaId: media.id, key: media.key, name: media.filename, options });

    return new Observable(subscriber => {
      channel.sendUserMessage(params, (message, error) => {
        if (error) {
          this.toastrService.error(error.message);
          subscriber.error(error);
          subscriber.complete();
          return;
        }

        this.messageReceived.next({ channelUrl: channel.url, message });
        this.trackChatMessageSent(channel, media.id);

        subscriber.next();
        subscriber.complete();
        this.messageSent.next();
      });
    })
  }

  public sendMessage(channel: ChatChannel, text: string, parentMessageId = null): void {
    if (!channel) {
      return;
    }
  
    const params = new this.sendBird.UserMessageParams();
    params.message = text;
    params.parentMessageId = parentMessageId;

    channel.sendUserMessage(params, (message, error) => {
      if (error) {
        this.toastrService.error(error.message);
        return;
      }

      this.messageReceived.next({ channelUrl: channel.url, message });
      this.trackChatMessageSent(channel);

      this.messageSent.next();
    });
  }

  public getGroupChannelsQuery(search: string = '', additinalFilters: Partial<SendBird.GroupChannelListQuery> = {}): SendBird.GroupChannelListQuery {
    const listQuery = this.sendBird.GroupChannel.createMyGroupChannelListQuery();
 
    listQuery.includeEmpty = true;
    listQuery.order = 'latest_last_message';
    listQuery.channelNameContainsFilter = search || '';
    listQuery.limit = 10;
    listQuery.customTypesFilter = [this.getCustomChannelBranchType()];
    Object.assign(listQuery, { ...additinalFilters });

    return listQuery;
  }

  public addChannelEventHandler(): void {
    this.removeChannelEventHandler();

    const channelHandler = new this.sendBird.ChannelHandler();

    channelHandler.onMessageReceived = (channel, message) => {
      this.messageReceived.next({ channelUrl: channel.url, message });
    };
    channelHandler.onMessageDeleted = (channel, messageId) => {
      this.messageDeleted.next({ channelUrl: channel.url, messageId });
    };
    channelHandler.onMessageUpdated = (channel, message) => {
      this.messageUpdated.next({ channelUrl: channel.url, message });
    };

    this.sendBird.addChannelHandler(this.channelHandlerId, channelHandler);
  }

  public removeChannelEventHandler(): void {
    this.sendBird.removeChannelHandler(this.channelHandlerId);
  }

  public addUserEventHandler(): void {
    this.removeUserEventHandler();
    const userHandler = new this.sendBird.UserEventHandler();
    
    userHandler.onTotalUnreadMessageCountUpdated = (totalCount: number, countByCustomTypes: any) => {
      const type = this.getCustomChannelBranchType();
      this.unreadMessagesCount.next(countByCustomTypes?.[type] || totalCount);
    };

    this.sendBird.addUserEventHandler(this.userHandlerId, userHandler);
  }

  public removeUserEventHandler(): void {
    this.sendBird.removeUserEventHandler(this.userHandlerId);
  }

  public joinChat(id: string): void {
    this.getChannelUrl(id)
      .subscribe(data => {
        this.sendBird.GroupChannel.getChannel(data, (channel) => {
          const mappedChannel = this.mapToChatChannel(channel);
          this.activeChannel.next(mappedChannel);

          this.openChatDialog();
        });
      });
  }

  public getChannelByUrl(url: string): Observable<ChatChannel> {
    return new Observable(subscriber => {
      this.initialized
        .pipe(
          filter(initialized => initialized)
        )
        .subscribe(() => {
          this.sendBird.GroupChannel.getChannel(url, (channel) => {
            const mappedChannel = this.mapToChatChannel(channel);
            subscriber.next(mappedChannel);
            subscriber.complete();
          });
        });
    });
  }

  public mapToChatChannel(channel: SendBird.GroupChannel): ChatChannel {
    const channelData = channel.data;

    if (this.isJson(channelData)) {
      const data = JSON.parse(channelData);
      Object.assign(channel, { entityId: data.entityId });

      if (data.key) {
        Object.assign(channel, { image: { key: data.key, provider: data.provider } });
      }

      if (data.type) {
        Object.assign(channel, { type: data.type });
      }
    } else {
      Object.assign(channel, { entityId: channelData });
    }

    return channel;
  }

  public getChannelUrl(id: string, customType: string = null): Observable<string> {
    return this.chatApiService.joinChatRoom(id, customType)
      .pipe(
        mergeMap(data => {
          return this.checkSendingApplicationIsInitialized()
            .pipe(
              map(() => data)
            )
        }),
        map(data => data.channel_url),
        catchError(err => {
          this.closeChatListDialog();
          this.closeChatDialog();
          throw err;
        })
      );
  }

  public openChatRecordMediaDialog(type: ChatMessageType, element: HTMLElement): MatDialogRef<ChatRecordMediaDialogComponent> {
    return this.sendbirdHelperService.openChatRecordMediaDialog(type, element);
  }

  public closeChatRecordMediaDialog(): void {
    this.sendbirdHelperService.closeChatRecordMediaDialog();
  }

  public openChatListDialog(nativeElement = null): void {
    this.sendbirdHelperService.openChatListDialog(nativeElement);
  }

  public openChatDialog(): void {
    this.sendbirdHelperService.openChatDialog();
  }

  public minimizeChatDialogToggle(minimize = false): void {
    this.sendbirdHelperService.minimizeChatDialogToggle(minimize);
  }

  public closeChatDialog(): void {
    this.sendbirdHelperService.closeChatDialog();
  }

  public closeChatListDialog(): void {
    this.sendbirdHelperService.closeChatListDialog();
  }

  private initializeSendbird(appId: string): void {
    if (this.sendBird) {
      return;
    }

    this.sendBird = new SendBird({ appId });
  }

  private connectSendbirdUser(): Observable<void> {
    if (this.currentUser) {
      return of(null);
    }

    const userId = this.profileService.getCurrentProfileValue()?.id;

    if (userId) {
      return this.chatApiService.getSendbirdAccessToken()
        .pipe(
          tap((data) => data.token && this.setRefreshAccessTokenInterval(data)),
          mergeMap((data) => data.token ? this.connectUser(userId, data.token) : of(null))
        );
    }

    return of();
  }

  private setRefreshAccessTokenInterval(data: { token: string, expires_at: Date}): void {
    const timeout = new Date(+data.expires_at).getTime() - Date.now() - (60 * 1000);
    this.refreshTokenTimeout = setTimeout(() => this.connectSendbirdUser().subscribe(), timeout);
  }

  private connectUser(id: string, token: string): Observable<void> {
    return new Observable(subscriber => {
      this.sendBird.connect(id, token, user => {
        this.currentUser = user;

        subscriber.next();
        subscriber.complete();
      });
    });
  }

  private getTotalUnreadMessageCount(): void {
    const type = this.getCustomChannelBranchType();

    const params = new this.sendBird.GroupChannelTotalUnreadMessageCountParams();
    params.channelCustomTypesFilter = [type];
    
    this.sendBird.getTotalUnreadMessageCount(params, unreadMessagesCount => {
      this.unreadMessagesCount.next(unreadMessagesCount);
    });
  }

  private initializeSendbirdService(): void {
    this.settingsService.getSettings()
      .pipe(
        filter(data => !!data),
        take(1),
        tap(data => this.settings = data),
        filter(data => data.chat.chatType === ChatTypeEnum.INTERNAL_MESSAGING)
      )
      .subscribe(() => {
        this.addSubbscriptionOnCurrentUser();
      });
  }

  private addSubbscriptionOnCurrentUser(): void {
    this.profileService.getCurrentProfile()
      .pipe(
        distinctUntilChanged((old, current) => old && current && old.id === current.id && !!this.sendBird),
        filter(data => {
          if (!data) {
            this.disconnect();
            return false;
          }

          return true;
        }),
        debounceTime(200),
        mergeMap(() => this.initializeSendingApplication()),
        mergeMap(() => this.connectSendbirdUser())
      )
      .subscribe();
  }

  private initializeSendingApplication(): Observable<void> {
    if (this.sendBird) {
      return of(null);
    }

    return this.chatApiService.getSendbirdAppId()
      .pipe(
        map(data => {
          if (data && data.applicationId) {
            this.initializeSendbird(data.applicationId);
          }
        }),
        catchError(() => {
          this.disconnect();
          return EMPTY;
        })
      );
  }

  private disconnect(): void {
    if (this.sendBird) {
      this.removeChannelEventHandler();
      this.removeUserEventHandler();
      this.sendBird.disconnect();
    }
    this.initialized.next(false);
    this.activeChannel.next(null);
    this.unreadMessagesCount.next(0);
    clearTimeout(this.refreshTokenTimeout);
    this.closeChatDialog();
    this.closeChatListDialog();
    this.currentUser = null;
  }

  private trackChatMessageSent(channel: ChatChannel, mediaId = null): void {
    const { type, entityId } = channel;
    this.chatApiService.postChatSendMessage(type, entityId, mediaId).subscribe();
  }

  private checkSendingApplicationIsInitialized(): Observable<void> {
    return this.initializeSendingApplication()
      .pipe(
        mergeMap(() => this.connectSendbirdUser()),
      )
  }

  private getCurrentBranchId(): string {
    const currentBranch = this.settings.branch.currentBranch;
    return currentBranch ? currentBranch.branchId : 'global';
  }

  private getCustomChannelBranchType(): string {
    return `branch-${this.getCurrentBranchId()}`;
  }

  private isJson(data: string): boolean {
    try {
      JSON.parse(data);
    } catch (e) {
      return false;
    }

    return true;
  }
}
