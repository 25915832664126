import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { SharedLibraryModule } from '../common/shared-library.module';
import { MediaMessageRecorderComponent } from './components/media-message-recorder/media-message-recorder.component';
import { RecordMediaService } from './services/record-media.service';
import { RecordedVideoMessageComponent } from './components/recorded-video-message/recorded-video-message.component';



@NgModule({
  declarations: [
    MediaMessageRecorderComponent,
    RecordedVideoMessageComponent
  ],
  imports: [
    CommonModule,
    SharedLibraryModule
  ],
  exports: [
    MediaMessageRecorderComponent,
    RecordedVideoMessageComponent
  ],
  providers: [
    RecordMediaService
  ]
})
export class RecordMediaModule { }
