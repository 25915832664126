
<div class="media-recorder" [ngClass]="type">
  <div class="media-recorder-header">
    <h3 class="media-recorder-title">
      {{ titleLabel | translate }}
    </h3>
  </div>

  <div class="media-recorder-body">
    <h6 class="media-recorder-status-message">
      <ng-container [ngSwitch]="status">
        <ng-container *ngSwitchCase="statuses.INIT">
          {{ startLabel | translate }}
        </ng-container>
        <ng-container *ngSwitchCase="statuses.RECORDING">
          <div class="dot-indicator" [class.paused]="paused"></div>

          {{ (paused ? 'CHAT.paused' : 'CHAT.recording') | translate }}

          <div class="limit-time-block">
            {{ 'CHAT.limit' | translate }} 
            {{ maxDuration | secondsToTime }}
          </div>

        </ng-container>

        <ng-container *ngSwitchCase="statuses.FINISHED">
          {{ readyLabel | translate }}
        </ng-container>
      </ng-container>
    </h6>

    <div class="video-container media-element" [ngClass]="videoLayoutControl.value" [class.hidden]="type === messageTypes.AUDIO_MESSAGE || status === statuses.FINISHED">
      <video #mediaElement class="video-js vjs-default-skin" playsinline></video>
    </div>

    <ng-container [ngSwitch]="status">

      <ng-container *ngSwitchCase="statuses.FINISHED">
      
        <ng-container [ngSwitch]="type">

          <ng-container *ngSwitchCase="messageTypes.AUDIO_MESSAGE">
            <audio class="media-element hidden-media" [src]="recordDataUrl" #recordedAudioElement></audio>
          </ng-container>
      
          <ng-container *ngSwitchDefault>
            <lib-recorded-video-message 
              class="media-element" 
              theme="primary" 
              [src]="recordDataUrl" 
              [videoShape]="videoLayoutControl?.value"
              [hidePlayButton]="true"></lib-recorded-video-message>
          </ng-container>

        </ng-container>
      </ng-container>

      <ng-container *ngSwitchDefault>
        <ng-container [ngSwitch]="type">
          <ng-container *ngSwitchCase="messageTypes.VIDEO_MESSAGE">
            <div class="video-layout-switcher" *ngIf="deviceReady">
              <button 
                *ngFor="let item of videoLayoutOptions" 
                class="video-layout-option" 
                mat-icon-button
                [class.selected]="videoLayoutControl.value === item.type"
                (click)="setVideoLayout(item.type)">
                <lib-icon [icon]="item.icon"></lib-icon>
              </button>
            </div>
          </ng-container>
        </ng-container>
      </ng-container>
    </ng-container>
  </div>

  <div class="media-recorder-footer">
    <ng-container [ngSwitch]="status">
      <ng-container *ngSwitchCase="statuses.RECORDING">
        <button mat-icon-button class="record-button" (click)="resetPlayer()">
          <lib-icon icon="trash"></lib-icon>
        </button>

        <button 
          class="primary-button record-button pause-play-button" 
          mat-flat-button 
          (click)="toggleRecordingPause()">
          <div class="record-button-content">
            <lib-icon icon="play-pause"></lib-icon>

            <span class="record-timestamp">
              {{ timestamp | secondsToTime }}

              <span class="total">/ {{ maxDuration | secondsToTime }}</span>
            </span>
          </div>
        </button>

        <button class="primary-button record-button" mat-fab (click)="stopRecording()">
          <lib-icon icon="stop"></lib-icon>
        </button>

      </ng-container>
    
      <ng-container *ngSwitchCase="statuses.CONVERTING">
        
      </ng-container>

      <ng-container *ngSwitchCase="statuses.FINISHED">
        <button mat-icon-button class="record-button" (click)="resetPlayer()">
          <lib-icon icon="trash"></lib-icon>
        </button>

        <ng-container *ngIf="(type === messageTypes.AUDIO_MESSAGE ? recordedAudioElement?.nativeElement : recordedVideoElement?.videoElement?.plyr) as mediaRecorded">
          <button 
            class="primary-button record-button pause-play-button" 
            mat-flat-button 
            (click)="togglePlayPause(mediaRecorded)">
            <div class="record-button-content">
              <lib-icon [icon]="mediaRecorded.paused ? 'play' : 'pause'"></lib-icon>

              <span class="record-timestamp">
                {{ (mediaRecorded.paused ? timestamp : mediaRecorded.currentTime) | secondsToTime }} 
              </span>
            </div>
          </button>
        </ng-container>

        <button class="primary-button record-button" mat-fab (click)="emitMediaMessage()">
          <lib-icon icon="arrow-up"></lib-icon>
        </button>
      </ng-container>

      <ng-container *ngSwitchDefault>
        <button mat-flat-button class="primary-button record-button" (click)="startRecording()" [disabled]="!deviceReady">
          <div class="record-button-content">
            <lib-icon [icon]="icon"></lib-icon>
            {{ 'CHAT.start_recording' | translate }}
          </div>
        </button>
      </ng-container>
    </ng-container>

  </div>
</div>
