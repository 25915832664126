import { Directive, OnDestroy, OnInit, TemplateRef, ViewContainerRef } from '@angular/core';
import { BiometricAuthService } from '@app/services/biometric-auth.service';
import { combineLatest, Subscription } from 'rxjs';
import { take } from 'rxjs/operators';

@Directive({
  selector: '[ngNativeShowBiometricLogin]'
})
export class NativeShowBiometricLoginDirective implements OnInit, OnDestroy {
  private subscription: Subscription;

  constructor(
    private templateRef: TemplateRef<any>,
    private viewContainer: ViewContainerRef,
    private biometricAuthService: BiometricAuthService
  ) { }

  ngOnInit() {
    this.subscription = combineLatest([
      this.biometricAuthService.isLoginEnabled(),
      this.biometricAuthService.isBiometricDataAvailable()
    ])
      .pipe(take(1))
      .subscribe(([isLoginEnabled, isBiometricDataAvailable]) => {
        if (isLoginEnabled && isBiometricDataAvailable) {
          this.viewContainer.createEmbeddedView(this.templateRef);
          return;
        }

        this.viewContainer.clear();
      });

  }

  ngOnDestroy() {
    if (this.subscription) {
      this.subscription.unsubscribe();
    }
  }

}
