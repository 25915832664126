export enum PermissionKeys {
  ADMINISTER_USERS = 'administer_users',
  ADMINISTER_NODES = 'administer_nodes',
  ADMINISTER_ROLES = 'administer_roles',
  ADMINISTED_FORUM = 'administer_forum',
  VIEW_MENU_SECTION_EXPORE = 'view_menu_section_explore',
  VIEW_MENU_SECTION_LIBRARY = 'view_menu_section_library',
  VIEW_MENU_SECTION_USERS = 'view_menu_section_users',
  VIEW_MENU_SECTION_GLOSSARY = 'view_menu_section_glossary',
  VIEW_MENU_SECTION_WEBINARS = 'view_menu_section_webinars',
  VIEW_MENU_SECTION_PAGES = 'view_menu_section_pages',
  VIEW_MENU_SECTION_SETTINGS = 'view_menu_section_settings',
  VIEW_MENU_SECTION_FORUM = 'view_menu_section_forum'
}
