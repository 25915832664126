import { Component, Input } from '@angular/core';
import { BaseCardComponent } from '../base-card/base-card.component';

@Component({
  selector: 'lib-class-card',
  templateUrl: './class-card.component.html',
  styleUrls: ['./class-card.component.scss']
})
export class ClassCardComponent extends BaseCardComponent {

  @Input() orientation: 'horizontal'|'vertical' = 'horizontal';

}
