import { registerPlugin } from '@capacitor/core';

export interface SwipeNavigationPlugin {
  enable(): Promise<{ status: boolean }>;
  disable(): void;
  status(): Promise<{ status: boolean }>;
}

const SwipeNavigation = registerPlugin<SwipeNavigationPlugin>('SwipeNavigation');

export default SwipeNavigation;
