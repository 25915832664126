<div class="export-calendar-dialog">
  
  <button mat-icon-button mat-dialog-close class="button button--icon dialog-close-button">
    <lib-icon icon="x"></lib-icon>
  </button>
  

  <h4 class="dialog-title">
    {{ 'EXPORT_CALENDAR.add_event_to_calendar' | translate }}
  </h4>

  <span class="dialog-text">
    {{ 'EXPORT_CALENDAR.select_calendar_application_of_your_choice' | translate }}
  </span>

  <div class="dialog-warning">
    <lib-icon icon="info" [size]="18"></lib-icon>
    {{ 'EXPORT_CALENDAR.if_event_wasnt_added_automatically' | translate }}
  </div>

  <div class="dialog-warning yellow-warning">
    <lib-icon icon="info" [size]="18"></lib-icon>
    {{ 'EXPORT_CALENDAR.in_case_of_the_event_changes' | translate }}
  </div>


  <add-to-calendar-button
    [attr.name]="event.name"
    [attr.description]="event.description"
    [attr.timeZone]="event.timezone"
    [attr.location]="event.location"
    [attr.startDate]="eventDates.startDate"
    [attr.startTime]="eventDates.startTime"
    [attr.endDate]="eventDates.endDate"
    [attr.endTime]="eventDates.endTime"
    [attr.options]="exportOptions"
    buttonStyle="round"
    hideIconList
    buttonsList
    hideBackground
    lightMode="bodyScheme"
  ></add-to-calendar-button>
</div>
