import { Component, OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { of } from 'rxjs';
import { finalize, switchMap } from 'rxjs/operators';
import {  BranchModel } from 'library-explorer';
import { BranchService } from 'src/app/services/branch.service';
import { SettingsService } from '@app/services/settings.service';

@Component({
  selector: 'app-branch-select-dialog',
  templateUrl: './branch-select-dialog.component.html',
  styleUrls: ['./branch-select-dialog.component.scss']
})
export class BranchSelectDialogComponent implements OnInit {

  public branchList: BranchModel[] = [];
  public currentBranch: BranchModel;
  public isSelfEnrolment = false;
  public isLoading = true;

  constructor(
    private readonly dialogRef: MatDialogRef<BranchSelectDialogComponent>,
    private readonly settingsService: SettingsService,
    private readonly router: Router,
    private readonly branchService: BranchService
  ) { }

  ngOnInit() {
    this.branchService.getBranches().pipe(
      switchMap(res => {
        this.branchList = res.filter(item => item.isMember);
        return this.branchList.length ? this.settingsService.getSettings() : of(null);
      }),
      finalize(() => this.isLoading = false)
    ).subscribe(res => {
      if (!res) { return; }
      this.currentBranch = res.branch.currentBranch;
      this.isSelfEnrolment = res.branch.items.some(item => item.selfEnrolment);
    });
  }

  public selectBranch(id: string): void {
    this.branchService.selectBranch(id);
  }

  public navigateToBranchList(): void {
    this.router.navigate(['/branches']);
    this.dialogRef.close();
  }

  public onOutsideClick(): void {
    this.dialogRef.close();
  }

}
