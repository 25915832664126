import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class FlipcardService {

  private _flip$: Subject<{id: string, side?: 'front'|'back'}> = new Subject();

  constructor() { }

  public get onFlip(): Observable<{id: string, side?: 'front'|'back'}> {
    return this._flip$.asObservable();
  }

  public flipById(id: string, side?: 'front'|'back'): void {
    this._flip$.next({id, side});
  }

  
}
