<div class="tp-wrapper" [id]="item.id" [ngClass]="item.orientation">
  <div class="tp-box" [class.cursor-pointer]="allowFlipOnClick" [class.active]="flipped" [class.error]="errors" (click)="flipOnClick($event)" (mousedown)="onMouseDown($event)">
    <div class="tp-box__side tp-box__front d-flex" 
      [class.video]="item.frontEmbedVideo || item.frontVideo"
      [dynamicWidthCoeff]="3"
      [cardBackground]="{ backgroundColor: item.frontBackgroundColor, mediaBackground: item.frontMediaBackground, backgroundPresigned }">
      <mat-spinner *ngIf="item.frontMediaBackground?.uploadingInProgress" class="image-loader" color="primary" [diameter]="20"></mat-spinner>
      <lib-flipcard-side 
        side="front"
        [item]="item" 
        [slide]="slide"
        [canEdit]="canEdit"
        [isRtl]="isRtl"
        [autoTextDirection]="!!flipcardFormGroup && isTranslation"
        [autoTextDirectionValue]="item.frontText"
        [ngClass]="'alignment-' + (item.frontAlignment || 'center')"
        (flip)="toggleFlip()"
        (edit)="edit.emit()"
        (remove)="remove.emit()"
        (changeOrientation)="changeOrientation.emit()"
        [textColor]="item.frontTextColor">
        <ng-content select="[drag-handle]" drag-handle></ng-content>
      </lib-flipcard-side>
    </div>
    <div class="tp-box__side tp-box__back d-flex"
      [class.video]="item.backEmbedVideo || item.backVideo"
      [dynamicWidthCoeff]="3"
      [cardBackground]="{ backgroundColor: item.backBackgroundColor, mediaBackground: item.backMediaBackground, backgroundPresigned }">
      <mat-spinner *ngIf="item.backMediaBackground?.uploadingInProgress" class="image-loader" color="primary" [diameter]="20"></mat-spinner>
      <lib-flipcard-side 
        side="back" 
        [item]="item" 
        [slide]="slide"
        [canEdit]="canEdit"
        [isRtl]="isRtl"
        [autoTextDirection]="!!flipcardFormGroup && isTranslation"
        [autoTextDirectionValue]="item.backText"
        [ngClass]="'alignment-' + (item.backAlignment || 'center')"
        (flip)="toggleFlip()"
        (edit)="edit.emit()"
        (remove)="remove.emit()"
        (changeOrientation)="changeOrientation.emit()"
        [textColor]="item.backTextColor">
      </lib-flipcard-side>
    </div>
  </div>
</div>
