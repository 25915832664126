import { OverlayContainer } from '@angular/cdk/overlay';
import { DOCUMENT } from '@angular/common';
import { Component, ElementRef, Inject, Input, OnDestroy } from '@angular/core';
import { SlideDTO } from '@app/model/slideDTO.model';
import { SlideType } from 'library-explorer';
import { ToastContainerDirective, ToastrService } from 'ngx-toastr';
import { fromEvent, merge, Subject } from 'rxjs';
import { debounceTime, delay, filter, takeUntil } from 'rxjs/operators';
import * as screenfull from 'screenfull';
import { Screenfull } from 'screenfull';

@Component({
  selector: 'app-fullscreen-protection-overlay',
  templateUrl: './fullscreen-protection-overlay.component.html',
  styleUrls: ['./fullscreen-protection-overlay.component.scss']
})
export class FullscreenProtectionOverlayComponent implements OnDestroy {
  @Input() public set toastContainer(value: ToastContainerDirective) {
    this.toastrService.overlayContainer = value;
  }

  @Input() public disabled = false;
  @Input() public element: ElementRef;
  @Input() public currentSlide: SlideDTO;
  @Input() public set forceFullScreen(value: boolean) {
    this._forceFullScreen = value;
    this.enableFullscrenProtection();
  }

  public get forceFullScreen() {
    return this._forceFullScreen;
  }

  public screenfull: Screenfull = null;
  public fullscreenInitialized = false;

  private _forceFullScreen = false;

  private devtoolsIntevalId = null;

  private subscriptions: Subject<any> = new Subject<any>();

  constructor(
    @Inject(DOCUMENT) private readonly document: Document,
    private readonly overlayContainer: OverlayContainer,
    private readonly toastrService: ToastrService) { }

  ngOnDestroy(): void {
    this.subscriptions.next();
    this.subscriptions.complete();
    this.toastrService.overlayContainer = null;
    this.updateOverlayContainerPosition(this.document.body);
    clearInterval(this.devtoolsIntevalId);
  }

  public toggleFullscreen(): void {
    if (this.screenfull.isFullscreen) {
      this.screenfull.exit();

      return;
    }

    this.screenfull.request(this.element.nativeElement).then();
  }

  public exitFromFullscreen(): void {
    if (!this.screenfull?.isFullscreen) {
      return;
    }

    this.screenfull.exit();
  }

  private protectDevtools(): void {
    let devtoolsOpen = false;
  
    const detectDevtools = () => {
      const start = performance.now();
      debugger;
      const end = performance.now();
      devtoolsOpen = (end - start) > 100;
  
      if (devtoolsOpen) {
        window.location.href = window.location.origin;
      }
    };
  
    this.devtoolsIntevalId = setInterval(detectDevtools, 1000);
  }

  private enableFullscrenProtection(): void {
    const isPhone = window.matchMedia('(max-width: 768px)').matches;

    if (isPhone || !this._forceFullScreen) {
      return;
    }

    this.protectDevtools();
    this.screenfull = screenfull as Screenfull;

    if (this.screenfull.isEnabled) {
      this.addEventListeners();
      this.toggleFullscreen();
      this.updateOverlayContainerPosition(this.element.nativeElement);
      this.fullscreenInitialized = true;
    }
  }

  private updateOverlayContainerPosition(parent: HTMLElement): void {
    const container = this.overlayContainer.getContainerElement();
    parent.appendChild(container);
  }

  private addEventListeners(): void {
    merge(
      fromEvent(window, 'blur'),
      fromEvent(window, 'keyup'),
      fromEvent(window, 'keydown')
    )
      .pipe(
        filter(() => {
          return this.screenfull.isFullscreen;
        }),
        filter(event => {
          if (document.activeElement !== document.body) {
            const isFrameFocused = document.activeElement instanceof HTMLIFrameElement;
            
            if (this.currentSlide?.bundle === SlideType.SlideEmbeddedDocument) {
              setTimeout(() => {
                (document.activeElement as HTMLIFrameElement).blur();
              }, 10)
            }

            if (isFrameFocused) {
              return false;
            }
          }

          const activeElement = document.activeElement;
          const inputsElements = ['input', 'select', 'textarea'];
          const isInputFocused = activeElement && inputsElements.indexOf(activeElement.tagName.toLowerCase()) !== -1;
          const isBlurEvent = event.type === 'blur';
          const shouldExit = isBlurEvent ? !document.hasFocus() : !isInputFocused;

          return shouldExit;
        }),
        takeUntil(this.subscriptions)
      )
      .subscribe(() => this.exitFromFullscreen());

    fromEvent(window, 'resize')
      .pipe(
        debounceTime(100),
        filter(() => this.screenfull?.isFullscreen === true),
        filter(() => window.outerHeight - window.innerHeight > 1),
        delay(500)
      )
      .subscribe(() => {
        this.exitFromFullscreen();
      });
  }
}
