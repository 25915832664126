import { Directive, Input, OnInit, TemplateRef, ViewContainerRef } from '@angular/core';
import { Capacitor } from '@capacitor/core';

@Directive({
  selector: '[ngNativeAppConditionalVisibility]'
})
export class NativeAppConditionalVisibilityDirective implements OnInit {
  @Input() public ngNativeAppConditionalVisibility = true;

  constructor(private viewContainer: ViewContainerRef, private templateRef: TemplateRef<any>,) { }

  ngOnInit(): void {
    const isNative = Capacitor.isNativePlatform();

    // Determine whether to show or hide the element based on the app's nature
    const shouldShowElement = isNative !== this.ngNativeAppConditionalVisibility; 

    if (shouldShowElement) {
      this.viewContainer.createEmbeddedView(this.templateRef);
      return;
    }

    this.viewContainer.clear();

    return;
  }

}
