import { Component, OnInit } from '@angular/core';
import { SendbirdService } from '@app/services/sendbird.service';
import { ChatMode } from 'src/app/model/enums/chat-mode.enum';

@Component({
  selector: 'app-chat-list-dialog',
  templateUrl: './chat-list-dialog.component.html',
  styleUrls: ['./chat-list-dialog.component.scss']
})
export class ChatListDialogComponent implements OnInit {
  public mode = ChatMode.DIALOG;

  public userHasChannels = false;

  constructor(private readonly sendbirdService: SendbirdService) { }

  ngOnInit() {
  }

  public closeDialog(): void {
    this.sendbirdService.closeChatListDialog();
  }

  public channelsLoaded(hasChannels: boolean): void {
    this.userHasChannels = hasChannels;
  }

}
