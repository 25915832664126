import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatRadioModule } from '@angular/material/radio';
import { MatSliderModule } from '@angular/material/slider';
import { ScaleWidgetComponent } from './components/scale-widget/scale-widget.component';
import { SliderWidgetComponent } from './components/slider-widget/slider-widget.component';
import { SharedLibraryModule } from '../common/shared-library.module';

@NgModule({
  declarations: [
    SliderWidgetComponent,
    ScaleWidgetComponent
  ],
  exports: [
    SliderWidgetComponent,
    ScaleWidgetComponent
  ],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    MatSliderModule,
    MatRadioModule,
    SharedLibraryModule
  ]
})
export class ChaptersWidgetsModule {
}
