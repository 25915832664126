import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { NotificationDelivery } from '@app/model/notification-delivery';

@Component({
  selector: 'app-notification-popup',
  templateUrl: './notification-popup.component.html',
  styleUrls: ['./notification-popup.component.scss']
})
export class NotificationPopupComponent implements OnInit {

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: NotificationDelivery,
    private matDialogRef: MatDialogRef<NotificationPopupComponent>,
  ) { }

  ngOnInit() {
    console.log(this.data)
  }

  close() {
    this.matDialogRef.close();
  }

}
