import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';

import { ProviderType } from '../models';
import { HttpService } from './http.service';
import { NetworkService } from './network.service';

@Injectable({
  providedIn: 'root'
})
export class EmbedVideoUsageService {
  constructor(
    private readonly httpService: HttpService,
    private readonly networkService: NetworkService
  ) { }

  public getVideoProviderUsage(provider: ProviderType): Observable<{ isUsing: boolean }> {
    return (this.networkService.isOnlineValue()) ? this.httpService.get<{ isUsing: boolean }>(`embed-video-usage/${provider}`) : of(null);
  }
}
