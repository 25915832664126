<div class="certificate-received-modal" data-cy="certificate-received-modal">
  <button class="button button--icon button--icon-small button--gray close-btn" mat-fab mat-dialog-close data-cy="certificate-received-modal-close">
    <lib-icon icon="x"></lib-icon>
  </button>

  <div mat-dialog-content class="d-flex flex-column align-items-center certificate-received-modal__content">
    <span class="primary-color mt-2">{{'COMMON.congratulation' | translate}}</span>
    <img class="certificate-received-modal__image" src="assets/icons/certificate.svg" alt="">
    <span class="certificate-received-modal__msg">
      {{certificateMessage}}:
    </span>
    <h2>{{certificate.title}}</h2>
  </div>

  <div mat-dialog-actions class="certificate-received-modal__actions d-flex flex-column-reverse flex-sm-row justify-content-between">
    <button 
      class="button button--large" 
      mat-button 
      (click)="shareOnLinkedIn()"
      data-cy="certificate-received-modal-share">
      <lib-icon icon="share-fat"></lib-icon>
      {{'COMMON.share' | translate}}
    </button>

    <button 
      class="button button--primary button--large"
      mat-button 
      (click)="download()"
      data-cy="certificate-received-modal-download">
      <lib-icon icon="download-simple"></lib-icon>
      {{'CERTIFICATE.download_certificate' | translate}}
    </button>
  </div>
</div>