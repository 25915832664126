import { Injectable } from '@angular/core';
import { HttpService } from 'library-explorer';
import { Observable, of } from 'rxjs';
import { ClassDTO } from '../../model/classDTO.model';
import { catchError, publishReplay, refCount } from 'rxjs/operators';
import { interceptorsToken } from 'library-explorer';
import { HttpParams } from '@angular/common/http';
import { BundleType } from 'library-explorer';
import { NodeModel } from 'library-explorer';
import { ModuleDTO } from 'src/app/model/moduleDTO.model';
import { CourseDTO } from 'src/app/model/courseDTO.model';

@Injectable({
  providedIn: 'root'
})
export class ClassService {
  private cache: any = {};

  constructor(
    private httpService: HttpService
  ) { }

  public clearCache() {
    this.cache = {};
  }

  public getClassByID(courseID: string): Observable<ClassDTO> {
    const params = new HttpParams()
      .set('active', 'true')
      .set('_format', 'json')
      .set('id', courseID)
      .set(interceptorsToken.HANDLE_PERMISSION_DENIED_PARAM, 'true');

    return this.httpService.get<ClassDTO>('node', params);
  }

  public getAllClasses(extraParams: HttpParams = null): Observable<ClassDTO[]> {
    let params = extraParams || new HttpParams();
    params = params
      .set('active', 'true')
      .set(interceptorsToken.HANDLE_PERMISSION_DENIED_PARAM, 'true');

    return this.httpService.get<ClassDTO[]>('classes', params);
  }


  public getSitemap(): Observable<ClassDTO[]> {
    if (!this.cache.siteMap$) {
      this.cache.siteMap$ = this.httpService.get<ClassDTO[]>(
        'classes',
        new HttpParams({ fromObject: { active: true, display: 'site-map' } })
      )
        .pipe(
          publishReplay(1), // cache the latest emitted
          refCount(), // keep the Observable alive as long as there are any Subscribers
          catchError(err => {
            this.cache.siteMap$ = null;
            throw err;
          })
        );
    }

    return this.cache.siteMap$;
  }

  public getLightSitemap(): Observable<ClassDTO[]> {
    const params = new HttpParams({ fromObject: { active: true, display: 'site-map-light' } });

    return this.httpService.get<ClassDTO[]>(`classes`, params);
  }

  public getSitemapByEntityId(id: string): Observable<ClassDTO> {
    const params = new HttpParams({ fromObject: { active: true, display: 'site-map' } });

    return this.httpService.get<ClassDTO>(`content-info/${id}`, params);
  }

  public getEntityFromSitemap<T extends NodeModel>(siteMap: ClassDTO[], id: string): T {
    let item = null;

    const checkChildren = (children: NodeModel[], bundle = BundleType.CLASS) => {
      if (!children) {
        return;
      };

      children.forEach(child => {
        if (child.id === id) {
          item = child;
          Object.assign(item, { bundle });

          return;
        }

        switch (bundle) {
          case BundleType.CLASS:
            const classModel = child as ClassDTO;
            checkChildren(classModel.courses, BundleType.COURSE);
            break;
          case BundleType.COURSE:
            const courseModel = child as CourseDTO;
            checkChildren(courseModel.modules, BundleType.MODULE);
            break;
          case BundleType.MODULE:
            const moduleModel = child as ModuleDTO;
            checkChildren(moduleModel.lessons, BundleType.LESSON);
            break;
        }

      });
    };

    checkChildren(siteMap);

    return item;
  }

  public getStructureById(siteMap: ClassDTO[], id: string, courseId?: string) {
    const structure: any = {
      class: null,
      course: null,
      module: null,
      lesson: null
    };
    classes:
    for (const cl of siteMap) {
      cl.name = cl.title;
      if (cl.id === id) {
        structure.class = cl;
        break;
      }

      for (const course of (cl.courses || [])) {
        course.name = course.title;
        if (courseId && course.id !== courseId) {
          continue;
        }

        if (course.id === id) {
          structure.class = cl;
          structure.course = course;
          break classes;
        }

        for (const module of (course.modules || [])) {
          module.name = module.title;
          if (module.id === id) {
            structure.class = cl;
            structure.course = course;
            structure.module = module;
            break classes;
          }

          for (const lesson of (module.lessons || [])) {
            lesson.name = lesson.title;
            if (lesson.id === id) {
              structure.class = cl;
              structure.course = course;
              structure.module = module;
              structure.lesson = lesson;
              break classes;
            }
          }
        }
      }
    }

    return structure;
  }
}
