import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, of } from 'rxjs';
import { filter, map, mergeMap, tap } from 'rxjs/operators';
import { UploadService } from './upload.service';
import { ProfileUserDTO, UserRole, ImageModel, UploadFileInfo, BundleType, FieldName, EntityTypeId, SettingsProfileFieldType, SettingsAdditionalProfileField } from '../models';
import { HttpService } from './http.service';

@Injectable({
  providedIn: 'root'
})
export class ProfileService {
  private userProfile: BehaviorSubject<ProfileUserDTO | null> = new BehaviorSubject<ProfileUserDTO | null>(null);

  constructor(
    private readonly httpService: HttpService,
    private readonly uploadService: UploadService) { }

  public getCurrentProfileValue(): ProfileUserDTO | null {
    return this.userProfile.value;
  }

  public getCurrentProfile(): Observable<ProfileUserDTO | null> {
    return this.userProfile.asObservable();
  }

  public setCurrentProfile(user: ProfileUserDTO): void {
    this.userProfile.next(user);
  }

  public isCurrentUserIsAdmin(): boolean {
    const profile = this.getCurrentProfileValue();
    if (!profile) {
      return false;
    }

    return profile.role === UserRole.ADMIN;
  }

  public updateProfile(profileDTO: Partial<ProfileUserDTO>): Observable<ProfileUserDTO> {
    const fileObs = this.uploadProfileImage(profileDTO.mediaImage as any)
      .pipe(
        tap((data) => {
          profileDTO.mediaImage = data;
        })
      );

    return fileObs.pipe(
      mergeMap(() => {
        return this.httpService.patch<ProfileUserDTO>('register', profileDTO);
      }),
      tap(user => {
        this.setCurrentProfile(user);
      })
    );
  }

  public uploadProfileImage(data: ImageModel | File): Observable<any> {
    if (!data) {
      return of([]);
    }

    const isFile = data instanceof File;

    if (!isFile) {
      return of(data);
    }

    const fileInfo: UploadFileInfo = {
      bundle: BundleType.USER,
      fieldName: FieldName.MEDIA_IMAGE,
      entityTypeId: EntityTypeId.USER,
      file: data
    };

    return this.uploadService.uploadToAws(fileInfo)
      .pipe(
        filter((res: { completed: boolean, key: string }) => res && res.completed),
        map(res => ({ key: res.key })),
      );
  }

  public mapAdditionalFieldsToRequest(additionalFields: SettingsAdditionalProfileField[], value: {[key: string]: string | Date}): any {
    const fieldsData = additionalFields.map(data => {
      let fieldValue;
      switch (data.type) {
        case SettingsProfileFieldType.URL: {
          fieldValue = value[data.name] && {
            isExternal: true,
            uri: value[data.name]
          };
          break;
        }
        case SettingsProfileFieldType.DATE: {
          if (value[data.name]) {
            // Get iso date taking in count timezone
            // otherwise it might give +1 or -1 days from the actual date.
            const date = new Date(value[data.name]);
            const tzoffset = date.getTimezoneOffset() * 60000;
            fieldValue = new Date(date.getTime() - tzoffset).toISOString().split('T')[0];
          }
          break;
        }
        default: {
          fieldValue = value[data.name];
        }
      }

      return {
        type: data.type,
        name: data.name,
        value: fieldValue
      };
    });

    return fieldsData.filter(item => item.value);
  }
}
