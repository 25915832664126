<section id="dashboard-hightlights" [class.learning-path-active]="(nextRecommendedItem && completedItem) || itemsToLearn?.length">
  <div class="widget-list row px-4 pb-5">
    <div class="col-12 p-0 welcome-text" [style.color]="block.textColor">
      <h1 class="mb-0 mt-5 my-md-5">{{'DASHBOARD_v2.welcome_username' | translate : { user: userName } }}!</h1>
    </div>
    <div class="row widget-list-wrapper">
      <div class="col-12 col-xl-4 px-0 px-md-3 started-courses" [class.empty]="!startedCourseList?.length">
        <div class="title-wrapper">
          <h4 class="title" [style.color]="block.textColor">{{'DASHBOARD_v2.started_courses' | translate}}</h4>
        </div>
        <mat-spinner [diameter]="40" *ngIf="!startedCourseList"></mat-spinner>
        <div class="carousel mt-4" *ngIf="startedCourseList" [class.mx-0]="!startedCourseList.length">
          <app-carousel #startedCoursesCarousel *ngIf="startedCourseList.length; else noStartedCourse" [items]="startedCourseList" [config]="carouselConfig" [extendedCard]="true" type="course"></app-carousel>
        </div>
        <button *ngIf="startedCourseList?.length" mat-flat-button class="button button--secondary button--large" type="button" (click)="continueCourse()">
          <span>{{'DASHBOARD_v2.continue_course' | translate}}</span>
        </button>
      </div>
      <div class="col-12 col-md-6 col-xl-4 px-0 px-md-3 learning-path"
        *ngIf="(nextRecommendedItem && completedItem) || itemsToLearn?.length">
        <div class="title-wrapper">
          <h4 class="title" [style.color]="block.textColor">{{'DASHBOARD_v2.learning_path' | translate}}</h4>
        </div>
        <ng-template #continueWithContent>
          <ng-container *ngIf="completedItem">
            <p class="continue-with">
              {{ ('DASHBOARD_v2.finished_complete_' + completedItem.bundle) | translate}}:
              <a [routerLink]="[completedItemRouterLink]"
                [queryParams]="completedItemQueryParams"
                class="link">{{completedItem.name}}</a>.
              {{'DASHBOARD_v2.we_recommend' | translate}}:</p>
          </ng-container>
        </ng-template>
        <div class="learning-path-wrapper mt-4">
          <ng-scrollbar thumbClass="custom-scrollbars" class="d-none d-md-block" appearance="standard">
            <div class="sections-wrapper">
              <section class="suggested-path mb-3 mb-md-4 mt-0" *ngIf="nextRecommendedItem && completedItem">
                <div class="header-wrapper pt-0">
                  <h4>{{'DASHBOARD_v2.suggested_path' | translate}}</h4>
                </div>
                <div class="item-list">
                  <ng-container *ngTemplateOutlet="continueWithContent"></ng-container>
                  <app-dashboard-item
                    [item]="nextRecommendedItem"
                    [progressShow]="false"
                    [itemCountLabel]="recommended && recommended.text | translate : { count: recommended?.count }"
                    [startButton]="true">
                  </app-dashboard-item>
                </div>
              </section>
              <section class="to-learn my-0 d-none d-md-block" *ngIf="itemsToLearn?.length">
                <div class="header-wrapper">
                  <h4>{{'DASHBOARD_v2.lessons_to_resume' | translate}}</h4>
                </div>
                <div class="item-list">
                  <app-dashboard-item
                    *ngFor="let item of itemsToLearn"
                    [chaptersLeft]="true"
                    [item]="item">
                  </app-dashboard-item>
                </div>
              </section>
            </div>
          </ng-scrollbar>
          <div class="d-block d-md-none sections-wrapper">
            <div class="header-wrapper pt-0">
              <h4>{{'DASHBOARD_v2.suggested_path' | translate}}</h4>
            </div>
            <ng-container *ngTemplateOutlet="continueWithContent"></ng-container>
            <app-carousel
              type="dashboard-item"
              [items]="learningPathCombined"
              [config]="carouselConfig"></app-carousel>
          </div>
        </div>
      </div>
      <div class="col-12 col-md-6 col-xl-4 px-0 px-md-3 explore" *ngIf="exploreItemList?.length">
        <div class="title-wrapper">
          <h4 class="title" [style.color]="block.textColor">{{'DASHBOARD_v2.explore_courses' | translate}}</h4>
          <mat-spinner [diameter]="20" *ngIf="!exploreItemList"></mat-spinner>
          <mat-form-field class="explore-field" *ngIf="exploreItemList?.length > 1">
            <mat-select [style.color]="block.textColor" [class.inherit-color]="block.textColor" [disableRipple]="true" [(ngModel)]="selectedItemId">
              <mat-option value="">{{'DASHBOARD_v2.all_courses' | translate}}</mat-option>
              <mat-option *ngFor="let item of exploreItemList" [value]="item.id">
                {{item.name}}
              </mat-option>
            </mat-select>
          </mat-form-field>
        </div>
        <app-dashboard-item-list class="mt-4" [skipModule]="skipModule" [selectedId]="selectedItemId" [carouselConfig]="carouselConfig"></app-dashboard-item-list>
      </div>
    </div>
  </div>
</section>

<ng-template #noStartedCourse>
  <a [routerLink]="['/classes']" class="no-started-course">
    <div class="discover">
      <h1>{{'DASHBOARD_v2.discover_courses' | translate}}</h1>
      <div class="info-box rounded primary-background">
        <mat-icon>arrow_forward_ios</mat-icon>
      </div>
    </div>
    <img src="/assets/images/computer-illustration.png" alt="Courses">
  </a>
</ng-template>
